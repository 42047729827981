@charset 'utf-8';

@import '../variable';

/* icon
------------------------------------------------ */
.icon {
	&-news {
		display: inline-block;
		border-radius: 15px;
		text-align: center;
		vertical-align: top;
		font-size: 12px;
		font-style: normal;
		&--news { background: #0168b7; color: #fff; }
		&--exhibition { background: #f6f033; }
		&--update { background: #404040; color: #fff; }
		&--mailmagazine { background: #000; color: #fff; }
		@include device_sp {
			padding: 3px 0;
		}
		@include device_pc {
			font-size: 16px;
		}
	}
	&-new {
		display: inline-block;
		min-width: 38px;
		height: 15px;
		background: #aa111d;
		border-radius: 8px;
		text-align: center;
		vertical-align: top;
		font-size: 10.5px;
		font-weight: 700;
		font-style: normal;
		line-height: 15px;
		color: #fff;
		@include device_pc {
			min-width: 50px;
			height: 20px;
			border-radius: 10px;
			font-size: 14px;
			line-height: 20px;
		}
	}
	&-form {
		display: inline-block;
		min-width: 54px;
		padding: 2px 0 3px;
		text-align: center;
		vertical-align: top;
		font-family: $fontMin;
		font-size: 14px;
		font-style: normal;
		line-height: 1.2;
		color: #fff;
		&--required {
			background: #b4161f;
		}
		&--any {
			background: #0068b7;
		}
	}
	&-pdf {
		display: inline-block;
		position: relative;
		width: 17px;
		height: 20px;
		vertical-align: top;
		text-indent: -9999px;
		white-space: nowrap;
		overflow: hidden;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/icon/pdf1.png) no-repeat center center;
			background-size: 100%;
		}
	}
	&-blank {
		position: absolute;
		top: 0;
		right: 0;
		width: 20px;
		height: 20px;
		vertical-align: top;
		text-indent: -9999px;
		white-space: nowrap;
		@include device_pc {
			width: 25px;
			height: 25px;
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/icon/blank1.png) no-repeat center center;
			background-size: 100%;
		}
	}
}
