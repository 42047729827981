@charset 'utf-8';

@import '../variable';

/* seminar
------------------------------------------------ */
.seminar {
	&-content {
		@include device_sp {
			padding: 0 20px;
		}
	}
	/* single */
	&-single {
		position: relative;
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/seminar/title_bg.jpg);
					font-size: 26px;
				}
				@include device_pc {
					background-image: url(../img/seminar/title_bg-pc.jpg);
				}
			}
		}
		.style {
			&1 {
				&-flow {
					&-btn {
						margin-bottom: 40px;
						@include device_pc {
							margin-bottom: 80px;
						}
					}
				}
			}
		}
	}
	/* stream */
	&-stream {
		position: relative;
		margin-bottom: 36px;
		@include device_pc {
			display: flex;
			margin-bottom: 44px;
		}
		&-video {
			position: relative;
			z-index: 1;
			@include device_pc {
				width: 73.0833333333%;
			}
			input {
				margin-top: 10px;
				padding: 10px;
				font-size: 16px;
				&[type='button'] {
					margin-left: 10px;
					cursor: pointer;
				}
			}
			& > div {
				position: relative;
				padding-bottom: 65.0746268657%;
				width: 100%;
				height: 0;
				overflow: hidden;
				& > div {
					position: absolute !important;
					top: 0;
					left: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100% !important;
				height: 100% !important;
			}
			.eqp {
				&-minimum {
					position: absolute !important;
					top: 0;
					left: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
		}
		&-chat {
			position: relative;
			padding-bottom: 135.52238806%;
			height: 0;
			overflow: hidden;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
			}
			@include device_sp {
				margin: 20px 28px 0;
			}
			@include device_pc {
				margin-left: 2.5833333334%;
				padding-bottom: 39.5%;
				width: 24.3333333333%;
			}
		}
	}
	/* detail */
	&-detail {
		&-hd {
			position: relative;
			margin-bottom: 20px;
			@include device_pc {
				margin-bottom: 50px;
			}
		}
		&-title {
			padding-bottom: 10px;
			border-bottom: solid #ccc 2px;
			font-size: 21px;
			font-weight: 400;
			line-height: 1.47619047619;
			color: #0168b7;
			@include device_pc {
				padding-bottom: 8px;
				font-size: 42px;
			}
		}
		&-content {
			font-size: 14px;
			line-height: 1.42857142857;
			color: #0168B7;
			p {
				& + & {
					margin-top: 1.42857142857em;
				}
			}
			h2 {
				margin-bottom: 20px;
				font-size: 24px;
				font-weight: 500;
				line-height: 1.5;
				@include device_pc {
					font-size: 28px;
				}
			}
		}
		&-ft {
			position: relative;
		}
	}
	/* related */
	&-related {
		position: relative;
		margin-top: 40px;
		@include device_pc {
			margin-top: 80px;
		}
		&-title {
			margin-bottom: 18px;
			font-size: 24px;
			font-weight: 500;
			line-height: 1.5;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 44px;
				font-size: 42px;
			}
		}
		&-lists {
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&--item {
				position: relative;
				& > a {
					display: block;
					position: relative;
				}
				@include device_sp {
					& + & {
						margin-top: 40px;
					}
				}
				@include device_pc {
					margin: 8.12499999995% 8.12499999995% 0 0;
					width: 27.9166666667%;
					&:nth-of-type(-n+3) { margin-top: 0; }
					&:nth-of-type(3n) { margin-right: 0; }
				}
			}
			&--photo {
				position: relative;
				margin-bottom: 18px;
				border: 1px solid #ccc;
				padding-bottom: 71.6417910448%;
				width: 100%;
				height: 0;
				overflow: hidden;
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
					height: 100%;
					max-width: inherit;
					object-fit: cover;
				}
			}
			&__info {
				position: relative;
				color: #0168B7;
			}
			&--title {
				text-align: center;
				font-size: 18px;
				font-weight: 500;
				line-height: 1.5;
			}
			&--btn {
				margin: 18px 20px 0;
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 1.2;
				& > span {
					display: block;
					position: relative;
					padding: 20px 0;
					background-color: #0168B7;
					border-radius: 32px;
					color: #fff;
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: 20px;
						margin-top: -8px;
						width: 10px;
						height: 16px;
						background: url(../img/icon/arrow6.png) no-repeat center center;
						background-size: 100%;
					}
				}
			}
		}
	}
}
