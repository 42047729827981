@charset 'utf-8';

@import '../variable';

/* search
------------------------------------------------ */
.search {
	/* top */
	&-top {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
	}
	/* result */
	&-result {
		@include device_sp {
			margin: 0 20px;
		}
		&-num {
			margin-bottom: 25px;
			font-size: 14px;
			font-weight: 700;
			line-height: 1.2;
		}
		&-lists {
			&--item {
				display: flex;
				align-items: flex-start;
				position: relative;
				margin-top: 35px;
				&:first-child {
					margin-top: 0;
				}
				@include device_pc {
					margin-top: 50px;
				}
			}
			&--photo {
				position: relative;
				width: 27.5297619048%;
				border: solid #ccc 1px;
				@include device_pc {
					width: 20.4166666667%;
				}
				& > a {
					display: block;
					width: 100%;
				}
				& + .search-result-lists__info {
					margin-left: 5.5059523809%;
					width: 66.9642857143%;
					@include device_pc {
						margin-left: 4.25%;
						width: 75.3333333333%;
					}
				}
			}
			&__info {
				position: relative;
				width: 100%;
			}
			&--title {
				margin-bottom: 15px;
				padding-bottom: 10px;
				border-bottom: solid #c6dbec 3px;
				font-size: 19px;
				font-weight: 500;
				line-height: 1.26315789474;
				@include device_pc {
					margin-bottom: 25px;
					padding-bottom: 12px;
					font-size: 26px;
				}
				& > a {
					display: inline-block;
					vertical-align: top;
					color: #0168b7;
				}
			}
			&--folder {
				margin-bottom: 15px;
				font-size: 14px;
				line-height: 1.2;
				color: #0168b7;
				@include device_pc {
					margin-bottom: 25px;
					font-size: 15px;
				}
			}
			&--excerpt {
				font-size: 14px;
				line-height: 1.7;
				@include device_pc {
					font-size: 15px;
				}
			}
		}
	}
}
