@charset 'utf-8';

@import '../variable';

/* contact
------------------------------------------------ */
.contact {
	&__btns {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		&--item {
			& > a {
				display: block;
				position: relative;
				padding: 30px 0 35px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: cover;
				text-align: center;
				color: #fff;
			}
		}
		&--contract {
			& > a { background-image: url(../img/contact/btn_contract.jpg); }
		}
		&--target {
			& > a { background-image: url(../img/contact/btn_target.jpg); }
		}
		&--leo {
			& > a { background-image: url(../img/contact/btn_leo.jpg); }
		}
		&--recruit {
			& > a { background-image: url(../img/contact/btn_recruit.jpg); }
		}
		&--english {
			& > a { background: #3284c4; }
		}
		&--title {
			font-family: $fontMin;
			font-size: 28px;
			font-weight: 700;
			line-height: 1.2;
		}
		&--text {
			margin-top: 10px;
			font-size: 14px;
		}
		&--btn {
			position: relative;
			margin: 24px auto 0;
			width: 77.8947368421%;
			font-size: 18px;
			line-height: 1.2;
			span {
				display: block;
				position: relative;
				padding: 18px 0;
				width: 100%;
				background: #0168b7;
				border-radius: 28px;
				color: #fff;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 16px;
					margin-top: -8px;
					width: 9px;
					height: 15px;
					background: url(../img/icon/arrow3.png) no-repeat center center;
					background-size: 100%;
				}
			}
		}
		@include device_pc {
			&--item {
				margin: 2.5% 2.5% 0 0;
				width: 31.6666666667%;
				&:nth-of-type(-n+3) { margin-top: 0; }
				&:nth-of-type(3n) { margin-right: 0; }
			}
		}
		@media screen and (max-width: 1000px) and (min-width: 769px) {
			&--item {
				margin: 4% 4% 0 0;
				width: 48%;
				&:nth-of-type(2n) { margin-right: 0; }
				&:nth-of-type(3n) { margin-top: 4%; }
			}
		}
		@include device_sp {
			display: block;
			margin: 0 20px;
			&--item {
				margin-top: 24px;
				&:first-child { margin-top: 0; }
				& > a {
					padding: 28px 0 30px;
				}
			}
			&--title {
				font-size: 24px;
			}
			&--text {
				margin-top: 10px;
				font-size: 12px;
			}
			&--btn {
				margin-top: 22px;
				width: 77.6119402985%;
				font-size: 16px;
				span {
					padding: 16px 0;
					&:after {
						right: 15px;
						margin-top: -6px;
						width: 7px;
						height: 13px;
					}
				}
			}
		}
	}
	/* top */
	&-top {
		&__btns {
			position: relative;
		}
		&__other {
			position: relative;
			margin: 90px 0 2px;
			.wrap {
				display: flex;
			}
			&__box {
				padding: 86px 0 90px;
				width: 50%;
			}
			.contact {
				&__btns {
					&--item {
					}
				}
			}
		}
		@include device_pc {
			&__other {
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 0;
					width: 50%;
					height: 100%;
					z-index: -1;
				}
				&:before {
					left: 0;
					background: #e5f0f8;
				}
				&:after {
					right: 0;
					background: #f8fbfd;
				}
				.contact {
					&__btns {
						&--item {
							width: 63.3333333333%;
						}
					}
				}
			}
		}
		@media screen and (max-width: 1000px) {
			&__other {
				&:before,
				&:after {
					display: none;
				}
				.wrap {
					display: block;
				}
				&__box {
					padding: 40px 0;
					width: auto;
				}
			}
		}
		@include device_sp {
			&__other {
				margin-top: 42px;
				padding-bottom: 48px;
				&__box {
					padding: 0;
					margin-top: 44px;
					&:first-child { margin-top: 0; }
				}
			}
		}
	}
	/* inq */
	&-inq {
		position: relative;
		padding-bottom: 96px;
		&__box {
		}
		&__flow {
			display: flex;
			justify-content: center;
			margin-bottom: 86px;
			&--item {
				position: relative;
				width: 33.333333%;
				padding-top: 90px;
				text-align: center;
				&:after {
					content: '';
					position: absolute;
					top: 40px;
					width: 100%;
					height: 1px;
					background: #404040;
					z-index: -1;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				&.active {
					.num {
						background: #404040;
						color: #fff;
					}
					.text {
						color: inherit;
					}
				}
				.num {
					position: absolute;
					top: 0;
					left: 50%;
					margin-left: -40px;
					width: 80px;
					height: 80px;
					background: #fff;
					border: solid #404040 1px;
					border-radius: 50%;
					font-size: 38px;
					line-height: 78px;
				}
				.text {
					font-size: 20px;
					color: #a5a5a5;
				}
			}
		}
		&__form {
			input,
			textarea,
			select,
			button {
				font-family: inherit;
				font-size: 16px;
			}
			input[type=text],
			input[type=email],
			input[type=tel],
			input[type=password],
			input[type=submit],
			textarea,
			button {
				appearance: none;
				background: none;
				border: none;
				border-radius: 0;
			}
			input[type=text],
			input[type=email],
			input[type=tel],
			input[type=password],
			input[type=submit],
			textarea {
				padding: 14px 16px;
				width: 100%;
				height: 40px;
				background: #fff;
				border: solid #ccc 1px;
			}
			textarea {
				height: 155px;
			}
			label {
				display: inline-block;
				position: relative;
				padding-left: 30px;
				vertical-align: baseline;
				input[type=checkbox],
				input[type=radio] {
					position: absolute;
					top: 7px;
					left: 0;
				}
			}
			.label {
				&-note {
					display: inline-block;
					padding-left: 30px;
					vertical-align: baseline;
					white-space: nowrap;
					&:first-child { padding-left: 0; }
				}
			}
			&__parts {
				display: flex;
				flex-wrap: wrap;
				margin-top: 12px;
				&:first-child { margin-top: 0; }
				&--item {
					&.w100 { width: 100%; }
					&.w48 { margin-right: 2.5165562914%; width: 48.7417218543%; }
					&.w10 { width: 10.0662251656%; min-width: 76px; }
					&:last-child { margin-right: 0; }
					.head {
						display: inline-block;
						padding-right: 26px;
						width: 74px;
						text-align: right;
						vertical-align: top;
						line-height: 40px;
						& + input {
							width: calc(100% - 74px);
						}
					}
				}
				&--btn {
					margin-left: 15px;
					max-width: 130px;
					a,
					button {
						display: block;
						padding: 10px 4px;
						width: 100%;
						background: #eee;
						border: solid #ccc 1px;
						border-radius: 5px;
						vertical-align: top;
						font-size: 12px;
						cursor: pointer;
					}
				}
				.zip,
				.hyphen {
					display: inline-block;
					vertical-align: top;
					font-size: 14px;
					line-height: 40px;
				}
				.zip {
					margin-right: 4px;
				}
				.hyphen {
					margin: 0 4px;
				}
				.subtitle {
					margin-bottom: 20px;
					width: 100%;
					font-size: 22px;
					font-weight: 700;
					color: #0068b7;
				}
				.multi {
					display: flex;
					position: relative;
					width: 100%;
					&--line {
						&--2 {
							.multi--box {
								margin-right: 2%;
								width: 49%;
								&:nth-of-type(2n) { margin-right: 0; }
							}
						}
						&--3 {
							.multi--box {
								margin-right: 2%;
								width: 32%;
								&:nth-of-type(3n) { margin-right: 0; }
							}
						}
					}
					&--box {
						dt {
							margin-bottom: 24px;
							font-size: 18px;
							font-weight: 700;
						}
						dd {
						}
					}
				}
				.horizontal {
					display: flex;
					flex-wrap: wrap;
					position: relative;
					width: 100%;
					li {
						position: relative;
						&.other {
							display: flex;
							align-items: center;
							label {
								width: 106px;
								& + input {
									width: calc(100% - 106px);
								}
							}
						}
					}
					&--line {
						&--liquid {
							margin-top: -18px;
							width: 100%;
							li {
								margin: 18px 18px 0 0;
							}
						}
						&--1 {
							display: block;
							li {
								margin-top: 24px;
								&:first-child { margin-top: 0; }
							}
						}
						&--2 {
							li {
								margin-top: 24px;
								width: 50%;
								&:nth-of-type(-n+2) { margin-top: 0; }
							}
						}
					}
				}
			}
			&--text {
				margin-bottom: 54px;
				font-size: 18px;
				.required {
					color: #b4161f;
				}
			}
			&__lists {
				&--item {
					display: flex;
					border-top: solid #e4e4e4 1px;
					border-bottom: solid #e7e7e7 1px;
				}
				&--title {
					position: relative;
					padding: 36px 90px 36px 30px;
					width: 386px;
					background: #f8fbfd;
					font-size: 22px;
					.icon-form {
						position: absolute;
						top: 46px;
						right: 25px;
					}
				}
				&__content {
					padding: 46px 30px;
					width: calc(100% - 386px);
					font-size: 16px;
					.confirm {
						&--list {
							li {
								margin-top: 24px;
								&:first-child { margin-top: 0; }
							}
						}
					}
				}
			}
			&__agree {
				margin-top: 50px;
				text-align: center;
				&--text {
					font-size: 18px;
					a {
						text-decoration: underline;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
				&--check {
					display: flex;
					justify-content: center;
					align-items: center;
					margin-top: 30px;
					label {
						margin-right: 15px;
						font-size: 16px;
					}
				}
			}
			&__btns {
				display: flex;
				justify-content: center;
				margin-top: 46px;
				&--item {
					position: relative;
					margin: 0 10px;
					width: 300px;
					text-align: center;
					a,
					button,
					input[type=submit] {
						display: block;
						position: relative;
						padding: 10px 0;
						width: 100%;
						border: solid #404040 1px;
						border-radius: 25px;
						font-size: 18px;
						cursor: pointer;
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							margin-top: -6px;
							width: 7px;
							height: 13px;
							background-position: center center;
							background-repeat: no-repeat;
							background-size: 100%;
							vertical-align: top;
						}
					}
				}
				&--submit {
					a,
					button,
					input[type=submit] {
						background: #404040;
						color: #fff;
						&:after {
							right: 25px;
							background-image: url(../img/icon/arrow3.png);
						}
					}
				}
				&--edit {
					a,
					button,
					input[type=submit] {
						background: #fff;
						&:after {
							left: 25px;
							transform: rotate(180deg);
							background-image: url(../img/icon/arrow5.png);
						}
					}
				}
			}
		}
		&__complete {
			&--title {
				margin-bottom: 64px;
				text-align: center;
				font-size: 36px;
				font-weight: 700;
				line-height: 1.2;
			}
			&--lead {
				text-align: center;
				font-size: 18px;
			}
		}
		@media screen and (max-width: 840px) {
			&__form {
				&__lists {
					&--title {
						width: 300px;
					}
					&__content {
						width: calc(100% - 300px);
					}
				}
			}
		}
		@include device_sp {
			padding-bottom: 48px;
			&__flow {
				margin-bottom: 30px;
				&--item {
					padding-top: 55px;
					&:after {
						top: 25px;
					}
					.num {
						margin-left: -25px;
						width: 50px;
						height: 50px;
						font-size: 26px;
						line-height: 48px;
					}
					.text {
						font-size: 14px;
						line-height: 1.4;
					}
				}
			}
			&__form {
				margin: 0 20px;
				input,
				textarea,
				select,
				button {
					font-size: 15px;
				}
				input[type=text],
				input[type=email],
				input[type=tel],
				input[type=password],
				input[type=submit],
				textarea {
					padding: 8px 10px;
					height: 40px;
				}
				textarea {
					height: 135px;
				}
				label {
					padding-left: 24px;
				}
				.label {
					&-note {
						display: block;
						margin-top: 10px;
						padding-left: 24px;
					}
				}
				&__parts {
					&--item {
						.head {
							display: block;
							padding: 0 0 8px;
							width: auto;
							text-align: left;
							line-height: 1.2;
							& + input {
								width: 100%;
							}
						}
					}
					&--btn {
						margin-left: 14px;
					}
					.zip {
						margin-right: 2px;
					}
					.hyphen {
						margin: 0 2px;
					}
					.multi {
						display: block;
						&--line {
							&--2,
							&--3 {
								.multi--box {
									margin-right: 0;
									width: auto;
								}
							}
						}
						&--box {
							margin-top: 25px;
							&:first-child { margin-top: 0; }
							dt {
								margin-bottom: 20px;
								font-size: 19px;
							}
						}
					}
					.horizontal {
						li {
							&.other {
								display: block;
								label {
									width: auto;
									& + input {
										margin-top: 10px;
										width: 100%;
									}
								}
							}
						}
						&--line {
							&--1 {
								li {
									margin-top: 20px;
								}
							}
							&--2 {
								li {
									margin-top: 20px;
								}
							}
							&--sp {
								&--2 {
									display: flex;
									li {
										width: 50%;
										&:nth-of-type(-n+2) { margin-top: 0; }
									}
								}
							}
						}
					}
				}
				&--text {
					margin-bottom: 25px;
					font-size: 15px;
				}
				&__lists {
					&--item {
						display: block;
						padding: 28px 0;
						border-top: none;
						border-bottom: solid #ccc 1px;
						&:first-child { border-top: solid #ccc 1px; }
					}
					&--title {
						padding: 0;
						width: auto;
						background: none;
						font-size: 21px;
						.icon-form {
							position: relative;
							top: auto;
							right: auto;
							margin: 6px 0 0 10px;
						}
					}
					&__content {
						margin-top: 16px;
						padding: 0;
						width: auto;
						font-size: 15px;
						.confirm {
							&--list {
								li {
									margin-top: 12px;
								}
							}
						}
					}
				}
				&__agree {
					margin-top: 45px;
					&--text {
						font-size: 16px;
					}
					&--check {
						margin-top: 20px;
						label {
							font-size: 15px;
						}
					}
				}
				&__btns {
					display: block;
					margin-top: 45px;
					&--item {
						margin: 10px 0 0;
						width: 100%;
						&:first-child { margin-top: 0; }
						a,
						button,
						input[type=submit] {
							padding: 12px 0;
							font-size: 16px;
						}
					}
				}
			}
			&__complete {
				&--title {
					margin-bottom: 28px;
					font-size: 24px;
				}
				&--lead {
					margin: 0 20px;
					font-size: 15px;
				}
			}
		}
	}
}
