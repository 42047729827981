@charset 'utf-8';

@import '../variable';

/* news
------------------------------------------------ */
.news {
	/* top */
	&-top {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
	}
	/* lists */
	&-lists {
		@include device_sp {
			.title--5 {
				margin: 0 20px;
			}
			.pager-1 {
				margin-right: 20px;
				margin-left: 20px;
			}
		}
	}
	/* nav */
	&-nav {
		margin: 12px 20px 0;
		@include device_pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 30px 0;
		}
		&-tab {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 20px;
			&--item {
				margin: 0 .29850746268% .29850746268% 0;
				text-align: center;
				font-size: 16px;
				line-height: 1.2;
				&:last-child { margin-right: 0; }
				@include device_sp {
					width: 24.776119403%;
				}
				@include device_pc {
					margin: 0 2px 2px 0;
					min-width: 200px;
					font-size: 24px;
				}
				a {
					display: block;
					padding: 10px 2px;
					border: solid #0168b7 1px;
					color: #0168b7;
					@include device_pc {
						padding: 14px 2px;
					}
					&.active {
						background: #0168b7;
						color: #fff;
					}
				}
			}
		}
		&-archive {
			margin-left: auto;
			width: 150px;
			height: 40px;
			@include device_pc {
				width: 300px;
				height: 58px;
			}
			select {
				padding: 0 10px;
				width: 100%;
				height: 100%;
				font-family: inherit;
				font-size: 15px;
				@include device_pc {
					padding: 0 20px;
					font-size: 24px;
				}
			}
		}
	}
	/* single */
	&-single {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
	}
	/* detail */
	&-detail {
		@include device_sp {
			margin: 0 20px;
		}
		&-hd {
			&__info {
				display: flex;
				align-items: center;
				margin-top: 30px;
				@include device_pc {
					margin-top: 40px;
				}
			}
		}
		&-title {
			border-bottom: solid #ccc 2px;
			font-size: 21px;
			font-weight: 400;
			color: #0168b7;
			@include device_sp {
				padding-bottom: 12px;
			}
			@include device_pc {
				font-size: 42px;
			}
		}
		&-cat {
			margin-right: 12px;
			@include device_pc {
				margin-right: 20px;
			}
			.icon-news {
				padding: 2px 10px 4px;
				@include device_pc {
					padding: 2px 14px;
				}
			}
		}
		&-date {
			font-size: 15px;
			font-weight: 600;
			color: #0168b7;
			@include device_pc {
				font-size: 18px;
			}
		}
		&-content {
			margin-top: 18px;
			font-size: 14px;
			line-height: 1.625;
			@include device_pc {
				margin-top: 36px;
				font-size: 16px;
			}
			p {
				& + p {
					margin-top: 1.4em;
					@include device_pc {
						margin-top: 1.6em;
					}
				}
			}
		}
		&-ft {
		}
	}
}
