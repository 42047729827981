@charset 'utf-8';

@import '../variable';

/* main
------------------------------------------------ */
#main {
	display: block;
	position: relative;
	width: 100%;
}

/* space
------------------------------------------------ */
.mt {
	&--0 { margin-top: 0 !important; }
	@for $i from 1 through 20 {
		&--#{$i * 5} { margin-top: #{$i * 5}px !important; }
	}
}

.pt {
	&--0 { padding-top: 0 !important; }
	@for $i from 1 through 20 {
		&--#{$i * 5} { padding-top: #{$i * 5}px !important; }
	}
}

.align {
	&--left { text-align: left; }
	&--center { text-align: center; }
	&--right { text-align: right; }
}

/* responsive */
$breakpoint: (
	'sp': 'screen and (max-width: #{$mediaSP})',
	'pc': 'screen and (min-width: #{$mediaPC})',
) !default;

@mixin _responsive($class, $bp: $breakpoint) {
	#{$class} {
		@content;
	}
	@each $suffix, $value in $bp {
		@media #{$value} {
			#{$class}-#{$suffix} {
				@content;
			}
		}
	}
}

$space-min: 1;
$space-max: 60;

@for $i from $space-min through $space-max {
	@include _responsive('.mt-#{$i}') {
		margin-top: #{$i}px;
	}
	@include _responsive('.mr-#{$i}') {
		margin-right: #{$i}px !important;
	}
	@include _responsive('.mb-#{$i}') {
		margin-bottom: #{$i}px !important;
	}
	@include _responsive('.ml-#{$i}') {
		margin-left: #{$i}px !important;
	}
}

@for $i from $space-min through $space-max {
	@include _responsive('.pt-#{$i}') {
		padding-top: #{$i}px;
	}
	@include _responsive('.pr-#{$i}') {
		padding-right: #{$i}px !important;
	}
	@include _responsive('.pb-#{$i}') {
		padding-bottom: #{$i}px !important;
	}
	@include _responsive('.pl-#{$i}') {
		padding-left: #{$i}px !important;
	}
}

/* device switch
------------------------------------------------ */
.visible {
	&-sp {
		@include device_sp {
			display: inherit !important;
		}
		@include device_pc {
			display: none !important;
		}
	}
	&-pc {
		@include device_sp {
			display: none !important;
		}
		@include device_pc {
			display: inherit !important;
		}
	}
}

/* topicpath
------------------------------------------------ */
.topicpath {
	position: relative;
	@include device_sp {
		margin: 0 20px 35px;
	}
	@include device_pc {
		margin-bottom: 52px;
	}
	&-lists {
		display: flex;
		flex-wrap: wrap;
		&-item {
			position: relative;
			font-size: 14px;
			color: #636363;
			& > a {
				display: inline-block;
				vertical-align: top;
				color: #0a4a90;
				&:after {
					content: '>';
					padding: 0 .4em;
				}
			}
		}
	}
}

/* title
------------------------------------------------ */
.title {
	&--page {
		position: relative;
		margin-bottom: 5px;
		padding: 38px 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-color: #e5f0f8;
		text-align: center;
		font-size: 36px;
		font-weight: 400;
		line-height: 1.2;
		color: #0168b7;
		.min {
			display: block;
			margin-top: 6px;
			font-size: 14px;
		}
		@include device_pc {
			margin-bottom: 16px;
			padding: 75px 0;
			font-size: 72px;
			.min {
				margin-top: 10px;
				font-size: 28px;
			}
		}
	}
	&--image {
		position: relative;
		margin: 0 auto 42px;
		padding-top: 60px;
		height: 225px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		font-family: $fontMin;
		font-size: 30px;
		font-weight: 700;
		color: #fff;
		@include device_pc {
			margin-bottom: 80px;
			padding-top: 130px;
			max-width: 1200px;
			height: 450px;
			font-size: 60px;
		}
	}
	&--ribbon {
		position: relative;
		margin-bottom: 4px;
		height: 225px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		@include device_pc {
			margin-bottom: 14px;
			height: 500px;
		}
		&--name {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			padding-top: 24px;
			width: 320px;
			height: 100px;
			background: url(../img/icon/title_ribbon1.png) no-repeat center center;
			background-size: 100%;
			text-align: center;
			font-family: $fontMin;
			font-size: 36px;
			font-weight: 700;
			line-height: 1.2;
			color: #fff;
			@include device_pc {
				padding-top: 55px;
				width: 712px;
				height: 220px;
				font-size: 80px;
			}
		}
	}
	&--1 {
		position: relative;
		margin-bottom: 20px;
		text-align: center;
		font-family: $fontMin;
		font-weight: 700;
		line-height: 1.2;
		&--inline {
			display: inline-block;
			position: relative;
			text-align: right;
			vertical-align: top;
		}
		.min,
		.normal {
			display: block;
			position: relative;
		}
		.min {
			font-size: 18px;
			color: #0168b7;
			& + .normal {
				margin-top: -14px;
			}
		}
		.normal {
			font-size: 36px;
			.l {
				font-size: 48px;
			}
			& + .min {
				margin-top: -5px;
			}
		}
		@include device_pc {
			.min {
				font-size: 30px;
				& + .normal {
					margin-top: -28px;
				}
			}
			.normal {
				font-size: 60px;
				.l {
					font-size: 80px;
				}
				& + .min {
					margin-top: -10px;
				}
			}
		}
	}
	&--2 {
		text-align: center;
		font-family: $fontMin;
		font-size: 36px;
		font-weight: 700;
		line-height: 1.2;
		.min {
			font-size: 26px;
		}
		@include device_pc {
			font-size: 60px;
			.min {
				font-size: 40px;
			}
		}
	}
	&--3 {
		position: relative;
		margin-bottom: 40px;
		text-align: center;
		font-family: $fontMin;
		font-size: 10px;
		line-height: 1.2;
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 50%;
			background: #404040;
			width: 25px;
			height: 2px;
		}
		&:before {
			top: 0;
			transform: rotate(-45deg);
			margin-left: 25px;
		}
		&:after {
			bottom: -18px;
			transform: rotate(-45deg);
			margin-left: -50px;
		}
		.en,
		.ja {
			display: block;
		}
		.en {
			font-size: 36px;
			font-weight: 700;
		}
		.ja {
			margin-top: -2px;
			font-size: 14.4px;
			font-weight: 400;
		}
		@include device_pc {
			margin-bottom: 74px;
			&:before,
			&:after {
				width: 40px;
			}
			&:before {
				margin-left: 35px;
			}
			&:after {
				bottom: -24px;
				margin-left: -90px;
			}
			.en {
				font-size: 60px;
			}
			.ja {
				font-size: 24px;
			}
		}
	}
	&--4 {
		position: relative;
		margin-bottom: 24px;
		text-align: center;
		font-family: $fontMin;
		font-size: 26px;
		font-weight: 700;
		line-height: 1.2;
		color: #0168b7;
		&:after {
			content: '';
			display: block;
			margin: 12px auto 0;
			width: 30px;
			height: 3px;
			background: #0068b7;
		}
		@include device_pc {
			margin-bottom: 58px;
			font-size: 52px;
			&:after {
				margin-top: 24px;
				width: 60px;
				height: 6px;
			}
		}
	}
	&--5 {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		color: #0168b7;
		@include device_pc {
			font-size: 52px;
		}
		.l,
		.min {
			display: inline-block;
			vertical-align: middle;
		}
		.l {
			margin-right: 16px;
			padding: 15px 0;
			font-weight: 400;
			@include device_pc {
				margin-right: 25px;
				padding: 24px 0;
			}
		}
		.min {
			font-size: 12px;
			@include device_pc {
				font-size: 18px;
			}
		}
	}
	&--6 {
		position: relative;
		margin-bottom: 35px;
		padding-bottom: 16px;
		border-bottom: solid #a5a5a5 1px;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		@include device_pc {
			margin-bottom: 80px;
			border-bottom-width: 2px;
			font-size: 36px;
		}
		&:before,
		&:after {
			content: '';
			bottom: -1px;
			position: absolute;
			@include device_pc {
				bottom: -2px;
				height: 2px;
			}
		}
		&:before {
			left: 95px;
			width: 10px;
			background: #fff;
			@include device_pc {
				left: 280px;
				width: 20px;
			}
		}
		&:after {
			left: 93px;
			transform-origin: right;
			transform: rotate(-45deg);
			width: 12px;
			background: #a5a5a5;
			@include device_pc {
				left: 277px;
				width: 24px;
			}
		}
	}
	&--7 {
		position: relative;
		margin-bottom: 16px;
		padding-bottom: 10px;
		border-bottom: solid #c6dbec 1px;
		font-size: 19px;
		font-weight: bold;
		line-height: 1.2;
		color: #0168b7;
		@include device_pc {
			margin-bottom: 26px;
			border-bottom-width: 3px;
			padding-bottom: 14px;
			font-size: 26px;
		}
	}
}

/* more
------------------------------------------------ */
.more {
	&--1 {
		position: relative;
		margin: 56px auto 0;
		max-width: 400px;
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 20px 10px;
			width: 100%;
			background: #404040;
			border-radius: 35px;
			color: #fff;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 38px;
				margin-top: -10px;
				width: 11px;
				height: 20px;
				background: url(../img/icon/arrow3.png) no-repeat center center;
				background-size: 100%;
			}
		}
		@include device_sp {
			margin-top: 28px;
			max-width: 300px;
			font-size: 16px;
			a {
				padding: 16px 10px;
				&:after {
					right: 25px;
					margin-top: -6px;
					width: 7px;
					height: 13px;
				}
			}
		}
	}
}

/* btn
------------------------------------------------ */
.btn {
	&--normal {
		position: relative;
		margin: 54px auto 0;
		max-width: 420px;
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 20px 10px;
			width: 100%;
			background: #0168b7;
			border: solid #0168b7 1px;
			border-radius: 35px;
			color: #fff;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 24px;
				margin-top: -11px;
				width: 11px;
				height: 22px;
				background: url(../img/icon/arrow3.png) no-repeat center center;
				background-size: 100%;
				vertical-align: top;
			}
		}
		@include device_sp {
			margin-top: 18px;
			font-size: 16px;
			max-width: 310px;
			a {
				padding: 15px 10px;
				&:after {
					right: 20px;
					margin-top: -6px;
					width: 7px;
					height: 13px;
				}
			}
		}
	}
	&--back {
		position: relative;
		margin: 72px auto 0;
		max-width: 300px;
		text-align: center;
		font-size: 18px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 14px 10px;
			background: #404040;
			border: solid #404040 1px;
			border-radius: 25px;
			color: #fff;
		}
		@include device_sp {
			margin-top: 34px;
			font-size: 16px;
		}
	}
}

/* icon
------------------------------------------------ */
.icon {
	&-news {
		display: inline-block;
		border-radius: 15px;
		text-align: center;
		vertical-align: top;
		font-size: 12px;
		font-style: normal;
		&--news { background: #0168b7; color: #fff; }
		&--exhibition { background: #f6f033; }
		&--update { background: #404040; color: #fff; }
		&--mailmagazine { background: #000; color: #fff; }
		@include device_sp {
			padding: 3px 0;
		}
		@include device_pc {
			font-size: 16px;
		}
	}
	&-new {
		display: inline-block;
		min-width: 38px;
		height: 15px;
		background: #aa111d;
		border-radius: 8px;
		text-align: center;
		vertical-align: top;
		font-size: 10.5px;
		font-weight: 700;
		font-style: normal;
		line-height: 15px;
		color: #fff;
		@include device_pc {
			min-width: 50px;
			height: 20px;
			border-radius: 10px;
			font-size: 14px;
			line-height: 20px;
		}
	}
	&-form {
		display: inline-block;
		min-width: 54px;
		padding: 2px 0 3px;
		text-align: center;
		vertical-align: top;
		font-family: $fontMin;
		font-size: 14px;
		font-style: normal;
		line-height: 1.2;
		color: #fff;
		&--required {
			background: #b4161f;
		}
		&--any {
			background: #0068b7;
		}
	}
	&-pdf {
		display: inline-block;
		position: relative;
		width: 17px;
		height: 20px;
		vertical-align: top;
		text-indent: -9999px;
		white-space: nowrap;
		overflow: hidden;
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/icon/pdf1.png) no-repeat center center;
			background-size: 100%;
		}
	}
	&-blank {
		position: absolute;
		top: 0;
		right: 0;
		width: 20px;
		height: 20px;
		vertical-align: top;
		text-indent: -9999px;
		white-space: nowrap;
		@include device_pc {
			width: 25px;
			height: 25px;
		}
		&:after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			background: url(../img/icon/blank1.png) no-repeat center center;
			background-size: 100%;
		}
	}
}

/* lists
------------------------------------------------ */
.lists {
	&-news {
		&--item {
			border-bottom: solid #ccc 1px;
			& > a,
			& > span {
				display: flex;
				position: relative;
				padding: 20px 2px;
			}
		}
		&--cat {
			width: 90px;
			.icon-news {
				width: 100%;
			}
		}
		&--date {
			margin-left: 15px;
			width: 110px;
			font-size: 18px;
			font-weight: 700;
			color: #0068b7;
		}
		&--title {
			width: calc(100% - 215px);
			font-size: 16px;
			font-weight: 400;
			line-height: 1.8;
		}
		@include device_sp {
			margin: 0 20px;
			&--item {
				& > a {
					flex-wrap: wrap;
					padding: 20px 0;
				}
			}
			&--cat {
				width: 65px;
				.icon-news {
					width: 100%;
				}
			}
			&--date {
				margin-left: 10px;
				width: auto;
				font-size: 15px;
			}
			&--title {
				margin-top: 15px;
				width: 100%;
				font-size: 14px;
				line-height: 1.6;
			}
		}
	}
}

/* style
------------------------------------------------ */
.style {
	/* tab */
	&-tab {
		display: flex;
		flex-wrap: wrap;
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
		}
		&-item {
			position: relative;
			height: 45px;
			text-align: center;
			font-size: 14px;
			line-height: 1.4;
			@include device_sp {
				margin: 2px 2px 0 0;
				width: calc(50% - 1px);
				&:nth-of-type(-n+2) { margin-top: 0; }
				&:nth-of-type(2n) { margin-right: 0; }
			}
			@include device_pc {
				margin: 2px 2px 0 0;
				width: calc(25% - 1.5px);
				height: 90px;
				font-size: 24px;
				&:nth-of-type(-n+4) { margin-top: 0; }
				&:nth-of-type(4n) { margin-right: 0; }
			}
			& > a {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				height: 100%;
				background: #fff;
				border: solid #0168b7 1px;
				color: #0168b7;
				&.active {
					background: #0168b7;
					color: #fff;
					&:after {
						background-image: url(../img/icon/arrow3.png);
					}
				}
				@include device_pc {
					padding: 35px 30px;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 8px;
					margin-top: -5px;
					width: 5px;
					height: 10px;
					background: url(../img/icon/arrow4.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						right: 20px;
						margin-top: -9px;
						width: 9px;
						height: 19px;
					}
				}
				.min {
					display: block;
					font-size: 11px;
					@include device_pc {
						font-size: 18px;
					}
				}
			}
		}
	}
	/* introduce */
	&-introduce {
		@include device_sp {
			padding: 10px 20px 30px;
		}
		@include device_pc {
			display: flex;
			justify-content: space-between;
			padding: 60px 20px;
		}
		&-info {
			@include device_pc {
				width: 45%;
			}
		}
		.title--5 {
			margin-bottom: 8px;
			@include device_pc {
				margin-bottom: 25px;
			}
		}
		&-text {
			font-size: 14px;
			line-height: 1.75;
			@include device_pc {
				font-size: 15px;
			}
		}
		&-photo {
			@include device_sp {
				margin-top: 26px;
			}
			@include device_pc {
				width: 43.3333333333%;
			}
		}
	}
	/* section */
	&-section {
		position: relative;
		margin-top: 1px;
		padding: 30px 20px 50px;
		background: #e5f0f8;
		@include device_pc {
			margin-top: 2px;
			padding: 70px 0 112px;
		}
		&-block {
			margin-top: 24px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 50px;
			}
		}
		&-title {
			margin-bottom: 16px;
			font-family: $fontMin;
			font-size: 26px;
			font-weight: 700;
			line-height: 1.4;
			color: #0168b7;
			@include device_pc {
				margin-bottom: 34px;
				font-size: 42px;
			}
		}
		&-lists {
			&--item {
				position: relative;
				margin-top: 10px;
				padding: 15px 20px 20px;
				background: #fff;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 20px;
					padding: 28px 65px;
				}
			}
			&--title {
				padding-bottom: 8px;
				border-bottom: solid #c6dbec 1px;
				font-size: 19px;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 18px;
					font-size: 28px;
				}
			}
			&__content {
				margin-top: 18px;
				font-size: 14px;
				@include device_sp {
					padding-top: 5px;
				}
				@include device_pc {
					margin-top: 32px;
					font-size: 15px;
				}
				& > * {
					& + * {
						margin-top: 1.4em;
						@include device_pc {
							margin-top: 1.5em;
						}
					}
				}
				.image {
					&-center {
						margin-right: auto;
						margin-left: auto;
						width: 50%;
						text-align: center;
						@include device_pc {
							// width: 27.5700934579%;
						}
					}
					&-lists {
						margin-top: 20px;
						@include device_pc {
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							margin-top: 40px;
						}
						&:first-child { margin-top: 0; }
						&-item {
							position: relative;
							text-align: center;
							@include device_sp {
								margin-top: 20px;
								font-size: 10px;
								&:first-child { margin-top: 0; }
							}
							@include device_pc {
								margin: 40px 2% 0;
								width: 28%;
								font-size: 12px;
								&:nth-of-type(-n+3) { margin-top: 0; }
							}
							figure {
								display: block;
								position: relative;
								img {
									@include device_sp {
										width: 50%;
									}
								}
							}
							figcaption {
								display: block;
								text-align: center;
								margin-top: 10px;
								@include device_pc {
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
			&__titles {
				@include device_sp {
					margin-top: 14px;
					&:first-child { margin-top: 0; }
				}
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
				}
				&--item {
					font-size: 14px;
					line-height: 1.2;
					& > a {
						display: inline-block;
						position: relative;
						text-decoration: underline;
						vertical-align: top;
						color: #0168b7;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
						&.link {
							&-icon {
								padding-left: 26px;
								i {
									position: absolute;
									top: 0;
									left: 0;
								}
							}
						}
					}
					@include device_sp {
						margin-top: 14px;
						&:first-child { margin-top: 0; }
					}
					@include device_pc {
						margin: 18px 60px 0 0;
						width: calc(25% - 45px);
						font-size: 15px;
						&:nth-of-type(-n+4) { margin-top: 0; }
						&:nth-of-type(4n) { margin-right: 0; }
						&.streight {
							margin-right: 0;
							width: 100%;
						}
					}
				}
			}
			&__links {
				@include device_sp {
				}
				@include device_pc {
				}
				&--item {
					margin-top: 18px;
					font-size: 14px;
					&:first-child { margin-top: 0; }
					@include device_pc {
						margin-top: 28px;
						font-size: 15px;
					}
					a,
					span {
						display: inline-block;
						position: relative;
						padding-left: 22px;
						vertical-align: top;
						@include device_pc {
							padding-left: 32px;
						}
						&:before {
							content: '\25B6\FE0E';
							position: absolute;
							top: 4px;
							left: 0;
							font-size: 10px;
							color: #0168b7;
						}
					}
					a {
						text-decoration: underline;
						color: #0168b7;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
					}
					.no {
						&-arrow {
							padding-left: 0;
							&:before {
								display: none;
							}
						}
					}
					.icon {
						&-pdf {
							margin-right: 10px;
						}
						&-new {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
	/* sitemap */
	&-sitemap {
		&__box {
			margin-top: 42px;
			&:first-child { margin-top: 0; }
		}
		&--title {
			position: relative;
			padding-bottom: 18px;
			border-bottom: solid #a5a5a5 2px;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.2;
			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 60px;
				width: 10px;
				height: 2px;
			}
			&:before {
				background: #f8fbfd;
			}
			&:after {
				transform-origin: right top;
				transform: rotate(-45deg);
				background: #a5a5a5;
			}
			a {
				display: block;
				position: relative;
			}
			span {
				font-size: 14px;
			}
		}
		&__content {
			margin-top: 32px;
		}
		&__links {
			&--item {
				position: relative;
				margin-top: 25px;
				font-size: 16px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				a {
					display: block;
					position: relative;
					padding-left: 16px;
					&:before {
						content: '';
						position: absolute;
						top: 6px;
						left: 2px;
						width: 5px;
						height: 8px;
						background: url(../img/icon/arrow1.png) no-repeat center center;
						background-size: 100%;
						vertical-align: top;
					}
				}
			}
		}
		@include device_pc {
			&__content {
				display: block !important;
			}
		}
		@media screen and (max-width: 1200px) {
			width: calc(100% - 200px);
			&__line {
				width: 24%;
			}
			&--title {
				font-size: 14px;
				span {
					font-size: 12px;
				}
			}
			&__links {
				&--item {
					font-size: 12px;
				}
			}
		}
		@include device_sp {
			display: block;
			width: auto;
			margin-top: 10px;
			&__line {
				width: auto;
				&:last-child {
					display: flex;
					flex-wrap: wrap;
					.style-sitemap {
						&__box {
							width: 50%;
							border-bottom: solid #a5a5a5 1px;
							&:nth-of-type(2n) {
								border-left: solid #a5a5a5 1px;
							}
						}
						&--title {
							display: flex;
							align-items: center;
							border-bottom: none;
							height: 100%;
							a {
								width: 100%;
							}
						}
					}
				}
			}
			&__box {
				margin-top: 0;
			}
			&--title {
				padding: 0;
				border-bottom-width: 1px;
				font-size: 16px;
				&:before,
				&:after {
					display: none;
				}
				a {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px;
					height: 100%;
					&.has--child {
						&:before,
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 18px;
							transition: ease .3s;
							width: 18px;
							height: 3px;
							background: #404040;
						}
						&:after {
							transform: rotate(90deg);
						}
						&.active {
							&:after {
								transform: rotate(0);
							}
						}
					}
				}
				span {
					display: block;
					font-size: 14px;
				}
			}
			&__content {
				display: none;
				margin-top: 0;
				padding: 25px 20px 5px;
			}
			&__links {
				&--item {
					margin-top: 16px;
					font-size: 16px;
					a {
						padding-left: 22px;
					}
				}
			}
		}
	}
	/* links */
	&-links {
		&--1 {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			&--item {
				margin-left: 16px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-left: 0; }
			}
			@media screen and (max-width: 1200px) {
				&--item {
					font-size: 12px;
				}
			}
			@include device_sp {
				justify-content: flex-start;
				margin-top: 0;
				&--item {
					margin-left: 0;
					width: 50%;
					border-bottom: solid #a5a5a5 1px;
					font-size: 14px;
					font-weight: 700;
					&:nth-of-type(2n) {
						border-left: solid #a5a5a5 1px;
					}
					a {
						display: block;
						padding: 20px;
					}
					&.visible--pc {
						& + .style-links--1--item {
							&:last-child {
								width: calc(50% + 1px);
								border-left: none;
								border-right: solid #a5a5a5 1px;
							}
						}
					}
				}
			}
		}
	}
	/* search */
	&-search {
		&--1 {
			&__tab {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				&--item {
					position: relative;
					width: 33.1666666667%;
					text-align: center;
					font-size: 24px;
					line-height: 1.2;
					a {
						display: block;
						position: relative;
						padding: 16px 0;
						width: 100%;
						background: #404040;
						color: #fff;
						&.active {
							background: #0168b7;
							&:after {
								content: '';
								position: absolute;
								left: 50%;
								bottom: -12px;
								margin-left: -8px;
								width: 0;
								height: 0;
								border: solid transparent 8px;
								border-top: solid #0168b7 12px;
								border-bottom: none;
								overflow: hidden;
							}
						}
					}
				}
			}
			&__content {
				display: none;
				margin-top: 12px;
				padding: 36px 0;
				border: solid #eee 4px;
				background: #fff;
			}
			&__from {
				margin: 0 auto;
				width: 58.3333333333%;
				input,
				button,
				select {
					appearance: none;
					height: 68px;
					background: none;
					border: none;
					border-radius: 0;
					font-family: inherit;
					font-size: 24px;
				}
			}
			&--select {
				select {
					padding: 0 30px;
					width: 100%;
					background: url(../img/icon/select_arrow1.png) no-repeat right 20px center #fff;
					background-size: 20px;
					border: solid #ccc 2px;
				}
			}
			&__keyword {
				display: flex;
				margin-top: 18px;
				&--input {
					width: calc(100% - 68px);
					input {
						padding: 0 30px;
						width: 100%;
						background: #eee;
						border: solid #ccc 2px;
						border-right: none;
					}
				}
				&--submit {
					position: relative;
					width: 68px;
					height: 68px;
					button {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url(../img/icon/search1.png) no-repeat center center #404040;
						background-size: 37px;
						text-indent: -9999px;
						white-space: nowrap;
						overflow: hidden;
						cursor: pointer;
					}
				}
			}
			@include device_sp {
				margin: 0 20px;
				&__tab {
					&--item {
						font-size: 16px;
						a {
							padding: 14px 0;
							&.active {
								&:after {
									bottom: -8px;
									margin-left: -3px;
									border-width: 6px;
									border-top-width: 8px;
								}
							}
							span {
								display: block;
								font-size: 12px;
							}
						}
					}
				}
				&__content {
					margin-top: 8px;
					padding: 16px;
					border-width: 2px;
				}
				&__from {
					width: auto;
					input,
					button,
					select {
						height: 60px;
						font-size: 16px;
					}
				}
				&--select {
					select {
						padding: 0 60px 0 12px;
						border-width: 1px;
					}
				}
				&__keyword {
					margin-top: 12px;
					&--input {
						width: calc(100% - 60px);
						input {
							padding: 0 12px;
							border-width: 1px;
						}
					}
					&--submit {
						width: 60px;
						height: 60px;
						button {
							background-size: 34px;
						}
					}
				}
			}
			&__links {
				margin-top: 20px;
				@include device_pc {
					margin: 40px 46px 0;
				}
				&__box {
					margin-top: 10px;
					padding: 25px;
					background: #f8fbfd;
					&:first-child { margin-top: 0; }
					@include device_pc {
						margin-top: 20px;
						padding: 40px 50px;
					}
				}
				&--title {
					padding-bottom: 10px;
					border-bottom: solid #c6dbec 1px;
					font-size: 19px;
					color: #0168b7; 
					@include device_pc {
						font-size: 28px;
					}
				}
				&__content {
					margin-top: 15px;
					@include device_pc {
						margin-top: 25px;
					}
				}
				&__titles {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					&--item {
						border-bottom: solid #c6dbec 1px;
						font-size: 14px;
						line-height: 1.2;
						@include device_sp {
							margin: 10px 30px 0 0;
							width: calc(50% - 15px);
							&:nth-of-type(-n+2) { margin-top: 0; }
							&:nth-of-type(2n) { margin-right: 0; }
						}
						@include device_pc {
							margin: 38px 40px 0 0;
							width: calc(25% - 30px);
							font-size: 16px;
							&:nth-of-type(-n+4) { margin-top: 0; }
							&:nth-of-type(4n) { margin-right: 0; }
						}
						& > a {
							display: block;
							padding: 6px 0;
							color: #0168b7;
							@include device_pc {
								padding: 8px;
							}
						}
					}
				}
			}
		}
	}
	/* index */
	&-index {
		margin-bottom: 96px;
		&__lists {
			&--item {
				position: relative;
				margin-top: 60px;
				padding: 40px 44px;
				background: #f8fbfd;
				&:nth-of-type(even) {
					background: #e5f0f8;
				}
				&:first-child { margin-top: 0; }
			}
			&--title {
				display: inline-block;
				position: relative;
				margin-bottom: 40px;
				vertical-align: top;
				font-size: 32px;
				font-weight: 700;
				line-height: 1.2;
				color: #0168b7;
				&:after {
					content: '';
					display: block;
					margin-top: 6px;
					width: 100%;
					height: 4px;
					background: #0168b7;
				}
			}
			&__detail {
				&__box {
					&--title {
						font-size: 18px;
						font-weight: 700;
						color: #0168b7;
						a {
							display: inline-block;
							vertical-align: top;
							color: #0168b7;
							&:before {
								content: '';
								display: inline-block;
								margin: 6px 4px 0 0;
								width: 8px;
								height: 16px;
								background: url(../img/icon/arrow2.png) no-repeat center center;
								background-size: 100%;
								vertical-align: top;
							}
						}
					}
					&--text {
						margin-top: 10px;
						font-size: 15px;
					}
				}
			}
			&__links {
				&--item {
					font-size: 18px;
					font-weight: 700;
					color: #0168b7;
					a {
						display: inline-block;
						vertical-align: top;
						color: #0168b7;
						&:before {
							content: '';
							display: inline-block;
							margin: 6px 4px 0 0;
							width: 8px;
							height: 16px;
							background: url(../img/icon/arrow2.png) no-repeat center center;
							background-size: 100%;
							vertical-align: top;
						}
					}
				}
			}
		}
		@include device_pc {
			&__lists {
				&__detail {
					display: flex;
					flex-wrap: wrap;
					&__box {
						margin: 40px 2.8776978418% 0 0;
						width: 48.5611510791%;
						&:nth-of-type(-n+2) { margin-top: 0; }
						&:nth-of-type(2n) { margin-right: 0; }
					}
				}
				&__links {
					display: flex;
					flex-wrap: wrap;
					&--item {
						margin-top: 12px;
						padding-right: 20px;
						width: 25%;
						&:nth-of-type(-n+4) { margin-top: 0; }
					}
				}
			}
		}
		@include device_sp {
			margin-bottom: 50px;
			&__lists {
				margin: 0 20px;
				&--item {
					margin-top: 30px;
					padding: 24px 20px;
				}
				&--title {
					margin-bottom: 24px;
					font-size: 21px;
					&:after {
						margin-top: 6px;
						height: 2px;
					}
				}
				&__detail {
					&__box {
						margin-top: 18px;
						&:first-child { margin-top: 0; }
						&--text {
							margin-top: 8px;
						}
					}
				}
				&__links {
					&--item {
						margin-top: 18px;
						&:first-child { margin-top: 0; }
					}
				}
			}
		}
	}
}

/* block
------------------------------------------------ */
.block {
	/* contact */
	&-contact {
		padding: 120px 0 100px;
		background: url(../img/share/contact_bg.png) no-repeat center center;
		background-size: cover;
		.title--3 {
			color: #fff;
			&:before,
			&:after {
				background: #fff;
			}
		}
		&--text {
			text-align: center;
			font-size: 28px;
			color: #fff;
		}
		&__links {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 20px;
			&--item {
				position: relative;
				margin: 20px 10px 0;
				font-size: 24px;
				line-height: 1.2;
				a {
					display: block;
					position: relative;
					padding: 20px 38px;
					background: #fff;
					border-radius: 35px;
					color: #0168b7;
					&:after {
						content: '';
						display: inline-block;
						margin: 4px 0 0 22px;
						width: 11px;
						height: 22px;
						background: url(../img/icon/arrow2.png) no-repeat center center;
						background-size: 100%;
						vertical-align: top;
					}
				}
			}
		}
		@include device_sp {
			padding: 60px 25px 48px;
			&--text {
				font-size: 18px;
			}
			&__links {
				display: block;
				margin: 24px 8px 0;
				&--item {
					margin: 16px 0 0;
					text-align: center;
					font-size: 16px;
					&:first-child { margin-top: 0; }
					a {
						padding: 12px 10px;
						&:after {
							position: absolute;
							top: 50%;
							right: 20px;
							margin: -6px 0 0;
							width: 7px;
							height: 13px;
						}
					}
				}
			}
		}
	}
}

/* pager
------------------------------------------------ */
.pager {
	&-1 {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		@include device_pc {
			margin-top: 80px;
		}
		&-item {
			position: relative;
			margin: 0 2px;
			width: 34px;
			height: 34px;
			text-align: center;
			font-family: Arial, sans-serif;
			font-size: 16px;
			line-height: 34px;
			a,
			span {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
			}
			span {
				background: #0168b7;
				color: #fff;
			}
			&.prev,
			&.next {
				a {
					text-indent: -9999px;
					white-space: nowrap;
					overflow: hidden;
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						margin: -6px 0 0 -6px;
						width: 12px;
						height: 13px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
					}
				}
			}
			&.prev {
				a {
					&:after {
						background-image: url(../img/icon/pager1_prev.png);
					}
				}
			}
			&.next {
				a {
					&:after {
						background-image: url(../img/icon/pager1_next.png);
					}
				}
			}
		}
	}
	&-single {
		margin-top: 25px;
		padding-top: 40px;
		border-top: solid #ccc 1px;
		@include device_pc {
			margin-top: 60px;
			padding-top: 60px;
		}
		&-post {
			display: flex;
			justify-content: center;
			@include device_sp {
				margin: 0 32px;
			}
			&--item {
				position: relative;
				margin: 0 4px;
				width: calc(50% - 4px);
				text-align: center;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-left: 0; }
				&:last-child { margin-right: 0; }
				@include device_pc {
					margin: 0 10px;
					width: 140px;
					font-size: 15px;
				}
				a {
					display: block;
					position: relative;
					padding: 12px 0;
					border: solid #0168b7 1px;
					color: #0168b7;
					@include device_pc {
						padding: 15px 0;
					}
					&:before,
					&:after {
						content: '';
						display: inline-block;
						width: 7px;
						height: 10px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
						vertical-align: top;
						@include device_sp {
							position: absolute;
							top: 16px;
						}
						@include device_pc {
							margin-top: 5px;
						}
					}
				}
				&.prev {
					a {
						&:before {
							background-image: url(../img/icon/pager_single_prev.png);
							@include device_sp {
								left: 10px;
							}
							@include device_pc {
								margin-right: 18px;
							}
						}
						&:after {
							display: none;
						}
					}
				}
				&.next {
					a {
						&:before {
							display: none;
						}
						&:after {
							background-image: url(../img/icon/pager_single_next.png);
							@include device_sp {
								right: 10px;
							}
							@include device_pc {
								margin-left: 18px;
							}
						}
					}
				}
			}
		}
		&-back {
			margin: 12px 32px 0;
			text-align: center;
			font-size: 14px;
			line-height: 1.2;
			@include device_pc {
				margin: 40px auto 0;
				width: 400px;
				font-size: 24px;
			}
			a {
				display: block;
				padding: 16px 0;
				background: #0168b7;
				border: solid #0168b7 1px;
				color: #fff;
				@include device_pc {
					padding: 25px 0;
				}
			}
		}
	}
}
