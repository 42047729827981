@charset 'utf-8';

@import '../variable';

/* common
------------------------------------------------ */
.page--leo {
	.title--ribbon--name {
		@include device_sp {
			padding-top: 20px;
			width: 302px;
			height: 124px;
			background-image: url(../img/leo/title_ribbon.png);
		}
		@include device_pc {
			width: 975px;
			background-image: url(../img/leo/title_ribbon-pc.png);
		}
		@media screen and (min-width: $mediaPC) and (max-width: 1000px) {
			padding-top: 5.5vw;
			width: 97.5vw;
			height: 22vw;
			font-size: 8vw;
		}
	}
}

/* leo
------------------------------------------------ */
.leo {
	/* parts */
	&-title {
		.title {
			&--page {
				background-size: cover;
				@include device_sp {
					background-image: url(../img/leo/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/leo/title_bg-pc.jpg);
				}
			}
		}
	}
	&-link {
		margin-top: 30px;
		text-align: center;
		font-size: 20px;
		line-height: 1.2;
		@include device_pc {
			margin-top: 40px;
			font-size: 28px;
		}
		& > a {
			display: inline-block;
			text-decoration: underline;
			vertical-align: top;
			color: #0168B7;
		}
	}
	/* top */
	&-top {
		.title {
			&--image {
				background-image: url(../img/leo/title.jpg);
			}
			&--ribbon {
				background-image: url(../img/leo/title2_bg.jpg);
			}
		}
		&-outline {
			position: relative;
			@include device_sp {
				padding: 0 20px;
			}
			@include device_pc {
				display: grid;
				padding: 0 calc((100% - 1200px) / 2) 36px 0;
				grid-column-gap: 66px;
				grid-template-columns: 41.6666666667%;
			}
			@media screen and (min-width: $mediaPC) and (max-width: 1240px) {
				padding-right: 20px;
			}
			&--title {
				font-family: $fontMin;
				font-size: 28px;
				font-weight: 700;
				color: #0168b7;
				@include device_pc {
					grid-row: 1;
					grid-column: 2 / 3;
					font-size: 60px;
				}
			}
			&--photo {
				@include device_sp {
					margin-top: 16px;
				}
				@include device_pc {
					grid-row: 1 / 4;
					grid-column: 1;
					height: calc(100% + 36px);
					img {
						width: 100%;
						height: 100%;
						max-width: inherit;
						object-fit: cover;
					}
				}
			}
			&--text {
				margin-top: 16px;
				font-family: $fontMin;
				font-size: 16px;
				line-height: 1.5625;
				@include device_pc {
					grid-row: 2;
					grid-column: 2 / 3;
					margin-top: 28px;
					font-size: 18px;
					line-height: 2.48888888889;
				}
			}
			&__btm {
				margin-top: 16px;
				@include device_pc {
					display: flex;
					align-items: center;
					grid-row: 3;
					grid-column: 2 / 3;
					margin-top: 74px;
				}
			}
			&--btn {
				text-align: center;
				font-size: 24px;
				line-height: 1.2;
				@include device_sp {
					padding: 0 18px;
				}
				@include device_pc {
					width: 54.1786743516%;
					font-size: 28px;
				}
				& > a {
					display: block;
					padding: 14px 10px;
					background-color: #0168B7;
					border-radius: 28px;
					color: #fff;
					@include device_pc {
						padding: 24px 10px;
						max-width: 376px;
						border-radius: 40px;
					}
				}
			}
			&--lng {
				text-align: center;
				font-size: 16px;
				line-height: 1.2;
				@include device_sp {
					margin-top: 22px;
					padding: 0 48px;
				}
				@include device_pc {
					margin-left: 8.64553314121%;
					width: 34.5821325648%;
					font-size: 20px;
				}
				& > a {
					display: block;
					padding: 10px;
					border: 1px solid #707070;
					color: #404040;
					@include device_pc {
						padding: 18px 12px;
					}
				}
			}
		}
	}
	/* index */
	&-index {
		position: relative;
		&-content {
			position: relative;
			margin-top: 1px;
			padding: 30px 20px 50px;
			background: #e5f0f8;
			@include device_pc {
				margin-top: 2px;
				padding: 70px 0 112px;
			}
		}
		&-title {
			margin-bottom: 80px;
			text-align: center;
			font-family: $fontMin;
			font-size: 26px;
			font-weight: 700;
			line-height: 1.2;
			@include device_pc {
				margin-bottom: 150px;
				font-size: 52px;
			}
		}
		&-lists {
			display: flex;
			flex-wrap: wrap;
			padding-top: 42px;
			@include device_pc {
				padding-top: 110px;
			}
			&--item {
				position: relative;
				@include device_sp {
					margin: 62px 4.4776119403% 0 0;
					width: calc(50% - (4.4776119403% / 2));
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 180px 5% 0 0;
					width: calc((100% / 3) - (5% / (3 / 2)));
					&:nth-of-type(-n+3) { margin-top: 0; }
					&:nth-of-type(3n) { margin-right: 0; }
				}
			}
			&--photo {
				position: absolute;
				top: -42px;
				left: 50%;
				margin-left: -46px;
				width: 92px;
				border-radius: 50%;
				overflow: hidden;
				z-index: 2;
				@include device_pc {
					top: -110px;
					margin-left: -92px;
					width: 185px;
				}
			}
			&__info {
				position: relative;
				padding: 64px 15px 68px;
				height: 100%;
				background: #fff;
				z-index: 1;
				@include device_pc {
					padding: 95px 35px 120px;
				}
			}
			&--title {
				margin-bottom: 10px;
				font-size: 20px;
				color: #0168b7;
				@include device_pc {
					margin-bottom: 22px;
					font-size: 28px;
				}
			}
			&__content {
				position: relative;
			}
			&--text {
				font-size: 14px;
				line-height: 1.6;
				@include device_pc {
					font-size: 15px;
				}
			}
			&__titles {
				@include device_sp {
					margin-top: 14px;
					&:first-child { margin-top: 0; }
				}
				&--item {
					margin-top: 14px;
					font-size: 14px;
					line-height: 1.2;
					&:first-child { margin-top: 0; }
					& > a {
						display: inline-block;
						position: relative;
						vertical-align: top;
						&.link {
							&-icon {
								padding-left: 26px;
								i {
									position: absolute;
									top: 0;
									left: 0;
								}
							}
						}
					}
					@include device_pc {
						margin-top: 15px;
						font-size: 15px;
					}
				}
			}
			&--btn {
				position: absolute;
				bottom: 15px;
				left: 50%;
				transform: translateX(-50%);
				width: calc(100% - 30px);
				max-width: 130px;
				font-size: 12px;
				a {
					padding: 7px 10px;
					&:after {
						right: 10px;
						margin-top: -4px;
						width: 5px;
						height: 9px;
					}
				}
				@include device_pc {
					bottom: 40px;
					width: calc(100% - 100px);
					max-width: 258px;
					font-size: 16px;
					a {
						padding: 12px 10px;
						&:after {
							right: 24px;
							margin-top: -8px;
							width: 8px;
							height: 16px;
						}
					}
				}
			}
		}
		&-measurement {
			position: relative;
			margin-top: 50px;
			padding: 40px 20px;
			background-color: #fff;
			border: 1px solid #0168B7;
			@include device_pc {
				display: grid;
				grid-column-gap: 96px;
				grid-template-columns: 39.9246704331%;
				margin-top: 96px;
				padding: 40px 70px 50px;
			}
			&__title {
				margin-bottom: 22px;
				text-align: center;
				font-family: $fontMin;
				font-size: 32px;
				font-weight: 700;
				color: #0168B7;
				@include device_pc {
					grid-row: 1;
					grid-column: 1 / 3;
					margin-bottom: 40px;
					font-size: 32px;
				}
			}
			&__photos {
				position: relative;
				@include device_sp {
					margin-bottom: 36px;
				}
				@include device_pc {
					grid-row: 2;
					grid-column: 1;
					aspect-ratio: 1 / 0.856132075472;
				}
				&__item {
					@include device_sp {
						&:not(:first-child) {
							margin-top: 4px;
						}
					}
					@include device_pc {
						position: absolute;
						width: 77.8301886792%;
						&:nth-of-type(1) {
							top: 0;
							left: 0;
						}
						&:nth-of-type(2) {
							bottom: 0;
							right: 0;
						}
					}
				}
			}
			&__lists {
				@include device_sp {
					margin: 0 14px;
				}
				@include device_pc {
					grid-row: 2;
					grid-column: 2;
				}
				&__item {
					position: relative;
					@include device_pc {
						display: flex;
						align-items: center;
					}
					&:not(:first-child) {
						margin-top: 36px;
						@include device_pc {
							margin-top: 24px;
						}
					}
				}
				&__title {
					padding-left: 5px;
					border-left: 4px solid #0168B7;
					font-size: 23px;
					font-weight: 400;
					line-height: 1.2;
					@include device_pc {
						padding: 0 10px 0 8px;
						width: calc(100% - 258px);
						font-size: 23px;
					}
				}
				&__btn {
					margin: 0;
					@include device_sp {
						margin-top: 12px;
					}
					@include device_pc {
						width: 258px;
						font-size: 16px;
						a {
							padding: 14px 10px;
							&::after {
								right: 20px;
								margin-top: -8px;
								width: 10px;
								height: 18px;
							}
						}
					}
				}
			}
		}
	}
	/* introduce */
	&-introduce {
		margin-top: 48px;
		@include device_pc {
			margin-top: 90px;
		}
		&-tab2 {
			display: flex;
			flex-wrap: wrap;
			@include device_sp {
				margin: 0 20px 35px;
			}
			@include device_pc {
				margin-bottom: 76px;
			}
			&--item {
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 1.2;
				@include device_sp {
					margin: 4.4776119403% 1.49253731343% 0 0;
					width: 49.2537313433%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 6.33333333333% 1.66666666667% 0 0;
					width: 49.1666666667%;
					font-size: 32px;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				& > a {
					display: block;
					position: relative;
					padding: 20px 0 28px;
					border: solid #0168b7 1px;
					color: #0168b7;
					@include device_pc {
						padding: 50px 0 48px;
						border-width: 2px;
					}
					&.active {
						background: #0168b7;
						color: #fff;
						&:before {
							background-image: url(../img/icon/door2.png);
						}
						&:after {
							content: '';
							position: absolute;
							bottom: -7px;
							left: 50%;
							margin-left: -3px;
							width: 0;
							height: 0;
							border: solid transparent 6px;
							border-top: solid #0168b7 7px;
							border-bottom: none;
							@include device_pc {
								bottom: -16px;
								margin-left: -12px;
								border-width: 12px;
								border-top-width: 14px;
							}
						}
					}
					&:before {
						content: '';
						display: block;
						width: 15px;
						height: 25px;
						background: url(../img/icon/door1.png) no-repeat center center;
						background-size: 100%;
						@include device_sp {
							margin: 0 auto 8px;
						}
						@include device_pc {
							position: absolute;
							top: 50%;
							left: 80px;
							margin-top: -40px;
							width: 48px;
							height: 81px;
						}
						@media screen and (min-width: $mediaPC) and (max-width: 1100px) {
							left: 6.66666666667vw;
						}
						@media screen and (min-width: $mediaPC) and (max-width: 980px) {
							left: 2vw;
						}
					}
				}
				span {
					display: block;
					margin-top: 6px;
					font-size: 10px;
					@include device_pc {
						margin-top: 6px;
						font-size: 15px;
					}
				}
			}
		}
		&-content {
			padding: 34px 20px 50px;
			background: #e5f0f8;
			@include device_pc {
				padding: 80px 0 140px;
			}
		}
		&-target {
			position: relative;
		}
	}
	/* search */
	&-search {
		position: relative;
		margin-top: 45px;
		@include device_pc {
			margin-top: 90px;
		}
		&-title {
			margin-bottom: 24px;
			text-align: center;
			font-size: 30px;
			font-weight: 700;
			line-height: 1.2;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 26px;
				font-size: 60px;
			}
		}
		.style-search--1 {
			@include device_sp {
				margin: 0;
			}
		}
	}
	&-page {
		position: relative;
	}
	/* measurement */
	&-measurement {
		&-lists {
			&__item {
				padding: 20px;
				background-color: #fff;
				@include device_pc {
					display: grid;
					grid-column-gap: 86px;
					grid-template-rows: auto auto 1fr;
					grid-template-columns: 360px;
					padding: 40px 60px;
				}
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
			&__above {
				margin-bottom: 16px;
				@include device_pc {
					display: flex;
					align-items: center;
					grid-row: 1;
					grid-column: 1 / 3;
					margin-bottom: 30px;
					padding-bottom: 30px;
					border-bottom: 1px solid #C6DBEC;
				}
			}
			&__title {
				position: relative;
				padding-left: 12px;
				font-size: 24px;
				font-weight: 400;
				color: #0168B7;
				@include device_pc {
					width: 410px;
					padding-left: 20px;
					font-size: 33px;
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 2px;
					height: 22px;
					background-color: #0168B7;
					@include device_pc {
						width: 3px;
						height: 46px;
					}
				}
			}
			&__text {
				font-size: 16px;
				line-height: 1.5625;
				@include device_sp {
					margin-top: 10px;
					padding-top: 16px;
					border-top: 1px solid #C6DBEC;
				}
				@include device_pc {
					margin-left: 36px;
					width: calc(100% - (410px + 36px));
					font-size: 18px;
				}
			}
			&__photo {
				@include device_pc {
					grid-row: 2;
					grid-column: 1;
				}
			}
			&__btn {
				margin-top: 16px;
				@include device_pc {
					grid-row: 3;
					grid-column: 1;
					margin: 24px 0 0;
					width: 308px;
					font-size: 16px;
					a {
						padding: 12px 10px;
						&:after {
							right: 20px;
							margin-top: -8px;
							width: 8px;
							height: 16px;
						}
					}
				}
			}
		}
		&-example {
			@include device_sp {
				margin-top: 20px;
			}
			@include device_pc {
				grid-row: 2 / 4;
				grid-column: 2;
			}
			&__title {
				margin-bottom: 20px;
				font-size: 19px;
				font-weight: 400;
				color: #0168B7;
				@include device_pc {
					margin-bottom: 20px;
					font-size: 28px;
				}
			}
			&__text {
				margin-bottom: 24px;
				font-size: 14px;
				line-height: 1.78571428571;
				@include device_pc {
					margin-bottom: 30px;
					font-size: 15px;
				}
			}
			&__gif {
				margin-bottom: 10px;
				text-align: center;
				@include device_pc {
					margin-bottom: 12px;
					max-width: 520px;
				}
				&:not(:first-child) {
					margin-top: 24px;
					@include device_pc {
						margin-top: 36px;
					}
				}
			}
			&__photos {
				@include device_pc {
					max-width: 520px;
				}
				&[data-column='2'] {
					@include device_pc {
						display: flex;
						flex-wrap: wrap;
						justify-content: center;
						.leo-measurement-example__photos__item {
							margin: 16px 16px 0 0;
							width: calc(50% - 8px);
							&:nth-of-type(-n+2) {
								margin-top: 0;
							}
							&:nth-of-type(2n) {
								margin-right: 0;
							}
						}
					}
				}
				&[data-column='3'] {
					display: flex;
					flex-wrap: wrap;
					justify-content: center;
					.leo-measurement-example__photos__item {
						@include device_sp {
							margin: 16px 8px 0 0;
							width: calc(50% - 4px);
							&:nth-of-type(-n+2) {
								margin-top: 0;
							}
							&:nth-of-type(2n) {
								margin-right: 0;
							}
						}
						@include device_pc {
							margin: 8px 4px 0 0;
							width: calc((100% / 3) - (4px / (2 / 3)));
							&:nth-of-type(-n+3) {
								margin-top: 0;
							}
							&:nth-of-type(3n) {
								margin-right: 0;
							}
						}
					}
				}
				&__item {
					figure {
						display: block;
						text-align: center;
						@include device_sp {
							img {
								width: 100%;
								max-width: inherit;
							}
						}
						figcaption {
							display: block;
							margin-top: 8px;
							text-align: center;
							font-size: 15px;
							@include device_pc {
								margin-top: 8px;
								font-size: 15px;
							}
						}
					}
				}
			}
		}
	}
	/* company */
	&-company {
		&-block {
			position: relative;
			&:not(:first-child) {
				margin-top: 40px;
				@include device_pc {
					margin-top: 80px;
				}
			}
			&__content {
				padding: 20px;
				background-color: #fff;
				@include device_pc {
					padding: 40px 60px;
				}
			}
			&__text {
				margin-bottom: 24px;
				font-size: 16px;
				line-height: 1.5625;
				@include device_pc {
					margin-bottom: 40px;
					font-size: 18px;
				}
			}
			&__multi {
				margin-bottom: 20px;
				@include device_pc {
					display: grid;
					grid-gap: 20px;
					grid-template-columns: 48.1481481481%;
					margin-bottom: 40px;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&__photo {
					@include device_sp {
						margin-bottom: 16px;
					}
					@include device_pc {
						grid-row: 1;
						grid-column: 1;
					}
				}
				&__text {
					font-size: 16px;
					line-height: 1.6625;
					@include device_pc {
						grid-row: 1;
						grid-column: 2;
						font-size: 18px;
					}
				}
			}
		}
		&-outline {
			&__table {
				width: 100%;
				border-spacing: 0;
				border-collapse: collapse;
				border-top: 1px solid #ccc;
				border-left: 1px solid #ccc;
				th,
				td {
					padding: 8px 24px;
					min-width: 68px;
					border-right: 1px solid #ccc;
					border-bottom: 1px solid #ccc;
					font-size: 14px;
					font-weight: 400;
					line-height: 1.4;
					color: #707070;
					@include device_pc {
						padding: 20px 32px;
						min-width: 220px;
						font-size: 18px;
					}
					&.is {
						&-align {
							&-cnter {
								padding-right: 2px;
								padding-left: 2px;
								text-align: center;
							}
						}
					}
				}
				th {
					background-color: #F2F2F2;
					@include device_pc {
						min-width: 200px;
					}
				}
				.link-map {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					width: 90px;
					height: 48px;
					background-color: #0168B7;
					border-radius: 32px;
					text-align: center;
					font-size: 14px;
					line-height: 1.2;
					color: #fff;
					@include device_sp {
						margin-top: 10px;
					}
					@include device_pc {
						margin: -36px 0 0 auto;
						font-size: 16px;
					}
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: 10px;
						margin-top: -8px;
						width: 8px;
						height: 16px;
						background: url(../img/icon/arrow6.png) no-repeat center center;
						background-size: 100% 100%;
					}
				}
			}
		}
		&-history {
			&__lists {
				border-top: 1px solid #ccc;
				&__item {
					display: flex;
					align-items: center;
					border-bottom: 1px solid #ccc;
				}
				&__year {
					padding: 2px;
					width: 98px;
					font-size: 14px;
					line-height: 1.4;
					@include device_pc {
						padding-left: 64px;
						width: 242px;
						font-size: 18px;
					}
				}
				&__text {
					display: flex;
					align-items: center;
					padding: 6px;
					width: calc(100% - 98px);
					min-height: 76px;
					border-left: 1px solid #ccc;
					font-size: 14px;
					line-height: 1.4;
					@include device_pc {
						padding: 6px 45px;
						width: calc(100% - 242px);
						min-height: 54px;
						font-size: 18px;
					}
				}
			}
		}
		&-element {
			&__lists {
				@include device_pc {
					display: grid;
					grid-gap: 20px;
					grid-template-columns: repeat(2,calc(50% - 10px));
				}
				&__item {
					padding: 8px 8px 16px;
					border: 1px solid #707070;
					@include device_sp {
						&:not(:first-child) {
							margin-top: 8px;
						}
					}
					@include device_pc {
						display: grid;
						grid-gap: 24px;
						grid-template-columns: auto 167px;
						padding: 24px;
					}
				}
				&__title {
					padding-bottom: 8px;
					border-bottom: 1px solid #707070;
					text-align: center;
					font-size: 19px;
					font-weight: 400;
					color: #0168B7;
					@include device_sp {
						margin-bottom: 16px;
					}
					@include device_pc {
						grid-row: 1;
						grid-column: 1 / 3;
						padding-bottom: 16px;
						font-size: 33px;
					}
				}
				&__photo {
					@include device_sp {
						margin: 0 auto 8px;
						width: 60.071942446%;
					}
					@include device_pc {
						grid-row: 2;
						grid-column: 2;
					}
				}
				&__text {
					font-size: 14px;
					line-height: 1.4;
					@include device_sp {
						margin: 0 auto;
						width: 60.071942446%;
					}
					@include device_pc {
						grid-row: 2;
						grid-column: 1;
						font-size: 16px;
						line-height: 1.875;
					}
				}
			}
		}
		&-product {
			&__lists {
				border-top: 1px solid #ccc;
				&__item {
					padding: 12px 12px 16px;
					border-bottom: 1px solid #ccc;
					@include device_pc {
						display: flex;
						align-items: center;
						padding: 6px 0;
					}
				}
				&__title {
					text-align: center;
					font-size: 14px;
					font-weight: 400;
					@include device_sp {
						margin-bottom: 16px;
						padding-bottom: 16px;
						border-bottom: 1px solid #ccc;
					}
					@include device_pc {
						width: 322px;
						font-size: 18px;
						line-height: 1.6;
					}
				}
				&__text {
					padding: 0 12px;
					font-size: 14px;
					line-height: 1.4;
					@include device_pc {
						display: flex;
						align-items: center;
						padding: 10px 40px;
						width: calc(100% - (322px + 258px));
						min-height: 80px;
						border-left: 1px solid #ccc;
						font-size: 18px;
						line-height: 1.6;
					}
				}
				&__btn {
					@include device_sp {
						margin-top: 16px;
					}
					@include device_pc {
						margin: 0;
						width: 258px;
						font-size: 16px;
						a {
							padding: 12px 10px;
							&:after {
								right: 20px;
								margin-top: -8px;
								width: 8px;
								height: 16px;
							}
						}
					}
				}
			}
		}
	}
	/* product */
	&-product {
		&-lists {
			&__item {
				padding: 20px;
				background-color: #fff;
				@include device_pc {
					display: grid;
					grid-column-gap: 80px;
					grid-template-rows: auto auto 1fr;
					grid-template-columns: 29.0740740741%;
					padding: 40px 60px;
				}
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
			&__title {
				margin-bottom: 16px;
				padding-bottom: 4px;
				border-bottom: 1px solid #C6DBEC;
				font-size: 24px;
				font-weight: 400;
				color: #0168B7;
				@include device_pc {
					grid-row: 1;
					grid-column: 2;
					margin-bottom: 16px;
					padding-bottom: 16px;
					font-size: 33px;
				}
			}
			&__photo {
				@include device_sp {
					margin-bottom: 16px;
				}
				@include device_pc {
					grid-row: 1 / 4;
					grid-column: 1;
				}
			}
			&__text {
				font-size: 16px;
				line-height: 1.5625;
				@include device_pc {
					grid-row: 2;
					grid-column: 2;
					font-size: 18px;
				}
			}
			&__btn {
				margin-top: 16px;
				@include device_pc {
					grid-row: 3;
					grid-column: 2;
					margin: 42px 0 0;
					width: 258px;
					font-size: 16px;
					a {
						padding: 12px 10px;
						&:after {
							right: 24px;
							margin-top: -8px;
							width: 8px;
							height: 16px;
						}
					}
				}
			}
		}
	}
	/* field */
	&-field {
		&-lists {
			&__item {
				padding: 20px;
				background-color: #fff;
				@include device_pc {
					display: grid;
					grid-column-gap: 22px;
					grid-template-rows: auto auto 1fr;
					grid-template-columns: 29.0740740741%;
					padding: 40px 60px;
				}
				&:not(:first-child) {
					margin-top: 20px;
				}
			}
			&__title {
				margin-bottom: 16px;
				padding-bottom: 4px;
				border-bottom: 1px solid #C6DBEC;
				font-size: 24px;
				font-weight: 400;
				color: #0168B7;
				@include device_pc {
					grid-row: 1;
					grid-column: 2;
					margin-bottom: 36px;
					padding-bottom: 16px;
					font-size: 33px;
				}
			}
			&__photo {
				@include device_sp {
					margin-bottom: 16px;
				}
				@include device_pc {
					grid-row: 1 / 4;
					grid-column: 1;
				}
			}
			&__links {
				@include device_pc {
					display: grid;
					grid-gap: 16px;
					grid-template-columns: repeat(2,calc(50% - 8px));
					grid-row: 2;
					grid-column: 2;
					font-size: 18px;
				}
				&__item {
					font-size: 16px;
					line-height: 1.5625;
					@include device_sp {
						&:not(:first-child) {
							margin-top: 16px;
						}
					}
					@include device_pc {
						font-size: 18px;
					}
					& > a {
						display: block;
						position: relative;
						padding: 16px 20px 16px 12px;
						border: 1px solid #0168B7;
						color: #0168B7;
						@include device_pc {
							padding: 14px 15px;
						}
						&::after {
							content: '';
							position: absolute;
							top: 50%;
							right: 10px;
							margin-top: -8px;
							width: 8px;
							height: 16px;
							background: url(../img/icon/arrow7.png) no-repeat center center;
							background-size: 100% 100%;
						}
					}
				}
			}
		}
	}
	/* detail */
	&-detail {
		& > .wrap {
			@include device_sp {
				padding: 0 20px;
			}
		}
		&-hd {
			position: relative;
		}
		&-title {
			border-bottom: solid #ccc 2px;
			font-size: 21px;
			font-weight: 400;
			color: #0168b7;
			@include device_sp {
				padding-bottom: 12px;
			}
			@include device_pc {
				font-size: 42px;
			}
		}
		&-content {
			margin-top: 18px;
			font-size: 14px;
			line-height: 1.625;
			@include device_pc {
				margin-top: 36px;
				font-size: 16px;
			}
			p {
				& + p {
					margin-top: 1.4em;
					@include device_pc {
						margin-top: 1.6em;
					}
				}
			}
			h2,
			h4 {
				position: relative;
				margin-bottom: 20px;
				padding-left: 12px;
				font-size: 20px;
				font-weight: 400;
				color: #0168B7;
				@include device_pc {
					margin-bottom: 40px;
					padding-left: 20px;
					font-size: 28px;
				}
				&:not(:first-child) {
					margin-top: 40px;
					@include device_pc {
						margin-top: 60px;
					}
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
					width: 2px;
					height: 22px;
					background-color: #0168B7;
					@include device_pc {
						width: 3px;
						height: 46px;
					}
				}
			}
			h3 {
				margin-bottom: 16px;
				font-size: 16px;
				font-weight: 400;
				@include device_pc {
					margin-bottom: 32px;
					font-size: 20px;
				}
				&:not(:first-child) {
					margin-top: 30px;
					@include device_pc {
						margin-top: 50px;
					}
				}
			}
			ul {
				margin-left: 20px;
				list-style: disc;
			}
		}
		.leo-link {
			margin: 60px 0;
			@include device_pc {
				margin: 80px 0;
			}
		}
	}
}

.page-lng-en {
	.title--ribbon--name {
		font-size: 35px;
		@include device_sp {
			padding: 12px 10px 0;
			width: 302px;
			height: 159px;
			background-image: url(../img/leo/en/title_ribbon.png);
		}
		@include device_pc {
			padding-top: 20px;
			font-size: 72px;
		}
		@media screen and (min-width: $mediaPC) and (max-width: 1000px) {
			padding-top: 2vw;
			font-size: 7.2vw;
		}
	}
	.leo-field-lists__links__item > a {
		display: flex;
		align-items: center;
		min-height: 84px;
		@include device_pc {
			min-height: 86px;
		}
	}
	.leo-measurement-lists__btn {
		@include device_sp {
			max-width: 100%;
			font-size: 14px;
			line-height: 1.6;
		}
		@include device_pc {
			line-height: 1.5625;
		}
		& > a {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 34px;
			min-height: 60px;
			@include device_pc {
				min-height: 72px;
			}
		}
	}
}