@charset 'utf-8';

@import '../variable';

/* more
------------------------------------------------ */
.more {
	&--1 {
		position: relative;
		margin: 56px auto 0;
		max-width: 400px;
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 20px 10px;
			width: 100%;
			background: #404040;
			border-radius: 35px;
			color: #fff;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 38px;
				margin-top: -10px;
				width: 11px;
				height: 20px;
				background: url(../img/icon/arrow3.png) no-repeat center center;
				background-size: 100%;
			}
		}
		@include device_sp {
			margin-top: 28px;
			max-width: 300px;
			font-size: 16px;
			a {
				padding: 16px 10px;
				&:after {
					right: 25px;
					margin-top: -6px;
					width: 7px;
					height: 13px;
				}
			}
		}
	}
	&--2 {
		position: relative;
		margin: 56px auto 0;
		max-width: 400px;
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 20px 10px;
			width: 100%;
			background: #fff;
			border: 1px solid #404040;
			border-radius: 35px;
			color: #404040;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 38px;
				margin-top: -10px;
				width: 11px;
				height: 20px;
				background: url(../img/icon/arrow9.png) no-repeat center center;
				background-size: 100%;
			}
		}
		@include device_sp {
			margin-top: 28px;
			max-width: 300px;
			font-size: 16px;
			a {
				padding: 16px 10px;
				&:after {
					right: 25px;
					margin-top: -6px;
					width: 7px;
					height: 13px;
				}
			}
		}
	}
}

/* btn
------------------------------------------------ */
.btn {
	&--normal {
		position: relative;
		margin: 54px auto 0;
		max-width: 420px;
		text-align: center;
		font-size: 24px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 20px 10px;
			width: 100%;
			background: #0168b7;
			border: solid #0168b7 1px;
			border-radius: 35px;
			color: #fff;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				right: 24px;
				margin-top: -11px;
				width: 11px;
				height: 22px;
				background: url(../img/icon/arrow3.png) no-repeat center center;
				background-size: 100%;
				vertical-align: top;
			}
		}
		@include device_sp {
			margin-top: 18px;
			font-size: 16px;
			max-width: 310px;
			a {
				padding: 15px 10px;
				&:after {
					right: 20px;
					margin-top: -6px;
					width: 7px;
					height: 13px;
				}
			}
		}
	}
	&--back {
		position: relative;
		margin: 72px auto 0;
		max-width: 300px;
		text-align: center;
		font-size: 18px;
		line-height: 1.2;
		a {
			display: block;
			position: relative;
			padding: 14px 10px;
			background: #404040;
			border: solid #404040 1px;
			border-radius: 25px;
			vertical-align: top;
			color: #fff;
		}
		@include device_sp {
			margin-top: 34px;
			font-size: 16px;
		}
	}
}
