@charset 'utf-8';

@import '../variable';

/* flow
------------------------------------------------ */
.flow {
	/* top */
	&-top {
		padding-bottom: 100px;
		@include device_sp {
			padding-bottom: 50px;
		}
	}
	/* step */
	&__step {
		&__lists {
			&--item {
				display: flex;
				align-items: center;
				position: relative;
				padding: 20px 50px;
				background: #e5f0f8;
				min-height: 260px;
				&:nth-of-type(even) {
					background: #f8fbfd;
				}
			}
			&--response {
				display: flex;
				justify-content: center;
				align-items: center;
				position: absolute;
				top: 0;
				width: 50px;
				height: 100%;
				font-size: 26px;
				font-weight: 700;
				line-height: 1.2;
				color: #fff;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					width: 0;
					height: 0;
					margin-top: -14px;
					border: solid transparent 14px;
				}
				&.guest {
					left: 0;
					background: #0168b7;
					&:after {
						right: -16px;
						border-right: none;
						border-left: solid #0168b7 16px;
					}
				}
				&.company {
					right: 0;
					background: #7cafd7;
					&:after {
						left: -16px;
						border-left: none;
						border-right: solid #7cafd7 16px;
					}
				}
			}
			&__symbol {
				width: 308px;
				text-align: center;
			}
			&--name {
				font-family: $fontMin;
				font-size: 50px;
				line-height: 1.2;
				color: #404040;
				.num {
					display: inline-block;
					vertical-align: baseline;
					font-size: 65px;
				}
			}
			&--icon {
				display: block;
				margin: 10px auto 0;
				width: 80px;
			}
			&__info {
				position: relative;
				width: calc(100% - 400px);
			}
			&--title {
				font-size: 40px;
				font-weight: 700;
				color: #0168b7;
			}
			&--text {
				font-size: 24px;
			}
		}
		&__contact {
			margin-top: 86px;
			&--text {
				text-align: center;
				font-size: 24px;
			}
		}
		@include device_pc {
		}
		@include device_sp {
			&__lists {
				margin: 0 20px;
				&--item {
					display: block;
					padding: 12px 50px;
					min-height: inherit;
				}
				&--response {
					width: 30px;
					font-size: 15px;
					&:after {
						margin-top: -8px;
						border: solid transparent 8px;
					}
					&.guest {
						&:after {
							right: -10px;
							border-right: none;
							border-left: solid #0168b7 10px;
						}
					}
					&.company {
						&:after {
							left: -10px;
							border-left: none;
							border-right: solid #7cafd7 10px;
						}
					}
				}
				&__symbol {
					width: auto;
					text-align: left;
				}
				&--name {
					font-size: 25px;
					.num {
						font-size: 30px;
					}
				}
				&--icon {
					display: none;
				}
				&__info {
					width: auto;
				}
				&--title {
					font-size: 20px;
				}
				&--text {
					font-size: 15px;
				}
			}
			&__contact {
				margin: 42px 20px 0;
				&--text {
					font-size: 15px;
				}
			}
		}
	}
}
