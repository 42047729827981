@charset 'utf-8';

@import '../variable';

/* header
------------------------------------------------ */
#header {
	position: relative;
	width: 100%;
	box-shadow: 0 2px 4px rgba(0,0,0,.1);
	z-index: 10;
	&-top {
		position: relative;
		width: 100%;
		height: 67px;
		background: #fff;
		.wrap {
			height: 100%;
		}
		@include device_sp {
			height: 55px;
		}
	}
	&-menu {
		display: none;
		position: absolute;
		top: 55px;
		left: 0;
		width: 100%;
		background: #f8fbfd;
		.frame {
			position: relative;
			width: 100%;
			height: calc(100vh - 55px);
			overflow: auto;
		}
		@include device_pc {
			display: none !important;
		}
		@include device_sp {
		}
	}
}

#hd {
	&-search {
		display: none;
		position: absolute;
		top: 55px;
		left: 0;
		width: 100%;
		background-color: #EDEDED;
		box-shadow: 0 2px 4px rgba(0,0,0,.1);
		@include device_pc {
			top: 67px;
		}
	}
}

#gnav {
	position: absolute;
	top: 0;
	right: 20px;
	height: 100%;
	.frame {
		display: flex;
		align-items: flex-start;
		height: 100%;
	}
	@include device_pc {
		display: block !important;
	}
	@include device_sp {
		display: none;
	}
}

.header {
	&--logo {
		position: absolute;
		top: 12px;
		left: 20px;
		width: 123px;
		font-size: 10px;
		font-weight: normal;
		a {
			display: block;
		}
		@media screen and (max-width: 1000px) and (min-width: $mediaPC) {
			top: 15px;
			width: 100px;
		}
		@include device_sp {
			top: 8px;
			width: 110px;
		}
	}
	&--menu {
		&--btn {
			position: absolute;
			top: 10px;
			right: 10px;
			width: 34px;
			height: 34px;
			a,
			span {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
			@mixin headerMenuBtn() {
				content: '';
				position: absolute;
				left: 7px;
				transition: ease .3s;
				width: 20px;
				height: 2px;
				background: #fff;
			}
			a {
				background: #0068b7;
				&:after {
					@include headerMenuBtn();
					top: 8px;
				}
				&.active {
					&:after {
						display: none;
					}
					span {
						&:before,
						&:after {
							top: 16px;
						}
						&:before {
							transform: rotate(45deg);
						}
						&:after {
							transform: rotate(-45deg);
						}
					}
				}
			}
			span {
				text-indent: -9999px;
				white-space: nowrap;
				overflow: hidden;
				&:before,
				&:after {
					@include headerMenuBtn();
				}
				&:before {
					top: 16px;
				}
				&:after {
					top: 24px;
				}
			}
			@include device_pc {
				display: none;
			}
		}
	}
}

.hd {
	&-search {
		&-btn {
			position: absolute;
			top: 10px;
			right: 54px;
			width: 34px;
			height: 34px;
			text-align: center;
			font-size: 12px;
			line-height: 1.2;
			@include device_pc {
				display: none;
			}
			& > a {
				display: flex;
				flex-wrap: wrap;
				align-items: center;
				position: relative;
				width: 100%;
				height: 100%;
				&::before,
				&::after {
					content: '';
					position: absolute;
					top: 6px;
					left: 50%;
					transition: ease 0.3s;
					margin-left: -9px;
					width: 18px;
					height: 2px;
					background: #000;
					opacity: 0;
				}
				&.is-active {
					&::before,
					&::after {
						opacity: 1;
					}
					&::before {
						transform: rotate(45deg);
					}
					&::after {
						transform: rotate(-45deg);
					}
					span {
						&::before {
							opacity: 0;
						}
					}
				}
			}
			span {
				display: block;
				width: 100%;
				&::before {
					content: '';
					display: block;
					margin: 0 auto 2px;
					width: 14px;
					height: 14px;
					background: url(../img/icon/search2.png) no-repeat center center;
					background-size: 100%;
				}
			}
		}
		&-from {
			padding: 10px;
			@include device_pc {
				padding: 28px 0;
			}
		}
		&-keyword {
			display: flex;
			height: 44px;
			&--input {
				width: calc(100% - 83px);
				height: 100%;
				@include device_pc {
					width: calc(100% - 143px);
				}
				input {
					display: block;
					appearance: none;
					padding: 10px;
					width: 100%;
					height: 100%;
					background-color: #fff;
					border: none;
					border-radius: 5px 0 0 5px;
					font-size: 16px;
					@include device_pc {
						padding: 10px 26px;
						font-size: 14px;
					}
				}
			}
			&--submit {
				margin-left: 3px;
				width: 80px;
				height: 100%;
				@include device_pc {
					width: 140px;
				}
				button {
					display: flex;
					justify-content: center;
					align-items: center;
					appearance: none;
					width: 100%;
					height: 100%;
					background-color: #0168B7;
					border: none;
					border-radius: 0 5px 5px 0;
					font-size: 14px;
					font-weight: bold;
					color: #fff;
					cursor: pointer;
					@include device_pc {
						font-size: 16px;
					}
					&::after {
						content: '';
						display: block;
						margin-left: 6px;
						width: 14px;
						height: 14px;
						background: url(../img/icon/search3.png) no-repeat center center;
						background-size: 100%;
						@include device_pc {
							margin-left: 12px;
							width: 17px;
							height: 17px;
						}
					}
				}
			}
		}
	}
}

.gnav {
	&__links {
		display: flex;
		align-items: center;
		margin-top: 14px;
		&--item {
			position: relative;
			margin-left: 20px;
			font-size: 15px;
			line-height: 1.2;
			&:first-child { margin-left: 0; }
			a {
				display: inline-block;
				padding: 11px 0;
				vertical-align: top;
				&.active {
					font-weight: 700;
					color: #0168b7;
				}
			}
		}
		&__sub {
			display: none;
			position: absolute;
			top: 53px;
			left: 50%;
			transform: translateX(-50%);
			padding: 0 32px 30px;
			min-width: 250px;
			background-color: #fff;
			box-shadow: 0 5px 6px rgba(0,0,0,0.2);
			&--item {
				position: relative;
				border-bottom: 1px solid #ddd;
				font-size: 14px;
				&:after {
					content: '';
					position: absolute;
					bottom: 0;
					left: 24px;
					width: calc(100% - 48px);
					height: 1px;
					background: #fff;
				}
				&:last-child {
					&:after {
						display: none;
					}
				}
				a {
					display: block;
					position: relative;
					padding: 14px 16px 14px 0;
					white-space: nowrap;
					color: #404040;
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: 4px;
						width: 6px;
						height: 10px;
						margin-top: -5px;
						background: url(../img/icon/arrow1.png) no-repeat center center;
						background-size: 100% 100%;
					}
				}
			}
		}
		@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
			&--item {
				font-size: 12px;
			}
		}
		@media screen and (max-width: 1000px) and (min-width: $mediaPC) {
			&--item {
				margin-left: 10px;
				font-size: 10px;
			}
		}
	}
	/*
	&__lng {
		display: flex;
		align-items: center;
		margin: 14px 0 0 20px;
		&--item {
			text-align: center;
			font-size: 15px;
			font-weight: 700;
			line-height: 1.2;
			a {
				display: inline-block;
				padding: 11px 4px;
				min-width: 100px;
				background: #404040;
				border-radius: 40px;
				vertical-align: top;
				color: #fff;
			}
		}
		@media screen and (max-width: 1200px) {
			&--item {
				font-size: 12px;
				a {
					min-width: 80px;
				}
			}
		}
	}
	*/
	&__btns {
		display: flex;
		align-items: center;
		margin-left: 10px;
		&--item {
			height: 59px;
			text-align: center;
			font-size: 15px;
			line-height: 1.2;
			&:first-child {
				a {
					border-radius: 0 0 0 10px;
				}
			}
			&:last-child {
				a {
					border-radius: 0 0 10px 0;
				}
			}
			a {
				display: block;
				padding: 25px 10px 0;
				height: 100%;
				color: #fff;
			}
		}
		&--recruit {
			a {
				min-width: 120px;
				background: #2b9ab7;
			}
		}
		&--contact {
			a {
				min-width: 160px;
				background: #0168b7;
				&:before {
					content: '';
					display: inline-block;
					margin: -1px 6px 0 0;
					width: 26px;
					height: 20px;
					background: url(../img/icon/mail1.png) no-repeat center center;
					background-size: 100%;
					vertical-align: top;
				}
			}
		}
		@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
			&--recruit {
				a {
					min-width: 100px;
				}
			}
			&--contact {
				a {
					min-width: 140px;
				}
			}
		}
		@media screen and (max-width: 1000px) and (min-width: $mediaPC) {
			margin-left: 5px;
			&--item {
				font-size: 12px;
			}
			&--recruit {
				a {
					min-width: 60px;
				}
			}
			&--contact {
				a {
					min-width: 100px;
					&:before {
						margin-top: 3px;
						width: 12px;
						height: 10px;
					}
				}
			}
		}
	}
	&-search {
		&-btn {
			width: 65px;
			height: 100%;
			text-align: center;
			font-size: 14px;
			line-height: 1.2;
			@include device_sp {
				display: none;
			}
			@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
				font-size: 12px;
				& > a {
					padding-top: 15px;
					&::before {
						width: 15px;
						height: 15px;
					}
				}
			}
			@media screen and (max-width: 1000px) and (min-width: $mediaPC) {
				font-size: 10px;
				& > a {
					padding-top: 18px;
					&::before {
						width: 12px;
						height: 12px;
					}
				}
			}
			& > a {
				display: block;
				padding-top: 13px;
				width: 100%;
				height: 100%;
				&::before {
					content: '';
					display: block;
					margin: 0 auto 6px;
					width: 17px;
					height: 17px;
					background: url(../img/icon/search2.png) no-repeat center center;
					background-size: 100%;
				}
				&.is-active {
					background-color: #EDEDED;
				}
			}
		}
	}
}