@charset 'utf-8';

@import '../variable';

/* notfound
------------------------------------------------ */
.notfound {
	&-top {
		padding-bottom: 40px;
		@include device_pc {
			padding-bottom: 86px;
		}
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/404/title_bg.jpg);
					background-size: cover;
					font-size: 26px;
				}
				@include device_pc {
					background-image: url(../img/404/title_bg-pc.jpg);
				}
			}
		}
	}
	&-content {
		padding-top: 40px;
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
			padding-top: 100px;
		}
		.more {
			&--1 {
				margin-top: 40px;
				@include device_pc {
					margin-top: 86px;
				}
				& > a {
					background-color: #0168B7;
				}
			}
		}
	}
	&-title {
		margin-bottom: 20px;
		text-align: center;
		font-size: 24px;
		font-weight: normal;
		line-height: 1.4;
		color: #0168B7;
		@include device_pc {
			margin-bottom: 40px;
			font-size: 48px;
		}
	}
	&-box {
		padding: 18px 30px;
		background-color: #F8F8F8;
		@include device_pc {
			margin: 0 auto;
			padding: 34px 60px;
			max-width: 950px;
		}
		&-title {
			padding-bottom: 12px;
			font-size: 12px;
			line-height: 1.8;
			border-bottom: 1px solid #707070;
			@include device_pc {
				padding-bottom: 18px;
				font-size: 23px;
			}
		}
		&-content {
			padding-top: 16px;
			@include device_pc {
				padding-top: 20px;
			}
		}
		&-lists {
			&--item {
				position: relative;
				font-size: 12px;
				line-height: 2;
				@include device_pc {
					font-size: 16px;
					line-height: 3.0625;
				}
				&::before {
					content: '\30FB';
				}
			}
		}
	}
}
