@charset 'utf-8';

$fontGotic: 'Noto Sans JP', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3', 'Meiryo', 'メイリオ', 'ＭＳ Ｐゴシック', 'MS P Gothic', Osaka, Arial, Helvetica, Verdana, sans-serif;
$fontMin: 'Noto Serif JP', 'HiraMinProN-W3', 'ヒラギノ明朝 ProN W3', 'MS P Mincho', 'ＭＳ Ｐ明朝', 'MS 明朝', 'HG明朝E', 'Century', 'Times New Roman', serif;

$mediaPC: 769px;
$mediaSP: 768px;

@mixin device_pc() {
	@media screen and (min-width: $mediaPC) {
		@content;
	}
}

@mixin device_sp() {
	@media screen and (max-width: $mediaSP) {
		@content;
	}
}
