
@charset 'utf-8';

@import '../../variable';

/* style1
------------------------------------------------ */
.style1 {
	position: relative;
	padding-top: 30px;
	background-color: #E5F0F8;
	@include device_pc {
		padding-top: 40px;
	}
	&-hd {
		position: relative;
		@include device_sp {
			margin: 0 20px 10px;
		}
		@include device_pc {
			padding-bottom: 15px;
		}
		&-title {
			margin-bottom: 20px;
			text-align: center;
			font-size: 30px;
			font-weight: normal;
			line-height: 1.33333333333;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 30px;
				font-size: 52px;
				line-height: 1.19230769231;
			}
		}
	}
	.topicpath {
		@include device_pc {
			margin-bottom: 15px;
		}
	}
	&-content {
		position: relative;
		@include device_sp {
			margin: 0 20px;
		}
	}
	&-block {
		position: relative;
		padding: 20px;
		background-color: #fff;
		@include device_pc {
			padding: 40px 60px;
		}
		& + & {
			margin-top: 20px;
		}
		&-title {
			margin-bottom: 20px;
			padding-bottom: 8px;
			border-bottom: 1px solid #ccc;
			font-size: 19px;
			font-weight: bold;
			line-height: 1.52631578947;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 30px;
				padding-bottom: 16px;
				font-size: 28px;
			}
		}
		&-content {
			position: relative;
			figure {
				display: block;
				width: 100%;
				figcaption {
					margin-top: 10px;
					text-align: center;
					font-size: 15px;
					font-weight: bold;
					line-height: 1.2;
					@include device_pc {
						margin-top: 14px;
						font-size: 18px;
					}
				}
			}
		}
	}
	&-sub {
		position: relative;
		& + & {
			margin-top: 40px;
		}
		&-title {
			margin-bottom: 20px;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.61111111111;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 18px;
				font-size: 24px;
			}
		}
		&-content {
			position: relative;
		}
	}
	&-text {
		&1 {
			margin: 20px 0;
			font-size: 15px;
			line-height: 1.66666666667;
			@include device_pc {
				margin: 40px 0 30px;
			}
			&:first-child { margin-top: 0; }
		}
	}
	&-image {
		&1 {
			margin-top: 20px;
			@include device_pc {
				margin: 30px 90px 0;
			}
		}
	}
	&-images {
		&1 {
			margin: 20px 0;
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
				justify-content: center;
				& + .style1-text1 { margin-top: 40px; }
			}
			& > figure {
				@include device_sp {
					margin: 0 auto;
				}
				@include device_pc {
					margin-left: 2.77777777778%;
					&:first-child { margin-left: 0; }
				}
				& + figure {
					@include device_sp {
						margin-top: 20px;
					}
				}
				&.w {
					@include device_sp {
						$base_width : 295;
						&-sp {
							&-262 {
								width: math.div(262*100%,$base_width);
							}
							&-256 {
								width: math.div(256*100%,$base_width);
							}
							&-212 {
								width: math.div(212*100%,$base_width);
							}
						}
					}
					@include device_pc {
						$base_width : 1080;
						&-pc {
							&-874 {
								width: math.div(874*100%,$base_width);
							}
							&-852 {
								width: math.div(852*100%,$base_width);
							}
							&-744 {
								width: math.div(744*100%,$base_width);
							}
							&-723 {
								width: math.div(723*100%,$base_width);
							}
							&-600 {
								width: math.div(600*100%,$base_width);
							}
							&-494 {
								width: math.div(494*100%,$base_width);
							}
							&-483 {
								width: math.div(483*100%,$base_width);
							}
							&-463 {
								width: math.div(463*100%,$base_width);
							}
							&-457 {
								width: math.div(457*100%,$base_width);
							}
							&-452 {
								width: math.div(452*100%,$base_width);
							}
							&-440 {
								width: math.div(440*100%,$base_width);
							}
							&-421 {
								width: math.div(421*100%,$base_width);
							}
							&-414 {
								width: math.div(414*100%,$base_width);
							}
							&-400 {
								width: math.div(400*100%,$base_width);
							}
							&-388 {
								width: math.div(388*100%,$base_width);
							}
							&-355 {
								width: math.div(355*100%,$base_width);
							}
							&-352 {
								width: math.div(352*100%,$base_width);
							}
							&-344 {
								width: math.div(344*100%,$base_width);
							}
							&-341 {
								width: math.div(341*100%,$base_width);
							}
							&-326 {
								width: math.div(326*100%,$base_width);
							}
							&-300 {
								width: math.div(300*100%,$base_width);
							}
							&-290 {
								width: math.div(290*100%,$base_width);
							}
							&-275 {
								width: math.div(275*100%,$base_width);
							}
							&-268 {
								width: math.div(268*100%,$base_width);
							}
							&-262 {
								width: math.div(262*100%,$base_width);
							}
							&-256 {
								width: math.div(256*100%,$base_width);
							}
							&-212 {
								width: math.div(212*100%,$base_width);
							}
							&-132 {
								width: math.div(132*100%,$base_width);
							}
						}
					}
				}
				&.space {
					@include device_pc {
						&-pc {
							$base_width : 1080;
							&-10 {
								margin-left: math.div(10*100%,$base_width);
							}
						}
					}
				}
			}
		}
	}
	&-lists {
		&1 {
			position: relative;
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&-item {
				position: relative;
				padding: 0 30px 0 75px;
				background-color: #E5F0F8;
				border-radius: 50px;
				font-size: 14px;
				font-weight: bold;
				line-height: 1.71428571429;
				@include device_sp {
					display: flex;
					align-items: center;
					width: 100%;
					height: 100px;
				}
				@include device_pc {
					margin: 0.92592592592% 0.92592592592% 0 0;
					padding: 80px 24px 10px;
					width: 19.2592592593%;
					min-height: 180px;
					text-align: center;
					font-size: 15px;
					&:nth-of-type(-n+5) { margin-top: 0; }
					&:nth-of-type(5n) { margin-right: 0; }
				}
				& + & {
					@include device_sp {
						margin-top: 10px;
					}
				}
				&::after {
					content: '';
					position: absolute;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: 100%;
					@include device_sp {
						top: 50%;
						left: 20px;
						transform: translateY(-50%);
					}
					@include device_pc {
						top: 20px;
						left: 50%;
						transform: translateX(-50%);
					}
				}
				&.i {
					&-light::after {
						width: 45px;
						height: 48px;
						background-image: url(../img/icon/light1.png);
					}
					&-brain::after {
						width: 40px;
						height: 41px;
						background-image: url(../img/icon/brain1.png);
						@include device_pc {
							top: 29px;
							width: 33px;
							height: 34px;
						}
					}
				}
			}
		}
		&2 {
			position: relative;
			margin-bottom: 20px;
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
				margin: -20px 0 30px -20px;
			}
			&-item {
				position: relative;
				padding: 18px 15px;
				background-color: #F8F8F8;
				border: 1px solid #ccc;
				text-align: center;
				font-size: 15px;
				line-height: 1.46666666667;
				@include device_pc {
					margin: 20px 0 0 20px;
					padding: 14px 15px;
					width: calc(33.3333333% - 20px);
					font-size: 18px;
					&.w-double {
						width: calc(50% - 20px);
					}
				}
				& + & {
					@include device_sp {
						margin-top: 15px;
					}
				}
			}
		}
	}
	&-layout {
		&1 {
			position: relative;
			@include device_pc {
				display: flex;
			}
			&-text {
				font-size: 15px;
				line-height: 1.66666666667;
				@include device_pc {
					margin-left: 10px;
					width: calc(100% - 310px);
				}
			}
			&-image {
				@include device_sp {
					margin: 20px 13px 0;
				}
				@include device_pc {
					margin-left: 30px;
					width: 270px;
				}
			}
		}
		&2 {
			margin-top: 30px;
			&-title {
				margin-bottom: 15px;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.38888888889;
				@include device_pc {
					font-size: 20px;
					line-height: 1.25;
				}
			}
			&-content {
				position: relative;
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
				}
				& > dl {
					padding: 20px 10px;
					background-color: #F8F8F8;
					@include device_pc {
						margin: 2.7777777778% 2.7777777778% 0 0;
						padding: 30px 52px;
						width: 48.6111111111%;
						&:nth-of-type(-n+2) { margin-top: 0; }
						&:nth-of-type(2n) { margin-right: 0; }
					}
					& + dl {
						@include device_sp {
							margin-top: 10px;
						}
					}
					& > dt {
						margin-bottom: 10px;
						text-align: center;
						font-size: 15px;
						font-weight: bold;
						line-height: 1.46666666667;
						@include device_pc {
							font-size: 18px;
							line-height: 1.5;
						}
					}
					& > dd {
						& + dt {
							margin-top: 20px;
							@include device_pc {
								margin-top: 30px;
							}
						}
						figure {
							figcaption {
								font-weight: normal;
								line-height: 1.46666666667;
							}
						}
					}
				}
			}
		}
	}
	&-box {
		&1 {
			position: relative;
			margin-top: 20px;
			padding: 20px;
			background-color: #F8F8F8;
			@include device_pc {
				margin-top: 30px;
				padding: 24px 40px;
			}
			&-title {
				margin-bottom: 15px;
				font-size: 18px;
				font-weight: bold;
				line-height: 1.4;
			}
			&-content {
				position: relative;
				@include device_pc {
					display: flex;
				}
			}
			&-lists {
				position: relative;
				@include device_pc {
					width: 50%;
				}
				& + & {
					@include device_sp {
						margin-top: 10px;
					}
				}
				&--item {
					font-size: 15px;
					line-height: 1.66666666667;
					& + & {
						margin-top: 10px;
					}
					& > a,
					& > span {
						display: inline-block;
						position: relative;
						padding-left: 25px;
						vertical-align: top;
						&::before {
							content: '';
							position: absolute;
							top: 10px;
							left: 0;
							width: 12px;
							height: 9px;
							background: url(../img/icon/check1.png) no-repeat center center;
							background-size: 100%;
						}
					}
					& > a {
						text-decoration: underline;
						color: #0168B7;
					}
				}
			}
		}
	}
	&-merit {
		&1 {
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&-item {
				position: relative;
				padding-bottom: 42.3728813559%;
				width: 100%;
				height: 0;
				overflow: hidden;
				@include device_pc {
					margin: 0.92592592592% 0.92592592592% 0 0;
					padding-bottom: 19.2592592593%;
					width: 19.2592592593%;
					text-align: center;
					&:nth-of-type(-n+5) { margin-top: 0; }
					&:nth-of-type(5n) { margin-right: 0; }
				}
				& + & {
					@include device_sp {
						margin-top: 10px;
					}
				}
				& > .frame {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #3284C4;
					border-radius: 63px;
					color: #fff;
					@include device_sp {
						display: flex;
						align-items: center;
					}
					@include device_pc {
						border-radius: 50%;
					}
				}
			}
			&-top {
				text-align: center;
				@include device_sp {
					width: 100px;
				}
				@include device_pc {
					margin-bottom: 10px;
					padding-top: 28px;
				}
			}
			&-name {
				font-family: $fontMin;
				font-size: 16px;
				line-height: 1.5;
				@include device_pc {
					font-size: 18px;
				}
			}
			&-num {
				font-family: $fontMin;
				font-size: 32px;
				line-height: 1.0625;
				@include device_pc {
					font-size: 34px;
				}
			}
			&-text {
				font-size: 15px;
				font-weight: bold;
				line-height: 1.53333333333;
				@include device_sp {
					padding-right: 30px;
					width: calc(100% - 100px);
				}
				@include device_pc {
					padding: 0 18px;
				}
			}
		}
		&2 {
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&-item {
				position: relative;
				padding-bottom: 42.3728813559%;
				width: 100%;
				height: 0;
				overflow: hidden;
				@include device_pc {
					margin: 0.9722222222% 0.9722222222% 0 0;
					padding-bottom: 11.5740740741%;
					width: 32.6851851852%;
					&:nth-of-type(-n+3) { margin-top: 0; }
					&:nth-of-type(3n) { margin-right: 0; }
				}
				& + & {
					@include device_sp {
						margin-top: 10px;
					}
				}
				& > .frame {
					display: flex;
					align-items: center;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-color: #3284C4;
					border-radius: 63px;
					color: #fff;
				}
			}
			&-top {
				text-align: center;
				width: 100px;
				@include device_pc {
					padding-left: 20px;
					width: 138px;
				}
			}
			&-name {
				font-family: $fontMin;
				font-size: 16px;
				line-height: 1.5;
				@include device_pc {
					font-size: 18px;
				}
			}
			&-num {
				font-family: $fontMin;
				font-size: 32px;
				line-height: 1.0625;
				@include device_pc {
					font-size: 34px;
				}
			}
			&-text {
				padding-right: 30px;
				width: calc(100% - 100px);
				font-size: 15px;
				font-weight: bold;
				line-height: 1.53333333333;
				@include device_pc {
					padding-right: 50px;
					width: calc(100% - 138px);
				}
			}
		}
	}
	&-back {
		&-link {
			margin-top: 30px;
			text-align: center;
			font-size: 20px;
			line-height: 1.5;
			@include device_pc {
				margin-top: 60px;
				font-size: 28px;
			}
			& > a {
				display: inline-block;
				text-decoration: underline;
				vertical-align: top;
				color: #0168B7;
			}
			& + .style1-flow-btn {
				margin-top: 30px;
				@include device_pc {
					margin-top: 68px;
				}
			}
		}
	}
	&-flow {
		&-btn {
			margin-top: 40px;
			@include device_pc {
				margin: 85px auto 0;
				width: 83.3333333333%;
			}
			& > a {
				display: block;
				position: relative;
				padding: 22px 0 18px;
				background-color: #2B9AB6;
				border-radius: 6px;
				@include device_pc {
					display: flex;
					justify-content: center;
					align-items: center;
					padding: 45px 0;
					border-radius: 10px;
				}
				&::before {
					content: '';
					position: absolute;
					top: 5px;
					left: 5px;
					width: calc(100% - 10px);
					height: calc(100% - 10px);
					border: 2px solid #fff;
					border-radius: 6px;
					@include device_pc {
						border-radius: 10px;
					}
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 22px;
					transform: translateY(-50%);
					width: 12px;
					height: 25px;
					background: url(../img/icon/arrow8.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						right: 75px;
						width: 14px;
						height: 29px;
					}
				}
			}
			&--icon {
				display: flex;
				justify-content: center;
				@include device_pc {
					width: 46.9%;
				}
				i {
					width: 35px;
					@include device_sp {
						margin: 0 4px;
					}
					@include device_pc {
						width: 18.5501066098%;
						&:not(:first-child) { margin-left: 1.81236673775%; }
					}
				}
			}
			&--text {
				font-size: 26px;
				font-weight: bold;
				line-height: 1.69230769231;
				color: #fff;
				@include device_sp {
					margin-top: 2px;
					text-align: center;
				}
				@include device_pc {
					margin-left: 32px;
					font-size: 40px;
					line-height: 1.1;
				}
			}
		}
	}
	.block-contact-btm {
		margin-top: 40px;
		@include device_pc {
			margin-top: 80px;
		}
	}
	@include device_sp {
	}
	@include device_pc {
	}
}
