@charset 'utf-8';

@import '../variable';

/* Tech Library
------------------------------------------------ */
.techlib {
	/* top */
	&-top {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/techlibrary/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/techlibrary/title_bg-pc.jpg);
				}
			}
		}
	}
	/* lists */
	&-lists {
		@include device_sp {
			margin: 0 20px;
			.title--5 {
				display: block;
				margin-bottom: 14px;
				.l,
				.min {
					display: inline;
				}
			}
		}
		&-lead {
			font-size: 14px;
			line-height: 1.75;
			@include device_pc {
				font-size: 15px;
			}
			span {
				display: inline-block;
				vertical-align: top;
				font-size: 11px;
				@include device_pc {
					font-size: 12px;
				}
			}
		}
	}
	/* nav */
	&-nav {
		margin: 12px 20px 0;
		@include device_pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			margin: 30px 0;
		}
		&-archive {
			margin-left: auto;
			width: 150px;
			height: 40px;
			@include device_pc {
				width: 300px;
				height: 58px;
			}
			select {
				padding: 0 10px;
				width: 100%;
				height: 100%;
				font-family: inherit;
				font-size: 15px;
				@include device_pc {
					padding: 0 20px;
					font-size: 24px;
				}
			}
		}
	}
	/* archive */
	&-archive {
		margin-top: 25px;
		@include device_pc {
			margin-top: 32px;
		}
		&-box {
			margin-top: 30px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 52px;
			}
			&--title {
				position: relative;
				padding-bottom: 12px;
				border-bottom: solid #ccc 1px;
				font-size: 26px;
				line-height: 1.2;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 20px;
					font-size: 42px;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: -2px;
					left: 0;
					width: 126px;
					height: 3px;
					background: #0168b7;
					@include device_pc {
						width: 200px;
					}
				}
			}
			&__content {
				position: relative;
			}
		}
		&-lists {
			&--item {
				border-bottom: solid #ccc 1px;
				& > a,
				& > span {
					padding: 15px 0;
					@include device_sp {
						display: block;
					}
					@include device_pc {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						padding: 30px 20px;
					}
				}
			}
			&--date {
				font-size: 15px;
				font-weight: 600;
				color: #0068b7;
				@include device_pc {
					width: 120px;
					font-size: 18px;
				}
			}
			&--title {
				font-size: 14px;
				font-weight: 400;
				@include device_sp {
					margin-top: 16px;
				}
				@include device_pc {
					width: calc(100% - 120px);
					font-size: 16px;
				}
			}
			&__btns {
				margin-top: 15px;
				width: 100%;
				@include device_sp {
					padding: 0 60px;
				}
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
					margin: 5px 0 0 -10px;
					padding-left: 120px;
				}
				&--item {
					text-align: center;
					font-size: 14px;
					font-weight: 500;
					line-height: 1.2;
					@include device_sp {
						& + & {
							margin-top: 10px;
						}
					}
					@include device_pc {
						margin: 10px 0 0 10px;
						width: 220px;
					}
					& > a {
						display: block;
						padding: 14px 0;
						border-radius: 30px;
						@include device_pc {
							padding: 7px 0;
						}
					}
					&.btn {
						&-applicate {
							& > a {
								background-color: #F6F033;
							}
						}
						&-applicated {
							& > a {
								background-color: #0168B7;
								color: #fff;
							}
						}
					}
				}
			}
		}
	}
	/* single */
	&-single {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/techlibrary/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/techlibrary/title_bg-pc.jpg);
				}
			}
		}
	}
	/* detail */
	&-detail {
		@include device_sp {
			margin: 0 20px;
		}
		&-hd {
			&__info {
				position: relative;
				margin-top: 30px;
				@include device_pc {
					margin-top: 40px;
					padding-left: 180px;
				}
			}
		}
		&-title {
			border-bottom: solid #ccc 2px;
			font-size: 21px;
			font-weight: 400;
			color: #0168b7;
			@include device_sp {
				padding-bottom: 12px;
			}
			@include device_pc {
				font-size: 42px;
			}
		}
		&-photo {
			@include device_sp {
				margin: 0 auto 20px;
				width: 35.7142857143%;
			}
			@include device_pc {
				position: absolute;
				top: 0;
				left: 0;
				width: 120px;
			}
		}
		&-date {
			font-size: 15px;
			font-weight: 600;
			color: #0168b7;
			@include device_pc {
				font-size: 18px;
			}
		}
		&-content {
			margin-top: 18px;
			font-size: 14px;
			line-height: 1.625;
			@include device_pc {
				margin-top: 24px;
				padding-left: 180px;
				min-height: 120px;
				font-size: 16px;
			}
			.linkPDF {
				display: inline-block;
				position: relative;
				vertical-align: top;
				color: #0168b7;
				&:before {
					content: '';
					position: absolute;
					top: 2px;
					left: -25px;
					width: 15px;
					height: 18px;
					background: url(../img/icon/pdf1.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						left: -40px;
						width: 20px;
						height: 24px;
					}
				}
			}
			p:not(.btn) {
				position: relative;
				padding-left: 25px;
				@include device_pc {
					padding-left: 40px;
				}
				& + p:not(.btn) {
					margin-top: 1.4em;
					@include device_pc {
						margin-top: 1.6em;
					}
				}
				&.no-space {
					padding-left: 0;
				}
			}
			.btn {
				margin-top: 8px;
				@include device_sp {
					display: grid;
					gap: 20px;
				}
				@include device_pc {
					display: flex;
					gap: 24px;
				}
			}
			.link {
				&-pdf,
				&-html {
					display: inline-flex;
					justify-content: center;
					align-items: center;
					// height: 32px;
					vertical-align: top;
					// border: 1px solid #AFAFAF;
					// border-radius: 48px;
					font-size: 14px;
					letter-spacing: 0.1em;
					color: #0168B7;
					@include device_sp {
						// max-width: 200px;
					}
					@include device_pc {
						// min-width: 193px;
					}
					&::before {
						content: '';
						margin-right: 10px;
						height: 100%;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
					}
				}
				&-pdf {
					&::before {
						position: relative;
						top: auto;
						left: auto;
						width: 17px;
						background-image: url(../img/icon/pdf1.png);
					}
				}
				&-html {
					&::before {
						width: 17px;
						background-image: url(../img/icon/html1.png);
					}
				}
			}
			dl {
				&:not(:first-child) {
					margin-top: 40px;
				}
				& + dl {
					margin-top: 40px;
				}
				dt {
					text-decoration: underline;
					font-size: 18px;
					font-weight: bold;
				}
				dd {
					padding-left: 20px;
					@include device_pc {
						padding-left: 38px;
					}
					& + dd {
						margin-top: 26px;
					}
				}
			}
			ul {
				&:not(:first-child) {
					margin-top: 62px;
				}
				li {
					& + li {
						margin-top: 40px;
					}
				}
			}
		}
		&-ft {
			position: relative;
		}
	}
}
