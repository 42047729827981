@charset 'utf-8';

@import '../variable';

/* container
------------------------------------------------ */
#container {
	position: relative;
}

.wrap {
	position: relative;
	margin: 0 auto;
	@include device_pc {
		padding: 0 20px;
		max-width: 1240px;
	}
}

.visible {
	&--pc {
		@include device_pc {
			display: block !important;
		}
		@include device_sp {
			display: none !important;
		}
	}
	&--sp {
		@include device_pc {
			display: none !important;
		}
		@include device_sp {
			display: block !important;
		}
	}
}
