@charset 'utf-8';

@import '../variable';

/* business
------------------------------------------------ */
.business {
	&-index {
		position: relative;
		&-tab {
			@include device_sp {
				margin: 0 20px;
			}
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&--item {
				position: relative;
				text-align: center;
				font-size: 19px;
				line-height: 1.2;
				@include device_sp {
					margin-top: 2px;
					&:first-child { margin-top: 0; }
				}
				@include device_pc {
					margin: 2px 2px 0 0;
					width: calc(33.333333% - 1.5px);
					font-size: 24px;
					&:nth-of-type(-n+3) { margin-top: 0; }
					&:nth-of-type(3n) { margin-right: 0; }
				}
				& > a {
					display: flex;
					justify-content: center;
					align-items: center;
					position: relative;
					padding: 24px 0;
					height: 100%;
					background: #fff;
					border: solid #0168b7 1px;
					color: #0168b7;
					&.active {
						background: #0168b7;
						color: #fff;
						&:after {
							background-image: url(../img/icon/arrow3.png);
						}
					}
					@include device_pc {
						padding: 35px 30px;
					}
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 18px;
						margin-top: -8px;
						width: 8px;
						height: 16px;
						background: url(../img/icon/arrow4.png) no-repeat center center;
						background-size: 100%;
						@include device_pc {
							right: 20px;
							margin-top: -9px;
							width: 9px;
							height: 19px;
						}
					}
				}
			}
		}
		.title--5 {
			@include device_sp {
				padding: 10px 20px 26px;
			}
			@include device_pc {
				padding: 28px 20px;
			}
		}
		&-content {
			position: relative;
			margin-top: 1px;
			padding: 30px 20px 50px;
			background: #e5f0f8;
			@include device_pc {
				margin-top: 2px;
				padding: 70px 0 112px;
			}
		}
		&-title {
			margin-bottom: 80px;
			text-align: center;
			font-family: $fontMin;
			font-size: 26px;
			font-weight: 700;
			line-height: 1.2;
			@include device_pc {
				margin-bottom: 150px;
				font-size: 52px;
			}
		}
		&-lists {
			display: flex;
			flex-wrap: wrap;
			&--item {
				position: relative;
				@include device_sp {
					margin: 86px 4.4776119403% 0 0;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 180px 5% 0 0;
					&:nth-of-type(-n+3) { margin-top: 0; }
					&:nth-of-type(3n) { margin-right: 0; }
				}
				&.w {
					@include device_sp {
						&-sp {
							&-single {
								width: 47.7611940299%;
							}
							&-double {
								margin-right: 0;
								width: 100%;
								.business-index-lists {
									&__content {
										display: flex;
										flex-wrap: wrap;
										justify-content: space-between;
									}
									&__titles {
										width: calc(50% - 12.5px);
										&:nth-of-type(-n+2) { margin-top: 0; }
									}
								}
							}
						}
					}
					@include device_pc {
						&-pc {
							&-single {
								width: 30%;
							}
							&-double {
								width: 65%;
								.business-index-lists {
									&__content {
										display: flex;
										flex-wrap: wrap;
										justify-content: space-between;
									}
									&__titles {
										width: calc(50% - 15px);
									}
								}
							}
							&-triple {
								margin-right: 0;
								width: 100%;
								.business-index-lists {
									&__content {
										display: flex;
										flex-wrap: wrap;
										justify-content: space-between;
									}
									&__titles {
										margin-right: 30px;
										&:nth-of-type(4n) { margin-right: 0; }
										width: calc(25% - 22.5px);
									}
								}
							}
						}
					}
				}
			}
			&--photo {
				position: absolute;
				top: -56px;
				left: 50%;
				margin-left: -46px;
				width: 92px;
				border-radius: 50%;
				overflow: hidden;
				z-index: 2;
				@include device_pc {
					top: -115px;
					margin-left: -92px;
					width: 185px;
				}
			}
			&__info {
				position: relative;
				padding: 42px 15px 68px;
				height: 100%;
				background: #fff;
				z-index: 1;
				@include device_pc {
					padding: 95px 35px 120px;
				}
			}
			&__box {
			}
			&--title {
				margin-bottom: 10px;
				font-size: 20px;
				color: #0168b7;
				@include device_pc {
					margin-bottom: 22px;
					font-size: 28px;
				}
			}
			&__content {
				position: relative;
			}
			&--text {
				font-size: 14px;
				line-height: 1.6;
				@include device_pc {
					font-size: 15px;
				}
			}
			&__titles {
				@include device_sp {
					margin-top: 14px;
					&:first-child { margin-top: 0; }
				}
				&--item {
					margin-top: 14px;
					font-size: 14px;
					line-height: 1.2;
					&:first-child { margin-top: 0; }
					& > a {
						display: inline-block;
						position: relative;
						vertical-align: top;
						&.link {
							&-icon {
								padding-left: 26px;
								i {
									position: absolute;
									top: 0;
									left: 0;
								}
							}
						}
					}
					@include device_pc {
						margin-top: 15px;
						font-size: 15px;
					}
				}
			}
			&--btn {
				position: absolute;
				bottom: 15px;
				left: 50%;
				transform: translateX(-50%);
				width: calc(100% - 30px);
				max-width: 130px;
				font-size: 12px;
				a {
					padding: 7px 10px;
					&:after {
						right: 10px;
						margin-top: -4px;
						width: 5px;
						height: 9px;
					}
				}
				@include device_pc {
					bottom: 40px;
					width: calc(100% - 100px);
					max-width: 258px;
					font-size: 16px;
					a {
						padding: 12px 10px;
						&:after {
							right: 24px;
							margin-top: -8px;
							width: 8px;
							height: 16px;
						}
					}
				}
			}
		}
	}
	/* top */
	&-top {
		&-copy {
			margin: 30px 20px;
			text-align: center;
			font-family: $fontMin;
			font-size: 26px;
			font-weight: 700;
			line-height: 1.2;
			color: #0168b7;
			@include device_pc {
				margin: 56px 20px;
				font-size: 52px;
			}
		}
		.business {
			&-index {
				margin-top: 42px;
				@include device_pc {
					margin-top: 80px;
				}
				&-tab {
					&--item {
						& > a {
							padding: 34px 0;
							@include device_pc {
								padding: 56px 30px;
							}
						}
					}
				}
				&-title {
					font-size: 30px;
					@include device_pc {
						font-size: 60px;
					}
				}
				&-lists {
					&--item {
						@include device_pc {
							margin: 180px 5% 0 0;
							&:nth-of-type(-n+3) { margin-top: 0; }
							&:nth-of-type(3n),
							&:nth-of-type(14) { margin-right: 0; }
							&:nth-of-type(13) { margin-right: 5%; }
						}
					}
				}
			}
		}
	}
	/* industry */
	&-industry {
	}
	/* solution */
	&-solution {
		.business {
			&-index {
				&-lists {
					padding-top: 98px;
					@include device_pc {
						padding-top: 196px;
					}
					&--item {
						@include device_sp {
							margin: 86px 0 0;
							&:nth-of-type(1) { margin-top: 0; }
						}
						@include device_pc {
							margin: 180px 5% 0 0;
							&:nth-of-type(-n+2) { margin-top: 0; }
							&:nth-of-type(3n) { margin-right: 5%; }
							&:nth-of-type(2n) { margin-right: 0; }
							&:nth-of-type(3) { margin-top: 180px; }
						}
						&.w {
							@include device_sp {
								&-sp {
									&-single {
										width: 100%;
									}
								}
							}
							@include device_pc {
								&-pc {
									&-single {
										width: 47.5%;
									}
									&-double {
										margin-right: 0;
										width: 100%;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	/* detail */
	&-detail {
		.business-index-tab {
			margin: 40px 20px;
			@include device_pc {
				margin: 80px auto;
			}
		}
	}
	/* page */
	&-page {
		position: relative;
		padding-top: 5px;
		@include device_pc {
			padding-top: 16px;
		}
		&-title {
			font-size: 30px;
			font-weight: 400;
			line-height: 1.2;
			color: #0168b7;
			@include device_sp {
				margin: 0 20px 20px;
			}
			@include device_pc {
				margin-bottom: 54px;
				font-size: 52px;
			}
		}
		&-content {
			position: relative;
			padding: 45px 20px 40px;
			background: #e5f0f8;
			@include device_pc {
				padding: 80px 0;
			}
		}
		&-block {
			position: relative;
			margin-top: 10px;
			padding: 15px 20px 20px;
			background: #fff;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 20px;
				padding: 28px 65px 40px;
			}
			&--title {
				padding-bottom: 8px;
				border-bottom: solid #c6dbec 1px;
				font-size: 19px;
				font-weight: 400;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 18px;
					font-size: 28px;
				}
			}
			&--subtitle {
				position: relative;
				margin: 25px auto 0;
				padding: 4px 6px;
				max-width: 900px;
				background: #0168b7;
				border-radius: 5px;
				text-align: center;
				font-size: 12px;
				font-weight: 700;
				line-height: 1.42857142857;
				color: #ffde00;
				@include device_pc {
					margin-top: 60px;
					padding: 16px 10px;
					border-radius: 10px;
					font-size: 28px;
				}
			}
			&__content {
				position: relative;
				margin-top: 20px;
			}
			&--lead {
				margin-top: 32px;
				font-size: 14px;
				line-height: 1.2;
				color: #0168b7;
				&:first-child { margin-top: 0; }
				@include device_pc {
					font-size: 18px;
					line-height: 1.61111111111;
				}
			}
			&--image {
				margin: 10px auto 0;
				max-width: 900px;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 30px;
				}
			}
			&--illustration {
				@include device_sp {
					margin: 15px auto 0;
				}
			}
			&__row {
				@include device_pc {
					display: flex;
					justify-content: space-between;
				}
				.row {
					&-text {
						@include device_pc {
							width: 77.358490566%;
						}
					}
					&-illustration {
						@include device_pc {
							width: 18.8679245283%;
						}
						&-1 {
							@include device_sp {
								width: 61.0169491525%;
							}
						}
						&-2 {
							@include device_sp {
								width: 48.1355932203%;
							}
						}
						&-3 {
							@include device_sp {
								width: 77.9661016949%;
							}
						}
						&-4 {
							@include device_sp {
								width: 72.5423728814%;
							}
						}
					}
				}
			}
		}
		&-subblock {
			position: relative;
			margin-top: 35px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 90px;
			}
			&--title {
				font-size: 17px;
				font-weight: 700;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					font-size: 24px;
				}
			}
			&__content {
				position: relative;
				margin-top: 20px;
			}
			&--lead {
				margin-top: 32px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					font-size: 15px;
				}
				& > p {
					text-indent: 1em;
				}
			}
			&--subtitle {
				position: relative;
				margin: 25px auto 0;
				padding: 4px 6px;
				max-width: 900px;
				background: #44719b;
				border-radius: 5px;
				text-align: center;
				font-size: 12px;
				font-weight: 700;
				line-height: 1.42857142857;
				color: #fff;
				span {
					display: inline-block;
					font-size: 10px;
					vertical-align: top;
					@include device_pc {
						font-size: 18px;
					}
				}
				@include device_pc {
					margin-top: 60px;
					padding: 16px 10px;
					border-radius: 10px;
					font-size: 28px;
				}
			}
			&--image {
				margin: 10px auto 0;
				max-width: 900px;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 30px;
				}
			}
		}
		&-box {
			position: relative;
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--title {
				position: relative;
				font-size: 15px;
				font-weight: 700;
				line-height: 1.2;
				@include device_pc {
					font-size: 18px;
				}
			}
			&__content {
				position: relative;
				margin-top: 16px;
			}
		}
		&-check {
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--item {
				position: relative;
				margin-top: 20px;
				padding-left: 24px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 15px;
					padding-left: 28px;
					font-size: 15px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 6px;
					left: 2px;
					width: 10px;
					height: 8px;
					background: url(../img/icon/check1.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						top: 5px;
						width: 12px;
						height: 9px;
					}
				}
			}
		}
		&-lists {
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--item {
				position: relative;
				margin-top: 20px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 30px;
					font-size: 16px;
					line-height: 1.625;
				}
				& > a {
					display: inline-block;
					position: relative;
					padding-left: 26px;
					vertical-align: top;
					@include device_pc {
						padding-left: 38px;
					}
				}
				.icon {
					&-pdf {
						position: absolute;
						top: 0;
						left: 0;
						@include device_pc {
							top: 4px;
						}
					}
					&-new {
						vertical-align: baseline;
						@include device_pc {
							margin-left: 6px;
						}
					}
				}
			}
		}
		.informatics {
			&-image {
				&-1 {
					$width: 900;
					$height: 707;
					@function widthPersent($num) {
						@return math.div(($num*100),$width)*1%;
					}
					@function heightPersent($num) {
						@return math.div(($num*100),$height)*1%;
					}
					position: relative;
					padding-bottom: widthPersent($height);
					width: 100%;
					height: 0;
					background: url(../img/business/chemistry_environment/informatics1_bg.png) no-repeat heightPersent(32) center;
					background-size: widthPersent(897);
					overflow: hidden;
					&--title {
						position: absolute;
						top: heightPersent(246);
						left: widthPersent(348);
						width: widthPersent(200);
					}
					&--illust {
						position: absolute;
						&.illust {
							&-1 {
								top: 0;
								left: widthPersent(2);
								width: widthPersent(300);
							}
							&-2 {
								top: heightPersent(15);
								left: widthPersent(598);
								width: widthPersent(300);
							}
							&-3 {
								top: heightPersent(433);
								left: widthPersent(587);
								width: widthPersent(311);
							}
							&-4 {
								top: heightPersent(433);
								left: widthPersent(2);
								width: widthPersent(300);
							}
						}
					}
					&--text {
						position: absolute;
						width: widthPersent(264);
						&.text {
							&-1 {
								top: heightPersent(82);
								left: widthPersent(316);
							}
							&-2 {
								top: heightPersent(281);
								left: widthPersent(598);
							}
							&-3 {
								top: heightPersent(483);
								left: widthPersent(316);
							}
							&-4 {
								top: heightPersent(281);
								left: widthPersent(38);
							}
						}
					}
				}
			}
		}
	}
}
