@charset 'utf-8';

@import '../variable';

/* lists
------------------------------------------------ */
.lists {
	&-news {
		&--item {
			border-bottom: solid #ccc 1px;
			& > a,
			& > span {
				display: flex;
				flex-wrap: wrap;
				position: relative;
				padding: 20px 2px;
			}
		}
		&--cat {
			width: 90px;
			.icon-news {
				width: 100%;
			}
		}
		&--date {
			margin-left: 15px;
			width: 110px;
			font-size: 18px;
			font-weight: 700;
			color: #0068b7;
		}
		&--title {
			width: calc(100% - 215px);
			font-size: 16px;
			font-weight: 400;
			line-height: 1.8;
		}
		&-btns {
			margin-top: 15px;
			width: 100%;
			@include device_sp {
				padding: 0 60px;
			}
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
				margin: 5px 0 0 -10px;
				padding-left: 215px;
			}
			&--item {
				text-align: center;
				font-size: 14px;
				font-weight: 500;
				line-height: 1.2;
				@include device_sp {
					& + & {
						margin-top: 10px;
					}
				}
				@include device_pc {
					margin: 10px 0 0 10px;
					width: 220px;
				}
				& > a {
					display: block;
					padding: 14px 0;
					border-radius: 30px;
					@include device_pc {
						padding: 7px 0;
					}
				}
				&.btn {
					&-applicate {
						& > a {
							background-color: #F6F033;
						}
					}
					&-applicated {
						& > a {
							background-color: #0168B7;
							color: #fff;
						}
					}
				}
			}
		}
		@include device_sp {
			margin: 0 20px;
			&--item {
				& > a {
					flex-wrap: wrap;
					padding: 20px 0;
				}
			}
			&--cat {
				width: 65px;
				.icon-news {
					width: 100%;
				}
			}
			&--date {
				margin-left: 10px;
				width: auto;
				font-size: 15px;
			}
			&--title {
				margin-top: 15px;
				width: 100%;
				font-size: 14px;
				line-height: 1.6;
			}
		}
	}
}
