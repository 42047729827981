@charset 'utf-8';

@import '../variable';

/* target
------------------------------------------------ */
.target {
	/* parts */
	&-link {
		margin-top: 30px;
		text-align: center;
		font-size: 20px;
		line-height: 1.2;
		@include device_pc {
			margin-top: 40px;
			font-size: 28px;
		}
		& > a {
			display: inline-block;
			text-decoration: underline;
			vertical-align: top;
			color: #0168B7;
		}
	}
	/* top */
	&-top {
		position: relative;
		.title {
			&--ribbon {
				background-image: url(../img/target/top_title.jpg);
			}
		}
		.topicpath {
			margin-bottom: 25px;
			@include device_pc {
				margin-bottom: 18px;
			}
		}
		&-outline {
			position: relative;
			margin-bottom: 30px;
			@include device_pc {
				margin-bottom: 60px;
			}
			@include device_sp {
				padding: 0 20px;
			}
			@include device_pc {
				padding: 115px 0;
			}
			&__info {
				@include device_pc {
					margin-left: 43.3333333333%;
				}
			}
			&--title {
				font-family: $fontMin;
				font-size: 28px;
				font-weight: 700;
				color: #0168b7;
				@include device_pc {
					font-size: 60px;
				}
			}
			&--text {
				margin-top: 10px;
				font-family: $fontMin;
				font-size: 16px;
				line-height: 1.5625;
				@include device_pc {
					margin-top: 28px;
					font-size: 18px;
				}
			}
			&--photo {
				@include device_sp {
					margin-top: 28px;
				}
				@include device_pc {
					position: absolute;
					top: 50%;
					left: -340px;
					transform: translateY(-50%);
					width: 64.5161290323%;
				}
			}
		}
		&-sds {
			position: relative;
			margin-top: 34px;
			@include device_pc {
				display: flex;
				margin: 60px auto 0;
				max-width: 836px;
			}
			&--title {
				padding: 12px 0;
				background-color: #0168B7;
				border-radius: 10px 10px 0 0;
				text-align: center;
				font-size: 22px;
				line-height: 1.2;
				color: #fff;
				@include device_pc {
					display: flex;
					flex-flow: row;
					justify-content: center;
					align-items: center;
					width: 236px;
					border-radius: 8px 0 0 8px;
					font-size: 25px;
				}
				& > span {
					@include device_sp {
						font-family: $fontMin;
					}
				}
			}
			&__info {
				position: relative;
				padding: 13px 20px 18px;
				background-color: #3486C5;
				border-radius: 0 0 10px 10px;
				color: #fff;
				@include device_pc {
					display: flex;
					align-items: center;
					padding: 18px 35px 18px 30px;
					border-radius: 0 8px 8px 0;
					width: calc(100% - 220px);
				}
			}
			&--text {
				font-size: 14px;
				line-height: 1.78571428571;
				@include device_sp {
					text-align: center;
				}
				@include device_pc {
					margin-right: 30px;
					width: calc(100% - 230px);
					font-size: 16px;
				}
			}
			&--btn {
				max-width: 258px;
				text-align: center;
				font-size: 16px;
				font-weight: 500;
				line-height: 1.2;
				@include device_sp {
					margin: 15px auto 0;
				}
				@include device_pc {
					width: 200px;
				}
				& > a {
					display: block;
					position: relative;
					padding: 10px;
					background-color: #fff;
					border: 1px solid #fff;
					border-radius: 32px;
					color: #0168B7;
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: 48px;
						margin-top: -8px;
						width: 10px;
						height: 17px;
						background: url(../img/icon/arrow7.png) no-repeat center center;
						background-size: 100%;
						@include device_pc {
							right: 22px;
						}
					}
				}
			}
		}
	}
	/* page */
	&-page {
		.topicpath {
			margin-bottom: 15px;
			@include device_pc {
				margin-bottom: 18px;
			}
		}
	}
	/* title */
	&-title {
		.title {
			&--page {
				color: #fff;
				@include device_sp {
					background-image: url(../img/target/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/target/title_bg-pc.jpg);
				}
			}
		}
	}
	/* introduce */
	&-introduce {
		background-color: #E5F0F8;
		padding: 30px 0 50px;
		@include device_sp {
			& > .wrap {
				padding: 0 20px;
			}
		}
		@include device_pc {
			padding: 80px 0 95px;
		}
		.business {
			&-index {
				&-lists {
					padding-top: 70px;
					@include device_pc {
						padding-top: 130px;
					}
					&--item {
						@include device_sp {
							margin: 122px 0 0;
							&:first-child { margin-top: 0; }
						}
						@include device_pc {
							width: 30%;
							margin: 122px 5% 0 0;
							&:nth-of-type(-n+3) { margin-top: 0; }
							&:nth-of-type(3n) { margin-right: 0; }
						}
					}
					&--photo {
						top: -92px;
						margin-left: -92px;
						width: 185px;
						height: 185px;
					}
					&__info {
						padding: 112px 20px 105px;
						@include device_pc {
							padding: 112px 30px 105px;
						}
					}
					&--title {
						font-size: 24px;
						font-weight: 700;
						@include device_pc {
							font-size: 28px;
						}
					}
					&--text {
						font-size: 15px;
						line-height: 1.66666666667;
					}
					&--pic {
						margin-top: 20px;
						max-width: 207px;
					}
					&--btn {
						bottom: 30px;
						max-width: 258px;
						font-size: 16px;
						& > a {
							padding: 14px 10px;
							&::after {
								right: 20px;
								margin-top: -8px;
								width: 10px;
								height: 17px;
								background-image: url(../img/icon/arrow6.png);
							}
						}
					}
				}
			}
		}
	}
	/* search */
	&-search {
		position: relative;
		margin-top: 45px;
		@include device_pc {
			margin-top: 90px;
		}
		&-title {
			margin-bottom: 24px;
			text-align: center;
			font-size: 30px;
			font-weight: 700;
			line-height: 1.2;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 26px;
				font-size: 60px;
			}
		}
	}
	/* flow */
	&-flow {
		margin-top: 55px;
		@include device_pc {
			margin-top: 90px;
		}
		&-title {
			margin-bottom: 20px;
			text-align: center;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 86px;
			}
			strong,
			span {
				display: block;
				line-height: 1.2;
			}
			strong {
				font-size: 30px;
				font-weight: 700;
				@include device_pc {
					font-size: 60px;
				}
			}
			span {
				margin-top: 10px;
				font-size: 18px;
				font-weight: 400;
				@include device_pc {
					font-size: 34px;
				}
			}
		}
		&-lists {
			@include device_sp {
				margin-left: 30px;
			}
			@include device_pc {
				display: flex;
				flex-wrap: wrap;
			}
			&--item {
				position: relative;
				border: 1px solid #fff;
				z-index: 1;
				height: 100px;
				@include device_pc {
					width: 20%;
					height: 260px;
				}
				&:nth-of-type(1) {
					background-color: #0A65B7;
					.target-flow-lists {
						&--num { color: #0A65B7; }
						&--icon {
							@include device_sp {
								width: 70px;
								height: 70px;
							}
							@include device_pc {
								padding-bottom: 50%;
								width: 50%;
							}
						}
					}
				}
				&:nth-of-type(2) {
					background-color: #3682C4;
					.target-flow-lists {
						&--num { color: #3682C4; }
						&--icon {
							@include device_sp {
								width: 100px;
								height: 77px;
							}
							@include device_pc {
								padding-bottom: 62.5%;
								width: 81.25%;
							}
						}
					}
				}
				&:nth-of-type(3) {
					background-color: #7EAED7;
					.target-flow-lists {
						&--num { color: #7EAED7; }
						&--icon {
							@include device_sp {
								width: 60px;
								height: 80px;
							}
							@include device_pc {
								padding-bottom: 50%;
								width: 37.5%;
							}
						}
					}
				}
				&:nth-of-type(4) {
					background-color: #21C8BA;
					.target-flow-lists {
						&--num { color: #21C8BA; }
						&--icon {
							@include device_sp {
								width: 87px;
								height: 70px;
							}
							@include device_pc {
								padding-bottom: 43.3333333333%;
								width: 53.75%;
							}
						}
					}
				}
				&:nth-of-type(5) {
					background-color: #FA960B;
					.target-flow-lists {
						&--num { color: #FA960B; }
					}
				}
				&.flow {
					&-5 {
						&-1 {
							.target-flow-lists {
								&--icon {
									@include device_sp {
										width: 87px;
										height: 70px;
									}
									@include device_pc {
										padding-bottom: 45.4166666667%;
										width: 57.5%;
									}
								}
							}
						}
						&-2 {
							.target-flow-lists {
								&--icon {
									@include device_sp {
										width: 96px;
										height: 70px;
									}
									@include device_pc {
										padding-bottom: 40%;
										width: 55%;
									}
								}
							}
						}
					}
				}
			}
			&--num {
				position: absolute;
				width: 80px;
				height: 80px;
				background-color: #fff;
				border-radius: 50%;
				text-align: center;
				font-family: $fontMin;
				font-size: 36px;
				line-height: 80px;
				@include device_sp {
					top: 50%;
					left: -30px;
					margin-top: -40px;
				}
				@include device_pc {
					top: -17.3076923077%;
					left: 50%;
					transform: translateX(-50%);
					padding-bottom: 50%;
					width: 50%;
					height: 0;
					font-size: 55px;
					line-height: 108px;
					overflow: hidden;
				}
				@media screen and (min-width: $mediaPC) and (max-width: 1200px) {
					font-size: 4.58333333333vw;
					line-height: 9vw;
				}
			}
			&--name {
				position: absolute;
				top: 50%;
				left: 0;
				transform: translateY(-50%);
				width: 100%;
				text-align: center;
				font-size: 20px;
				font-weight: 700;
				line-height: 1.6;
				color: #fff;
				@include device_pc {
					padding-top: 50px;
					text-align: center;
					font-size: 24px;
				}
				@media screen and (min-width: $mediaPC) and (max-width: 1200px) {
					font-size: 2vw;
				}
			}
			&--icon {
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				z-index: -1;
				@include device_pc {
					top: calc(50% + 30px);
					height: 0;
					overflow: hidden;
					img {
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	/* main */
	&-main {
		&-title {
			text-align: center;
			font-size: 28px;
			font-weight: 700;
			line-height: 1.42857142857;
			color: #0168B7;
			@include device_pc {
				font-size: 60px;
			}
			& + .target-main {
				&-text {
					margin-top: 18px;
					@include device_pc {
						margin-top: 24px;
					}
				}
				&-copy {
					margin-top: 18px;
					@include device_pc {
						margin-top: 30px;
					}
				}
			}
		}
		&-en {
			text-align: center;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.4;
			color: #0168B7;
			@include device_pc {
				font-size: 34px;
			}
			& + .target-main-copy {
				margin-top: 12px;
				@include device_pc {
					margin-top: 32px;
				}
			}
		}
		&-copy {
			margin-bottom: 16px;
			text-align: center;
			font-size: 18px;
			line-height: 1.4;
			@include device_pc {
				margin-bottom: 20px;
				font-size: 24px;
			}
		}
		&-text {
			text-align: center;
			font-size: 16px;
			line-height: 1.5625;
			@include device_pc {
				&.has-pc-margin {
					margin-right: 60px;
					margin-left: 60px;
				}
			}
		}
		&-lng {
			margin-top: 20px;
			text-align: right;
			font-size: 16px;
			line-height: 1.2;
			& > a {
				display: inline-block;
				padding: 8px 24px 12px 50px;
				background-color: #fff;
				border: 1px solid #404040;
				border-radius: 32px;
				text-align: center;
				vertical-align: top;
				&::after {
					content: '\2192';
					padding-left: 16px;
				}
			}
		}
		&-visual {
			margin-top: 20px;
		}
	}
	/* sputtering */
	&-sputtering {
		margin-top: 37px;
		@include device_pc {
			margin-top: 90px;
		}
		&-lists {
			&--item {
				position: relative;
				padding: 80px 20px 28px;
				background-color: #fff;
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
					padding: 64px 50px 50px;
				}
				&:not(:first-child) {
					margin-top: 30px;
					@include device_pc {
						margin-top: 40px;
					}
				}
			}
			&--num {
				position: absolute;
				top: 0;
				left: 0;
				width: 80px;
				height: 63px;
				background-color: #3486C5;
				text-align: center;
				font-family: $fontMin;
				font-size: 40px;
				line-height: 63px;
				color: #fff;
				@include device_pc {
					width: 130px;
					height: 110px;
					font-size: 64px;
					line-height: 110px;
				}
			}
			&--title {
				position: relative;
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				line-height: 1.4;
				color: #0168B7;
				@include device_pc {
					margin-bottom: 50px;
					width: 100%;
					font-size: 33px;
				}
			}
			&--photo {
				position: relative;
				@include device_pc {
					width: 45.4545454545%;
					& + .target-sputtering-lists__info {
						margin-left: 3.6363636364%;
						width: 50.9090909091%;
					}
				}
			}
			&__info {
				@include device_sp {
					margin-top: 20px;
				}
				table {
					width: 100%;
					border-top: 1px solid #ccc;
					border-left: 1px solid #ccc;
					border-spacing: 0;
					border-collapse: collapse;
					th,
					td {
						padding: 12px;
						border-right: 1px solid #ccc;
						border-bottom: 1px solid #ccc;
						vertical-align: top;
						line-height: 1.6;
						@include device_pc {
							padding: 20px 12px;
						}
					}
					th {
						width: 100px;
						background-color: #E5F0F8;
						font-size: 15px;
						font-weight: 400;
						@include device_pc {
							width: 180px;
						}
					}
					td {
						font-size: 14px;
					}
				}
			}
			&--pdf {
				margin-top: 20px;
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 1.2;
				@include device_pc {
					margin-top: 27px;
					max-width: 420px;
				}
				& > a {
					display: block;
					padding: 20px 0 15px;
					background-color: #0168B7;
					border-radius: 32px;
					color: #fff;
					&::before {
						content: '';
						display: inline-block;
						margin: -5px 16px 0 0;
						width: 26px;
						height: 31px;
						background: url(../img/icon/pdf3.png) no-repeat center center;
						background-size: 100%;
						vertical-align: top;
					}
				}
			}
		}
	}
	/* c3 */
	&-c3 {
		margin-top: 20px;
		@include device_pc {
			margin-top: 50px;
		}
		&-post {
			position: relative;
			padding: 40px 20px 30px;
			background-color: #fff;
			@include device_pc {
				padding: 42px 50px 48px;
			}
		}
		&-hd {
			position: relative;
			margin-bottom: 20px;
			@include device_pc {
				margin-bottom: 30px;
			}
		}
		&-copy {
			margin-bottom: 20px;
			font-size: 15px;
			font-weight: 700;
			line-height: 1.4;
			@include device_pc {
				margin-bottom: 30px;
			}
		}
		&-photo {
			margin: 0 auto;
			max-width: 693px;
		}
		&-content {
			font-size: 15px;
			line-height: 1.6;
			& > p + p {
				margin-top: 1.6em;
			}
			.photos {
				margin-top: 20px;
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
					margin: 30px auto 0;
					max-width: 943px;
				}
				& > li {
					@include device_sp {
						& + & {
							@include device_sp {
								margin-top: 30px;
							}
						}
					}
					@include device_pc {
						margin: 2.4390243902% 2.4390243902% 0 0;
						width: 48.7804878049%;
						&:nth-of-type(-n+2) { margin-top: 0; } 
						&:nth-of-type(2n) { margin-right: 0; } 
					}
				}
			}
		}
	}
	/* special */
	&-special {
		position: relative;
		margin-top: 20px;
		padding-bottom: 110.471641791%;
		height: 0;
		overflow: hidden;
		@include device_pc {
			margin-top: 40px;
			padding-bottom: 57.9391666667%;
		}
		&-lists {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			&--item {
				$special_sp_width : 335;
				$special_sp_height : 370.08;
				$special_pc_width : 1200;
				$special_pc_height : 695.27;
				position: absolute;
				height: 0;
				overflow: hidden;
				&:nth-of-type(1) {
					@include device_sp {
						$special_item_width : 139.23;
						$special_item_height : 149.93;
						top: math.div(20*100%,$special_sp_height);
						left: 0;
						padding-bottom: math.div($special_item_height*100%,$special_sp_width);
						width: math.div($special_item_width*100%,$special_sp_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								right: 0;
								padding-top: math.div(20*100%,$special_item_width);
								width: math.div(105.96*100%,$special_item_width);
								height: math.div(107.91*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon1.png);
								}
							}
							&--character {
								bottom: 0;
								left: 0;
								width: math.div(65.26*100%,$special_item_width);
							}
						}
					}
					@include device_pc {
						$special_item_width : 351.06;
						$special_item_height : 321.03;
						top: math.div(37*100%,$special_pc_height);
						left: 0;
						padding-bottom: math.div($special_item_height*100%,$special_pc_width);
						width: math.div($special_item_width*100%,$special_pc_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								right: 0;
								padding-top: math.div(68*100%,$special_item_width);
								width: math.div(214.06*100%,$special_item_width);
								height: math.div(218*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon1-pc.png);
								}
							}
							&--character {
								bottom: 0;
								left: 0;
								width: math.div(173.63*100%,$special_item_width);
							}
						}
					}
				}
				&:nth-of-type(2) {
					@include device_sp {
						$special_item_width : 153;
						$special_item_height : 162;
						top: 0;
						right: 0;
						padding-bottom: math.div($special_item_height*100%,$special_sp_width);
						width: math.div($special_item_width*100%,$special_sp_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								left: 0;
								padding-top: math.div(20*100%,$special_item_width);
								width: math.div(120.4*100%,$special_item_width);
								height: math.div(122.62*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon2.png);
								}
							}
							&--character {
								right: 0;
								bottom: 0;
								width: math.div(66.3*100%,$special_item_width);
							}
						}
					}
					@include device_pc {
						$special_item_width : 453.85;
						$special_item_height : 266.04;
						top: math.div(37*100%,$special_sp_height);
						right: 0;
						padding-bottom: math.div($special_item_height*100%,$special_pc_width);
						width: math.div($special_item_width*100%,$special_pc_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								left: 0;
								padding-top: math.div(42*100%,$special_item_width);
								width: math.div(319.85*100%,$special_item_width);
								height: math.div(162.89*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon2-pc.png);
								}
							}
							&--character {
								right: 0;
								bottom: 0;
								width: math.div(160.19*100%,$special_item_width);
							}
						}
					}
				}
				&:nth-of-type(3) {
					@include device_sp {
						$special_item_width : 153.2;
						$special_item_height : 163.99;
						bottom: 0;
						left: 0;
						padding-bottom: math.div($special_item_height*100%,$special_sp_width);
						width: math.div($special_item_width*100%,$special_sp_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								right: 0;
								padding-top: math.div(20*100%,$special_item_width);
								width: math.div(120.4*100%,$special_item_width);
								height: math.div(122.62*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon3.png);
								}
							}
							&--character {
								bottom: 0;
								left: 0;
								width: math.div(65.01*100%,$special_item_width);
							}
						}
					}
					@include device_pc {
						$special_item_width : 472.85;
						$special_item_height : 304.22;
						bottom: 0;
						left: 0;
						padding-bottom: math.div($special_item_height*100%,$special_pc_width);
						width: math.div($special_item_width*100%,$special_pc_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								right: 0;
								padding-top: math.div(40*100%,$special_item_width);
								width: math.div(319.85*100%,$special_item_width);
								height: math.div(162.89*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon3-pc.png);
								}
							}
							&--character {
								bottom: 0;
								left: 0;
								width: math.div(186.42*100%,$special_item_width);
							}
						}
					}
				}
				&:nth-of-type(4) {
					@include device_sp {
						$special_item_width : 159;
						$special_item_height : 129.09;
						right: 0;
						bottom: 0;
						padding-bottom: math.div($special_item_height*100%,$special_sp_width);
						width: math.div($special_item_width*100%,$special_sp_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								left: 0;
								padding-top: math.div(10*100%,$special_item_width);
								width: math.div(141*100%,$special_item_width);
								height: math.div(71.81*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon4.png);
								}
							}
							&--character {
								right: 0;
								bottom: 0;
								width: math.div(66.97*100%,$special_item_width);
							}
						}
					}
					@include device_pc {
						$special_item_width : 348.06;
						$special_item_height : 323.84;
						right: 0;
						bottom: 0;
						padding-bottom: math.div($special_item_height*100%,$special_pc_width);
						width: math.div($special_item_width*100%,$special_pc_width);
						.target-special-lists {
							&--balloon {
								top: 0;
								left: 0;
								padding-top: math.div(64*100%,$special_item_width);
								width: math.div(214.06*100%,$special_item_width);
								height: math.div(218*100%,$special_item_height);
								&::after {
									background-image: url(../img/target/special_balloon4-pc.png);
								}
							}
							&--character {
								right: 0;
								bottom: 0;
								width: math.div(170.95*100%,$special_item_width);
							}
						}
					}
				}
			}
			&--balloon {
				position: absolute;
				text-align: center;
				font-size: 3.46666666667vw;
				font-weight: 700;
				line-height: 1.47692307692;
				color: #fff;
				overflow: hidden;
				z-index: 1;
				@include device_pc {
					font-size: 20px;
				}
				@media screen and (min-width: $mediaPC) and (max-width: 1200px) {
					font-size: 1.66666666667vw;
				}
				&::after {
					content: '';
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					background-repeat: no-repeat;
					background-position: center center;
					background-size: cover;
					z-index: -1;
				}
			}
			&--character {
				position: absolute;
				z-index: 2;
			}
		}
		&-visual {
			position: absolute;
			top: 17.5637699957%;
			left: 10px;
			width: calc(100% - 20px);
			border-radius: 50px;
			overflow: hidden;
			@include device_pc {
				top: 0;
				left: 8.33333333335%;
				width: 83.3333333333%;
			}
		}
	}
	/* alloy */
	&-alloy {
		margin-top: 40px;
		@include device_pc {
			margin-top: 60px;
		}
		&-detail {
			padding: 32px 20px 28px;
			background-color: #fff;
			@include device_pc {
				padding: 42px 50px 48px;
			}
		}
		&-title {
			margin-bottom: 20px;
			text-align: center;
			font-size: 24px;
			font-weight: 700;
			line-height: 1.4;
			@include device_pc {
				margin-bottom: 28px;
				font-size: 33px;
			}
			span {
				@include device_sp {
					display: block;
					font-size: 18px;
				}
				@include device_pc {
					display: inline-block;
					margin-left: 0.5em;
					vertical-align: top;
				}
			}
		}
		&-content {
			& > dl {
				border-top: 1px solid #ccc;
				border-left: 1px solid #ccc;
				&:last-child {
					border-bottom: 1px solid #ccc;
				}
				dt,
				dd {
					border-right: 1px solid #ccc;
					padding: 15px 20px;
					@include device_pc {
						padding: 16px 20px;
					}
				}
				dt {
					background-color: #E5F0F8;
					font-size: 15px;
					line-height: 1.6;
					@include device_sp {
						border-bottom: 1px solid #ccc;
					}
					@include device_pc {
						line-height: 2;
					}
				}
				dd {
					font-size: 14px;
					line-height: 1.6;
					@include device_pc {
						line-height: 2.14285714286;
					}
				}
			}
		}
		&-note {
			margin-top: 20px;
			font-size: 15px;
			line-height: 1.66666666667;
		}
	}
	/* sds */
	&-sds {
		margin-top: 20px;
		@include device_pc {
			margin-top: 52px;
		}
		&-download {
			padding: 40px 20px 26px;
			background-color: #fff;
			@include device_pc {
				padding: 42px 52px 82px;
			}
			&--title {
				margin-bottom: 32px;
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				line-height: 1.4;
				@include device_pc {
					margin-bottom: 50px;
					font-size: 33px;
				}
				span {
					@include device_sp {
						display: block;
					}
					@include device_pc {
						display: inline-block;
						margin-left: 0.5em;
						vertical-align: top;
					}
				}
			}
			&--subtitle {
				margin-bottom: 30px;
				text-align: center;
				font-size: 24px;
				font-weight: 700;
				line-height: 1.4;
				@include device_pc {
					margin-bottom: 24px;
					font-size: 28px;
				}
			}
			&__block {
				position: relative;
				& + & {
					margin-top: 42px;
					@include device_pc {
						margin-top: 54px;
					}
				}
			}
			&__lists {
				&--item {
					position: relative;
					@include device_sp {
						border-top: 1px solid #ccc;
					}
					@include device_pc {
						display: grid;
						column-gap: 5px;
						grid-template-columns: 24.5% 24.5% 51%;
						border-bottom: 1px solid #ccc;
					}
					& + & {
						@include device_sp {
							margin-top: 10px;
						}
					}
					&:not(:first-child) {
						@include device_pc {
							dt { display: none; }
						}
					}
					dl {
						@include device_sp {
							display: flex;
						}
						@include device_pc {
							&:nth-of-type(1) {
								dd {
									justify-content: center;
									.name {
										justify-content: center;
									}
								}
							}
							&:nth-of-type(2) {
								dd {
									padding-left: 32px;
								}
							}
						}
						dt,
						dd {
							padding: 10px;
							font-weight: 400;
							line-height: 1.6;
							@include device_sp {
								border-bottom: 1px solid #ccc;
							}
							@include device_pc {
								padding-top: 10px;
								padding-bottom: 10px;
							}
						}
						dt {
							background-color: #E5F0F8;
							font-size: 15px;
							@include device_sp {
								width: 130px;
							}
							@include device_pc {
								text-align: center;
								font-size: 18px;
							}
						}
						dd {
							text-align: center;
							font-size: 14px;
							@include device_sp {
								width: calc(100% - 130px);
							}
							@include device_pc {
								display: flex;
								align-items: center;
								font-size: 18px;
								min-height: 80px;
							}
							.name {
								display: flex;
								align-items: baseline;
								width: 100%;
							}
							.btn {
								font-size: 14px;
								line-height: 1.2;
								@include device_pc {
									margin: 0 auto;
									width: 100%;
									max-width: 340px;
									font-size: 16px;
								}
								& > a {
									display: block;
									padding: 8px 10px 10px;
									border: 1px solid #ccc;
									border-radius: 32px;
									@include device_sp {
										span { display: none; }
									}
									@include device_pc {
										padding: 13px 10px 11px;
									}
									&::before {
										content: '';
										display: block;
										margin: 0 auto 4px;
										width: 13px;
										height: 16px;
										background: url(../img/icon/pdf2.png) no-repeat center center;
										background-size: 100%;
										@include device_pc {
											display: inline-block;
											margin: -2px 30px 0 0;
											width: 22px;
											height: 26px;
											vertical-align: top;
										}
									}
								}
							}
						}
					}
				}
			}
		}
		&-note {
			margin-top: 20px;
			font-size: 15px;
			line-height: 1.66666666667;
		}
	}
}
