@charset 'utf-8';

@import '../variable';

/* company
------------------------------------------------ */
.company {
	&-tab {
		@include device_sp {
			margin: 0 20px 10px;
		}
		@include device_pc {
			display: flex;
			flex-wrap: wrap;
			margin-bottom: 26px;
		}
		&--item {
			position: relative;
			text-align: center;
			font-size: 19px;
			line-height: 1.2;
			@include device_sp {
				margin-top: 2px;
				&:first-child { margin-top: 0; }
			}
			@include device_pc {
				margin: 2px 2px 0 0;
				width: calc(25% - 1.5px);
				font-size: 24px;
				&:nth-of-type(-n+4) { margin-top: 0; }
				&:nth-of-type(4n) { margin-right: 0; }
			}
			& > a {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				padding: 24px 0;
				height: 100%;
				background: #fff;
				border: solid #0168b7 1px;
				color: #0168b7;
				&.active {
					background: #0168b7;
					color: #fff;
					&:after {
						background-image: url(../img/icon/arrow3.png);
					}
				}
				@include device_pc {
					padding: 35px 30px;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 18px;
					margin-top: -8px;
					width: 8px;
					height: 16px;
					background: url(../img/icon/arrow4.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						right: 20px;
						margin-top: -9px;
						width: 9px;
						height: 19px;
					}
				}
			}
		}
	}
	&-greeting {
		padding-bottom: 60px;
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
			padding-bottom: 120px;
		}
		&-content {
			position: relative;
			margin-top: 8px;
			@include device_pc {
				display: flex;
				flex-flow: row-reverse;
				justify-content: space-between;
			}
		}
		&-info {
			@include device_pc {
				width: 70%;
			}
		}
		&-copy {
			margin-bottom: 20px;
			font-size: 16px;
			color: #0168b7;
			@include device_pc {
				margin-bottom: 25px;
				font-size: 32px;
			}
		}
		&-text {
			font-size: 14px;
			@include device_pc {
				font-size: 15px;
			}
			& > p {
				& + p {
					margin-top: 1.4em;
					@include device_pc {
						margin-top: 1.5em;
					}
				}
			}
		}
		&-photo {
			@include device_sp {
				margin: 30px auto 0;
				width: 74.6268656716%;
			}
			@include device_pc {
				width: 22.5%;
			}
		}
	}
	&-philosophy {
		position: relative;
		padding-bottom: 60px;
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
			padding-bottom: 125px;
		}
		&-lists {
			@include device_sp {
				margin: 20px auto 0;
				width: calc(100% - 50px);
			}
			@include device_pc {
				display: flex;
				margin-top: 30px;
			}
			&--item {
				position: relative;
				@include device_sp {
					margin-top: 20px;
					&:first-child {
						margin-top: 0;
					}
				}
				@include device_pc {
					margin-right: 60px;
					width: calc(33.333333% - 30px);
					&:nth-of-type(3n) { margin-right: 0; }
				}
			}
			&__box {
				position: relative;
				@include device_pc {
					height: 100%;
				}
			}
			&--title {
				display: flex;
				align-items: center;
				position: relative;
				margin: 0 auto;
				width: 167px;
				height: 167px;
				background: #0168b7;
				border-radius: 50%;
				color: #fff;
				z-index: 2;
				@include device_pc {
					width: 210px;
					height: 210px;
				}
				.frame {
					position: relative;
					width: 100%;
				}
				.l,
				.min {
					display: block;
					position: relative;
					text-align: center;
					line-height: 1.2;
				}
				.l {
					font-size: 25px;
					font-weight: 500;
					@include device_pc {
						font-size: 32px;
					}
				}
				.min {
					margin: 8px auto 0;
					width: 90px;
					font-size: 14px;
					@include device_pc {
						margin-top: 10px;
						width: 114px;
						font-size: 18px;
					}
					&:before,
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						width: 16px;
						height: 1px;
						background: #fff;
						@include device_pc {
							width: 20px;
						}
					}
					&:before {
						right: 0;
					}
					&:after {
						left: 0;
					}
				}
			}
			&--text {
				position: relative;
				margin-top: -82px;
				padding: 90px 28px 18px;
				background: #fff;
				border: solid #0168b7 1px;
				font-size: 14px;
				z-index: 1;
				@include device_pc {
					margin-top: -104px;
					padding: 120px 35px 30px;
					font-size: 15px;
					border-width: 2px;
					height: calc(100% - 104px);
				}
			}
		}
		&-pic {
			margin: 30px auto 0;
			width: calc(100% - 50px);
			text-align: center;
			@include device_pc {
				margin-top: 52px;
				width: 420px;
			}
		}
	}
	&-about {
		position: relative;
		@include device_sp {
			margin: 0 20px;
		}
		&-cell {
			&__box {
				position: relative;
				@include device_sp {
					margin-top: 35px;
					&:first-child { margin-top: 0; }
				}
				@include device_pc {
					display: flex;
					align-items: center;
					padding: 36px 0;
					border-bottom: solid #ccc 1px;
					&:after {
						content: '';
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 200px;
						height: 3px;
						background: #0168b7;
					}
				}
			}
			&--title {
				position: relative;
				font-size: 21px;
				color: #0168b7;
				@include device_sp {
					padding-bottom: 14px;
					border-bottom: solid #ccc 1px;
					&:after {
						content: '';
						position: absolute;
						bottom: -2px;
						left: 0;
						width: 126px;
						height: 3px;
						background: #0168b7;
					}
				}
				@include device_pc {
					width: 200px;
					text-align: center;
					font-size: 22px;
				}
			}
			&__content {
				font-size: 14px;
				line-height: 1.75;
				@include device_sp {
					margin-top: 28px;
				}
				@include device_pc {
					padding: 0 80px;
					width: calc(100% - 200px);
					font-size: 16px;
				}
			}
			&--map {
				position: relative;
				margin-top: 24px;
				padding-bottom: 44.776119403%;
				width: 100%;
				height: 0;
				overflow: hidden;
				@include device_pc {
					padding-bottom: 42.8571428571%;
					max-width: 750px;
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			&__lists {
				&--item {
					line-height: 2.2;
					dl {
						display: flex;
						dt {
							width: 180px;
							color: #0168b7;
							@include device_pc {
								width: 210px;
							}
						}
						dd {
							width: calc(100% - 180px);
							@include device_pc {
								width: calc(100% - 210px);
							}
						}
					}
				}
			}
			&--note {
				margin-top: 25px;
				text-align: right;
				font-size: 14px;
				color: #0168b7;
				@include device_sp {
					padding-top: 10px;
					border-top: solid #ccc 1px;
				}
				@include device_pc {
					margin-top: 5px;
					font-size: 15px;
				}
			}
		}
		&-note {
			margin-top: 40px;
			font-size: 14px;
			@include device_pc {
				margin-top: 30px;
				font-size: 15px;
			}
			p {
				@include device_sp {
					&:nth-of-type(2) { margin-top: 1.4em; }
				}
				@include device_pc {
					&:nth-of-type(2),
					&:nth-of-type(3) { margin-left: 4em; }
					&:nth-of-type(4) { margin-left: 8em; }
				}
			}
		}
	}
	&-organization {
		position: relative;
		margin-top: 40px;
		background: #e5f0f8;
		@include device_sp {
			padding: 20px 20px 40px;
		}
		@include device_pc {
			padding: 50px 0 100px;
		}
		&-chart {
			margin-top: 12px;
			padding: 16px;
			background: #fff;
			@include device_pc {
				margin-top: 32px;
				padding: 60px;
			}
		}
	}
	&-information {
		position: relative;
		@include device_sp {
			padding: 20px 20px 50px;
		}
		@include device_pc {
			padding: 50px 20px 100px;
		}
		&-box {
			position: relative;
			margin-top: 32px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 70px;
			}
			&--title {
				margin-bottom: 10px;
				padding-bottom: 12px;
				border-bottom: solid #c6dbec 3px;
				font-size: 19px;
				font-weight: 700;
				line-height: 1.26315789474;
				color: #0168b7;
				@include device_pc {
					margin-bottom: 30px;
					padding-bottom: 15px;
					border-bottom-width: 4px;
					font-size: 26px;
				}
			}
			&__content {
			}
		}
		&-lists {
			margin-top: 6px;
			&--item {
				border-bottom: solid #ccc 1px;
				font-size: 14px;
				line-height: 1.6;
				@include device_pc {
					font-size: 18px;
				}
				a {
					display: block;
					position: relative;
					padding: 10px 35px;
					color: #0168b7;
					@include device_pc {
						padding: 28px 62px;
					}
					span {
						padding-left: 1em;
						font-size: 12px;
						color: #404040;
						@include device_pc {
							font-size: 15px;
						}
					}
				}
				.icon {
					&-pdf {
						position: absolute;
						top: 10px;
						left: 0;
						width: 20px;
						height: 24px;
						@include device_pc {
							top: 25px;
							width: 30px;
							height: 36px;
						}
					}
					&-blank {
						top: 10px;
						@include device_pc {
							top: 30px;
							width: 25px;
							height: 25px;
						}
					}
				}
			}
		}
	}
	&-office {
		@include device_sp {
			.title--5 {
				margin: 0 20px;
			}
		}
		&-name {
			font-size: 21px;
			font-weight: 400;
			color: #0168b7;
			@include device_pc {
				font-size: 28px;
			}
		}
		&-address {
			margin-top: 6px;
			font-size: 14px;
			line-height: 1.75;
			@include device_pc {
				margin-top: 14px;
				font-size: 16px;
			}
		}
		&-business {
			margin-top: 10px;
			@include device_pc {
				margin-top: 20px;
			}
			&--title {
				font-size: 16px;
				color: #0168b7;
				@include device_pc {
					font-size: 18px;
				}
			}
			&--text {
				margin-top: 2px;
				font-size: 14px;
				line-height: 1.75;
				@include device_pc {
					margin-top: 4px;
					font-size: 16px;
				}
			}
		}
		&-tab {
			display: flex;
			@include device_sp {
				margin: 0 20px;
				flex-wrap: wrap;
			}
			@include device_pc {
				justify-content: space-between;
			}
			&--item {
				text-align: center;
				font-size: 16px;
				@include device_sp {
					margin: 1px 1px 0 0;
					width: calc(50% - .5px);
					&:nth-of-type(1) {
						& > a {
							border-radius: 20px 0 0 0;
						}
					}
					&:nth-of-type(2) {
						& > a {
							border-radius: 0 20px 0 0;
						}
					}
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin-left: 1px;
					width: 100%;
					font-size: 24px;
					&:first-child {
						margin-left: 0;
						& > a {
							border-radius: 30px 0 0 0;
						}
					}
					&:last-child {
						& > a {
							border-radius: 0 30px 0 0;
						}
					}
				}
				& > a {
					display: block;
					padding: 16px 0 13px;
					height: 100%;
					background: #0168b7;
					color: #fff;
					&:after {
						content: '';
						display: block;
						margin: 8px auto 0;
						width: 14px;
						height: 8px;
						background: url(../img/icon/arrow_down1.png) no-repeat center center;
						background-size: 100%;
					}
					@include device_pc {
						padding: 30px 0 23px;
						&:after {
							margin-top: 10px;
							width: 18px;
							height: 10px;
						}
					}
				}
			}
		}
		&-lists {
			&--item {
				padding: 25px 0 30px;
				border-bottom: solid #ccc 1px;
				@include device_pc {
					display: flex;
					padding: 44px 0;
				}
			}
			&__info {
				@include device_pc {
					padding-right: 40px;
					width: 58.3333333333%;
				}
			}
			&--map {
				position: relative;
				padding-bottom: 44.776119403%;
				width: 100%;
				height: 0;
				overflow: hidden;
				@include device_sp {
					margin-top: 15px;
				}
				@include device_pc {
					padding-bottom: 18.3333333333%;
					width: 41.6666666667%;
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		&-group {
			background: #f8fbfd;
			@include device_sp {
				padding: 35px 20px 50px;
			}
			@include device_pc {
				padding: 70px 0 100px;
			}
			&__box {
				margin-top: 30px;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 62px;
				}
			}
			&--title {
				position: relative;
				padding-bottom: 14px;
				font-size: 26px;
				font-weight: 400;
				border-bottom: solid #ccc 1px;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 18px;
					font-size: 42px;
				}
				&:after {
					content: '';
					position: absolute;
					bottom: -2px;
					left: 0;
					width: 126px;
					height: 3px;
					background: #0168b7;
				}
			}
		}
		&-head {
			background: #e5f0f8;
			@include device_sp {
				padding: 25px 20px 30px;
			}
			@include device_pc {
				margin-top: 30px;
				padding: 60px 0;
				.company-office-name {
					font-size: 32px;
				}
			}
			&__content {
				@include device_pc {
					display: flex;
					flex-flow: row-reverse;
				}
			}
			&__info {
				@include device_pc {
					padding-left: 60px;
					width: 62.5%;
				}
			}
			&--photo {
				@include device_sp {
					margin-top: 22px;
				}
				@include device_pc {
					width: 37.5%;
				}
			}
			&--map {
				position: relative;
				margin-top: 20px;
				padding-bottom: 44.776119403%;
				width: 100%;
				height: 0;
				overflow: hidden;
				@include device_pc {
					margin-top: 40px;
					padding-bottom: 33.3333333333%;
				}
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
		}
		&-prefecture {
			margin-top: 30px;
			@include device_pc {
				margin-top: 70px;
			}
		}
	}
	/* top */
	&-top {
	}
	/* idea */
	&-idea {
	}
	/* company */
	&-company {
	}
	/* list */
	&-list {
	}
}