@charset 'utf-8';

@import '../variable';

/* sitemap
------------------------------------------------ */
.sitemap {
	/* top */
	&-top {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
	}
	/* lists */
	&-lists {
		@include device_sp {
			margin: 0 20px;
		}
		&-block {
			position: relative;
			margin-top: 45px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 70px;
			}
		}
		&-box {
			position: relative;
			margin: 0 15px 42px;
			&:last-child { margin-bottom: 0; }
			@include device_pc {
				margin: 0 50px 80px;
			}
			&--title {
				position: relative;
				margin-bottom: 10px;
				padding-bottom: 10px;
				border-bottom: solid #c6dbec 1px;
				font-size: 19px;
				font-weight: 500;
				@include device_pc {
					margin-bottom: 24px;
					padding-bottom: 12px;
					border-bottom-width: 2px;
					font-size: 26px;
				}
				a {
					display: block;
					color: #0168b7;
				}
			}
		}
		&-links {
			display: flex;
			flex-wrap: wrap;
			align-items: flex-start;
			&--item {
				position: relative;
				border-bottom: solid #c6dbec 1px;
				font-size: 14px;
				line-height: 1.28571428571;
				@include device_sp {
					margin: 8px 8.1967213114% 0 0;
					width: 45.9016393443%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 22px 4.2424242424% 0 0;
					width: 21.8181818182%;
					font-size: 16px;
					&:nth-of-type(-n+4) { margin-top: 0; }
					&:nth-of-type(4n) { margin-right: 0; }
				}
				a {
					display: block;
					position: relative;
					padding: 8px 0;
					color: #0168b7;
					@include device_pc {
						padding: 14px 0;
					}
				}
			}
		}
		&-pages {
			display: flex;
			flex-wrap: wrap;
			&--item {
				position: relative;
				border-bottom: solid #a5a5a5 1px;
				font-size: 18px;
				line-height: 1.33333333333;
				@include device_sp {
					margin: 40px 11.9402985074% 0 0;
					width: 44.0298507463%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 90px 10% 0 0;
					width: 17.5%;
					border-bottom-width: 2px;
					font-weight: 700;
					&:nth-of-type(-n+4) { margin-top: 0; }
					&:nth-of-type(4n) { margin-right: 0; }
				}
				a {
					display: block;
					position: relative;
					padding: 14px 0;
					height: 100%;
					@include device_pc {
						padding: 16px 0;
					}
				}
				span {
					@include device_pc {
						font-size: 14px;
					}
				}
				&:before,
				&:after {
					content: '';
					bottom: -1px;
					position: absolute;
					height: 1px;
					@include device_pc {
						bottom: -2px;
						height: 2px;
					}
				}
				&:before {
					left: 40px;
					width: 10px;
					background: #fff;
					@include device_pc {
						left: 60px;
					}
				}
				&:after {
					left: 38px;
					transform-origin: right;
					transform: rotate(-45deg);
					width: 12px;
					background: #a5a5a5;
					@include device_pc {
						left: 58px;
					}
				}
			}
		}
	}
}
