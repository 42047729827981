@charset 'utf-8';
@use 'sass:math';

@import './variable';

/* reset
------------------------------------------------ */
body,div,pre,p,a,img,form,fieldset,input,textarea,select,option,
dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,table,th,td,embed,object,
figure,figcaption {
	margin: 0;
	padding: 0;
}
html { height: 100%; font-size: 62.5%; }
body {
	width: 100%;
	height: 100%;
	background: #fff;
	-webkit-text-size-adjust: 100%;
	font-family: $fontGotic;
	font-size: 10px;
	font-weight: 400;
	line-height: 1.6;
	color: #404040;
}

* {
	box-sizing: border-box;
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

ul,ol {
	list-style: none;
}

img {
	width: auto;
	height: auto;
	max-width: 100%;
	border: none;
	vertical-align: top;
}

picture {
	display: block;
	position: relative;
}

a {
	text-decoration: none;
	color: #404040;
	.device--click & {
		transition: ease .3s;
		&:hover {
			opacity: .8;
		}
	}
}

a,
input[type=submit],
button {
	.device--click & {
		transition: ease .3s;
		&:hover {
			opacity: .8;
		}
	}
}

.clearfix {
	clear: both;
	*zoom: 1;
	&:before,
	&:after { content: ' '; display: table; }
	&:after { clear: both; }
}

/* visible */
.visible {
	&-sp {
		@include device_sp {
			display: inherit !important;
		}
		@include device_pc {
			display: none !important;
		}
	}
	&-pc {
		@include device_sp {
			display: none !important;
		}
		@include device_pc {
			display: inherit !important;
		}
	}
}

/* color */
.c {
	&-red {
		color: #bf0303;
	}
	&-blue {
		color: #0168b7;
	}
}

@import './component/layout';
@import './component/header';
@import './component/main';
@import './component/heritage';
@import './component/title';
@import './component/button';
@import './component/icon';
@import './component/lists';
@import './component/style';
@import './component/table';
@import "module/_business.scss";
@import "module/_company.scss";
@import "module/_contact.scss";
@import "module/_contract.scss";
@import "module/_flow.scss";
@import "module/_leo.scss";
@import "module/_news.scss";
@import "module/_notfound.scss";
@import "module/_privacypolicy.scss";
@import "module/_search.scss";
@import "module/_seminar.scss";
@import "module/_sitemap.scss";
@import "module/_target.scss";
@import "module/_techlib.scss";
@import "module/_techlib2.scss";
@import "module/_top.scss";
@import "module/_topics.scss";
@import './component/footer';

