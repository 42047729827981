@charset 'utf-8';

@import '../variable';

/* contract
------------------------------------------------ */
.contract {
	&-introduce {
		&-tab {
			display: flex;
			flex-wrap: wrap;
			@include device_sp {
				margin: 0 20px;
			}
			@include device_pc {
				justify-content: space-between;
			}
			&--item {
				text-align: center;
				font-size: 16px;
				@include device_sp {
					margin: 1px 1px 0 0;
					width: calc(50% - .5px);
					&:nth-of-type(1) {
						margin-top: 0;
						width: 100%;
						& > a {
							border-radius: 20px 20px 0 0;
						}
					}
					&:nth-of-type(2n+1) { margin-right: 0; }
				}
				@include device_pc {
					width: calc(14.2857142857% - 1px);
					font-size: 24px;
					&:first-child {
						& > a {
							border-radius: 30px 0 0 0;
						}
					}
					&:last-child {
						& > a {
							border-radius: 0 30px 0 0;
						}
					}
				}
				& > a {
					display: block;
					padding: 16px 0 13px;
					height: 100%;
					background: #0168b7;
					color: #fff;
					&:after {
						content: '';
						display: block;
						margin: 8px auto 0;
						width: 14px;
						height: 8px;
						background: url(../img/icon/arrow_down1.png) no-repeat center center;
						background-size: 100%;
					}
					@include device_pc {
						padding: 30px 0 23px;
						&:after {
							margin-top: 10px;
							width: 18px;
							height: 10px;
						}
					}
				}
			}
		}
		&-tab2 {
			display: flex;
			flex-wrap: wrap;
			@include device_sp {
				margin: 0 20px 35px;
			}
			@include device_pc {
				margin-bottom: 76px;
			}
			&--item {
				text-align: center;
				font-size: 16px;
				font-weight: 700;
				line-height: 1.2;
				@include device_sp {
					margin: 4.4776119403% 1.49253731343% 0 0;
					width: 49.2537313433%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 6.33333333333% 1.66666666667% 0 0;
					width: 49.1666666667%;
					font-size: 32px;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				& > a {
					display: block;
					position: relative;
					padding: 10px 0 18px;
					border: solid #0168b7 1px;
					color: #0168b7;
					@include device_pc {
						padding: 38px 0 36px;
						border-width: 2px;
					}
					&.active {
						background: #0168b7;
						color: #fff;
						&:before {
							background-image: url(../img/icon/door2.png);
						}
						&:after {
							content: '';
							position: absolute;
							bottom: -7px;
							left: 50%;
							margin-left: -3px;
							width: 0;
							height: 0;
							border: solid transparent 6px;
							border-top: solid #0168b7 7px;
							border-bottom: none;
							@include device_pc {
								bottom: -16px;
								margin-left: -12px;
								border-width: 12px;
								border-top-width: 14px;
							}
						}
					}
					&:before {
						content: '';
						display: block;
						width: 15px;
						height: 25px;
						background: url(../img/icon/door1.png) no-repeat center center;
						background-size: 100%;
						@include device_sp {
							margin: 0 auto 8px;
						}
						@include device_pc {
							position: absolute;
							top: 50%;
							left: 80px;
							margin-top: -40px;
							width: 48px;
							height: 81px;
						}
						@media screen and (min-width: $mediaPC) and (max-width: 1100px) {
							left: 6.66666666667vw;
						}
						@media screen and (min-width: $mediaPC) and (max-width: 980px) {
							left: 2vw;
						}
					}
				}
				span {
					display: block;
					margin-top: 6px;
					font-size: 10px;
					@include device_pc {
						margin-top: 6px;
						font-size: 15px;
					}
				}
			}
		}
		&-content {
			padding: 58px 20px 50px;
			background: #e5f0f8;
			@include device_pc {
				padding: 106px 0 140px;
			}
		}
		.business {
			&-index {
				&-lists {
					padding-top: 70px;
					@include device_pc {
						padding-top: 130px;
					}
				}
			}
		}
		&-lists {
			margin-top: 58px;
			@include device_pc {
				margin-top: 94px;
			}
			&--item {
				display: flex;
				flex-wrap: wrap;
				align-items: flex-start;
				position: relative;
				margin-top: 34px;
				&:first-child { margin-top: 0; }
				&:nth-of-type(odd) {
					.contract-introduce-lists--photo {
						&:before {
							top: -4px;
							left: -4px;
							@include device_pc {
								top: -8px;
								left: -8px;
							}
						}
						&:after {
							bottom: -4px;
							right: -4px;
							@include device_pc {
								bottom: -8px;
								right: -8px;
							}
						}
					}
				}
				&:nth-of-type(even) {
					.contract-introduce-lists--photo {
						&:before {
							top: -4px;
							right: -4px;
							@include device_pc {
								top: -8px;
								right: -8px;
							}
						}
						&:after {
							bottom: -4px;
							left: -4px;
							@include device_pc {
								bottom: -8px;
								left: -8px;
							}
						}
					}
				}
				@include device_sp {
					flex-flow: column-reverse;
				}
				@include device_pc {
					margin-top: 70px;
					&:nth-of-type(odd) {
						flex-flow: row-reverse;
						.contract-introduce-lists {
							&__info {
								margin-left: auto;
								padding-left: 13.3333333333%;
							}
							&--photo {
								margin-right: -6.5%;
							}
						}
					}
					&:nth-of-type(even) {
						.contract-introduce-lists {
							&__info {
								padding-right: 13.3333333333%;
							}
							&--photo {
								margin-left: -6.5%;
							}
						}
					}
				}
			}
			&__info {
				position: relative;
				padding: 22% 25px 20px;
				width: 100%;
				background: #c6dbec;
				z-index: 1;
				@include device_pc {
					margin-top: 44px;
					padding: 40px 60px 45px;
					width: 62.5%;
				}
			}
			&--title {
				font-weight: 400;
				line-height: 1.2;
				color: #0168b7;
				.l,
				.min {
					display: block;
				}
				.l {
					font-size: 27px;
					font-weight: 400;
					@include device_pc {
						font-size: 32px;
					}
				}
				.min {
					margin-top: 6px;
					font-size: 13px;
					@include device_pc {
						margin-top: 8px;
						font-size: 16px;
					}
				}
			}
			&--text {
				margin-top: 14px;
				font-size: 14px;
				@include device_pc {
					margin-top: 32px;
					font-size: 15px;
				}
			}
			&--btn {
				margin: 16px 20px 0;
				font-size: 14px;
				a {
					padding: 12px 10px;
				}
				@include device_pc {
					margin: 34px 0 0;
					max-width: 240px;
					a {
						&:after {
							right: 24px;
							margin-top: -6px;
							width: 7px;
							height: 12px;
						}
					}
				}
			}
			&--photo {
				position: relative;
				width: 77.6119402985%;
				z-index: 2;
				@include device_sp {
					margin: 0 auto -17.2727272727%;
				}
				@include device_pc {
					width: 43.3333333333%;
				}
				img {
					position: relative;
					z-index: 1;
				}
				&:before,
				&:after {
					content: '';
					position: absolute;
					padding-bottom: 14.4230769231%;
					width: 14.4230769231%;
					background: #0068b7;
					z-index: -1;
				}
			}
		}
	}
	&-search {
		position: relative;
		padding: 60px 0 48px;
		background: url(../img/top/search_bg.jpg) no-repeat center center;
		background-size: cover;
		@include device_pc {
			padding: 124px 0 90px;
		}
	}
	&-index {
		position: relative;
	}
	/* top */
	&-top {
		.title {
			&--image {
				background-image: url(../img/contract/title.jpg);
			}
			&--ribbon {
				background-image: url(../img/contract/title2_bg.jpg);
			}
		}
		&-outline {
			position: relative;
			@include device_sp {
				padding: 0 20px;
			}
			@include device_pc {
				padding: 90px 0 136px;
			}
			&__info {
				@include device_pc {
					margin-left: 41.6666666667%;
				}
			}
			&--title {
				font-family: $fontMin;
				font-size: 30px;
				font-weight: 700;
				color: #0168b7;
				@include device_pc {
					font-size: 60px;
				}
			}
			&--copy {
				margin-top: 6px;
				font-size: 18px;
				color: #0168b7;
				@include device_pc {
					margin-top: 32px;
					font-size: 28px;
				}
			}
			&--text {
				margin-top: 10px;
				font-family: $fontMin;
				font-size: 14px;
				@include device_pc {
					margin-top: 28px;
					font-size: 18px;
				}
			}
			&--photo {
				@include device_sp {
					margin-top: 28px;
				}
				@include device_pc {
					position: absolute;
					top: 50%;
					left: -29.0322580645%;
					transform: translateY(-50%);
					width: 66.3709677419%;
				}
			}
		}
	}
	/* category */
	&-category {
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/contract/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/contract/title_bg-pc.jpg);
				}
			}
		}
	}
	/* detail */
	&-detail {
		.title {
			&--page {
				@include device_sp {
					background-image: url(../img/contract/title_bg.jpg);
				}
				@include device_pc {
					background-image: url(../img/contract/title_bg-pc.jpg);
				}
			}
		}
		&-title {
			font-size: 30px;
			font-weight: 400;
			line-height: 1.2;
			color: #0168b7;
			@include device_sp {
				margin: 0 20px 20px;
			}
			@include device_pc {
				margin-bottom: 54px;
				font-size: 52px;
			}
		}
		&-content {
			position: relative;
			padding: 45px 20px 40px;
			background: #e5f0f8;
			@include device_pc {
				padding: 80px 0;
			}
		}
		&-block {
			position: relative;
			margin-top: 10px;
			padding: 15px 20px 20px;
			background: #fff;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 20px;
				padding: 28px 65px 40px;
			}
			&--title {
				padding-bottom: 8px;
				border-bottom: solid #c6dbec 1px;
				font-size: 19px;
				font-weight: 400;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 18px;
					font-size: 28px;
				}
			}
			&__content {
				position: relative;
				margin-top: 20px;
			}
			&--lead {
				margin-top: 32px;
				font-size: 14px;
				line-height: 1.2;
				color: #0168b7;
				&:first-child { margin-top: 0; }
				@include device_pc {
					font-size: 18px;
					line-height: 1.61111111111;
				}
			}
			&--image {
				margin: 32px auto 0;
				max-width: 900px;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 60px;
				}
			}
		}
		&-subblock {
			position: relative;
			margin-top: 35px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 90px;
			}
			&--title {
				font-size: 17px;
				font-weight: 700;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					font-size: 24px;
				}
			}
			&__content {
				position: relative;
				margin-top: 20px;
			}
			&--lead {
				margin-top: 32px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					font-size: 15px;
				}
				& > p {
					text-indent: 1em;
				}
			}
			&--image {
				margin: 32px auto 0;
				max-width: 900px;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 60px;
				}
			}
		}
		&-box {
			position: relative;
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--title {
				position: relative;
				font-size: 15px;
				font-weight: 700;
				line-height: 1.2;
				@include device_pc {
					font-size: 18px;
				}
			}
			&__content {
				position: relative;
				margin-top: 16px;
			}
		}
		&-check {
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--item {
				position: relative;
				margin-top: 20px;
				padding-left: 24px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 15px;
					padding-left: 28px;
					font-size: 15px;
				}
				&:before {
					content: '';
					position: absolute;
					top: 6px;
					left: 2px;
					width: 10px;
					height: 8px;
					background: url(../img/icon/check1.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						top: 5px;
						width: 12px;
						height: 9px;
					}
				}
			}
		}
		&-lists {
			margin-top: 25px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 35px;
			}
			&--item {
				position: relative;
				margin-top: 20px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 30px;
					font-size: 16px;
					line-height: 1.625;
				}
				& > a {
					display: inline-block;
					position: relative;
					padding-left: 26px;
					vertical-align: top;
					@include device_pc {
						padding-left: 38px;
					}
				}
				.icon {
					&-pdf {
						position: absolute;
						top: 0;
						left: 0;
						@include device_pc {
							top: 4px;
						}
					}
					&-new {
						vertical-align: baseline;
						@include device_pc {
							margin-left: 6px;
						}
					}
				}
			}
		}
	}
}
