@charset 'utf-8';

@import '../variable';

/* privacy
------------------------------------------------ */
.privacy {
	/* top */
	&-top {
		padding-bottom: 50px;
		@include device_pc {
			padding-bottom: 100px;
		}
	}
	/* lists */
	&-content {
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
			max-width: 1140px;
		}
	}
	/* block */
	&-block {
		position: relative;
		margin-top: 35px;
		&:first-child { margin-top: 0; }
		@include device_pc {
			margin-top: 64px;
		}
		&-content {
			font-size: 14px;
			line-height: 1.7;
			@include device_pc {
				font-size: 15px;
			}
			a {
				text-decoration: underline;
				color: #0168b7;
				.device--click & {
					&:hover {
						text-decoration: none;
					}
				}
			}
			p {
				& + p {
					margin-top: 26px;
					@include device_pc {
						margin-top: 28px;
					}
				}
			}
			ul {
				margin: 26px 0;
				@include device_pc {
					margin: 28px 0;
				}
				&:first-child { margin-top: 0; }
				&:last-child { margin-bottom: 0; }
				& > li {
					position: relative;
					padding-left: 1.6em;
					&::before {
						content: '\25A0';
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
			ol {
				margin-top: 26px;
				list-style: decimal inside;
				@include device_pc {
					margin-top: 28px;
				}
				&:first-child { margin-top: 0; }
				& > li {
					& + li {
						margin-top: 26px;
						@include device_pc {
							margin-top: 28px;
						}
					}
				}
			}
		}
	}
}
