@charset 'utf-8';

@import '../variable';

div {
	&.wrapper {
		margin-bottom: 55px;
	}
	&.wrapperCorrosion01 {
		text-align: center;
		margin: 0 0 75px 0;
		h4 {
			background: #fafbf3;
			font-size: 142%;
			font-weight: normal;
			color: #515151;
			width: 160px;
			margin: 0 auto 0 auto;
			padding: 0;
			border: 1px solid #dbdbdb;
		}
	}
	&.conttainerOutside01 {
		text-align: left;
		border: 1px solid #d2d1d2;
		margin:0 auto;
	}
	&.conttainerInside01 {
		padding: 8px 0 7px 8px;
		border: 4px solid #f5f6fa;
		ul {
			margin-top:10px;
			li {
				position: relative;
				line-height: 1.4em;
				padding: 0 0 0 20px;
				&:before {
					content: '\30FB';
					position: absolute;
					top: 0;
					left: 0;
				}
			}
		}
	}
}

dl	 {
	&.first {
		margin: 0 0 37px 15px;
		dt {
			background: #fbfbf4 ;
			padding: 4px 23px;
			border: 1px solid #eaeaea;
		}
		dd {
			padding: 10px 0 10px 45px;
			ul {
				margin: 16px 0 25px 0;
				li {
					position: relative;
					line-height: 1.4em;
					padding: 0 0 0 20px;
					&:before {
						content: '\30FB';
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	&.child {
		margin: 15px 0 0 0;
		dt {
			background: none;
			border: none;
			padding: 0;
		}
		dd {
			margin-bottom: 25px;
			padding: 0;
			ul {
				margin: 0;
				li {
					line-height: 1.7em;
				}
			}
		}
	}
	&.sec {
		margin: 0 0 110px 15px;
		dt {
			background: #fbfbf4 ;
			padding: 4px 23px;
			border: 1px solid #eaeaea;
		}
		dd {
			padding: 15px 0 15px 30px;
			span {
				display: block;
				text-indent: 6.5em;
			}
			table {
				margin: 10px 0 15px;
				width: 100%;
				border-top: 1px solid #e1e1db;
				border-left: 1px solid #e1e1db;
				th,
				td {
					padding: 10px;
				}
				th {
					background: #f5f5f5;
					text-align: center;
					vertical-align: middle;
					font-weight: normal;
					padding: 6px 0 3px 0;
					border-right: 1px solid #e1e1db;
					border-bottom: 1px solid #e1e1db;
				}
				td {
					background: #fff;
					padding: 2px 13px;
					vertical-align: top;
					border-right: 1px solid #e1e1db;
					border-bottom: 1px solid #e1e1db;
					li {
						position: relative;
						line-height: 1.9em;
						padding: 0 0 0 20px;	
						&:before {
							content: '\30FB';
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
			}
		}
	}
	&.subRead01 {
		margin: 0 0 0 15px;
		dt {
			margin-bottom: 1px;
			font-size: 14px;
			@include device_pc {
				font-size: 17px;
			}
		}
		dd {
			margin-bottom:10px;
			font-size: 12px;
			@include device_pc {
				font-size: 15px;
			}
		}
	}
}

ul {
	&#plamtCatList {
		
		margin:12px 0 23px 0;
		li {
			float: left;
			width: 33.5%;
		}
	}
	&#corrosionCatList {
		margin:12px 0 23px 0;
		li {
			float: left;
			width: 335px;
		}
	}
	&.frictionList01 {
		margin: 0 0 52px 0;
		padding: 0 0 0 22px;
		li {
			position: relative;
			line-height: 1.9em;
			padding: 0 0 0 20px;
			&:before {
				content: '\30FB';
				position: absolute;
				top: 0;
				left: 0;
			}
		}
	}
}

.tableHeadTitle {
	text-align: center !important;
	padding: 7px 0 !important;
}

.arrow01 {
	width: 33px;
	height: 30px;
	margin: 15px auto;
}

.pdbrance {
	padding-top: 4px;	
	padding-bottom: 7px;
}

.containerOutside01 {
	margin: 0 auto 25px;
	width: 278px;
	border: 1px solid #d1d1d3;
}

.containerInside01 {
	background: #fff;
	border: 4px solid #f5f6fa;
}

.insideList {
	margin: 3px 0 3px 12px;
	list-style-position: outside;
	li {
		font-size: 12px;
		line-height: 1.6em;
	}
}

.innerBg {
	margin: 0 auto;
	width: 420px;
	background: url(../../business/corrosion_control/img/pro_bg01.gif) no-repeat center 33px;
	@include device_sp {
		width: 100%;
		background-size: 100%;
	}
}

.alCenter {
	text-align: center !important;
}

.borderWrapper {
	text-align: center;
	width: 78px;
	margin: 0 0 0 18px;
	border: 1px solid #d1d1d3;
	@include device_sp {
		width: 100%;
		margin-left: 0;
	}
	p {
		background: #fff;
		border: 4px solid #f5f6fa;
		margin: 0;
		padding: 5px 0 3px 0;
	}
}

.listwrapper {
	float: left;
	margin: 0 0 135px 52px;
	padding: 0 0 25px 0;
	&:first-child { margin-left: 0; }
	@include device_sp {
		margin-left: 7.5vw;
		width: 21vw;
	}
	ul {
		margin: 9px 0 0 17px;
		@include device_sp {
			margin: 8px 4px 0;
		}
		li {
			font-size: 11px;
			line-height: 1.5em;
		}
	}
}

.lHbrance01 {
	line-height: 1.55em;	
}

