@charset 'utf-8';

@import '../variable';

/* Tech Library2
------------------------------------------------ */
.page--tech_library2 {
	.title {
		&--page {
			margin-bottom: 0;
			background-image: url(../img/techlibrary2/title_bg_page.jpg);
		}
	}
}

.techlib2 {
	&-content {
		padding: 20px 0 40px;
		background: url(../img/techlibrary2/bg1.png) repeat left top;
		background-size: 14px;
		@include device_sp {
			& > .wrap:not(.topicpath) {
				padding-right: 20px;
				padding-left: 20px;
			}
		}
		@include device_pc {
			padding: 40px 0 80px;
		}
	}
	/* search */
	&-search {
		margin-bottom: 40px;
		background-color: #F8F7F6;
		border-radius: 10px;
		@include device_pc {
			margin-bottom: 80px;
		}
		&-title {
			padding: 12px 0;
			border-bottom: 1px solid #E5F0F8;
			text-align: center;
			font-size: 17px;
			font-weight: 500;
			line-height: 1.8;
		}
		form {
			display: block;
			width: 100%;
		}
		&-form {
			display: flex;
			position: relative;
			margin: 0 10px;
			padding: 18px 0;
			@include device_pc {
				margin: 0 74px;
			}
			&__input {
				margin-right: 3px;
				width: calc(100% - (80px + 3px));
				height: 44px;
				@include device_pc {
					width: calc(100% - (140px + 3px));
				}
				input {
					appearance: none;
					padding: 0 8px;
					width: 100%;
					height: 44px;
					background-color: #fff;
					border: none;
					border-radius: 5px;
					box-shadow: none;
					font-family: inherit;
					font-size: 16px;
					font-weight: 500;
					@include device_pc {
						padding: 0 25px;
					}
				}
			}
			&__submit {
				display: flex;
				justify-content: center;
				align-items: center;
				appearance: none;
				width: 80px;
				height: 44px;
				background-color: #0168B7;
				border: none;
				border-radius: 0 5px 5px 0;
				box-shadow: none;
				text-align: center;
				font-family: inherit;
				font-size: 16px;
				font-weight: 500;
				cursor: pointer;
				@include device_pc {
					width: 140px;
				}
				color: #fff;
				&::after {
					content: '';
					display: block;
					margin-left: 12px;
					width: 17px;
					height: 17px;
					background: url(../img/icon/search3.png) no-repeat center center;
					background-size: 100%;
				}
			}
		}
	}
	/* lists */
	&-lists {
		display: grid;
		@include device_sp {
			row-gap: 40px;
			column-gap: 16px;
			grid-template-columns: repeat(2,calc(50% - 8px));
		}
		@include device_pc {
			row-gap: 80px;
			column-gap: 24px;
			grid-template-columns: repeat(4,calc((100% / 4) - (24px / (4 / 3))));
		}
		&-item {
			display: grid;
			gap: 0;
			grid-template-rows: subgrid;
			grid-row: span 4;
			position: relative;
			padding: 16px;
			background-color: #fff;
			border-radius: 10px;
			@include device_pc {
				padding: 24px;
			}
		}
		&-no {
			margin-bottom: 8px;
			font-size: 20px;
			line-height: 1.2;
			color: #0168B7;
			@include device_pc {
				font-size: 24px;
			}
			& > span {
				display: inline-block;
				padding: 5px 16px;
				border: 1px solid #0168B7;
				border-radius: 32px;
				vertical-align: top;
				& > span {
					padding-right: 0.2em;
					font-size: 16px;
					@include device_pc {
						font-size: 20px;
					}
				}
			}
		}
		&-title {
			font-size: 16px;
			font-weight: 700;
			line-height: 1.4;
			color: #0168B7;
			@include device_pc {
				font-size: 20px;
			}
		}
		&-excerpt {
			display: -webkit-box;
			margin-top: 8px;
			padding-top: 8px;
			border-top: 1px solid #404040;
			text-overflow: ellipsis;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 4;
			line-clamp: 4;
			font-size: 13px;
			letter-spacing: 0.1em;
			line-height: 1.4;
			max-height: 94px;
			overflow: hidden;
			@include device_pc {
				font-size: 15px;
			}
		}
		&-btn {
			margin: 24px auto 0;
			text-align: center;
			width: 100%;
			font-size: 16px;
			font-weight: 500;
			@include device_pc {
				width: 160px;
				font-size: 20px;
			}
			& > a {
				display: block;
				padding: 4px 0;
				background: linear-gradient(90.39deg, #125797 0.34%, #0A60A7 20.78%, #0168B7 100.03%);
				border-radius: 20px;
				color: #fff;
			}
		}
	}
	/* detail */
	&-detail {
		&-title {
			position: relative;
			padding: 38px 0;
			background: url(../img/techlibrary2/title_bg_page.jpg) no-repeat center bottom;
			background-size: auto 100%;
			background-color: #e5f0f8;
			color: #0168b7;
			@include device_pc {
				display: flex;
				justify-content: center;
				align-items: center;
				padding: 82px 0;
			}
			&__name {
				text-align: center;
				@include device_sp {
					margin-bottom: 20px;
				}
			}
			&__sub {
				margin-bottom: 8px;
				font-size: 25px;
				line-height: 1;
				@include device_pc {
					font-size: 34px;
				}
			}
			&__main {
				font-size: 54px;
				line-height: 1;
				@include device_pc {
					font-size: 72px;
				}
			}
			&__num {
				position: relative;
				@include device_sp {
					text-align: center;
				}
				@include device_pc {
					margin-left: 20px;
				}
			}
			&__vol {
				display: inline-flex;
				align-items: baseline;
				position: absolute;
				top: 10px;
				left: 0;
				margin-left: 18px;
				padding: 1px 10px 3px;
				font-size: 20px;
				line-height: 1.2;
				background-color: #0168B7;
				border-radius: 27px;
				color: #fff;
				@include device_pc {
					top: 14px;
					margin-left: 26px;
					font-size: 25px;
				}
				& > span {
					font-size: 14px;
					@include device_pc {
						font-size: 19px;
					}
				}
			}
			&__no {
				display: inline-flex;
				align-items: baseline;
				padding: 6px 32px 14px;
				background-color: rgba($color: #fff, $alpha: 0.7);
				border-radius: 58px;
				font-family: $fontMin;
				font-size: 70px;
				line-height: 1;
				@include device_pc {
					font-size: 96px;
				}
				& > span {
					font-size: 34px;
					@include device_pc {
						font-size: 50px;
					}
				}
			}
		}
	}
	/* post */
	&-post {
		&-title {
			position: relative;
			margin-bottom: 16px;
			padding: 12px 18px;
			min-height: 80px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			border-radius: 10px;
			@include device_pc {
				display: flex;
				align-items: center;
				margin-bottom: 20px;
				padding: 25px 32px;
				min-height: 210px;
			}
			&--en {
				position: relative;
				padding-right: 20px;
				font-family: $fontMin;
				font-size: 20px;
				font-weight: 500;
				line-height: 1.2;
				color: #C6E4FC;
				@include device_sp {
					display: inline-block;
					margin-bottom: 10px;
					vertical-align: top;
				}
				@include device_pc {
					margin-right: 45px;
					padding-right: 40px;
					font-size: 38px;
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%) rotate(20deg);
					width: 1px;
					height: 28px;
					background-color: #C6E4FC;
					@include device_pc {
						width: 2px;
						height: 98px;
					}
				}
			}
			&--ja {
				position: relative;
				font-size: 24px;
				font-weight: 500;
				line-height: 1.4;
				@include device_pc {
					font-size: 35px;
				}
			}
			&--name {
				position: relative;
				padding-right: 20px;
				font-family: $fontMin;
				font-size: 24px;
				font-weight: 500;
				line-height: 1.2;
				@include device_sp {
					display: inline-block;
					margin-bottom: 10px;
					vertical-align: top;
				}
				@include device_pc {
					margin-right: 45px;
					padding-right: 40px;
					font-size: 38px;
				}
				&::after {
					content: '';
					position: absolute;
					top: 50%;
					right: 0;
					transform: translateY(-50%) rotate(20deg);
					width: 1px;
					height: 28px;
					background-color: #fff;
					@include device_pc {
						width: 2px;
						height: 98px;
					}
				}
			}
			&--sub {
				@include device_sp {
					margin-bottom: 10px;
				}
				& > span {
					display: inline-block;
					padding: 4px 18px;
					background-color: #fff;
					border-radius: 24px;
					vertical-align: top;
					font-size: 18px;
					font-weight: 500;
					line-height: 1.2;
					color: #404040;
					@include device_pc {
						padding: 6px 28px;
						font-size: 25px;
					}
				}
			}
			&--text {
				font-size: 18px;
				font-weight: 500;
				line-height: 1.6;
				@include device_sp {
					margin-bottom: 10px;
				}
				@include device_pc {
					font-size: 28px;
					line-height: 2.21428571429;
				}
			}
			&--author {
				padding: 8px 18px;
				background-color: #02508B;
				border-radius: 10px;
				font-size: 14px;
				font-weight: 700;
				letter-spacing: 0.04em;
				line-height: 1.4;
				@include device_sp {
					display: inline-block;
					margin-top: 12px;
					vertical-align: top;
				}
				@include device_pc {
					position: absolute;
					bottom: -8px;
					right: 28px;
					transform: translateY(50%);
					padding: 12px 32px;
					font-size: 20px;
				}
			}
			&.is {
				&-introduce {
					background-image: url(../img/techlibrary2/title_bg1.png);
					color: #fff;
				}
				&-report {
					background-image: url(../img/techlibrary2/title_bg2.png);
					color: #fff;
					.techlib2-post-title--en {
						display: inline-flex;
						align-items: center;
						font-size: 54px;
						@include device_pc {
							font-size: 80px;
						}
						& > span {
							font-size: 18px;
							@include device_pc {
								font-size: 24px;
							}
						}
					}
				}
				&-chemistry {
					background-image: url(../img/techlibrary2/title_bg3.png);
					color: #fff;
					.techlib2-post-title {
						&--sub {
							width: 100%;
							& > span {
								display: inline-flex;
								align-items: center;
								background-color: #E5F0F8;
								padding: 4px 30px;
								vertical-align: top;
								font-size: 24px;
								font-weight: 600;
								letter-spacing: 0.04em;
								color: #0168B7;
								@include device_pc {
									padding: 4px 34px;
									font-size: 32px;
								}
								&::before,
								&::after {
									content: '';
									width: 0;
									height: 30px;
									border: 1px solid #0168B7;
									@include device_pc {
										border-width: 2px;
										height: 40px;
									}
								}
								&::before {
									transform: rotate(-30deg);
									margin-right: 14px;
								}
								&::after {
									transform: rotate(30deg);
									margin-left: 14px;
								}
								& > span {
									margin-left: 8px;
									font-size: 28px;
									letter-spacing: 0;
									@include device_pc {
										font-size: 35px;
									}
									& > span {
										font-size: 20px;
										@include device_pc {
											font-size: 26px;
										}
									}
								}
							}
						}
					}
					@include device_pc {
						flex-wrap: wrap;
					}
				}
				&-technology {
					background-image: url(../img/techlibrary2/title_bg4.png);
					color: #fff;
					@include device_pc {
						margin-bottom: 54px;
						flex-wrap: wrap;
						.techlib2-post-title--sub {
							margin-right: 18px;
						}
					}
				}
				&-record {
					background-image: url(../img/techlibrary2/title_bg5.png);
					color: #fff;
					@include device_sp {
						display: flex;
						align-items: center;
					}
					@include device_pc {
						align-items: flex-end;
					}
				}
				&-product {
					&-above {
						background-image: url(../img/techlibrary2/title_bg6.png);
						color: #fff;
						@include device_sp {
							display: flex;
							align-items: center;
						}
						@include device_pc {
							align-items: flex-end;
						}
					}
					&-below {
						background-image: url(../img/techlibrary2/title_bg7.png);
						color: #fff;
						@include device_pc {
							margin-bottom: 54px;
							min-height: 164px;
						}
						.techlib2-post-title--name {
							&::after {
								content: '';
								width: 1px;
								height: 28px;
								@include device_pc {
									width: 2px;
									height: 62px;
								}
							}
						}
					}
				}
				&-office {
					background-image: url(../img/techlibrary2/title_bg8.png);
					color: #fff;
					@include device_sp {
						display: flex;
						align-items: center;
					}
					@include device_pc {
						align-items: flex-end;
					}
				}
				&-column {
					background-image: url(../img/techlibrary2/title_bg9.png);
					color: #fff;
					@include device_sp {
						display: flex;
						align-items: center;
					}
					@include device_pc {
						align-items: flex-end;
					}
				}
			}
		}
		&-block {
			display: grid;
			gap: 30px;
			position: relative;
			background-color: #fff;
			border: 1px solid #fff;
			padding: 20px 30px;
			border-radius: 10px;
			font-size: 15px;
			line-height: 1.8;
			@include device_pc {
				padding: 40px 70px;
				gap: 40px;
			}
			& + & {
				margin-top: 16px;
				@include device_pc {
					margin-top: 20px;
				}
			}
			img {
				@include device_sp {
					max-width: 100% !important;
				}
			}
			a {
				text-decoration: underline;
				color: #0168b7;
			}
			&.bg {
				&-gray {
					background-color: #F8F7F6;
					border-color: #F8F7F6;
				}
				&-lightyellow {
					background-color: #FFFBED;
					border-color: #FFFBED;
				}
			}
			&.bd {
				&-blue {
					border-color: #0168B7;
				}
			}
			&.bdr {
				&-0 {
					border-radius: 0;
				}
			}
			.color {
				&-blue {
					color: #0168B7;
				}
			}
			.align {
				&-center {
					text-align: center;
				}
				&-right {
					text-align: right;
				}
			}
			.m {
				&-t {
					@for $i from 1 through 5 {
						&-#{$i}em {
							margin-top: #{$i}em;
						}
					}
				}
			}
			.bd {
				&-top {
					padding-top: 30px;
					border-top: 1px solid #D9D9D9;
					@include device_pc {
						padding-top: 40px;
					}
				}
				&-bottom {
					padding-bottom: 30px;
					border-bottom: 1px solid #D9D9D9;
					@include device_pc {
						padding-bottom: 40px;
					}
				}
				&-top {
					&-dotted {
						padding-top: 18px;
						border-top: 1px dotted #404040;
						@include device_pc {
							padding-top: 24px;
						}
					}
				}
				&-bottom {
					&-dotted {
						padding-bottom: 18px;
						border-bottom: 1px dotted #404040;
						@include device_pc {
							padding-bottom: 24px;
						}
					}
				}
			}
			/* techlib2 post block */
			.t2pb {
				&-title {
					&1 {
						display: flex;
						align-items: center;
						padding: 0 8px 8px;
						border-bottom: 3px solid #6FB6ED;
						font-size: 20px;
						font-weight: 500;
						line-height: 1.4;
						color: #0168B7;
						@include device_pc {
							font-size: 30px;
						}
						span {
							margin-right: 20px;
							display: inline-flex;
							vertical-align: top;
							white-space: nowrap;
							font-family: $fontMin;
							font-size: 40px;
							font-weight: 500;
							line-height: 1;
							@include device_pc {
								margin-right: 56px;
								font-size: 70px;
							}
						}
					}
					&2 {
						display: flex;
						align-items: center;
						position: relative;
						margin-top: 30px;
						border-left: 4px solid #404040;
						font-size: 20px;
						font-weight: 500;
						line-height: 1.2;
						@include device_pc {
							margin-top: 50px;
							border-left-width: 7px;
							font-size: 30px;
						}
						&::before {
							content: '';
							display: block;
							margin: 0 20px 0 2px;
							width: 2px;
							height: 100%;
							background-color: #404040;
							@include device_pc {
								width: 4px;
								margin: 0 30px 0 4px;
							}
						}
						&.color {
							&-blue {
								border-left-color: #0168B7;
								&::before {
									background-color: #0168B7;
								}
							}
						}
					}
					&3 {
						font-size: 18px;
						font-weight: 500;
						line-height: 1.2;
						color: #0168B7;
						@include device_pc {
							font-size: 24px;
						}
						& > span {
							display: inline-block;
							padding: 6px 14px;
							background-color: #F8F7F6;
							vertical-align: top;
							@include device_pc {
								padding: 8px 18px;
							}
						}
					}
				}
				&-text {
					p {
						text-indent: 1em;
					}
					ul,
					ol {
						margin: 10px 1em;
						@include device_pc {
							margin: 16px 1em;
						}
					}
					img {
						margin: 10px 0;
						@include device_pc {
							margin: 16px 0;
						}
					}
				}
				&-note {
					padding: 12px 0;
					border-top: 1px solid #0168B7;
					border-bottom: 1px solid #0168B7;
					font-size: 15px;
					font-weight: 500;
					line-height: 1.8;
					color: #0168B7;
					@include device_pc {
						padding: 18px 0;
						font-size: 17px;
					}
				}
				&-image {
					padding: 20px 10px;
					background-color: #fff;
					border: 1px solid #404040;
					border-radius: 10px;
					@include device_pc {
						min-width: 444px;
					}
					&-center {
						margin: 0 auto;
						max-width: 944px;
					}
					&-title {
						display: flex;
						align-items: flex-start;
						margin-bottom: 16px;
						font-size: 14px;
						font-weight: 500;
						line-height: 1.2;
						span {
							display: inline-block;
							margin: -2px 4px 0 0;
							padding: 4px 18px;
							background-color: #00518F;
							border-radius: 14px;
							vertical-align: top;
							white-space: nowrap;
							font-size: 14px;
							font-weight: 700;
							line-height: 1;
							color: #fff;
						}
						sup {
							position: relative;
							top: -0.4ex;
							vertical-align: top;
						}
						sub {
							position: relative;
							bottom: -0.4ex;
							vertical-align: top;
						}
					}
					&-photo {
						position: relative;
						text-align: center;
					}
				}
				&-box {
					&1 {
						padding: 16px;
						border: 1px solid #404040;
						border-radius: 10px;
						@include device_pc {
							padding: 24px;
						}
					}
				}
				&-type {
					&1 {
						display: grid;
						gap: 16px;
						@include device_pc {
							gap: 24px;
						}
						&-block {
							padding: 18px 26px;
							background-color: #F8F7F6;
							border-radius: 10px;
							@include device_pc {
								padding: 24px 32px;
							}
							&__title {
								margin-bottom: 18px;
								padding-left: 12px;
								border-left: 4px solid #6FB6ED;
								font-size: 20px;
								font-weight: 500;
								line-height: 1.2;
								color: #0168B7;
								@include device_pc {
									margin-bottom: 24px;
									padding-left: 16px;
									font-size: 24px;
								}
							}
						}
					}
					&2 {
						display: grid;
						gap: 16px;
						padding: 18px 20px 20px;
						background-color: #F8F7F6;
						border-radius: 10px;
						@include device_pc {
							gap: 24px;
							padding: 24px 32px 32px;
						}
						&-title {
							font-size: 16px;
							font-weight: 500;
							line-height: 1.2;
							color: #0168B7;
							@include device_pc {
								font-size: 20px;
							}
						}
					}
					&3 {
						display: grid;
						gap: 8px;
						@include device_pc {
							gap: 16px;
						}
						&-block {
							padding: 18px 26px;
							background-color: #fff;
							border-radius: 10px;
							@include device_pc {
								padding: 24px 32px;
							}
							&__title {
								display: flex;
								align-items: flex-start;
								margin-bottom: 12px;
								padding-bottom: 12px;
								border-bottom: 1px solid #6FB6ED;
								font-size: 20px;
								font-weight: 400;
								line-height: 1.2;
								color: #0168B7;
								@include device_pc {
									margin-bottom: 16px;
									padding-bottom: 16px;
									font-size: 24px;
								}
								& > span {
									margin-right: 8px;
									padding: 1px 8px;
									background-color: #0168B7;
									border-radius: 50px;
									white-space: nowrap;
									font-size: 16px;
									font-weight: 400;
									line-height: 1.2;
									color: #fff;
									@include device_sp {
										margin-top: 3px;
									}
									@include device_pc {
										margin-right: 16px;
										padding: 1px 17px;
										font-size: 24px;
									}
								}
							}
						}
					}
					&3 {
						display: grid;
						gap: 32px;
						@include device_pc {
							gap: 56px;
						}
						&-block {
							display: grid;
							gap: 16px;
							padding: 18px 26px;
							background-color: #fff;
							border-radius: 10px;
							@include device_pc {
								gap: 24px;
								padding: 24px 32px;
							}
							&__title {
								position: relative;
								padding: 0 0 10px 16px;
								font-size: 18px;
								font-weight: 500;
								line-height: 1.2;
								@include device_pc {
									padding: 0 0 16px 20px;
									font-size: 24px;
								}
								&::before,
								&::after {
									content: '';
									position: absolute;
									left: 0;
								}
								&::before {
									top: 0;
									width: 2px;
									height: calc(100% - 10px);
									@include device_pc {
										width: 4px;
										height: calc(100% - 16px);
									}
								}
								&::after {
									bottom: 0;
									width: 100%;
									height: 2px;
									@include device_pc {
										height: 4px;
									}
								}
							}
							&__text {
								p {
									text-indent: 1em;
								}
							}
							&.type3 {
								&-1 {
									.t2pb-type3-block__title { color: #005D35; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #005D35; }
								}
								&-2 {
									.t2pb-type3-block__title { color: #74201C; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #74201C; }
								}
								&-3 {
									.t2pb-type3-block__title { color: #00326C; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #00326C; }
								}
								&-4 {
									.t2pb-type3-block__title { color: #016368; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #016368; }
								}
								&-5 {
									.t2pb-type3-block__title { color: #846A01; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #846A01; }
								}
								&-6 {
									.t2pb-type3-block__title { color: #5C015E; }
									.t2pb-type3-block__title::before,
									.t2pb-type3-block__title::after,
									.t2pb-type3-sub__title::before { background-color: #5C015E; }
								}
							}
						}
						&-sub {
							position: relative;
							padding-left: 18px;
							@include device_pc {
								padding-left: 26px;
							}
							&__title {
								position: relative;
								font-size: 15px;
								font-weight: 500;
								line-height: 1.8;
								@include device_pc {
									font-size: 17px;
								}
								&::before {
									content: '';
									position: absolute;
									top: 8px;
									left: -18px;
									width: 12px;
									height: 12px;
									border-radius: 50%;
									@include device_pc {
										top: 7px;
										left: -26px;
										width: 18px;
										height: 18px;
									}
								}
							}
						}
					}
					&4 {
						display: grid;
						gap: 28px;
						@include device_pc {
							gap: 48px;
						}
						&-block {
							&__title {
								margin-bottom: 18px;
								padding: 6px 20px;
								background-color: #F8F7F6;
								font-size: 18px;
								font-weight: 500;
								line-height: 1.2;
								@include device_pc {
									margin-bottom: 24px;
									padding: 8px 32px;
									font-size: 24px;
								}
							}
							&__text {
								padding: 0 20px;
								@include device_pc {
									padding: 0 32px;
								}
								p {
									text-indent: 1em;
								}
							}
						}
					}
				}
				&-introduce {
					@include device_sp {
						& > * + * {
							margin-top: 20px;
						}
					}
					@include device_pc {
						display: flex;
						.t2pb-people {
							margin-left: 43px;
						}
					}
				}
				&-people {
					display: flex;
					@include device_sp {
						justify-content: center;
					}
					& > li {
						@include device_sp {
							margin: 20px 20px 0 0;
							width: calc(50% - 10px);
							&:nth-of-type(-n+2) { margin-top: 0; }
							&:last-child,
							&:nth-of-type(2n) { margin-right: 0; }
						}
						& + li {
							@include device_sp {
								margin-top: 10px;
							}
							@include device_pc {
								margin-left: 8px;
							}
						}
						&.is {
							&-flex {
								display: flex;
								align-items: flex-end;
								width: 100%;
								.t2pb-people-text {
									margin: 0 0 0 8px;
									white-space: nowrap;
								}
							}
						}
					}
					&-photo {
						min-width: 155px;
					}
					&-text {
						margin-top: 8px;
						font-size: 12px;
						line-height: 1.2;
						ruby {
							font-size: 15px;
						}
					}
				}
				&-flex {
					@include device_sp {
						& > * + * {
							margin-top: 20px;
						}
					}
					@include device_pc {
						display: flex;
						gap: 40px;
						&.is {
							&-align {
								&-start {
									align-items: flex-start;
								}
								&-end {
									align-items: flex-end;
								}
								&-center {
									align-items: center;
								}
							}
						}
					}
				}
				&-grid {
					@include device_sp {
						& > * + * {
							margin-top: 20px;
						}
					}
					@include device_pc {
						display: grid;
						gap: 40px;
						.is {
							&-row {
								&-1 {
									grid-row: 1;
									&-3 {
										grid-row: 1 / 3;
									}
								}
								&-2 {
									grid-row: 2;
								}
							}
							&-column {
								&-1 {
									grid-column: 1;
								}
								&-2 {
									grid-column: 2;
								}
							}
						}
					}
				}
				&-literature {
					@include device_pc {
						display: flex;
						flex-wrap: wrap;
					}
					&-title {
						margin-bottom: 16px;
						padding-bottom: 16px;
						border-bottom: 1px solid #D9D9D9;
						font-size: 15px;
						font-weight: 400;
						line-height: 1;
						@include device_pc {
							width: 100%;
						}
					}
					ol {
						@include device_pc {
							padding-right: 40px;
							width: 50%;
						}
						li {
							font-size: 12px;
							line-height: 1.8;
						}
					}
				}
				&-office {
					&-title {
						margin-bottom: 2px;
						padding: 12px 20px;
						background-color: #0168B7;
						font-size: 20px;
						font-weight: 700;
						line-height: 1.2;
						color: #fff;
						@include device_pc {
							margin-bottom: 4px;
							padding: 16px 40px;
							font-size: 26px;
						}
					}
					&-cell {
						table {
							width: 100%;
							border-top: 1px solid #6FB6ED;
							border-spacing: 0;
							border-collapse: collapse;
							tr {
								border-bottom: 1px solid #6FB6ED;
								@include device_sp {
									display: block;
									th,
									td {
										display: block;
										width: 100%;
									}
								}
							}
							th,
							td {
								padding: 10px 8px;
								text-align: left;
								font-size: 13px;
								@include device_pc {
									padding: 16px 40px;
									font-size: 15px;
								}
								&.name {
									background-color: #F8F7F6;
									font-weight: 500;
								}
								&.address {
									font-weight: 400;
								}
								&.tel {
									background-color: #F8F7F6;
									font-weight: 500;
								}
							}
						}
					}
					&-qr {
						display: flex;
						justify-content: flex-end;
						align-items: flex-end;
						margin-top: 26px;
						@include device_pc {
							margin-top: 32px;
						}
						p {
							margin-right: 6px;
							text-align: right;
							font-size: 12px;
							@include device_pc {
								margin-right: 8px;
								font-size: 15px;
								line-height: 1.8;
							}
						}
						img {
							width: 85px;
							@include device_pc {
								width: 105px;
							}
						}
					}
				}
				&-column {
					&-title {
						margin-bottom: 18px;
						padding-bottom: 18px;
						border-bottom: 1px dotted #404040;
						font-family: $fontMin;
						font-size: 26px;
						font-weight: 500;
						line-height: 1.2;
						@include device_pc {
							margin-bottom: 24px;
							padding-bottom: 24px;
							font-size: 35px;
						}
					}
					&-text {
						font-family: $fontMin;
						font-size: 13px;
						line-height: 1.8;
						@include device_pc {
							font-size: 15px;
							line-height: 2.2;
						}
						p {
							text-indent: 1em;
						}
					}
					&-author {
						margin-top: 18px;
						padding-top: 18px;
						border-top: 1px dotted #404040;
						font-family: $fontMin;
						font-size: 13px;
						line-height: 1.8;
						@include device_pc {
							margin-top: 24px;
							padding-top: 24px;
							font-size: 15px;
							line-height: 2.2;
						}
					}
				}
			}
		}
		&-attention {
			margin-top: 20px;
			font-size: 12px;
			line-height: 1.8;
		}
	}
	/* related */
	&-related {
		margin-top: 40px;
		&-title {
			margin-bottom: 32px;
			padding: 0 8px 10px;
			border-bottom: 1px solid #6FB6ED;
			font-size: 24px;
			font-weight: 500;
			line-height: 1.4;
			color: #0168B7;
			@include device_pc {
				margin-bottom: 65px;
				font-size: 30px;
			}
		}
		&-lists {
			display: grid;
			@include device_sp {
				row-gap: 40px;
				column-gap: 16px;
				grid-template-columns: repeat(2,calc(50% - 8px));
			}
			@include device_pc {
				gap: 80px;
				grid-template-columns: repeat(4,calc((100% / 4) - (80px / (4 / 3))));
			}
			&__item {
				display: grid;
				gap: 0;
				grid-template-rows: subgrid;
				grid-row: span 2;
				position: relative;
				& > a {
					display: grid;
					gap: 0;
					grid-template-rows: subgrid;
					grid-row: span 2;
					position: relative;
					width: 100%;
				}
			}
			&__photo {
				position: relative;
				margin-bottom: 16px;
				aspect-ratio: 1 / 0.625;
				border-radius: 10px;
				overflow: hidden;
				img {
					width: 100%;
					height: 100%;
					object-fit: cover;
				}
			}
			&__title {
				font-size: 16px;
				font-weight: 700;
				line-height: 1.4;
				color: #0168B7;
				@include device_pc {
					font-size: 20px;
				}
			}
			&__excerpt {
				display: -webkit-box;
				margin-top: 12px;
				padding-top: 8px;
				border-top: 1px solid #404040;
				text-overflow: ellipsis;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 4;
				line-clamp: 4;
				font-size: 13px;
				letter-spacing: 0.1em;
				line-height: 1.4;
				max-height: 94px;
				overflow: hidden;
				@include device_pc {
					font-size: 15px;
				}
			}
		}
	}
	/* btns */
	&-btns {
		margin-top: 80px;
		@include device_pc {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: flex-start;
			margin-top: 160px;
		}
		&-item {
			@include device_sp {
				&:not(:first-child) {
					margin-top: 30px;
				}
			}
			@include device_pc {
				margin: 60px 32px 0 0;
				width: calc((100% / 3) - (32px / (3 / 2)));
				&:nth-of-type(-n+3) {
					margin-top: 0;
				}
				&:nth-of-type(3n) {
					margin-right: 0;
				}
			}
			& > a {
				display: flex;
				width: 100%;
				height: 78px;
				justify-content: center;
				align-items: center;
				text-align: center;
				border-radius: 48px;
				font-size: 20px;
				font-weight: 500;
				letter-spacing: 0.1em;
				@include device_pc {
					height: 96px;
					font-size: 28px;
				}
				&.is {
					&-pdf {
						background-color: #fff;
						border: 4px solid #A42811;
						color: #A42811;
					}
					&-back {
						background: linear-gradient(90.39deg, #125797 0.34%, #0A60A7 20.78%, #0168B7 100.03%);
						color: #fff;
					}
					&-next {
						background: linear-gradient(90.39deg, #017BEB 0.34%, #1A8CEB 20.78%, #0577CE 100.03%);
						color: #fff;
					}
				}
			}
		}
		&-note {
			margin: 8px 10px 0;
			font-size: 10px;
			line-height: 1.4;
			@include device_pc {
				font-size: 12px;
			}
		}
	}
}
