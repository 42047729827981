@charset 'utf-8';

@import '../variable';

/* title
------------------------------------------------ */
.title {
	&--page {
		position: relative;
		margin-bottom: 5px;
		padding: 38px 0;
		background-position: center bottom;
		background-repeat: no-repeat;
		background-size: auto 100%;
		background-color: #e5f0f8;
		text-align: center;
		font-size: 36px;
		font-weight: 400;
		line-height: 1.2;
		color: #0168b7;
		.min {
			display: block;
			margin-top: 6px;
			font-size: 14px;
		}
		@include device_pc {
			margin-bottom: 16px;
			padding: 75px 0;
			font-size: 72px;
			.min {
				margin-top: 10px;
				font-size: 28px;
			}
		}
	}
	&--image {
		position: relative;
		margin: 0 auto 42px;
		padding-top: 60px;
		height: 225px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		text-align: center;
		font-family: $fontMin;
		font-size: 30px;
		font-weight: 700;
		color: #fff;
		@include device_pc {
			margin-bottom: 80px;
			padding-top: 130px;
			max-width: 1200px;
			height: 450px;
			font-size: 60px;
		}
	}
	&--ribbon {
		position: relative;
		margin-bottom: 4px;
		height: 225px;
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
		@include device_pc {
			margin-bottom: 14px;
			height: 500px;
		}
		&--name {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			padding-top: 24px;
			width: 320px;
			height: 100px;
			background: url(../img/icon/title_ribbon1.png) no-repeat center center;
			background-size: 100%;
			text-align: center;
			font-family: $fontMin;
			font-size: 36px;
			font-weight: 700;
			line-height: 1.2;
			color: #fff;
			@include device_pc {
				padding-top: 55px;
				width: 712px;
				height: 220px;
				font-size: 80px;
			}
		}
	}
	&--1 {
		position: relative;
		margin-bottom: 20px;
		text-align: center;
		font-family: $fontMin;
		font-weight: 700;
		line-height: 1.2;
		&--inline {
			display: inline-block;
			position: relative;
			text-align: right;
			vertical-align: top;
		}
		.min,
		.normal {
			display: block;
			position: relative;
		}
		.min {
			font-size: 18px;
			color: #0168b7;
			& + .normal {
				margin-top: -14px;
			}
		}
		.normal {
			font-size: 36px;
			.l {
				font-size: 48px;
			}
			& + .min {
				margin-top: -5px;
			}
		}
		@include device_pc {
			.min {
				font-size: 30px;
				& + .normal {
					margin-top: -28px;
				}
			}
			.normal {
				font-size: 60px;
				.l {
					font-size: 80px;
				}
				& + .min {
					margin-top: -10px;
				}
			}
		}
	}
	&--2 {
		text-align: center;
		font-family: $fontMin;
		font-size: 36px;
		font-weight: 700;
		line-height: 1.2;
		.min {
			font-size: 26px;
		}
		@include device_pc {
			font-size: 60px;
			.min {
				font-size: 40px;
			}
		}
	}
	&--3 {
		position: relative;
		margin-bottom: 40px;
		text-align: center;
		font-family: $fontMin;
		font-size: 10px;
		line-height: 1.2;
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 50%;
			background: #404040;
			width: 25px;
			height: 2px;
		}
		&:before {
			top: 0;
			transform: rotate(-45deg);
			margin-left: 25px;
		}
		&:after {
			bottom: -18px;
			transform: rotate(-45deg);
			margin-left: -50px;
		}
		.en,
		.ja {
			display: block;
		}
		.en {
			font-size: 36px;
			font-weight: 700;
		}
		.ja {
			margin-top: -2px;
			font-size: 14.4px;
			font-weight: 400;
		}
		@include device_pc {
			margin-bottom: 74px;
			&:before,
			&:after {
				width: 40px;
			}
			&:before {
				margin-left: 35px;
			}
			&:after {
				bottom: -24px;
				margin-left: -90px;
			}
			.en {
				font-size: 60px;
			}
			.ja {
				font-size: 24px;
			}
		}
	}
	&--4 {
		position: relative;
		margin-bottom: 24px;
		text-align: center;
		font-family: $fontMin;
		font-size: 26px;
		font-weight: 700;
		line-height: 1.2;
		color: #0168b7;
		&:after {
			content: '';
			display: block;
			margin: 12px auto 0;
			width: 30px;
			height: 3px;
			background: #0068b7;
		}
		@include device_pc {
			margin-bottom: 58px;
			font-size: 52px;
			&:after {
				margin-top: 24px;
				width: 60px;
				height: 6px;
			}
		}
	}
	&--5 {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		color: #0168b7;
		@include device_pc {
			font-size: 52px;
		}
		.l,
		.min {
			display: inline-block;
			vertical-align: middle;
		}
		.l {
			margin-right: 16px;
			padding: 15px 0;
			font-weight: 400;
			@include device_pc {
				margin-right: 25px;
				padding: 24px 0;
			}
		}
		.min {
			font-size: 12px;
			@include device_pc {
				font-size: 18px;
			}
		}
	}
	&--6 {
		position: relative;
		margin-bottom: 35px;
		padding-bottom: 16px;
		border-bottom: solid #a5a5a5 1px;
		font-size: 30px;
		font-weight: 400;
		line-height: 1.2;
		@include device_pc {
			margin-bottom: 80px;
			border-bottom-width: 2px;
			font-size: 36px;
		}
		&:before,
		&:after {
			content: '';
			bottom: -1px;
			position: absolute;
			@include device_pc {
				bottom: -2px;
				height: 2px;
			}
		}
		&:before {
			left: 95px;
			width: 10px;
			background: #fff;
			@include device_pc {
				left: 280px;
				width: 20px;
			}
		}
		&:after {
			left: 93px;
			transform-origin: right;
			transform: rotate(-45deg);
			width: 12px;
			background: #a5a5a5;
			@include device_pc {
				left: 277px;
				width: 24px;
			}
		}
	}
	&--7 {
		position: relative;
		margin-bottom: 16px;
		padding-bottom: 10px;
		border-bottom: solid #c6dbec 1px;
		font-size: 19px;
		font-weight: bold;
		line-height: 1.2;
		color: #0168b7;
		@include device_pc {
			margin-bottom: 26px;
			border-bottom-width: 3px;
			padding-bottom: 14px;
			font-size: 26px;
		}
	}
}
