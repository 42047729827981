@charset "UTF-8";
/* reset
------------------------------------------------ */
body, div, pre, p, a, img, form, fieldset, input, textarea, select, option,
dl, dt, dd, ul, ol, li, h1, h2, h3, h4, h5, h6, table, th, td, embed, object,
figure, figcaption {
  margin: 0;
  padding: 0;
}

html {
  height: 100%;
  font-size: 62.5%;
}

body {
  width: 100%;
  height: 100%;
  background: #fff;
  -webkit-text-size-adjust: 100%;
  font-family: "Noto Sans JP", "Hiragino Kaku Gothic ProN", "ヒラギノ角ゴ ProN W3", "Meiryo", "メイリオ", "ＭＳ Ｐゴシック", "MS P Gothic", Osaka, Arial, Helvetica, Verdana, sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 1.6;
  color: #404040;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

ul, ol {
  list-style: none;
}

img {
  width: auto;
  height: auto;
  max-width: 100%;
  border: none;
  vertical-align: top;
}

picture {
  display: block;
  position: relative;
}

a {
  text-decoration: none;
  color: #404040;
}
.device--click a {
  transition: ease 0.3s;
}
.device--click a:hover {
  opacity: 0.8;
}

.device--click a,
.device--click input[type=submit],
.device--click button {
  transition: ease 0.3s;
}
.device--click a:hover,
.device--click input[type=submit]:hover,
.device--click button:hover {
  opacity: 0.8;
}

.clearfix {
  clear: both;
  *zoom: 1;
}
.clearfix:before, .clearfix:after {
  content: " ";
  display: table;
}
.clearfix:after {
  clear: both;
}

/* visible */
@media screen and (max-width: 768px) {
  .visible-sp {
    display: inherit !important;
  }
}
@media screen and (min-width: 769px) {
  .visible-sp {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .visible-pc {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  .visible-pc {
    display: inherit !important;
  }
}

/* color */
.c-red {
  color: #bf0303;
}
.c-blue {
  color: #0168b7;
}

/* container
------------------------------------------------ */
#container {
  position: relative;
}

.wrap {
  position: relative;
  margin: 0 auto;
}
@media screen and (min-width: 769px) {
  .wrap {
    padding: 0 20px;
    max-width: 1240px;
  }
}

@media screen and (min-width: 769px) {
  .visible--pc {
    display: block !important;
  }
}
@media screen and (max-width: 768px) {
  .visible--pc {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  .visible--sp {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .visible--sp {
    display: block !important;
  }
}

/* header
------------------------------------------------ */
#header {
  position: relative;
  width: 100%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 10;
}
#header-top {
  position: relative;
  width: 100%;
  height: 67px;
  background: #fff;
}
#header-top .wrap {
  height: 100%;
}
@media screen and (max-width: 768px) {
  #header-top {
    height: 55px;
  }
}
#header-menu {
  display: none;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background: #f8fbfd;
}
#header-menu .frame {
  position: relative;
  width: 100%;
  height: calc(100vh - 55px);
  overflow: auto;
}
@media screen and (min-width: 769px) {
  #header-menu {
    display: none !important;
  }
}
#hd-search {
  display: none;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  background-color: #EDEDED;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
@media screen and (min-width: 769px) {
  #hd-search {
    top: 67px;
  }
}

#gnav {
  position: absolute;
  top: 0;
  right: 20px;
  height: 100%;
}
#gnav .frame {
  display: flex;
  align-items: flex-start;
  height: 100%;
}
@media screen and (min-width: 769px) {
  #gnav {
    display: block !important;
  }
}
@media screen and (max-width: 768px) {
  #gnav {
    display: none;
  }
}

.header--logo {
  position: absolute;
  top: 12px;
  left: 20px;
  width: 123px;
  font-size: 10px;
  font-weight: normal;
}
.header--logo a {
  display: block;
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .header--logo {
    top: 15px;
    width: 100px;
  }
}
@media screen and (max-width: 768px) {
  .header--logo {
    top: 8px;
    width: 110px;
  }
}
.header--menu--btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 34px;
  height: 34px;
}
.header--menu--btn a,
.header--menu--btn span {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.header--menu--btn a {
  background: #0068b7;
}
.header--menu--btn a:after {
  content: "";
  position: absolute;
  left: 7px;
  transition: ease 0.3s;
  width: 20px;
  height: 2px;
  background: #fff;
  top: 8px;
}
.header--menu--btn a.active:after {
  display: none;
}
.header--menu--btn a.active span:before, .header--menu--btn a.active span:after {
  top: 16px;
}
.header--menu--btn a.active span:before {
  transform: rotate(45deg);
}
.header--menu--btn a.active span:after {
  transform: rotate(-45deg);
}
.header--menu--btn span {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.header--menu--btn span:before, .header--menu--btn span:after {
  content: "";
  position: absolute;
  left: 7px;
  transition: ease 0.3s;
  width: 20px;
  height: 2px;
  background: #fff;
}
.header--menu--btn span:before {
  top: 16px;
}
.header--menu--btn span:after {
  top: 24px;
}
@media screen and (min-width: 769px) {
  .header--menu--btn {
    display: none;
  }
}

.hd-search-btn {
  position: absolute;
  top: 10px;
  right: 54px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-size: 12px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .hd-search-btn {
    display: none;
  }
}
.hd-search-btn > a {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
}
.hd-search-btn > a::before, .hd-search-btn > a::after {
  content: "";
  position: absolute;
  top: 6px;
  left: 50%;
  transition: ease 0.3s;
  margin-left: -9px;
  width: 18px;
  height: 2px;
  background: #000;
  opacity: 0;
}
.hd-search-btn > a.is-active::before, .hd-search-btn > a.is-active::after {
  opacity: 1;
}
.hd-search-btn > a.is-active::before {
  transform: rotate(45deg);
}
.hd-search-btn > a.is-active::after {
  transform: rotate(-45deg);
}
.hd-search-btn > a.is-active span::before {
  opacity: 0;
}
.hd-search-btn span {
  display: block;
  width: 100%;
}
.hd-search-btn span::before {
  content: "";
  display: block;
  margin: 0 auto 2px;
  width: 14px;
  height: 14px;
  background: url(../img/icon/search2.png) no-repeat center center;
  background-size: 100%;
}
.hd-search-from {
  padding: 10px;
}
@media screen and (min-width: 769px) {
  .hd-search-from {
    padding: 28px 0;
  }
}
.hd-search-keyword {
  display: flex;
  height: 44px;
}
.hd-search-keyword--input {
  width: calc(100% - 83px);
  height: 100%;
}
@media screen and (min-width: 769px) {
  .hd-search-keyword--input {
    width: calc(100% - 143px);
  }
}
.hd-search-keyword--input input {
  display: block;
  appearance: none;
  padding: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border: none;
  border-radius: 5px 0 0 5px;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .hd-search-keyword--input input {
    padding: 10px 26px;
    font-size: 14px;
  }
}
.hd-search-keyword--submit {
  margin-left: 3px;
  width: 80px;
  height: 100%;
}
@media screen and (min-width: 769px) {
  .hd-search-keyword--submit {
    width: 140px;
  }
}
.hd-search-keyword--submit button {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 100%;
  height: 100%;
  background-color: #0168B7;
  border: none;
  border-radius: 0 5px 5px 0;
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  cursor: pointer;
}
@media screen and (min-width: 769px) {
  .hd-search-keyword--submit button {
    font-size: 16px;
  }
}
.hd-search-keyword--submit button::after {
  content: "";
  display: block;
  margin-left: 6px;
  width: 14px;
  height: 14px;
  background: url(../img/icon/search3.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .hd-search-keyword--submit button::after {
    margin-left: 12px;
    width: 17px;
    height: 17px;
  }
}

.gnav {
  /*
  &__lng {
  	display: flex;
  	align-items: center;
  	margin: 14px 0 0 20px;
  	&--item {
  		text-align: center;
  		font-size: 15px;
  		font-weight: 700;
  		line-height: 1.2;
  		a {
  			display: inline-block;
  			padding: 11px 4px;
  			min-width: 100px;
  			background: #404040;
  			border-radius: 40px;
  			vertical-align: top;
  			color: #fff;
  		}
  	}
  	@media screen and (max-width: 1200px) {
  		&--item {
  			font-size: 12px;
  			a {
  				min-width: 80px;
  			}
  		}
  	}
  }
  */
}
.gnav__links {
  display: flex;
  align-items: center;
  margin-top: 14px;
}
.gnav__links--item {
  position: relative;
  margin-left: 20px;
  font-size: 15px;
  line-height: 1.2;
}
.gnav__links--item:first-child {
  margin-left: 0;
}
.gnav__links--item a {
  display: inline-block;
  padding: 11px 0;
  vertical-align: top;
}
.gnav__links--item a.active {
  font-weight: 700;
  color: #0168b7;
}
.gnav__links__sub {
  display: none;
  position: absolute;
  top: 53px;
  left: 50%;
  transform: translateX(-50%);
  padding: 0 32px 30px;
  min-width: 250px;
  background-color: #fff;
  box-shadow: 0 5px 6px rgba(0, 0, 0, 0.2);
}
.gnav__links__sub--item {
  position: relative;
  border-bottom: 1px solid #ddd;
  font-size: 14px;
}
.gnav__links__sub--item:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 24px;
  width: calc(100% - 48px);
  height: 1px;
  background: #fff;
}
.gnav__links__sub--item:last-child:after {
  display: none;
}
.gnav__links__sub--item a {
  display: block;
  position: relative;
  padding: 14px 16px 14px 0;
  white-space: nowrap;
  color: #404040;
}
.gnav__links__sub--item a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 4px;
  width: 6px;
  height: 10px;
  margin-top: -5px;
  background: url(../img/icon/arrow1.png) no-repeat center center;
  background-size: 100% 100%;
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .gnav__links--item {
    font-size: 12px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .gnav__links--item {
    margin-left: 10px;
    font-size: 10px;
  }
}
.gnav__btns {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.gnav__btns--item {
  height: 59px;
  text-align: center;
  font-size: 15px;
  line-height: 1.2;
}
.gnav__btns--item:first-child a {
  border-radius: 0 0 0 10px;
}
.gnav__btns--item:last-child a {
  border-radius: 0 0 10px 0;
}
.gnav__btns--item a {
  display: block;
  padding: 25px 10px 0;
  height: 100%;
  color: #fff;
}
.gnav__btns--recruit a {
  min-width: 120px;
  background: #2b9ab7;
}
.gnav__btns--contact a {
  min-width: 160px;
  background: #0168b7;
}
.gnav__btns--contact a:before {
  content: "";
  display: inline-block;
  margin: -1px 6px 0 0;
  width: 26px;
  height: 20px;
  background: url(../img/icon/mail1.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .gnav__btns--recruit a {
    min-width: 100px;
  }
  .gnav__btns--contact a {
    min-width: 140px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .gnav__btns {
    margin-left: 5px;
  }
  .gnav__btns--item {
    font-size: 12px;
  }
  .gnav__btns--recruit a {
    min-width: 60px;
  }
  .gnav__btns--contact a {
    min-width: 100px;
  }
  .gnav__btns--contact a:before {
    margin-top: 3px;
    width: 12px;
    height: 10px;
  }
}
.gnav-search-btn {
  width: 65px;
  height: 100%;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .gnav-search-btn {
    display: none;
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .gnav-search-btn {
    font-size: 12px;
  }
  .gnav-search-btn > a {
    padding-top: 15px;
  }
  .gnav-search-btn > a::before {
    width: 15px;
    height: 15px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .gnav-search-btn {
    font-size: 10px;
  }
  .gnav-search-btn > a {
    padding-top: 18px;
  }
  .gnav-search-btn > a::before {
    width: 12px;
    height: 12px;
  }
}
.gnav-search-btn > a {
  display: block;
  padding-top: 13px;
  width: 100%;
  height: 100%;
}
.gnav-search-btn > a::before {
  content: "";
  display: block;
  margin: 0 auto 6px;
  width: 17px;
  height: 17px;
  background: url(../img/icon/search2.png) no-repeat center center;
  background-size: 100%;
}
.gnav-search-btn > a.is-active {
  background-color: #EDEDED;
}

/* main
------------------------------------------------ */
#main {
  display: block;
  position: relative;
  width: 100%;
}

/* space
------------------------------------------------ */
.mt--0 {
  margin-top: 0 !important;
}
.mt--5 {
  margin-top: 5px !important;
}
.mt--10 {
  margin-top: 10px !important;
}
.mt--15 {
  margin-top: 15px !important;
}
.mt--20 {
  margin-top: 20px !important;
}
.mt--25 {
  margin-top: 25px !important;
}
.mt--30 {
  margin-top: 30px !important;
}
.mt--35 {
  margin-top: 35px !important;
}
.mt--40 {
  margin-top: 40px !important;
}
.mt--45 {
  margin-top: 45px !important;
}
.mt--50 {
  margin-top: 50px !important;
}
.mt--55 {
  margin-top: 55px !important;
}
.mt--60 {
  margin-top: 60px !important;
}
.mt--65 {
  margin-top: 65px !important;
}
.mt--70 {
  margin-top: 70px !important;
}
.mt--75 {
  margin-top: 75px !important;
}
.mt--80 {
  margin-top: 80px !important;
}
.mt--85 {
  margin-top: 85px !important;
}
.mt--90 {
  margin-top: 90px !important;
}
.mt--95 {
  margin-top: 95px !important;
}
.mt--100 {
  margin-top: 100px !important;
}

.pt--0 {
  padding-top: 0 !important;
}
.pt--5 {
  padding-top: 5px !important;
}
.pt--10 {
  padding-top: 10px !important;
}
.pt--15 {
  padding-top: 15px !important;
}
.pt--20 {
  padding-top: 20px !important;
}
.pt--25 {
  padding-top: 25px !important;
}
.pt--30 {
  padding-top: 30px !important;
}
.pt--35 {
  padding-top: 35px !important;
}
.pt--40 {
  padding-top: 40px !important;
}
.pt--45 {
  padding-top: 45px !important;
}
.pt--50 {
  padding-top: 50px !important;
}
.pt--55 {
  padding-top: 55px !important;
}
.pt--60 {
  padding-top: 60px !important;
}
.pt--65 {
  padding-top: 65px !important;
}
.pt--70 {
  padding-top: 70px !important;
}
.pt--75 {
  padding-top: 75px !important;
}
.pt--80 {
  padding-top: 80px !important;
}
.pt--85 {
  padding-top: 85px !important;
}
.pt--90 {
  padding-top: 90px !important;
}
.pt--95 {
  padding-top: 95px !important;
}
.pt--100 {
  padding-top: 100px !important;
}

.align--left {
  text-align: left;
}
.align--center {
  text-align: center;
}
.align--right {
  text-align: right;
}

/* responsive */
.mt-1 {
  margin-top: 1px;
}

@media screen and (max-width: 768px) {
  .mt-1-sp {
    margin-top: 1px;
  }
}
@media screen and (min-width: 769px) {
  .mt-1-pc {
    margin-top: 1px;
  }
}
.mr-1 {
  margin-right: 1px !important;
}

@media screen and (max-width: 768px) {
  .mr-1-sp {
    margin-right: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-1-pc {
    margin-right: 1px !important;
  }
}
.mb-1 {
  margin-bottom: 1px !important;
}

@media screen and (max-width: 768px) {
  .mb-1-sp {
    margin-bottom: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-1-pc {
    margin-bottom: 1px !important;
  }
}
.ml-1 {
  margin-left: 1px !important;
}

@media screen and (max-width: 768px) {
  .ml-1-sp {
    margin-left: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-1-pc {
    margin-left: 1px !important;
  }
}
.mt-2 {
  margin-top: 2px;
}

@media screen and (max-width: 768px) {
  .mt-2-sp {
    margin-top: 2px;
  }
}
@media screen and (min-width: 769px) {
  .mt-2-pc {
    margin-top: 2px;
  }
}
.mr-2 {
  margin-right: 2px !important;
}

@media screen and (max-width: 768px) {
  .mr-2-sp {
    margin-right: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-2-pc {
    margin-right: 2px !important;
  }
}
.mb-2 {
  margin-bottom: 2px !important;
}

@media screen and (max-width: 768px) {
  .mb-2-sp {
    margin-bottom: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-2-pc {
    margin-bottom: 2px !important;
  }
}
.ml-2 {
  margin-left: 2px !important;
}

@media screen and (max-width: 768px) {
  .ml-2-sp {
    margin-left: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-2-pc {
    margin-left: 2px !important;
  }
}
.mt-3 {
  margin-top: 3px;
}

@media screen and (max-width: 768px) {
  .mt-3-sp {
    margin-top: 3px;
  }
}
@media screen and (min-width: 769px) {
  .mt-3-pc {
    margin-top: 3px;
  }
}
.mr-3 {
  margin-right: 3px !important;
}

@media screen and (max-width: 768px) {
  .mr-3-sp {
    margin-right: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-3-pc {
    margin-right: 3px !important;
  }
}
.mb-3 {
  margin-bottom: 3px !important;
}

@media screen and (max-width: 768px) {
  .mb-3-sp {
    margin-bottom: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-3-pc {
    margin-bottom: 3px !important;
  }
}
.ml-3 {
  margin-left: 3px !important;
}

@media screen and (max-width: 768px) {
  .ml-3-sp {
    margin-left: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-3-pc {
    margin-left: 3px !important;
  }
}
.mt-4 {
  margin-top: 4px;
}

@media screen and (max-width: 768px) {
  .mt-4-sp {
    margin-top: 4px;
  }
}
@media screen and (min-width: 769px) {
  .mt-4-pc {
    margin-top: 4px;
  }
}
.mr-4 {
  margin-right: 4px !important;
}

@media screen and (max-width: 768px) {
  .mr-4-sp {
    margin-right: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-4-pc {
    margin-right: 4px !important;
  }
}
.mb-4 {
  margin-bottom: 4px !important;
}

@media screen and (max-width: 768px) {
  .mb-4-sp {
    margin-bottom: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-4-pc {
    margin-bottom: 4px !important;
  }
}
.ml-4 {
  margin-left: 4px !important;
}

@media screen and (max-width: 768px) {
  .ml-4-sp {
    margin-left: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-4-pc {
    margin-left: 4px !important;
  }
}
.mt-5 {
  margin-top: 5px;
}

@media screen and (max-width: 768px) {
  .mt-5-sp {
    margin-top: 5px;
  }
}
@media screen and (min-width: 769px) {
  .mt-5-pc {
    margin-top: 5px;
  }
}
.mr-5 {
  margin-right: 5px !important;
}

@media screen and (max-width: 768px) {
  .mr-5-sp {
    margin-right: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-5-pc {
    margin-right: 5px !important;
  }
}
.mb-5 {
  margin-bottom: 5px !important;
}

@media screen and (max-width: 768px) {
  .mb-5-sp {
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-5-pc {
    margin-bottom: 5px !important;
  }
}
.ml-5 {
  margin-left: 5px !important;
}

@media screen and (max-width: 768px) {
  .ml-5-sp {
    margin-left: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-5-pc {
    margin-left: 5px !important;
  }
}
.mt-6 {
  margin-top: 6px;
}

@media screen and (max-width: 768px) {
  .mt-6-sp {
    margin-top: 6px;
  }
}
@media screen and (min-width: 769px) {
  .mt-6-pc {
    margin-top: 6px;
  }
}
.mr-6 {
  margin-right: 6px !important;
}

@media screen and (max-width: 768px) {
  .mr-6-sp {
    margin-right: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-6-pc {
    margin-right: 6px !important;
  }
}
.mb-6 {
  margin-bottom: 6px !important;
}

@media screen and (max-width: 768px) {
  .mb-6-sp {
    margin-bottom: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-6-pc {
    margin-bottom: 6px !important;
  }
}
.ml-6 {
  margin-left: 6px !important;
}

@media screen and (max-width: 768px) {
  .ml-6-sp {
    margin-left: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-6-pc {
    margin-left: 6px !important;
  }
}
.mt-7 {
  margin-top: 7px;
}

@media screen and (max-width: 768px) {
  .mt-7-sp {
    margin-top: 7px;
  }
}
@media screen and (min-width: 769px) {
  .mt-7-pc {
    margin-top: 7px;
  }
}
.mr-7 {
  margin-right: 7px !important;
}

@media screen and (max-width: 768px) {
  .mr-7-sp {
    margin-right: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-7-pc {
    margin-right: 7px !important;
  }
}
.mb-7 {
  margin-bottom: 7px !important;
}

@media screen and (max-width: 768px) {
  .mb-7-sp {
    margin-bottom: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-7-pc {
    margin-bottom: 7px !important;
  }
}
.ml-7 {
  margin-left: 7px !important;
}

@media screen and (max-width: 768px) {
  .ml-7-sp {
    margin-left: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-7-pc {
    margin-left: 7px !important;
  }
}
.mt-8 {
  margin-top: 8px;
}

@media screen and (max-width: 768px) {
  .mt-8-sp {
    margin-top: 8px;
  }
}
@media screen and (min-width: 769px) {
  .mt-8-pc {
    margin-top: 8px;
  }
}
.mr-8 {
  margin-right: 8px !important;
}

@media screen and (max-width: 768px) {
  .mr-8-sp {
    margin-right: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-8-pc {
    margin-right: 8px !important;
  }
}
.mb-8 {
  margin-bottom: 8px !important;
}

@media screen and (max-width: 768px) {
  .mb-8-sp {
    margin-bottom: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-8-pc {
    margin-bottom: 8px !important;
  }
}
.ml-8 {
  margin-left: 8px !important;
}

@media screen and (max-width: 768px) {
  .ml-8-sp {
    margin-left: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-8-pc {
    margin-left: 8px !important;
  }
}
.mt-9 {
  margin-top: 9px;
}

@media screen and (max-width: 768px) {
  .mt-9-sp {
    margin-top: 9px;
  }
}
@media screen and (min-width: 769px) {
  .mt-9-pc {
    margin-top: 9px;
  }
}
.mr-9 {
  margin-right: 9px !important;
}

@media screen and (max-width: 768px) {
  .mr-9-sp {
    margin-right: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-9-pc {
    margin-right: 9px !important;
  }
}
.mb-9 {
  margin-bottom: 9px !important;
}

@media screen and (max-width: 768px) {
  .mb-9-sp {
    margin-bottom: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-9-pc {
    margin-bottom: 9px !important;
  }
}
.ml-9 {
  margin-left: 9px !important;
}

@media screen and (max-width: 768px) {
  .ml-9-sp {
    margin-left: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-9-pc {
    margin-left: 9px !important;
  }
}
.mt-10 {
  margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .mt-10-sp {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .mt-10-pc {
    margin-top: 10px;
  }
}
.mr-10 {
  margin-right: 10px !important;
}

@media screen and (max-width: 768px) {
  .mr-10-sp {
    margin-right: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-10-pc {
    margin-right: 10px !important;
  }
}
.mb-10 {
  margin-bottom: 10px !important;
}

@media screen and (max-width: 768px) {
  .mb-10-sp {
    margin-bottom: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-10-pc {
    margin-bottom: 10px !important;
  }
}
.ml-10 {
  margin-left: 10px !important;
}

@media screen and (max-width: 768px) {
  .ml-10-sp {
    margin-left: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-10-pc {
    margin-left: 10px !important;
  }
}
.mt-11 {
  margin-top: 11px;
}

@media screen and (max-width: 768px) {
  .mt-11-sp {
    margin-top: 11px;
  }
}
@media screen and (min-width: 769px) {
  .mt-11-pc {
    margin-top: 11px;
  }
}
.mr-11 {
  margin-right: 11px !important;
}

@media screen and (max-width: 768px) {
  .mr-11-sp {
    margin-right: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-11-pc {
    margin-right: 11px !important;
  }
}
.mb-11 {
  margin-bottom: 11px !important;
}

@media screen and (max-width: 768px) {
  .mb-11-sp {
    margin-bottom: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-11-pc {
    margin-bottom: 11px !important;
  }
}
.ml-11 {
  margin-left: 11px !important;
}

@media screen and (max-width: 768px) {
  .ml-11-sp {
    margin-left: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-11-pc {
    margin-left: 11px !important;
  }
}
.mt-12 {
  margin-top: 12px;
}

@media screen and (max-width: 768px) {
  .mt-12-sp {
    margin-top: 12px;
  }
}
@media screen and (min-width: 769px) {
  .mt-12-pc {
    margin-top: 12px;
  }
}
.mr-12 {
  margin-right: 12px !important;
}

@media screen and (max-width: 768px) {
  .mr-12-sp {
    margin-right: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-12-pc {
    margin-right: 12px !important;
  }
}
.mb-12 {
  margin-bottom: 12px !important;
}

@media screen and (max-width: 768px) {
  .mb-12-sp {
    margin-bottom: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-12-pc {
    margin-bottom: 12px !important;
  }
}
.ml-12 {
  margin-left: 12px !important;
}

@media screen and (max-width: 768px) {
  .ml-12-sp {
    margin-left: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-12-pc {
    margin-left: 12px !important;
  }
}
.mt-13 {
  margin-top: 13px;
}

@media screen and (max-width: 768px) {
  .mt-13-sp {
    margin-top: 13px;
  }
}
@media screen and (min-width: 769px) {
  .mt-13-pc {
    margin-top: 13px;
  }
}
.mr-13 {
  margin-right: 13px !important;
}

@media screen and (max-width: 768px) {
  .mr-13-sp {
    margin-right: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-13-pc {
    margin-right: 13px !important;
  }
}
.mb-13 {
  margin-bottom: 13px !important;
}

@media screen and (max-width: 768px) {
  .mb-13-sp {
    margin-bottom: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-13-pc {
    margin-bottom: 13px !important;
  }
}
.ml-13 {
  margin-left: 13px !important;
}

@media screen and (max-width: 768px) {
  .ml-13-sp {
    margin-left: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-13-pc {
    margin-left: 13px !important;
  }
}
.mt-14 {
  margin-top: 14px;
}

@media screen and (max-width: 768px) {
  .mt-14-sp {
    margin-top: 14px;
  }
}
@media screen and (min-width: 769px) {
  .mt-14-pc {
    margin-top: 14px;
  }
}
.mr-14 {
  margin-right: 14px !important;
}

@media screen and (max-width: 768px) {
  .mr-14-sp {
    margin-right: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-14-pc {
    margin-right: 14px !important;
  }
}
.mb-14 {
  margin-bottom: 14px !important;
}

@media screen and (max-width: 768px) {
  .mb-14-sp {
    margin-bottom: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-14-pc {
    margin-bottom: 14px !important;
  }
}
.ml-14 {
  margin-left: 14px !important;
}

@media screen and (max-width: 768px) {
  .ml-14-sp {
    margin-left: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-14-pc {
    margin-left: 14px !important;
  }
}
.mt-15 {
  margin-top: 15px;
}

@media screen and (max-width: 768px) {
  .mt-15-sp {
    margin-top: 15px;
  }
}
@media screen and (min-width: 769px) {
  .mt-15-pc {
    margin-top: 15px;
  }
}
.mr-15 {
  margin-right: 15px !important;
}

@media screen and (max-width: 768px) {
  .mr-15-sp {
    margin-right: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-15-pc {
    margin-right: 15px !important;
  }
}
.mb-15 {
  margin-bottom: 15px !important;
}

@media screen and (max-width: 768px) {
  .mb-15-sp {
    margin-bottom: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-15-pc {
    margin-bottom: 15px !important;
  }
}
.ml-15 {
  margin-left: 15px !important;
}

@media screen and (max-width: 768px) {
  .ml-15-sp {
    margin-left: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-15-pc {
    margin-left: 15px !important;
  }
}
.mt-16 {
  margin-top: 16px;
}

@media screen and (max-width: 768px) {
  .mt-16-sp {
    margin-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .mt-16-pc {
    margin-top: 16px;
  }
}
.mr-16 {
  margin-right: 16px !important;
}

@media screen and (max-width: 768px) {
  .mr-16-sp {
    margin-right: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-16-pc {
    margin-right: 16px !important;
  }
}
.mb-16 {
  margin-bottom: 16px !important;
}

@media screen and (max-width: 768px) {
  .mb-16-sp {
    margin-bottom: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-16-pc {
    margin-bottom: 16px !important;
  }
}
.ml-16 {
  margin-left: 16px !important;
}

@media screen and (max-width: 768px) {
  .ml-16-sp {
    margin-left: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-16-pc {
    margin-left: 16px !important;
  }
}
.mt-17 {
  margin-top: 17px;
}

@media screen and (max-width: 768px) {
  .mt-17-sp {
    margin-top: 17px;
  }
}
@media screen and (min-width: 769px) {
  .mt-17-pc {
    margin-top: 17px;
  }
}
.mr-17 {
  margin-right: 17px !important;
}

@media screen and (max-width: 768px) {
  .mr-17-sp {
    margin-right: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-17-pc {
    margin-right: 17px !important;
  }
}
.mb-17 {
  margin-bottom: 17px !important;
}

@media screen and (max-width: 768px) {
  .mb-17-sp {
    margin-bottom: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-17-pc {
    margin-bottom: 17px !important;
  }
}
.ml-17 {
  margin-left: 17px !important;
}

@media screen and (max-width: 768px) {
  .ml-17-sp {
    margin-left: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-17-pc {
    margin-left: 17px !important;
  }
}
.mt-18 {
  margin-top: 18px;
}

@media screen and (max-width: 768px) {
  .mt-18-sp {
    margin-top: 18px;
  }
}
@media screen and (min-width: 769px) {
  .mt-18-pc {
    margin-top: 18px;
  }
}
.mr-18 {
  margin-right: 18px !important;
}

@media screen and (max-width: 768px) {
  .mr-18-sp {
    margin-right: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-18-pc {
    margin-right: 18px !important;
  }
}
.mb-18 {
  margin-bottom: 18px !important;
}

@media screen and (max-width: 768px) {
  .mb-18-sp {
    margin-bottom: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-18-pc {
    margin-bottom: 18px !important;
  }
}
.ml-18 {
  margin-left: 18px !important;
}

@media screen and (max-width: 768px) {
  .ml-18-sp {
    margin-left: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-18-pc {
    margin-left: 18px !important;
  }
}
.mt-19 {
  margin-top: 19px;
}

@media screen and (max-width: 768px) {
  .mt-19-sp {
    margin-top: 19px;
  }
}
@media screen and (min-width: 769px) {
  .mt-19-pc {
    margin-top: 19px;
  }
}
.mr-19 {
  margin-right: 19px !important;
}

@media screen and (max-width: 768px) {
  .mr-19-sp {
    margin-right: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-19-pc {
    margin-right: 19px !important;
  }
}
.mb-19 {
  margin-bottom: 19px !important;
}

@media screen and (max-width: 768px) {
  .mb-19-sp {
    margin-bottom: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-19-pc {
    margin-bottom: 19px !important;
  }
}
.ml-19 {
  margin-left: 19px !important;
}

@media screen and (max-width: 768px) {
  .ml-19-sp {
    margin-left: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-19-pc {
    margin-left: 19px !important;
  }
}
.mt-20 {
  margin-top: 20px;
}

@media screen and (max-width: 768px) {
  .mt-20-sp {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .mt-20-pc {
    margin-top: 20px;
  }
}
.mr-20 {
  margin-right: 20px !important;
}

@media screen and (max-width: 768px) {
  .mr-20-sp {
    margin-right: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-20-pc {
    margin-right: 20px !important;
  }
}
.mb-20 {
  margin-bottom: 20px !important;
}

@media screen and (max-width: 768px) {
  .mb-20-sp {
    margin-bottom: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-20-pc {
    margin-bottom: 20px !important;
  }
}
.ml-20 {
  margin-left: 20px !important;
}

@media screen and (max-width: 768px) {
  .ml-20-sp {
    margin-left: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-20-pc {
    margin-left: 20px !important;
  }
}
.mt-21 {
  margin-top: 21px;
}

@media screen and (max-width: 768px) {
  .mt-21-sp {
    margin-top: 21px;
  }
}
@media screen and (min-width: 769px) {
  .mt-21-pc {
    margin-top: 21px;
  }
}
.mr-21 {
  margin-right: 21px !important;
}

@media screen and (max-width: 768px) {
  .mr-21-sp {
    margin-right: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-21-pc {
    margin-right: 21px !important;
  }
}
.mb-21 {
  margin-bottom: 21px !important;
}

@media screen and (max-width: 768px) {
  .mb-21-sp {
    margin-bottom: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-21-pc {
    margin-bottom: 21px !important;
  }
}
.ml-21 {
  margin-left: 21px !important;
}

@media screen and (max-width: 768px) {
  .ml-21-sp {
    margin-left: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-21-pc {
    margin-left: 21px !important;
  }
}
.mt-22 {
  margin-top: 22px;
}

@media screen and (max-width: 768px) {
  .mt-22-sp {
    margin-top: 22px;
  }
}
@media screen and (min-width: 769px) {
  .mt-22-pc {
    margin-top: 22px;
  }
}
.mr-22 {
  margin-right: 22px !important;
}

@media screen and (max-width: 768px) {
  .mr-22-sp {
    margin-right: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-22-pc {
    margin-right: 22px !important;
  }
}
.mb-22 {
  margin-bottom: 22px !important;
}

@media screen and (max-width: 768px) {
  .mb-22-sp {
    margin-bottom: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-22-pc {
    margin-bottom: 22px !important;
  }
}
.ml-22 {
  margin-left: 22px !important;
}

@media screen and (max-width: 768px) {
  .ml-22-sp {
    margin-left: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-22-pc {
    margin-left: 22px !important;
  }
}
.mt-23 {
  margin-top: 23px;
}

@media screen and (max-width: 768px) {
  .mt-23-sp {
    margin-top: 23px;
  }
}
@media screen and (min-width: 769px) {
  .mt-23-pc {
    margin-top: 23px;
  }
}
.mr-23 {
  margin-right: 23px !important;
}

@media screen and (max-width: 768px) {
  .mr-23-sp {
    margin-right: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-23-pc {
    margin-right: 23px !important;
  }
}
.mb-23 {
  margin-bottom: 23px !important;
}

@media screen and (max-width: 768px) {
  .mb-23-sp {
    margin-bottom: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-23-pc {
    margin-bottom: 23px !important;
  }
}
.ml-23 {
  margin-left: 23px !important;
}

@media screen and (max-width: 768px) {
  .ml-23-sp {
    margin-left: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-23-pc {
    margin-left: 23px !important;
  }
}
.mt-24 {
  margin-top: 24px;
}

@media screen and (max-width: 768px) {
  .mt-24-sp {
    margin-top: 24px;
  }
}
@media screen and (min-width: 769px) {
  .mt-24-pc {
    margin-top: 24px;
  }
}
.mr-24 {
  margin-right: 24px !important;
}

@media screen and (max-width: 768px) {
  .mr-24-sp {
    margin-right: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-24-pc {
    margin-right: 24px !important;
  }
}
.mb-24 {
  margin-bottom: 24px !important;
}

@media screen and (max-width: 768px) {
  .mb-24-sp {
    margin-bottom: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-24-pc {
    margin-bottom: 24px !important;
  }
}
.ml-24 {
  margin-left: 24px !important;
}

@media screen and (max-width: 768px) {
  .ml-24-sp {
    margin-left: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-24-pc {
    margin-left: 24px !important;
  }
}
.mt-25 {
  margin-top: 25px;
}

@media screen and (max-width: 768px) {
  .mt-25-sp {
    margin-top: 25px;
  }
}
@media screen and (min-width: 769px) {
  .mt-25-pc {
    margin-top: 25px;
  }
}
.mr-25 {
  margin-right: 25px !important;
}

@media screen and (max-width: 768px) {
  .mr-25-sp {
    margin-right: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-25-pc {
    margin-right: 25px !important;
  }
}
.mb-25 {
  margin-bottom: 25px !important;
}

@media screen and (max-width: 768px) {
  .mb-25-sp {
    margin-bottom: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-25-pc {
    margin-bottom: 25px !important;
  }
}
.ml-25 {
  margin-left: 25px !important;
}

@media screen and (max-width: 768px) {
  .ml-25-sp {
    margin-left: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-25-pc {
    margin-left: 25px !important;
  }
}
.mt-26 {
  margin-top: 26px;
}

@media screen and (max-width: 768px) {
  .mt-26-sp {
    margin-top: 26px;
  }
}
@media screen and (min-width: 769px) {
  .mt-26-pc {
    margin-top: 26px;
  }
}
.mr-26 {
  margin-right: 26px !important;
}

@media screen and (max-width: 768px) {
  .mr-26-sp {
    margin-right: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-26-pc {
    margin-right: 26px !important;
  }
}
.mb-26 {
  margin-bottom: 26px !important;
}

@media screen and (max-width: 768px) {
  .mb-26-sp {
    margin-bottom: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-26-pc {
    margin-bottom: 26px !important;
  }
}
.ml-26 {
  margin-left: 26px !important;
}

@media screen and (max-width: 768px) {
  .ml-26-sp {
    margin-left: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-26-pc {
    margin-left: 26px !important;
  }
}
.mt-27 {
  margin-top: 27px;
}

@media screen and (max-width: 768px) {
  .mt-27-sp {
    margin-top: 27px;
  }
}
@media screen and (min-width: 769px) {
  .mt-27-pc {
    margin-top: 27px;
  }
}
.mr-27 {
  margin-right: 27px !important;
}

@media screen and (max-width: 768px) {
  .mr-27-sp {
    margin-right: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-27-pc {
    margin-right: 27px !important;
  }
}
.mb-27 {
  margin-bottom: 27px !important;
}

@media screen and (max-width: 768px) {
  .mb-27-sp {
    margin-bottom: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-27-pc {
    margin-bottom: 27px !important;
  }
}
.ml-27 {
  margin-left: 27px !important;
}

@media screen and (max-width: 768px) {
  .ml-27-sp {
    margin-left: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-27-pc {
    margin-left: 27px !important;
  }
}
.mt-28 {
  margin-top: 28px;
}

@media screen and (max-width: 768px) {
  .mt-28-sp {
    margin-top: 28px;
  }
}
@media screen and (min-width: 769px) {
  .mt-28-pc {
    margin-top: 28px;
  }
}
.mr-28 {
  margin-right: 28px !important;
}

@media screen and (max-width: 768px) {
  .mr-28-sp {
    margin-right: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-28-pc {
    margin-right: 28px !important;
  }
}
.mb-28 {
  margin-bottom: 28px !important;
}

@media screen and (max-width: 768px) {
  .mb-28-sp {
    margin-bottom: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-28-pc {
    margin-bottom: 28px !important;
  }
}
.ml-28 {
  margin-left: 28px !important;
}

@media screen and (max-width: 768px) {
  .ml-28-sp {
    margin-left: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-28-pc {
    margin-left: 28px !important;
  }
}
.mt-29 {
  margin-top: 29px;
}

@media screen and (max-width: 768px) {
  .mt-29-sp {
    margin-top: 29px;
  }
}
@media screen and (min-width: 769px) {
  .mt-29-pc {
    margin-top: 29px;
  }
}
.mr-29 {
  margin-right: 29px !important;
}

@media screen and (max-width: 768px) {
  .mr-29-sp {
    margin-right: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-29-pc {
    margin-right: 29px !important;
  }
}
.mb-29 {
  margin-bottom: 29px !important;
}

@media screen and (max-width: 768px) {
  .mb-29-sp {
    margin-bottom: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-29-pc {
    margin-bottom: 29px !important;
  }
}
.ml-29 {
  margin-left: 29px !important;
}

@media screen and (max-width: 768px) {
  .ml-29-sp {
    margin-left: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-29-pc {
    margin-left: 29px !important;
  }
}
.mt-30 {
  margin-top: 30px;
}

@media screen and (max-width: 768px) {
  .mt-30-sp {
    margin-top: 30px;
  }
}
@media screen and (min-width: 769px) {
  .mt-30-pc {
    margin-top: 30px;
  }
}
.mr-30 {
  margin-right: 30px !important;
}

@media screen and (max-width: 768px) {
  .mr-30-sp {
    margin-right: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-30-pc {
    margin-right: 30px !important;
  }
}
.mb-30 {
  margin-bottom: 30px !important;
}

@media screen and (max-width: 768px) {
  .mb-30-sp {
    margin-bottom: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-30-pc {
    margin-bottom: 30px !important;
  }
}
.ml-30 {
  margin-left: 30px !important;
}

@media screen and (max-width: 768px) {
  .ml-30-sp {
    margin-left: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-30-pc {
    margin-left: 30px !important;
  }
}
.mt-31 {
  margin-top: 31px;
}

@media screen and (max-width: 768px) {
  .mt-31-sp {
    margin-top: 31px;
  }
}
@media screen and (min-width: 769px) {
  .mt-31-pc {
    margin-top: 31px;
  }
}
.mr-31 {
  margin-right: 31px !important;
}

@media screen and (max-width: 768px) {
  .mr-31-sp {
    margin-right: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-31-pc {
    margin-right: 31px !important;
  }
}
.mb-31 {
  margin-bottom: 31px !important;
}

@media screen and (max-width: 768px) {
  .mb-31-sp {
    margin-bottom: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-31-pc {
    margin-bottom: 31px !important;
  }
}
.ml-31 {
  margin-left: 31px !important;
}

@media screen and (max-width: 768px) {
  .ml-31-sp {
    margin-left: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-31-pc {
    margin-left: 31px !important;
  }
}
.mt-32 {
  margin-top: 32px;
}

@media screen and (max-width: 768px) {
  .mt-32-sp {
    margin-top: 32px;
  }
}
@media screen and (min-width: 769px) {
  .mt-32-pc {
    margin-top: 32px;
  }
}
.mr-32 {
  margin-right: 32px !important;
}

@media screen and (max-width: 768px) {
  .mr-32-sp {
    margin-right: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-32-pc {
    margin-right: 32px !important;
  }
}
.mb-32 {
  margin-bottom: 32px !important;
}

@media screen and (max-width: 768px) {
  .mb-32-sp {
    margin-bottom: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-32-pc {
    margin-bottom: 32px !important;
  }
}
.ml-32 {
  margin-left: 32px !important;
}

@media screen and (max-width: 768px) {
  .ml-32-sp {
    margin-left: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-32-pc {
    margin-left: 32px !important;
  }
}
.mt-33 {
  margin-top: 33px;
}

@media screen and (max-width: 768px) {
  .mt-33-sp {
    margin-top: 33px;
  }
}
@media screen and (min-width: 769px) {
  .mt-33-pc {
    margin-top: 33px;
  }
}
.mr-33 {
  margin-right: 33px !important;
}

@media screen and (max-width: 768px) {
  .mr-33-sp {
    margin-right: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-33-pc {
    margin-right: 33px !important;
  }
}
.mb-33 {
  margin-bottom: 33px !important;
}

@media screen and (max-width: 768px) {
  .mb-33-sp {
    margin-bottom: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-33-pc {
    margin-bottom: 33px !important;
  }
}
.ml-33 {
  margin-left: 33px !important;
}

@media screen and (max-width: 768px) {
  .ml-33-sp {
    margin-left: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-33-pc {
    margin-left: 33px !important;
  }
}
.mt-34 {
  margin-top: 34px;
}

@media screen and (max-width: 768px) {
  .mt-34-sp {
    margin-top: 34px;
  }
}
@media screen and (min-width: 769px) {
  .mt-34-pc {
    margin-top: 34px;
  }
}
.mr-34 {
  margin-right: 34px !important;
}

@media screen and (max-width: 768px) {
  .mr-34-sp {
    margin-right: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-34-pc {
    margin-right: 34px !important;
  }
}
.mb-34 {
  margin-bottom: 34px !important;
}

@media screen and (max-width: 768px) {
  .mb-34-sp {
    margin-bottom: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-34-pc {
    margin-bottom: 34px !important;
  }
}
.ml-34 {
  margin-left: 34px !important;
}

@media screen and (max-width: 768px) {
  .ml-34-sp {
    margin-left: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-34-pc {
    margin-left: 34px !important;
  }
}
.mt-35 {
  margin-top: 35px;
}

@media screen and (max-width: 768px) {
  .mt-35-sp {
    margin-top: 35px;
  }
}
@media screen and (min-width: 769px) {
  .mt-35-pc {
    margin-top: 35px;
  }
}
.mr-35 {
  margin-right: 35px !important;
}

@media screen and (max-width: 768px) {
  .mr-35-sp {
    margin-right: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-35-pc {
    margin-right: 35px !important;
  }
}
.mb-35 {
  margin-bottom: 35px !important;
}

@media screen and (max-width: 768px) {
  .mb-35-sp {
    margin-bottom: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-35-pc {
    margin-bottom: 35px !important;
  }
}
.ml-35 {
  margin-left: 35px !important;
}

@media screen and (max-width: 768px) {
  .ml-35-sp {
    margin-left: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-35-pc {
    margin-left: 35px !important;
  }
}
.mt-36 {
  margin-top: 36px;
}

@media screen and (max-width: 768px) {
  .mt-36-sp {
    margin-top: 36px;
  }
}
@media screen and (min-width: 769px) {
  .mt-36-pc {
    margin-top: 36px;
  }
}
.mr-36 {
  margin-right: 36px !important;
}

@media screen and (max-width: 768px) {
  .mr-36-sp {
    margin-right: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-36-pc {
    margin-right: 36px !important;
  }
}
.mb-36 {
  margin-bottom: 36px !important;
}

@media screen and (max-width: 768px) {
  .mb-36-sp {
    margin-bottom: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-36-pc {
    margin-bottom: 36px !important;
  }
}
.ml-36 {
  margin-left: 36px !important;
}

@media screen and (max-width: 768px) {
  .ml-36-sp {
    margin-left: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-36-pc {
    margin-left: 36px !important;
  }
}
.mt-37 {
  margin-top: 37px;
}

@media screen and (max-width: 768px) {
  .mt-37-sp {
    margin-top: 37px;
  }
}
@media screen and (min-width: 769px) {
  .mt-37-pc {
    margin-top: 37px;
  }
}
.mr-37 {
  margin-right: 37px !important;
}

@media screen and (max-width: 768px) {
  .mr-37-sp {
    margin-right: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-37-pc {
    margin-right: 37px !important;
  }
}
.mb-37 {
  margin-bottom: 37px !important;
}

@media screen and (max-width: 768px) {
  .mb-37-sp {
    margin-bottom: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-37-pc {
    margin-bottom: 37px !important;
  }
}
.ml-37 {
  margin-left: 37px !important;
}

@media screen and (max-width: 768px) {
  .ml-37-sp {
    margin-left: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-37-pc {
    margin-left: 37px !important;
  }
}
.mt-38 {
  margin-top: 38px;
}

@media screen and (max-width: 768px) {
  .mt-38-sp {
    margin-top: 38px;
  }
}
@media screen and (min-width: 769px) {
  .mt-38-pc {
    margin-top: 38px;
  }
}
.mr-38 {
  margin-right: 38px !important;
}

@media screen and (max-width: 768px) {
  .mr-38-sp {
    margin-right: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-38-pc {
    margin-right: 38px !important;
  }
}
.mb-38 {
  margin-bottom: 38px !important;
}

@media screen and (max-width: 768px) {
  .mb-38-sp {
    margin-bottom: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-38-pc {
    margin-bottom: 38px !important;
  }
}
.ml-38 {
  margin-left: 38px !important;
}

@media screen and (max-width: 768px) {
  .ml-38-sp {
    margin-left: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-38-pc {
    margin-left: 38px !important;
  }
}
.mt-39 {
  margin-top: 39px;
}

@media screen and (max-width: 768px) {
  .mt-39-sp {
    margin-top: 39px;
  }
}
@media screen and (min-width: 769px) {
  .mt-39-pc {
    margin-top: 39px;
  }
}
.mr-39 {
  margin-right: 39px !important;
}

@media screen and (max-width: 768px) {
  .mr-39-sp {
    margin-right: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-39-pc {
    margin-right: 39px !important;
  }
}
.mb-39 {
  margin-bottom: 39px !important;
}

@media screen and (max-width: 768px) {
  .mb-39-sp {
    margin-bottom: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-39-pc {
    margin-bottom: 39px !important;
  }
}
.ml-39 {
  margin-left: 39px !important;
}

@media screen and (max-width: 768px) {
  .ml-39-sp {
    margin-left: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-39-pc {
    margin-left: 39px !important;
  }
}
.mt-40 {
  margin-top: 40px;
}

@media screen and (max-width: 768px) {
  .mt-40-sp {
    margin-top: 40px;
  }
}
@media screen and (min-width: 769px) {
  .mt-40-pc {
    margin-top: 40px;
  }
}
.mr-40 {
  margin-right: 40px !important;
}

@media screen and (max-width: 768px) {
  .mr-40-sp {
    margin-right: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-40-pc {
    margin-right: 40px !important;
  }
}
.mb-40 {
  margin-bottom: 40px !important;
}

@media screen and (max-width: 768px) {
  .mb-40-sp {
    margin-bottom: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-40-pc {
    margin-bottom: 40px !important;
  }
}
.ml-40 {
  margin-left: 40px !important;
}

@media screen and (max-width: 768px) {
  .ml-40-sp {
    margin-left: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-40-pc {
    margin-left: 40px !important;
  }
}
.mt-41 {
  margin-top: 41px;
}

@media screen and (max-width: 768px) {
  .mt-41-sp {
    margin-top: 41px;
  }
}
@media screen and (min-width: 769px) {
  .mt-41-pc {
    margin-top: 41px;
  }
}
.mr-41 {
  margin-right: 41px !important;
}

@media screen and (max-width: 768px) {
  .mr-41-sp {
    margin-right: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-41-pc {
    margin-right: 41px !important;
  }
}
.mb-41 {
  margin-bottom: 41px !important;
}

@media screen and (max-width: 768px) {
  .mb-41-sp {
    margin-bottom: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-41-pc {
    margin-bottom: 41px !important;
  }
}
.ml-41 {
  margin-left: 41px !important;
}

@media screen and (max-width: 768px) {
  .ml-41-sp {
    margin-left: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-41-pc {
    margin-left: 41px !important;
  }
}
.mt-42 {
  margin-top: 42px;
}

@media screen and (max-width: 768px) {
  .mt-42-sp {
    margin-top: 42px;
  }
}
@media screen and (min-width: 769px) {
  .mt-42-pc {
    margin-top: 42px;
  }
}
.mr-42 {
  margin-right: 42px !important;
}

@media screen and (max-width: 768px) {
  .mr-42-sp {
    margin-right: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-42-pc {
    margin-right: 42px !important;
  }
}
.mb-42 {
  margin-bottom: 42px !important;
}

@media screen and (max-width: 768px) {
  .mb-42-sp {
    margin-bottom: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-42-pc {
    margin-bottom: 42px !important;
  }
}
.ml-42 {
  margin-left: 42px !important;
}

@media screen and (max-width: 768px) {
  .ml-42-sp {
    margin-left: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-42-pc {
    margin-left: 42px !important;
  }
}
.mt-43 {
  margin-top: 43px;
}

@media screen and (max-width: 768px) {
  .mt-43-sp {
    margin-top: 43px;
  }
}
@media screen and (min-width: 769px) {
  .mt-43-pc {
    margin-top: 43px;
  }
}
.mr-43 {
  margin-right: 43px !important;
}

@media screen and (max-width: 768px) {
  .mr-43-sp {
    margin-right: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-43-pc {
    margin-right: 43px !important;
  }
}
.mb-43 {
  margin-bottom: 43px !important;
}

@media screen and (max-width: 768px) {
  .mb-43-sp {
    margin-bottom: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-43-pc {
    margin-bottom: 43px !important;
  }
}
.ml-43 {
  margin-left: 43px !important;
}

@media screen and (max-width: 768px) {
  .ml-43-sp {
    margin-left: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-43-pc {
    margin-left: 43px !important;
  }
}
.mt-44 {
  margin-top: 44px;
}

@media screen and (max-width: 768px) {
  .mt-44-sp {
    margin-top: 44px;
  }
}
@media screen and (min-width: 769px) {
  .mt-44-pc {
    margin-top: 44px;
  }
}
.mr-44 {
  margin-right: 44px !important;
}

@media screen and (max-width: 768px) {
  .mr-44-sp {
    margin-right: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-44-pc {
    margin-right: 44px !important;
  }
}
.mb-44 {
  margin-bottom: 44px !important;
}

@media screen and (max-width: 768px) {
  .mb-44-sp {
    margin-bottom: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-44-pc {
    margin-bottom: 44px !important;
  }
}
.ml-44 {
  margin-left: 44px !important;
}

@media screen and (max-width: 768px) {
  .ml-44-sp {
    margin-left: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-44-pc {
    margin-left: 44px !important;
  }
}
.mt-45 {
  margin-top: 45px;
}

@media screen and (max-width: 768px) {
  .mt-45-sp {
    margin-top: 45px;
  }
}
@media screen and (min-width: 769px) {
  .mt-45-pc {
    margin-top: 45px;
  }
}
.mr-45 {
  margin-right: 45px !important;
}

@media screen and (max-width: 768px) {
  .mr-45-sp {
    margin-right: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-45-pc {
    margin-right: 45px !important;
  }
}
.mb-45 {
  margin-bottom: 45px !important;
}

@media screen and (max-width: 768px) {
  .mb-45-sp {
    margin-bottom: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-45-pc {
    margin-bottom: 45px !important;
  }
}
.ml-45 {
  margin-left: 45px !important;
}

@media screen and (max-width: 768px) {
  .ml-45-sp {
    margin-left: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-45-pc {
    margin-left: 45px !important;
  }
}
.mt-46 {
  margin-top: 46px;
}

@media screen and (max-width: 768px) {
  .mt-46-sp {
    margin-top: 46px;
  }
}
@media screen and (min-width: 769px) {
  .mt-46-pc {
    margin-top: 46px;
  }
}
.mr-46 {
  margin-right: 46px !important;
}

@media screen and (max-width: 768px) {
  .mr-46-sp {
    margin-right: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-46-pc {
    margin-right: 46px !important;
  }
}
.mb-46 {
  margin-bottom: 46px !important;
}

@media screen and (max-width: 768px) {
  .mb-46-sp {
    margin-bottom: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-46-pc {
    margin-bottom: 46px !important;
  }
}
.ml-46 {
  margin-left: 46px !important;
}

@media screen and (max-width: 768px) {
  .ml-46-sp {
    margin-left: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-46-pc {
    margin-left: 46px !important;
  }
}
.mt-47 {
  margin-top: 47px;
}

@media screen and (max-width: 768px) {
  .mt-47-sp {
    margin-top: 47px;
  }
}
@media screen and (min-width: 769px) {
  .mt-47-pc {
    margin-top: 47px;
  }
}
.mr-47 {
  margin-right: 47px !important;
}

@media screen and (max-width: 768px) {
  .mr-47-sp {
    margin-right: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-47-pc {
    margin-right: 47px !important;
  }
}
.mb-47 {
  margin-bottom: 47px !important;
}

@media screen and (max-width: 768px) {
  .mb-47-sp {
    margin-bottom: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-47-pc {
    margin-bottom: 47px !important;
  }
}
.ml-47 {
  margin-left: 47px !important;
}

@media screen and (max-width: 768px) {
  .ml-47-sp {
    margin-left: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-47-pc {
    margin-left: 47px !important;
  }
}
.mt-48 {
  margin-top: 48px;
}

@media screen and (max-width: 768px) {
  .mt-48-sp {
    margin-top: 48px;
  }
}
@media screen and (min-width: 769px) {
  .mt-48-pc {
    margin-top: 48px;
  }
}
.mr-48 {
  margin-right: 48px !important;
}

@media screen and (max-width: 768px) {
  .mr-48-sp {
    margin-right: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-48-pc {
    margin-right: 48px !important;
  }
}
.mb-48 {
  margin-bottom: 48px !important;
}

@media screen and (max-width: 768px) {
  .mb-48-sp {
    margin-bottom: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-48-pc {
    margin-bottom: 48px !important;
  }
}
.ml-48 {
  margin-left: 48px !important;
}

@media screen and (max-width: 768px) {
  .ml-48-sp {
    margin-left: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-48-pc {
    margin-left: 48px !important;
  }
}
.mt-49 {
  margin-top: 49px;
}

@media screen and (max-width: 768px) {
  .mt-49-sp {
    margin-top: 49px;
  }
}
@media screen and (min-width: 769px) {
  .mt-49-pc {
    margin-top: 49px;
  }
}
.mr-49 {
  margin-right: 49px !important;
}

@media screen and (max-width: 768px) {
  .mr-49-sp {
    margin-right: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-49-pc {
    margin-right: 49px !important;
  }
}
.mb-49 {
  margin-bottom: 49px !important;
}

@media screen and (max-width: 768px) {
  .mb-49-sp {
    margin-bottom: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-49-pc {
    margin-bottom: 49px !important;
  }
}
.ml-49 {
  margin-left: 49px !important;
}

@media screen and (max-width: 768px) {
  .ml-49-sp {
    margin-left: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-49-pc {
    margin-left: 49px !important;
  }
}
.mt-50 {
  margin-top: 50px;
}

@media screen and (max-width: 768px) {
  .mt-50-sp {
    margin-top: 50px;
  }
}
@media screen and (min-width: 769px) {
  .mt-50-pc {
    margin-top: 50px;
  }
}
.mr-50 {
  margin-right: 50px !important;
}

@media screen and (max-width: 768px) {
  .mr-50-sp {
    margin-right: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-50-pc {
    margin-right: 50px !important;
  }
}
.mb-50 {
  margin-bottom: 50px !important;
}

@media screen and (max-width: 768px) {
  .mb-50-sp {
    margin-bottom: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-50-pc {
    margin-bottom: 50px !important;
  }
}
.ml-50 {
  margin-left: 50px !important;
}

@media screen and (max-width: 768px) {
  .ml-50-sp {
    margin-left: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-50-pc {
    margin-left: 50px !important;
  }
}
.mt-51 {
  margin-top: 51px;
}

@media screen and (max-width: 768px) {
  .mt-51-sp {
    margin-top: 51px;
  }
}
@media screen and (min-width: 769px) {
  .mt-51-pc {
    margin-top: 51px;
  }
}
.mr-51 {
  margin-right: 51px !important;
}

@media screen and (max-width: 768px) {
  .mr-51-sp {
    margin-right: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-51-pc {
    margin-right: 51px !important;
  }
}
.mb-51 {
  margin-bottom: 51px !important;
}

@media screen and (max-width: 768px) {
  .mb-51-sp {
    margin-bottom: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-51-pc {
    margin-bottom: 51px !important;
  }
}
.ml-51 {
  margin-left: 51px !important;
}

@media screen and (max-width: 768px) {
  .ml-51-sp {
    margin-left: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-51-pc {
    margin-left: 51px !important;
  }
}
.mt-52 {
  margin-top: 52px;
}

@media screen and (max-width: 768px) {
  .mt-52-sp {
    margin-top: 52px;
  }
}
@media screen and (min-width: 769px) {
  .mt-52-pc {
    margin-top: 52px;
  }
}
.mr-52 {
  margin-right: 52px !important;
}

@media screen and (max-width: 768px) {
  .mr-52-sp {
    margin-right: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-52-pc {
    margin-right: 52px !important;
  }
}
.mb-52 {
  margin-bottom: 52px !important;
}

@media screen and (max-width: 768px) {
  .mb-52-sp {
    margin-bottom: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-52-pc {
    margin-bottom: 52px !important;
  }
}
.ml-52 {
  margin-left: 52px !important;
}

@media screen and (max-width: 768px) {
  .ml-52-sp {
    margin-left: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-52-pc {
    margin-left: 52px !important;
  }
}
.mt-53 {
  margin-top: 53px;
}

@media screen and (max-width: 768px) {
  .mt-53-sp {
    margin-top: 53px;
  }
}
@media screen and (min-width: 769px) {
  .mt-53-pc {
    margin-top: 53px;
  }
}
.mr-53 {
  margin-right: 53px !important;
}

@media screen and (max-width: 768px) {
  .mr-53-sp {
    margin-right: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-53-pc {
    margin-right: 53px !important;
  }
}
.mb-53 {
  margin-bottom: 53px !important;
}

@media screen and (max-width: 768px) {
  .mb-53-sp {
    margin-bottom: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-53-pc {
    margin-bottom: 53px !important;
  }
}
.ml-53 {
  margin-left: 53px !important;
}

@media screen and (max-width: 768px) {
  .ml-53-sp {
    margin-left: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-53-pc {
    margin-left: 53px !important;
  }
}
.mt-54 {
  margin-top: 54px;
}

@media screen and (max-width: 768px) {
  .mt-54-sp {
    margin-top: 54px;
  }
}
@media screen and (min-width: 769px) {
  .mt-54-pc {
    margin-top: 54px;
  }
}
.mr-54 {
  margin-right: 54px !important;
}

@media screen and (max-width: 768px) {
  .mr-54-sp {
    margin-right: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-54-pc {
    margin-right: 54px !important;
  }
}
.mb-54 {
  margin-bottom: 54px !important;
}

@media screen and (max-width: 768px) {
  .mb-54-sp {
    margin-bottom: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-54-pc {
    margin-bottom: 54px !important;
  }
}
.ml-54 {
  margin-left: 54px !important;
}

@media screen and (max-width: 768px) {
  .ml-54-sp {
    margin-left: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-54-pc {
    margin-left: 54px !important;
  }
}
.mt-55 {
  margin-top: 55px;
}

@media screen and (max-width: 768px) {
  .mt-55-sp {
    margin-top: 55px;
  }
}
@media screen and (min-width: 769px) {
  .mt-55-pc {
    margin-top: 55px;
  }
}
.mr-55 {
  margin-right: 55px !important;
}

@media screen and (max-width: 768px) {
  .mr-55-sp {
    margin-right: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-55-pc {
    margin-right: 55px !important;
  }
}
.mb-55 {
  margin-bottom: 55px !important;
}

@media screen and (max-width: 768px) {
  .mb-55-sp {
    margin-bottom: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-55-pc {
    margin-bottom: 55px !important;
  }
}
.ml-55 {
  margin-left: 55px !important;
}

@media screen and (max-width: 768px) {
  .ml-55-sp {
    margin-left: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-55-pc {
    margin-left: 55px !important;
  }
}
.mt-56 {
  margin-top: 56px;
}

@media screen and (max-width: 768px) {
  .mt-56-sp {
    margin-top: 56px;
  }
}
@media screen and (min-width: 769px) {
  .mt-56-pc {
    margin-top: 56px;
  }
}
.mr-56 {
  margin-right: 56px !important;
}

@media screen and (max-width: 768px) {
  .mr-56-sp {
    margin-right: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-56-pc {
    margin-right: 56px !important;
  }
}
.mb-56 {
  margin-bottom: 56px !important;
}

@media screen and (max-width: 768px) {
  .mb-56-sp {
    margin-bottom: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-56-pc {
    margin-bottom: 56px !important;
  }
}
.ml-56 {
  margin-left: 56px !important;
}

@media screen and (max-width: 768px) {
  .ml-56-sp {
    margin-left: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-56-pc {
    margin-left: 56px !important;
  }
}
.mt-57 {
  margin-top: 57px;
}

@media screen and (max-width: 768px) {
  .mt-57-sp {
    margin-top: 57px;
  }
}
@media screen and (min-width: 769px) {
  .mt-57-pc {
    margin-top: 57px;
  }
}
.mr-57 {
  margin-right: 57px !important;
}

@media screen and (max-width: 768px) {
  .mr-57-sp {
    margin-right: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-57-pc {
    margin-right: 57px !important;
  }
}
.mb-57 {
  margin-bottom: 57px !important;
}

@media screen and (max-width: 768px) {
  .mb-57-sp {
    margin-bottom: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-57-pc {
    margin-bottom: 57px !important;
  }
}
.ml-57 {
  margin-left: 57px !important;
}

@media screen and (max-width: 768px) {
  .ml-57-sp {
    margin-left: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-57-pc {
    margin-left: 57px !important;
  }
}
.mt-58 {
  margin-top: 58px;
}

@media screen and (max-width: 768px) {
  .mt-58-sp {
    margin-top: 58px;
  }
}
@media screen and (min-width: 769px) {
  .mt-58-pc {
    margin-top: 58px;
  }
}
.mr-58 {
  margin-right: 58px !important;
}

@media screen and (max-width: 768px) {
  .mr-58-sp {
    margin-right: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-58-pc {
    margin-right: 58px !important;
  }
}
.mb-58 {
  margin-bottom: 58px !important;
}

@media screen and (max-width: 768px) {
  .mb-58-sp {
    margin-bottom: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-58-pc {
    margin-bottom: 58px !important;
  }
}
.ml-58 {
  margin-left: 58px !important;
}

@media screen and (max-width: 768px) {
  .ml-58-sp {
    margin-left: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-58-pc {
    margin-left: 58px !important;
  }
}
.mt-59 {
  margin-top: 59px;
}

@media screen and (max-width: 768px) {
  .mt-59-sp {
    margin-top: 59px;
  }
}
@media screen and (min-width: 769px) {
  .mt-59-pc {
    margin-top: 59px;
  }
}
.mr-59 {
  margin-right: 59px !important;
}

@media screen and (max-width: 768px) {
  .mr-59-sp {
    margin-right: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-59-pc {
    margin-right: 59px !important;
  }
}
.mb-59 {
  margin-bottom: 59px !important;
}

@media screen and (max-width: 768px) {
  .mb-59-sp {
    margin-bottom: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-59-pc {
    margin-bottom: 59px !important;
  }
}
.ml-59 {
  margin-left: 59px !important;
}

@media screen and (max-width: 768px) {
  .ml-59-sp {
    margin-left: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-59-pc {
    margin-left: 59px !important;
  }
}
.mt-60 {
  margin-top: 60px;
}

@media screen and (max-width: 768px) {
  .mt-60-sp {
    margin-top: 60px;
  }
}
@media screen and (min-width: 769px) {
  .mt-60-pc {
    margin-top: 60px;
  }
}
.mr-60 {
  margin-right: 60px !important;
}

@media screen and (max-width: 768px) {
  .mr-60-sp {
    margin-right: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .mr-60-pc {
    margin-right: 60px !important;
  }
}
.mb-60 {
  margin-bottom: 60px !important;
}

@media screen and (max-width: 768px) {
  .mb-60-sp {
    margin-bottom: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .mb-60-pc {
    margin-bottom: 60px !important;
  }
}
.ml-60 {
  margin-left: 60px !important;
}

@media screen and (max-width: 768px) {
  .ml-60-sp {
    margin-left: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .ml-60-pc {
    margin-left: 60px !important;
  }
}
.pt-1 {
  padding-top: 1px;
}

@media screen and (max-width: 768px) {
  .pt-1-sp {
    padding-top: 1px;
  }
}
@media screen and (min-width: 769px) {
  .pt-1-pc {
    padding-top: 1px;
  }
}
.pr-1 {
  padding-right: 1px !important;
}

@media screen and (max-width: 768px) {
  .pr-1-sp {
    padding-right: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-1-pc {
    padding-right: 1px !important;
  }
}
.pb-1 {
  padding-bottom: 1px !important;
}

@media screen and (max-width: 768px) {
  .pb-1-sp {
    padding-bottom: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-1-pc {
    padding-bottom: 1px !important;
  }
}
.pl-1 {
  padding-left: 1px !important;
}

@media screen and (max-width: 768px) {
  .pl-1-sp {
    padding-left: 1px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-1-pc {
    padding-left: 1px !important;
  }
}
.pt-2 {
  padding-top: 2px;
}

@media screen and (max-width: 768px) {
  .pt-2-sp {
    padding-top: 2px;
  }
}
@media screen and (min-width: 769px) {
  .pt-2-pc {
    padding-top: 2px;
  }
}
.pr-2 {
  padding-right: 2px !important;
}

@media screen and (max-width: 768px) {
  .pr-2-sp {
    padding-right: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-2-pc {
    padding-right: 2px !important;
  }
}
.pb-2 {
  padding-bottom: 2px !important;
}

@media screen and (max-width: 768px) {
  .pb-2-sp {
    padding-bottom: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-2-pc {
    padding-bottom: 2px !important;
  }
}
.pl-2 {
  padding-left: 2px !important;
}

@media screen and (max-width: 768px) {
  .pl-2-sp {
    padding-left: 2px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-2-pc {
    padding-left: 2px !important;
  }
}
.pt-3 {
  padding-top: 3px;
}

@media screen and (max-width: 768px) {
  .pt-3-sp {
    padding-top: 3px;
  }
}
@media screen and (min-width: 769px) {
  .pt-3-pc {
    padding-top: 3px;
  }
}
.pr-3 {
  padding-right: 3px !important;
}

@media screen and (max-width: 768px) {
  .pr-3-sp {
    padding-right: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-3-pc {
    padding-right: 3px !important;
  }
}
.pb-3 {
  padding-bottom: 3px !important;
}

@media screen and (max-width: 768px) {
  .pb-3-sp {
    padding-bottom: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-3-pc {
    padding-bottom: 3px !important;
  }
}
.pl-3 {
  padding-left: 3px !important;
}

@media screen and (max-width: 768px) {
  .pl-3-sp {
    padding-left: 3px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-3-pc {
    padding-left: 3px !important;
  }
}
.pt-4 {
  padding-top: 4px;
}

@media screen and (max-width: 768px) {
  .pt-4-sp {
    padding-top: 4px;
  }
}
@media screen and (min-width: 769px) {
  .pt-4-pc {
    padding-top: 4px;
  }
}
.pr-4 {
  padding-right: 4px !important;
}

@media screen and (max-width: 768px) {
  .pr-4-sp {
    padding-right: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-4-pc {
    padding-right: 4px !important;
  }
}
.pb-4 {
  padding-bottom: 4px !important;
}

@media screen and (max-width: 768px) {
  .pb-4-sp {
    padding-bottom: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-4-pc {
    padding-bottom: 4px !important;
  }
}
.pl-4 {
  padding-left: 4px !important;
}

@media screen and (max-width: 768px) {
  .pl-4-sp {
    padding-left: 4px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-4-pc {
    padding-left: 4px !important;
  }
}
.pt-5 {
  padding-top: 5px;
}

@media screen and (max-width: 768px) {
  .pt-5-sp {
    padding-top: 5px;
  }
}
@media screen and (min-width: 769px) {
  .pt-5-pc {
    padding-top: 5px;
  }
}
.pr-5 {
  padding-right: 5px !important;
}

@media screen and (max-width: 768px) {
  .pr-5-sp {
    padding-right: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-5-pc {
    padding-right: 5px !important;
  }
}
.pb-5 {
  padding-bottom: 5px !important;
}

@media screen and (max-width: 768px) {
  .pb-5-sp {
    padding-bottom: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-5-pc {
    padding-bottom: 5px !important;
  }
}
.pl-5 {
  padding-left: 5px !important;
}

@media screen and (max-width: 768px) {
  .pl-5-sp {
    padding-left: 5px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-5-pc {
    padding-left: 5px !important;
  }
}
.pt-6 {
  padding-top: 6px;
}

@media screen and (max-width: 768px) {
  .pt-6-sp {
    padding-top: 6px;
  }
}
@media screen and (min-width: 769px) {
  .pt-6-pc {
    padding-top: 6px;
  }
}
.pr-6 {
  padding-right: 6px !important;
}

@media screen and (max-width: 768px) {
  .pr-6-sp {
    padding-right: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-6-pc {
    padding-right: 6px !important;
  }
}
.pb-6 {
  padding-bottom: 6px !important;
}

@media screen and (max-width: 768px) {
  .pb-6-sp {
    padding-bottom: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-6-pc {
    padding-bottom: 6px !important;
  }
}
.pl-6 {
  padding-left: 6px !important;
}

@media screen and (max-width: 768px) {
  .pl-6-sp {
    padding-left: 6px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-6-pc {
    padding-left: 6px !important;
  }
}
.pt-7 {
  padding-top: 7px;
}

@media screen and (max-width: 768px) {
  .pt-7-sp {
    padding-top: 7px;
  }
}
@media screen and (min-width: 769px) {
  .pt-7-pc {
    padding-top: 7px;
  }
}
.pr-7 {
  padding-right: 7px !important;
}

@media screen and (max-width: 768px) {
  .pr-7-sp {
    padding-right: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-7-pc {
    padding-right: 7px !important;
  }
}
.pb-7 {
  padding-bottom: 7px !important;
}

@media screen and (max-width: 768px) {
  .pb-7-sp {
    padding-bottom: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-7-pc {
    padding-bottom: 7px !important;
  }
}
.pl-7 {
  padding-left: 7px !important;
}

@media screen and (max-width: 768px) {
  .pl-7-sp {
    padding-left: 7px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-7-pc {
    padding-left: 7px !important;
  }
}
.pt-8 {
  padding-top: 8px;
}

@media screen and (max-width: 768px) {
  .pt-8-sp {
    padding-top: 8px;
  }
}
@media screen and (min-width: 769px) {
  .pt-8-pc {
    padding-top: 8px;
  }
}
.pr-8 {
  padding-right: 8px !important;
}

@media screen and (max-width: 768px) {
  .pr-8-sp {
    padding-right: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-8-pc {
    padding-right: 8px !important;
  }
}
.pb-8 {
  padding-bottom: 8px !important;
}

@media screen and (max-width: 768px) {
  .pb-8-sp {
    padding-bottom: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-8-pc {
    padding-bottom: 8px !important;
  }
}
.pl-8 {
  padding-left: 8px !important;
}

@media screen and (max-width: 768px) {
  .pl-8-sp {
    padding-left: 8px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-8-pc {
    padding-left: 8px !important;
  }
}
.pt-9 {
  padding-top: 9px;
}

@media screen and (max-width: 768px) {
  .pt-9-sp {
    padding-top: 9px;
  }
}
@media screen and (min-width: 769px) {
  .pt-9-pc {
    padding-top: 9px;
  }
}
.pr-9 {
  padding-right: 9px !important;
}

@media screen and (max-width: 768px) {
  .pr-9-sp {
    padding-right: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-9-pc {
    padding-right: 9px !important;
  }
}
.pb-9 {
  padding-bottom: 9px !important;
}

@media screen and (max-width: 768px) {
  .pb-9-sp {
    padding-bottom: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-9-pc {
    padding-bottom: 9px !important;
  }
}
.pl-9 {
  padding-left: 9px !important;
}

@media screen and (max-width: 768px) {
  .pl-9-sp {
    padding-left: 9px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-9-pc {
    padding-left: 9px !important;
  }
}
.pt-10 {
  padding-top: 10px;
}

@media screen and (max-width: 768px) {
  .pt-10-sp {
    padding-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .pt-10-pc {
    padding-top: 10px;
  }
}
.pr-10 {
  padding-right: 10px !important;
}

@media screen and (max-width: 768px) {
  .pr-10-sp {
    padding-right: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-10-pc {
    padding-right: 10px !important;
  }
}
.pb-10 {
  padding-bottom: 10px !important;
}

@media screen and (max-width: 768px) {
  .pb-10-sp {
    padding-bottom: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-10-pc {
    padding-bottom: 10px !important;
  }
}
.pl-10 {
  padding-left: 10px !important;
}

@media screen and (max-width: 768px) {
  .pl-10-sp {
    padding-left: 10px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-10-pc {
    padding-left: 10px !important;
  }
}
.pt-11 {
  padding-top: 11px;
}

@media screen and (max-width: 768px) {
  .pt-11-sp {
    padding-top: 11px;
  }
}
@media screen and (min-width: 769px) {
  .pt-11-pc {
    padding-top: 11px;
  }
}
.pr-11 {
  padding-right: 11px !important;
}

@media screen and (max-width: 768px) {
  .pr-11-sp {
    padding-right: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-11-pc {
    padding-right: 11px !important;
  }
}
.pb-11 {
  padding-bottom: 11px !important;
}

@media screen and (max-width: 768px) {
  .pb-11-sp {
    padding-bottom: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-11-pc {
    padding-bottom: 11px !important;
  }
}
.pl-11 {
  padding-left: 11px !important;
}

@media screen and (max-width: 768px) {
  .pl-11-sp {
    padding-left: 11px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-11-pc {
    padding-left: 11px !important;
  }
}
.pt-12 {
  padding-top: 12px;
}

@media screen and (max-width: 768px) {
  .pt-12-sp {
    padding-top: 12px;
  }
}
@media screen and (min-width: 769px) {
  .pt-12-pc {
    padding-top: 12px;
  }
}
.pr-12 {
  padding-right: 12px !important;
}

@media screen and (max-width: 768px) {
  .pr-12-sp {
    padding-right: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-12-pc {
    padding-right: 12px !important;
  }
}
.pb-12 {
  padding-bottom: 12px !important;
}

@media screen and (max-width: 768px) {
  .pb-12-sp {
    padding-bottom: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-12-pc {
    padding-bottom: 12px !important;
  }
}
.pl-12 {
  padding-left: 12px !important;
}

@media screen and (max-width: 768px) {
  .pl-12-sp {
    padding-left: 12px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-12-pc {
    padding-left: 12px !important;
  }
}
.pt-13 {
  padding-top: 13px;
}

@media screen and (max-width: 768px) {
  .pt-13-sp {
    padding-top: 13px;
  }
}
@media screen and (min-width: 769px) {
  .pt-13-pc {
    padding-top: 13px;
  }
}
.pr-13 {
  padding-right: 13px !important;
}

@media screen and (max-width: 768px) {
  .pr-13-sp {
    padding-right: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-13-pc {
    padding-right: 13px !important;
  }
}
.pb-13 {
  padding-bottom: 13px !important;
}

@media screen and (max-width: 768px) {
  .pb-13-sp {
    padding-bottom: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-13-pc {
    padding-bottom: 13px !important;
  }
}
.pl-13 {
  padding-left: 13px !important;
}

@media screen and (max-width: 768px) {
  .pl-13-sp {
    padding-left: 13px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-13-pc {
    padding-left: 13px !important;
  }
}
.pt-14 {
  padding-top: 14px;
}

@media screen and (max-width: 768px) {
  .pt-14-sp {
    padding-top: 14px;
  }
}
@media screen and (min-width: 769px) {
  .pt-14-pc {
    padding-top: 14px;
  }
}
.pr-14 {
  padding-right: 14px !important;
}

@media screen and (max-width: 768px) {
  .pr-14-sp {
    padding-right: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-14-pc {
    padding-right: 14px !important;
  }
}
.pb-14 {
  padding-bottom: 14px !important;
}

@media screen and (max-width: 768px) {
  .pb-14-sp {
    padding-bottom: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-14-pc {
    padding-bottom: 14px !important;
  }
}
.pl-14 {
  padding-left: 14px !important;
}

@media screen and (max-width: 768px) {
  .pl-14-sp {
    padding-left: 14px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-14-pc {
    padding-left: 14px !important;
  }
}
.pt-15 {
  padding-top: 15px;
}

@media screen and (max-width: 768px) {
  .pt-15-sp {
    padding-top: 15px;
  }
}
@media screen and (min-width: 769px) {
  .pt-15-pc {
    padding-top: 15px;
  }
}
.pr-15 {
  padding-right: 15px !important;
}

@media screen and (max-width: 768px) {
  .pr-15-sp {
    padding-right: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-15-pc {
    padding-right: 15px !important;
  }
}
.pb-15 {
  padding-bottom: 15px !important;
}

@media screen and (max-width: 768px) {
  .pb-15-sp {
    padding-bottom: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-15-pc {
    padding-bottom: 15px !important;
  }
}
.pl-15 {
  padding-left: 15px !important;
}

@media screen and (max-width: 768px) {
  .pl-15-sp {
    padding-left: 15px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-15-pc {
    padding-left: 15px !important;
  }
}
.pt-16 {
  padding-top: 16px;
}

@media screen and (max-width: 768px) {
  .pt-16-sp {
    padding-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .pt-16-pc {
    padding-top: 16px;
  }
}
.pr-16 {
  padding-right: 16px !important;
}

@media screen and (max-width: 768px) {
  .pr-16-sp {
    padding-right: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-16-pc {
    padding-right: 16px !important;
  }
}
.pb-16 {
  padding-bottom: 16px !important;
}

@media screen and (max-width: 768px) {
  .pb-16-sp {
    padding-bottom: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-16-pc {
    padding-bottom: 16px !important;
  }
}
.pl-16 {
  padding-left: 16px !important;
}

@media screen and (max-width: 768px) {
  .pl-16-sp {
    padding-left: 16px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-16-pc {
    padding-left: 16px !important;
  }
}
.pt-17 {
  padding-top: 17px;
}

@media screen and (max-width: 768px) {
  .pt-17-sp {
    padding-top: 17px;
  }
}
@media screen and (min-width: 769px) {
  .pt-17-pc {
    padding-top: 17px;
  }
}
.pr-17 {
  padding-right: 17px !important;
}

@media screen and (max-width: 768px) {
  .pr-17-sp {
    padding-right: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-17-pc {
    padding-right: 17px !important;
  }
}
.pb-17 {
  padding-bottom: 17px !important;
}

@media screen and (max-width: 768px) {
  .pb-17-sp {
    padding-bottom: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-17-pc {
    padding-bottom: 17px !important;
  }
}
.pl-17 {
  padding-left: 17px !important;
}

@media screen and (max-width: 768px) {
  .pl-17-sp {
    padding-left: 17px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-17-pc {
    padding-left: 17px !important;
  }
}
.pt-18 {
  padding-top: 18px;
}

@media screen and (max-width: 768px) {
  .pt-18-sp {
    padding-top: 18px;
  }
}
@media screen and (min-width: 769px) {
  .pt-18-pc {
    padding-top: 18px;
  }
}
.pr-18 {
  padding-right: 18px !important;
}

@media screen and (max-width: 768px) {
  .pr-18-sp {
    padding-right: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-18-pc {
    padding-right: 18px !important;
  }
}
.pb-18 {
  padding-bottom: 18px !important;
}

@media screen and (max-width: 768px) {
  .pb-18-sp {
    padding-bottom: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-18-pc {
    padding-bottom: 18px !important;
  }
}
.pl-18 {
  padding-left: 18px !important;
}

@media screen and (max-width: 768px) {
  .pl-18-sp {
    padding-left: 18px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-18-pc {
    padding-left: 18px !important;
  }
}
.pt-19 {
  padding-top: 19px;
}

@media screen and (max-width: 768px) {
  .pt-19-sp {
    padding-top: 19px;
  }
}
@media screen and (min-width: 769px) {
  .pt-19-pc {
    padding-top: 19px;
  }
}
.pr-19 {
  padding-right: 19px !important;
}

@media screen and (max-width: 768px) {
  .pr-19-sp {
    padding-right: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-19-pc {
    padding-right: 19px !important;
  }
}
.pb-19 {
  padding-bottom: 19px !important;
}

@media screen and (max-width: 768px) {
  .pb-19-sp {
    padding-bottom: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-19-pc {
    padding-bottom: 19px !important;
  }
}
.pl-19 {
  padding-left: 19px !important;
}

@media screen and (max-width: 768px) {
  .pl-19-sp {
    padding-left: 19px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-19-pc {
    padding-left: 19px !important;
  }
}
.pt-20 {
  padding-top: 20px;
}

@media screen and (max-width: 768px) {
  .pt-20-sp {
    padding-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .pt-20-pc {
    padding-top: 20px;
  }
}
.pr-20 {
  padding-right: 20px !important;
}

@media screen and (max-width: 768px) {
  .pr-20-sp {
    padding-right: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-20-pc {
    padding-right: 20px !important;
  }
}
.pb-20 {
  padding-bottom: 20px !important;
}

@media screen and (max-width: 768px) {
  .pb-20-sp {
    padding-bottom: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-20-pc {
    padding-bottom: 20px !important;
  }
}
.pl-20 {
  padding-left: 20px !important;
}

@media screen and (max-width: 768px) {
  .pl-20-sp {
    padding-left: 20px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-20-pc {
    padding-left: 20px !important;
  }
}
.pt-21 {
  padding-top: 21px;
}

@media screen and (max-width: 768px) {
  .pt-21-sp {
    padding-top: 21px;
  }
}
@media screen and (min-width: 769px) {
  .pt-21-pc {
    padding-top: 21px;
  }
}
.pr-21 {
  padding-right: 21px !important;
}

@media screen and (max-width: 768px) {
  .pr-21-sp {
    padding-right: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-21-pc {
    padding-right: 21px !important;
  }
}
.pb-21 {
  padding-bottom: 21px !important;
}

@media screen and (max-width: 768px) {
  .pb-21-sp {
    padding-bottom: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-21-pc {
    padding-bottom: 21px !important;
  }
}
.pl-21 {
  padding-left: 21px !important;
}

@media screen and (max-width: 768px) {
  .pl-21-sp {
    padding-left: 21px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-21-pc {
    padding-left: 21px !important;
  }
}
.pt-22 {
  padding-top: 22px;
}

@media screen and (max-width: 768px) {
  .pt-22-sp {
    padding-top: 22px;
  }
}
@media screen and (min-width: 769px) {
  .pt-22-pc {
    padding-top: 22px;
  }
}
.pr-22 {
  padding-right: 22px !important;
}

@media screen and (max-width: 768px) {
  .pr-22-sp {
    padding-right: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-22-pc {
    padding-right: 22px !important;
  }
}
.pb-22 {
  padding-bottom: 22px !important;
}

@media screen and (max-width: 768px) {
  .pb-22-sp {
    padding-bottom: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-22-pc {
    padding-bottom: 22px !important;
  }
}
.pl-22 {
  padding-left: 22px !important;
}

@media screen and (max-width: 768px) {
  .pl-22-sp {
    padding-left: 22px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-22-pc {
    padding-left: 22px !important;
  }
}
.pt-23 {
  padding-top: 23px;
}

@media screen and (max-width: 768px) {
  .pt-23-sp {
    padding-top: 23px;
  }
}
@media screen and (min-width: 769px) {
  .pt-23-pc {
    padding-top: 23px;
  }
}
.pr-23 {
  padding-right: 23px !important;
}

@media screen and (max-width: 768px) {
  .pr-23-sp {
    padding-right: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-23-pc {
    padding-right: 23px !important;
  }
}
.pb-23 {
  padding-bottom: 23px !important;
}

@media screen and (max-width: 768px) {
  .pb-23-sp {
    padding-bottom: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-23-pc {
    padding-bottom: 23px !important;
  }
}
.pl-23 {
  padding-left: 23px !important;
}

@media screen and (max-width: 768px) {
  .pl-23-sp {
    padding-left: 23px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-23-pc {
    padding-left: 23px !important;
  }
}
.pt-24 {
  padding-top: 24px;
}

@media screen and (max-width: 768px) {
  .pt-24-sp {
    padding-top: 24px;
  }
}
@media screen and (min-width: 769px) {
  .pt-24-pc {
    padding-top: 24px;
  }
}
.pr-24 {
  padding-right: 24px !important;
}

@media screen and (max-width: 768px) {
  .pr-24-sp {
    padding-right: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-24-pc {
    padding-right: 24px !important;
  }
}
.pb-24 {
  padding-bottom: 24px !important;
}

@media screen and (max-width: 768px) {
  .pb-24-sp {
    padding-bottom: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-24-pc {
    padding-bottom: 24px !important;
  }
}
.pl-24 {
  padding-left: 24px !important;
}

@media screen and (max-width: 768px) {
  .pl-24-sp {
    padding-left: 24px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-24-pc {
    padding-left: 24px !important;
  }
}
.pt-25 {
  padding-top: 25px;
}

@media screen and (max-width: 768px) {
  .pt-25-sp {
    padding-top: 25px;
  }
}
@media screen and (min-width: 769px) {
  .pt-25-pc {
    padding-top: 25px;
  }
}
.pr-25 {
  padding-right: 25px !important;
}

@media screen and (max-width: 768px) {
  .pr-25-sp {
    padding-right: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-25-pc {
    padding-right: 25px !important;
  }
}
.pb-25 {
  padding-bottom: 25px !important;
}

@media screen and (max-width: 768px) {
  .pb-25-sp {
    padding-bottom: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-25-pc {
    padding-bottom: 25px !important;
  }
}
.pl-25 {
  padding-left: 25px !important;
}

@media screen and (max-width: 768px) {
  .pl-25-sp {
    padding-left: 25px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-25-pc {
    padding-left: 25px !important;
  }
}
.pt-26 {
  padding-top: 26px;
}

@media screen and (max-width: 768px) {
  .pt-26-sp {
    padding-top: 26px;
  }
}
@media screen and (min-width: 769px) {
  .pt-26-pc {
    padding-top: 26px;
  }
}
.pr-26 {
  padding-right: 26px !important;
}

@media screen and (max-width: 768px) {
  .pr-26-sp {
    padding-right: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-26-pc {
    padding-right: 26px !important;
  }
}
.pb-26 {
  padding-bottom: 26px !important;
}

@media screen and (max-width: 768px) {
  .pb-26-sp {
    padding-bottom: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-26-pc {
    padding-bottom: 26px !important;
  }
}
.pl-26 {
  padding-left: 26px !important;
}

@media screen and (max-width: 768px) {
  .pl-26-sp {
    padding-left: 26px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-26-pc {
    padding-left: 26px !important;
  }
}
.pt-27 {
  padding-top: 27px;
}

@media screen and (max-width: 768px) {
  .pt-27-sp {
    padding-top: 27px;
  }
}
@media screen and (min-width: 769px) {
  .pt-27-pc {
    padding-top: 27px;
  }
}
.pr-27 {
  padding-right: 27px !important;
}

@media screen and (max-width: 768px) {
  .pr-27-sp {
    padding-right: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-27-pc {
    padding-right: 27px !important;
  }
}
.pb-27 {
  padding-bottom: 27px !important;
}

@media screen and (max-width: 768px) {
  .pb-27-sp {
    padding-bottom: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-27-pc {
    padding-bottom: 27px !important;
  }
}
.pl-27 {
  padding-left: 27px !important;
}

@media screen and (max-width: 768px) {
  .pl-27-sp {
    padding-left: 27px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-27-pc {
    padding-left: 27px !important;
  }
}
.pt-28 {
  padding-top: 28px;
}

@media screen and (max-width: 768px) {
  .pt-28-sp {
    padding-top: 28px;
  }
}
@media screen and (min-width: 769px) {
  .pt-28-pc {
    padding-top: 28px;
  }
}
.pr-28 {
  padding-right: 28px !important;
}

@media screen and (max-width: 768px) {
  .pr-28-sp {
    padding-right: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-28-pc {
    padding-right: 28px !important;
  }
}
.pb-28 {
  padding-bottom: 28px !important;
}

@media screen and (max-width: 768px) {
  .pb-28-sp {
    padding-bottom: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-28-pc {
    padding-bottom: 28px !important;
  }
}
.pl-28 {
  padding-left: 28px !important;
}

@media screen and (max-width: 768px) {
  .pl-28-sp {
    padding-left: 28px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-28-pc {
    padding-left: 28px !important;
  }
}
.pt-29 {
  padding-top: 29px;
}

@media screen and (max-width: 768px) {
  .pt-29-sp {
    padding-top: 29px;
  }
}
@media screen and (min-width: 769px) {
  .pt-29-pc {
    padding-top: 29px;
  }
}
.pr-29 {
  padding-right: 29px !important;
}

@media screen and (max-width: 768px) {
  .pr-29-sp {
    padding-right: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-29-pc {
    padding-right: 29px !important;
  }
}
.pb-29 {
  padding-bottom: 29px !important;
}

@media screen and (max-width: 768px) {
  .pb-29-sp {
    padding-bottom: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-29-pc {
    padding-bottom: 29px !important;
  }
}
.pl-29 {
  padding-left: 29px !important;
}

@media screen and (max-width: 768px) {
  .pl-29-sp {
    padding-left: 29px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-29-pc {
    padding-left: 29px !important;
  }
}
.pt-30 {
  padding-top: 30px;
}

@media screen and (max-width: 768px) {
  .pt-30-sp {
    padding-top: 30px;
  }
}
@media screen and (min-width: 769px) {
  .pt-30-pc {
    padding-top: 30px;
  }
}
.pr-30 {
  padding-right: 30px !important;
}

@media screen and (max-width: 768px) {
  .pr-30-sp {
    padding-right: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-30-pc {
    padding-right: 30px !important;
  }
}
.pb-30 {
  padding-bottom: 30px !important;
}

@media screen and (max-width: 768px) {
  .pb-30-sp {
    padding-bottom: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-30-pc {
    padding-bottom: 30px !important;
  }
}
.pl-30 {
  padding-left: 30px !important;
}

@media screen and (max-width: 768px) {
  .pl-30-sp {
    padding-left: 30px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-30-pc {
    padding-left: 30px !important;
  }
}
.pt-31 {
  padding-top: 31px;
}

@media screen and (max-width: 768px) {
  .pt-31-sp {
    padding-top: 31px;
  }
}
@media screen and (min-width: 769px) {
  .pt-31-pc {
    padding-top: 31px;
  }
}
.pr-31 {
  padding-right: 31px !important;
}

@media screen and (max-width: 768px) {
  .pr-31-sp {
    padding-right: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-31-pc {
    padding-right: 31px !important;
  }
}
.pb-31 {
  padding-bottom: 31px !important;
}

@media screen and (max-width: 768px) {
  .pb-31-sp {
    padding-bottom: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-31-pc {
    padding-bottom: 31px !important;
  }
}
.pl-31 {
  padding-left: 31px !important;
}

@media screen and (max-width: 768px) {
  .pl-31-sp {
    padding-left: 31px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-31-pc {
    padding-left: 31px !important;
  }
}
.pt-32 {
  padding-top: 32px;
}

@media screen and (max-width: 768px) {
  .pt-32-sp {
    padding-top: 32px;
  }
}
@media screen and (min-width: 769px) {
  .pt-32-pc {
    padding-top: 32px;
  }
}
.pr-32 {
  padding-right: 32px !important;
}

@media screen and (max-width: 768px) {
  .pr-32-sp {
    padding-right: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-32-pc {
    padding-right: 32px !important;
  }
}
.pb-32 {
  padding-bottom: 32px !important;
}

@media screen and (max-width: 768px) {
  .pb-32-sp {
    padding-bottom: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-32-pc {
    padding-bottom: 32px !important;
  }
}
.pl-32 {
  padding-left: 32px !important;
}

@media screen and (max-width: 768px) {
  .pl-32-sp {
    padding-left: 32px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-32-pc {
    padding-left: 32px !important;
  }
}
.pt-33 {
  padding-top: 33px;
}

@media screen and (max-width: 768px) {
  .pt-33-sp {
    padding-top: 33px;
  }
}
@media screen and (min-width: 769px) {
  .pt-33-pc {
    padding-top: 33px;
  }
}
.pr-33 {
  padding-right: 33px !important;
}

@media screen and (max-width: 768px) {
  .pr-33-sp {
    padding-right: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-33-pc {
    padding-right: 33px !important;
  }
}
.pb-33 {
  padding-bottom: 33px !important;
}

@media screen and (max-width: 768px) {
  .pb-33-sp {
    padding-bottom: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-33-pc {
    padding-bottom: 33px !important;
  }
}
.pl-33 {
  padding-left: 33px !important;
}

@media screen and (max-width: 768px) {
  .pl-33-sp {
    padding-left: 33px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-33-pc {
    padding-left: 33px !important;
  }
}
.pt-34 {
  padding-top: 34px;
}

@media screen and (max-width: 768px) {
  .pt-34-sp {
    padding-top: 34px;
  }
}
@media screen and (min-width: 769px) {
  .pt-34-pc {
    padding-top: 34px;
  }
}
.pr-34 {
  padding-right: 34px !important;
}

@media screen and (max-width: 768px) {
  .pr-34-sp {
    padding-right: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-34-pc {
    padding-right: 34px !important;
  }
}
.pb-34 {
  padding-bottom: 34px !important;
}

@media screen and (max-width: 768px) {
  .pb-34-sp {
    padding-bottom: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-34-pc {
    padding-bottom: 34px !important;
  }
}
.pl-34 {
  padding-left: 34px !important;
}

@media screen and (max-width: 768px) {
  .pl-34-sp {
    padding-left: 34px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-34-pc {
    padding-left: 34px !important;
  }
}
.pt-35 {
  padding-top: 35px;
}

@media screen and (max-width: 768px) {
  .pt-35-sp {
    padding-top: 35px;
  }
}
@media screen and (min-width: 769px) {
  .pt-35-pc {
    padding-top: 35px;
  }
}
.pr-35 {
  padding-right: 35px !important;
}

@media screen and (max-width: 768px) {
  .pr-35-sp {
    padding-right: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-35-pc {
    padding-right: 35px !important;
  }
}
.pb-35 {
  padding-bottom: 35px !important;
}

@media screen and (max-width: 768px) {
  .pb-35-sp {
    padding-bottom: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-35-pc {
    padding-bottom: 35px !important;
  }
}
.pl-35 {
  padding-left: 35px !important;
}

@media screen and (max-width: 768px) {
  .pl-35-sp {
    padding-left: 35px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-35-pc {
    padding-left: 35px !important;
  }
}
.pt-36 {
  padding-top: 36px;
}

@media screen and (max-width: 768px) {
  .pt-36-sp {
    padding-top: 36px;
  }
}
@media screen and (min-width: 769px) {
  .pt-36-pc {
    padding-top: 36px;
  }
}
.pr-36 {
  padding-right: 36px !important;
}

@media screen and (max-width: 768px) {
  .pr-36-sp {
    padding-right: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-36-pc {
    padding-right: 36px !important;
  }
}
.pb-36 {
  padding-bottom: 36px !important;
}

@media screen and (max-width: 768px) {
  .pb-36-sp {
    padding-bottom: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-36-pc {
    padding-bottom: 36px !important;
  }
}
.pl-36 {
  padding-left: 36px !important;
}

@media screen and (max-width: 768px) {
  .pl-36-sp {
    padding-left: 36px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-36-pc {
    padding-left: 36px !important;
  }
}
.pt-37 {
  padding-top: 37px;
}

@media screen and (max-width: 768px) {
  .pt-37-sp {
    padding-top: 37px;
  }
}
@media screen and (min-width: 769px) {
  .pt-37-pc {
    padding-top: 37px;
  }
}
.pr-37 {
  padding-right: 37px !important;
}

@media screen and (max-width: 768px) {
  .pr-37-sp {
    padding-right: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-37-pc {
    padding-right: 37px !important;
  }
}
.pb-37 {
  padding-bottom: 37px !important;
}

@media screen and (max-width: 768px) {
  .pb-37-sp {
    padding-bottom: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-37-pc {
    padding-bottom: 37px !important;
  }
}
.pl-37 {
  padding-left: 37px !important;
}

@media screen and (max-width: 768px) {
  .pl-37-sp {
    padding-left: 37px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-37-pc {
    padding-left: 37px !important;
  }
}
.pt-38 {
  padding-top: 38px;
}

@media screen and (max-width: 768px) {
  .pt-38-sp {
    padding-top: 38px;
  }
}
@media screen and (min-width: 769px) {
  .pt-38-pc {
    padding-top: 38px;
  }
}
.pr-38 {
  padding-right: 38px !important;
}

@media screen and (max-width: 768px) {
  .pr-38-sp {
    padding-right: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-38-pc {
    padding-right: 38px !important;
  }
}
.pb-38 {
  padding-bottom: 38px !important;
}

@media screen and (max-width: 768px) {
  .pb-38-sp {
    padding-bottom: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-38-pc {
    padding-bottom: 38px !important;
  }
}
.pl-38 {
  padding-left: 38px !important;
}

@media screen and (max-width: 768px) {
  .pl-38-sp {
    padding-left: 38px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-38-pc {
    padding-left: 38px !important;
  }
}
.pt-39 {
  padding-top: 39px;
}

@media screen and (max-width: 768px) {
  .pt-39-sp {
    padding-top: 39px;
  }
}
@media screen and (min-width: 769px) {
  .pt-39-pc {
    padding-top: 39px;
  }
}
.pr-39 {
  padding-right: 39px !important;
}

@media screen and (max-width: 768px) {
  .pr-39-sp {
    padding-right: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-39-pc {
    padding-right: 39px !important;
  }
}
.pb-39 {
  padding-bottom: 39px !important;
}

@media screen and (max-width: 768px) {
  .pb-39-sp {
    padding-bottom: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-39-pc {
    padding-bottom: 39px !important;
  }
}
.pl-39 {
  padding-left: 39px !important;
}

@media screen and (max-width: 768px) {
  .pl-39-sp {
    padding-left: 39px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-39-pc {
    padding-left: 39px !important;
  }
}
.pt-40 {
  padding-top: 40px;
}

@media screen and (max-width: 768px) {
  .pt-40-sp {
    padding-top: 40px;
  }
}
@media screen and (min-width: 769px) {
  .pt-40-pc {
    padding-top: 40px;
  }
}
.pr-40 {
  padding-right: 40px !important;
}

@media screen and (max-width: 768px) {
  .pr-40-sp {
    padding-right: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-40-pc {
    padding-right: 40px !important;
  }
}
.pb-40 {
  padding-bottom: 40px !important;
}

@media screen and (max-width: 768px) {
  .pb-40-sp {
    padding-bottom: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-40-pc {
    padding-bottom: 40px !important;
  }
}
.pl-40 {
  padding-left: 40px !important;
}

@media screen and (max-width: 768px) {
  .pl-40-sp {
    padding-left: 40px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-40-pc {
    padding-left: 40px !important;
  }
}
.pt-41 {
  padding-top: 41px;
}

@media screen and (max-width: 768px) {
  .pt-41-sp {
    padding-top: 41px;
  }
}
@media screen and (min-width: 769px) {
  .pt-41-pc {
    padding-top: 41px;
  }
}
.pr-41 {
  padding-right: 41px !important;
}

@media screen and (max-width: 768px) {
  .pr-41-sp {
    padding-right: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-41-pc {
    padding-right: 41px !important;
  }
}
.pb-41 {
  padding-bottom: 41px !important;
}

@media screen and (max-width: 768px) {
  .pb-41-sp {
    padding-bottom: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-41-pc {
    padding-bottom: 41px !important;
  }
}
.pl-41 {
  padding-left: 41px !important;
}

@media screen and (max-width: 768px) {
  .pl-41-sp {
    padding-left: 41px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-41-pc {
    padding-left: 41px !important;
  }
}
.pt-42 {
  padding-top: 42px;
}

@media screen and (max-width: 768px) {
  .pt-42-sp {
    padding-top: 42px;
  }
}
@media screen and (min-width: 769px) {
  .pt-42-pc {
    padding-top: 42px;
  }
}
.pr-42 {
  padding-right: 42px !important;
}

@media screen and (max-width: 768px) {
  .pr-42-sp {
    padding-right: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-42-pc {
    padding-right: 42px !important;
  }
}
.pb-42 {
  padding-bottom: 42px !important;
}

@media screen and (max-width: 768px) {
  .pb-42-sp {
    padding-bottom: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-42-pc {
    padding-bottom: 42px !important;
  }
}
.pl-42 {
  padding-left: 42px !important;
}

@media screen and (max-width: 768px) {
  .pl-42-sp {
    padding-left: 42px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-42-pc {
    padding-left: 42px !important;
  }
}
.pt-43 {
  padding-top: 43px;
}

@media screen and (max-width: 768px) {
  .pt-43-sp {
    padding-top: 43px;
  }
}
@media screen and (min-width: 769px) {
  .pt-43-pc {
    padding-top: 43px;
  }
}
.pr-43 {
  padding-right: 43px !important;
}

@media screen and (max-width: 768px) {
  .pr-43-sp {
    padding-right: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-43-pc {
    padding-right: 43px !important;
  }
}
.pb-43 {
  padding-bottom: 43px !important;
}

@media screen and (max-width: 768px) {
  .pb-43-sp {
    padding-bottom: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-43-pc {
    padding-bottom: 43px !important;
  }
}
.pl-43 {
  padding-left: 43px !important;
}

@media screen and (max-width: 768px) {
  .pl-43-sp {
    padding-left: 43px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-43-pc {
    padding-left: 43px !important;
  }
}
.pt-44 {
  padding-top: 44px;
}

@media screen and (max-width: 768px) {
  .pt-44-sp {
    padding-top: 44px;
  }
}
@media screen and (min-width: 769px) {
  .pt-44-pc {
    padding-top: 44px;
  }
}
.pr-44 {
  padding-right: 44px !important;
}

@media screen and (max-width: 768px) {
  .pr-44-sp {
    padding-right: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-44-pc {
    padding-right: 44px !important;
  }
}
.pb-44 {
  padding-bottom: 44px !important;
}

@media screen and (max-width: 768px) {
  .pb-44-sp {
    padding-bottom: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-44-pc {
    padding-bottom: 44px !important;
  }
}
.pl-44 {
  padding-left: 44px !important;
}

@media screen and (max-width: 768px) {
  .pl-44-sp {
    padding-left: 44px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-44-pc {
    padding-left: 44px !important;
  }
}
.pt-45 {
  padding-top: 45px;
}

@media screen and (max-width: 768px) {
  .pt-45-sp {
    padding-top: 45px;
  }
}
@media screen and (min-width: 769px) {
  .pt-45-pc {
    padding-top: 45px;
  }
}
.pr-45 {
  padding-right: 45px !important;
}

@media screen and (max-width: 768px) {
  .pr-45-sp {
    padding-right: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-45-pc {
    padding-right: 45px !important;
  }
}
.pb-45 {
  padding-bottom: 45px !important;
}

@media screen and (max-width: 768px) {
  .pb-45-sp {
    padding-bottom: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-45-pc {
    padding-bottom: 45px !important;
  }
}
.pl-45 {
  padding-left: 45px !important;
}

@media screen and (max-width: 768px) {
  .pl-45-sp {
    padding-left: 45px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-45-pc {
    padding-left: 45px !important;
  }
}
.pt-46 {
  padding-top: 46px;
}

@media screen and (max-width: 768px) {
  .pt-46-sp {
    padding-top: 46px;
  }
}
@media screen and (min-width: 769px) {
  .pt-46-pc {
    padding-top: 46px;
  }
}
.pr-46 {
  padding-right: 46px !important;
}

@media screen and (max-width: 768px) {
  .pr-46-sp {
    padding-right: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-46-pc {
    padding-right: 46px !important;
  }
}
.pb-46 {
  padding-bottom: 46px !important;
}

@media screen and (max-width: 768px) {
  .pb-46-sp {
    padding-bottom: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-46-pc {
    padding-bottom: 46px !important;
  }
}
.pl-46 {
  padding-left: 46px !important;
}

@media screen and (max-width: 768px) {
  .pl-46-sp {
    padding-left: 46px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-46-pc {
    padding-left: 46px !important;
  }
}
.pt-47 {
  padding-top: 47px;
}

@media screen and (max-width: 768px) {
  .pt-47-sp {
    padding-top: 47px;
  }
}
@media screen and (min-width: 769px) {
  .pt-47-pc {
    padding-top: 47px;
  }
}
.pr-47 {
  padding-right: 47px !important;
}

@media screen and (max-width: 768px) {
  .pr-47-sp {
    padding-right: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-47-pc {
    padding-right: 47px !important;
  }
}
.pb-47 {
  padding-bottom: 47px !important;
}

@media screen and (max-width: 768px) {
  .pb-47-sp {
    padding-bottom: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-47-pc {
    padding-bottom: 47px !important;
  }
}
.pl-47 {
  padding-left: 47px !important;
}

@media screen and (max-width: 768px) {
  .pl-47-sp {
    padding-left: 47px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-47-pc {
    padding-left: 47px !important;
  }
}
.pt-48 {
  padding-top: 48px;
}

@media screen and (max-width: 768px) {
  .pt-48-sp {
    padding-top: 48px;
  }
}
@media screen and (min-width: 769px) {
  .pt-48-pc {
    padding-top: 48px;
  }
}
.pr-48 {
  padding-right: 48px !important;
}

@media screen and (max-width: 768px) {
  .pr-48-sp {
    padding-right: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-48-pc {
    padding-right: 48px !important;
  }
}
.pb-48 {
  padding-bottom: 48px !important;
}

@media screen and (max-width: 768px) {
  .pb-48-sp {
    padding-bottom: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-48-pc {
    padding-bottom: 48px !important;
  }
}
.pl-48 {
  padding-left: 48px !important;
}

@media screen and (max-width: 768px) {
  .pl-48-sp {
    padding-left: 48px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-48-pc {
    padding-left: 48px !important;
  }
}
.pt-49 {
  padding-top: 49px;
}

@media screen and (max-width: 768px) {
  .pt-49-sp {
    padding-top: 49px;
  }
}
@media screen and (min-width: 769px) {
  .pt-49-pc {
    padding-top: 49px;
  }
}
.pr-49 {
  padding-right: 49px !important;
}

@media screen and (max-width: 768px) {
  .pr-49-sp {
    padding-right: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-49-pc {
    padding-right: 49px !important;
  }
}
.pb-49 {
  padding-bottom: 49px !important;
}

@media screen and (max-width: 768px) {
  .pb-49-sp {
    padding-bottom: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-49-pc {
    padding-bottom: 49px !important;
  }
}
.pl-49 {
  padding-left: 49px !important;
}

@media screen and (max-width: 768px) {
  .pl-49-sp {
    padding-left: 49px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-49-pc {
    padding-left: 49px !important;
  }
}
.pt-50 {
  padding-top: 50px;
}

@media screen and (max-width: 768px) {
  .pt-50-sp {
    padding-top: 50px;
  }
}
@media screen and (min-width: 769px) {
  .pt-50-pc {
    padding-top: 50px;
  }
}
.pr-50 {
  padding-right: 50px !important;
}

@media screen and (max-width: 768px) {
  .pr-50-sp {
    padding-right: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-50-pc {
    padding-right: 50px !important;
  }
}
.pb-50 {
  padding-bottom: 50px !important;
}

@media screen and (max-width: 768px) {
  .pb-50-sp {
    padding-bottom: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-50-pc {
    padding-bottom: 50px !important;
  }
}
.pl-50 {
  padding-left: 50px !important;
}

@media screen and (max-width: 768px) {
  .pl-50-sp {
    padding-left: 50px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-50-pc {
    padding-left: 50px !important;
  }
}
.pt-51 {
  padding-top: 51px;
}

@media screen and (max-width: 768px) {
  .pt-51-sp {
    padding-top: 51px;
  }
}
@media screen and (min-width: 769px) {
  .pt-51-pc {
    padding-top: 51px;
  }
}
.pr-51 {
  padding-right: 51px !important;
}

@media screen and (max-width: 768px) {
  .pr-51-sp {
    padding-right: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-51-pc {
    padding-right: 51px !important;
  }
}
.pb-51 {
  padding-bottom: 51px !important;
}

@media screen and (max-width: 768px) {
  .pb-51-sp {
    padding-bottom: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-51-pc {
    padding-bottom: 51px !important;
  }
}
.pl-51 {
  padding-left: 51px !important;
}

@media screen and (max-width: 768px) {
  .pl-51-sp {
    padding-left: 51px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-51-pc {
    padding-left: 51px !important;
  }
}
.pt-52 {
  padding-top: 52px;
}

@media screen and (max-width: 768px) {
  .pt-52-sp {
    padding-top: 52px;
  }
}
@media screen and (min-width: 769px) {
  .pt-52-pc {
    padding-top: 52px;
  }
}
.pr-52 {
  padding-right: 52px !important;
}

@media screen and (max-width: 768px) {
  .pr-52-sp {
    padding-right: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-52-pc {
    padding-right: 52px !important;
  }
}
.pb-52 {
  padding-bottom: 52px !important;
}

@media screen and (max-width: 768px) {
  .pb-52-sp {
    padding-bottom: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-52-pc {
    padding-bottom: 52px !important;
  }
}
.pl-52 {
  padding-left: 52px !important;
}

@media screen and (max-width: 768px) {
  .pl-52-sp {
    padding-left: 52px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-52-pc {
    padding-left: 52px !important;
  }
}
.pt-53 {
  padding-top: 53px;
}

@media screen and (max-width: 768px) {
  .pt-53-sp {
    padding-top: 53px;
  }
}
@media screen and (min-width: 769px) {
  .pt-53-pc {
    padding-top: 53px;
  }
}
.pr-53 {
  padding-right: 53px !important;
}

@media screen and (max-width: 768px) {
  .pr-53-sp {
    padding-right: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-53-pc {
    padding-right: 53px !important;
  }
}
.pb-53 {
  padding-bottom: 53px !important;
}

@media screen and (max-width: 768px) {
  .pb-53-sp {
    padding-bottom: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-53-pc {
    padding-bottom: 53px !important;
  }
}
.pl-53 {
  padding-left: 53px !important;
}

@media screen and (max-width: 768px) {
  .pl-53-sp {
    padding-left: 53px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-53-pc {
    padding-left: 53px !important;
  }
}
.pt-54 {
  padding-top: 54px;
}

@media screen and (max-width: 768px) {
  .pt-54-sp {
    padding-top: 54px;
  }
}
@media screen and (min-width: 769px) {
  .pt-54-pc {
    padding-top: 54px;
  }
}
.pr-54 {
  padding-right: 54px !important;
}

@media screen and (max-width: 768px) {
  .pr-54-sp {
    padding-right: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-54-pc {
    padding-right: 54px !important;
  }
}
.pb-54 {
  padding-bottom: 54px !important;
}

@media screen and (max-width: 768px) {
  .pb-54-sp {
    padding-bottom: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-54-pc {
    padding-bottom: 54px !important;
  }
}
.pl-54 {
  padding-left: 54px !important;
}

@media screen and (max-width: 768px) {
  .pl-54-sp {
    padding-left: 54px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-54-pc {
    padding-left: 54px !important;
  }
}
.pt-55 {
  padding-top: 55px;
}

@media screen and (max-width: 768px) {
  .pt-55-sp {
    padding-top: 55px;
  }
}
@media screen and (min-width: 769px) {
  .pt-55-pc {
    padding-top: 55px;
  }
}
.pr-55 {
  padding-right: 55px !important;
}

@media screen and (max-width: 768px) {
  .pr-55-sp {
    padding-right: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-55-pc {
    padding-right: 55px !important;
  }
}
.pb-55 {
  padding-bottom: 55px !important;
}

@media screen and (max-width: 768px) {
  .pb-55-sp {
    padding-bottom: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-55-pc {
    padding-bottom: 55px !important;
  }
}
.pl-55 {
  padding-left: 55px !important;
}

@media screen and (max-width: 768px) {
  .pl-55-sp {
    padding-left: 55px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-55-pc {
    padding-left: 55px !important;
  }
}
.pt-56 {
  padding-top: 56px;
}

@media screen and (max-width: 768px) {
  .pt-56-sp {
    padding-top: 56px;
  }
}
@media screen and (min-width: 769px) {
  .pt-56-pc {
    padding-top: 56px;
  }
}
.pr-56 {
  padding-right: 56px !important;
}

@media screen and (max-width: 768px) {
  .pr-56-sp {
    padding-right: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-56-pc {
    padding-right: 56px !important;
  }
}
.pb-56 {
  padding-bottom: 56px !important;
}

@media screen and (max-width: 768px) {
  .pb-56-sp {
    padding-bottom: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-56-pc {
    padding-bottom: 56px !important;
  }
}
.pl-56 {
  padding-left: 56px !important;
}

@media screen and (max-width: 768px) {
  .pl-56-sp {
    padding-left: 56px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-56-pc {
    padding-left: 56px !important;
  }
}
.pt-57 {
  padding-top: 57px;
}

@media screen and (max-width: 768px) {
  .pt-57-sp {
    padding-top: 57px;
  }
}
@media screen and (min-width: 769px) {
  .pt-57-pc {
    padding-top: 57px;
  }
}
.pr-57 {
  padding-right: 57px !important;
}

@media screen and (max-width: 768px) {
  .pr-57-sp {
    padding-right: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-57-pc {
    padding-right: 57px !important;
  }
}
.pb-57 {
  padding-bottom: 57px !important;
}

@media screen and (max-width: 768px) {
  .pb-57-sp {
    padding-bottom: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-57-pc {
    padding-bottom: 57px !important;
  }
}
.pl-57 {
  padding-left: 57px !important;
}

@media screen and (max-width: 768px) {
  .pl-57-sp {
    padding-left: 57px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-57-pc {
    padding-left: 57px !important;
  }
}
.pt-58 {
  padding-top: 58px;
}

@media screen and (max-width: 768px) {
  .pt-58-sp {
    padding-top: 58px;
  }
}
@media screen and (min-width: 769px) {
  .pt-58-pc {
    padding-top: 58px;
  }
}
.pr-58 {
  padding-right: 58px !important;
}

@media screen and (max-width: 768px) {
  .pr-58-sp {
    padding-right: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-58-pc {
    padding-right: 58px !important;
  }
}
.pb-58 {
  padding-bottom: 58px !important;
}

@media screen and (max-width: 768px) {
  .pb-58-sp {
    padding-bottom: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-58-pc {
    padding-bottom: 58px !important;
  }
}
.pl-58 {
  padding-left: 58px !important;
}

@media screen and (max-width: 768px) {
  .pl-58-sp {
    padding-left: 58px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-58-pc {
    padding-left: 58px !important;
  }
}
.pt-59 {
  padding-top: 59px;
}

@media screen and (max-width: 768px) {
  .pt-59-sp {
    padding-top: 59px;
  }
}
@media screen and (min-width: 769px) {
  .pt-59-pc {
    padding-top: 59px;
  }
}
.pr-59 {
  padding-right: 59px !important;
}

@media screen and (max-width: 768px) {
  .pr-59-sp {
    padding-right: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-59-pc {
    padding-right: 59px !important;
  }
}
.pb-59 {
  padding-bottom: 59px !important;
}

@media screen and (max-width: 768px) {
  .pb-59-sp {
    padding-bottom: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-59-pc {
    padding-bottom: 59px !important;
  }
}
.pl-59 {
  padding-left: 59px !important;
}

@media screen and (max-width: 768px) {
  .pl-59-sp {
    padding-left: 59px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-59-pc {
    padding-left: 59px !important;
  }
}
.pt-60 {
  padding-top: 60px;
}

@media screen and (max-width: 768px) {
  .pt-60-sp {
    padding-top: 60px;
  }
}
@media screen and (min-width: 769px) {
  .pt-60-pc {
    padding-top: 60px;
  }
}
.pr-60 {
  padding-right: 60px !important;
}

@media screen and (max-width: 768px) {
  .pr-60-sp {
    padding-right: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .pr-60-pc {
    padding-right: 60px !important;
  }
}
.pb-60 {
  padding-bottom: 60px !important;
}

@media screen and (max-width: 768px) {
  .pb-60-sp {
    padding-bottom: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .pb-60-pc {
    padding-bottom: 60px !important;
  }
}
.pl-60 {
  padding-left: 60px !important;
}

@media screen and (max-width: 768px) {
  .pl-60-sp {
    padding-left: 60px !important;
  }
}
@media screen and (min-width: 769px) {
  .pl-60-pc {
    padding-left: 60px !important;
  }
}
/* device switch
------------------------------------------------ */
@media screen and (max-width: 768px) {
  .visible-sp {
    display: inherit !important;
  }
}
@media screen and (min-width: 769px) {
  .visible-sp {
    display: none !important;
  }
}
@media screen and (max-width: 768px) {
  .visible-pc {
    display: none !important;
  }
}
@media screen and (min-width: 769px) {
  .visible-pc {
    display: inherit !important;
  }
}

/* topicpath
------------------------------------------------ */
.topicpath {
  position: relative;
}
@media screen and (max-width: 768px) {
  .topicpath {
    margin: 0 20px 35px;
  }
}
@media screen and (min-width: 769px) {
  .topicpath {
    margin-bottom: 52px;
  }
}
.topicpath-lists {
  display: flex;
  flex-wrap: wrap;
}
.topicpath-lists-item {
  position: relative;
  font-size: 14px;
  color: #636363;
}
.topicpath-lists-item > a {
  display: inline-block;
  vertical-align: top;
  color: #0a4a90;
}
.topicpath-lists-item > a:after {
  content: ">";
  padding: 0 0.4em;
}

/* title
------------------------------------------------ */
.title--page {
  position: relative;
  margin-bottom: 5px;
  padding: 38px 0;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: #e5f0f8;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
.title--page .min {
  display: block;
  margin-top: 6px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .title--page {
    margin-bottom: 16px;
    padding: 75px 0;
    font-size: 72px;
  }
  .title--page .min {
    margin-top: 10px;
    font-size: 28px;
  }
}
.title--image {
  position: relative;
  margin: 0 auto 42px;
  padding-top: 60px;
  height: 225px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .title--image {
    margin-bottom: 80px;
    padding-top: 130px;
    max-width: 1200px;
    height: 450px;
    font-size: 60px;
  }
}
.title--ribbon {
  position: relative;
  margin-bottom: 4px;
  height: 225px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 769px) {
  .title--ribbon {
    margin-bottom: 14px;
    height: 500px;
  }
}
.title--ribbon--name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 24px;
  width: 320px;
  height: 100px;
  background: url(../img/icon/title_ribbon1.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .title--ribbon--name {
    padding-top: 55px;
    width: 712px;
    height: 220px;
    font-size: 80px;
  }
}
.title--1 {
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-weight: 700;
  line-height: 1.2;
}
.title--1--inline {
  display: inline-block;
  position: relative;
  text-align: right;
  vertical-align: top;
}
.title--1 .min,
.title--1 .normal {
  display: block;
  position: relative;
}
.title--1 .min {
  font-size: 18px;
  color: #0168b7;
}
.title--1 .min + .normal {
  margin-top: -14px;
}
.title--1 .normal {
  font-size: 36px;
}
.title--1 .normal .l {
  font-size: 48px;
}
.title--1 .normal + .min {
  margin-top: -5px;
}
@media screen and (min-width: 769px) {
  .title--1 .min {
    font-size: 30px;
  }
  .title--1 .min + .normal {
    margin-top: -28px;
  }
  .title--1 .normal {
    font-size: 60px;
  }
  .title--1 .normal .l {
    font-size: 80px;
  }
  .title--1 .normal + .min {
    margin-top: -10px;
  }
}
.title--2 {
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}
.title--2 .min {
  font-size: 26px;
}
@media screen and (min-width: 769px) {
  .title--2 {
    font-size: 60px;
  }
  .title--2 .min {
    font-size: 40px;
  }
}
.title--3 {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 10px;
  line-height: 1.2;
}
.title--3:before, .title--3:after {
  content: "";
  position: absolute;
  left: 50%;
  background: #404040;
  width: 25px;
  height: 2px;
}
.title--3:before {
  top: 0;
  transform: rotate(-45deg);
  margin-left: 25px;
}
.title--3:after {
  bottom: -18px;
  transform: rotate(-45deg);
  margin-left: -50px;
}
.title--3 .en,
.title--3 .ja {
  display: block;
}
.title--3 .en {
  font-size: 36px;
  font-weight: 700;
}
.title--3 .ja {
  margin-top: -2px;
  font-size: 14.4px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .title--3 {
    margin-bottom: 74px;
  }
  .title--3:before, .title--3:after {
    width: 40px;
  }
  .title--3:before {
    margin-left: 35px;
  }
  .title--3:after {
    bottom: -24px;
    margin-left: -90px;
  }
  .title--3 .en {
    font-size: 60px;
  }
  .title--3 .ja {
    font-size: 24px;
  }
}
.title--4 {
  position: relative;
  margin-bottom: 24px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168b7;
}
.title--4:after {
  content: "";
  display: block;
  margin: 12px auto 0;
  width: 30px;
  height: 3px;
  background: #0068b7;
}
@media screen and (min-width: 769px) {
  .title--4 {
    margin-bottom: 58px;
    font-size: 52px;
  }
  .title--4:after {
    margin-top: 24px;
    width: 60px;
    height: 6px;
  }
}
.title--5 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .title--5 {
    font-size: 52px;
  }
}
.title--5 .l,
.title--5 .min {
  display: inline-block;
  vertical-align: middle;
}
.title--5 .l {
  margin-right: 16px;
  padding: 15px 0;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .title--5 .l {
    margin-right: 25px;
    padding: 24px 0;
  }
}
.title--5 .min {
  font-size: 12px;
}
@media screen and (min-width: 769px) {
  .title--5 .min {
    font-size: 18px;
  }
}
.title--6 {
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 16px;
  border-bottom: solid #a5a5a5 1px;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .title--6 {
    margin-bottom: 80px;
    border-bottom-width: 2px;
    font-size: 36px;
  }
}
.title--6:before, .title--6:after {
  content: "";
  bottom: -1px;
  position: absolute;
}
@media screen and (min-width: 769px) {
  .title--6:before, .title--6:after {
    bottom: -2px;
    height: 2px;
  }
}
.title--6:before {
  left: 95px;
  width: 10px;
  background: #fff;
}
@media screen and (min-width: 769px) {
  .title--6:before {
    left: 280px;
    width: 20px;
  }
}
.title--6:after {
  left: 93px;
  transform-origin: right;
  transform: rotate(-45deg);
  width: 12px;
  background: #a5a5a5;
}
@media screen and (min-width: 769px) {
  .title--6:after {
    left: 277px;
    width: 24px;
  }
}
.title--7 {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .title--7 {
    margin-bottom: 26px;
    border-bottom-width: 3px;
    padding-bottom: 14px;
    font-size: 26px;
  }
}

/* more
------------------------------------------------ */
.more--1 {
  position: relative;
  margin: 56px auto 0;
  max-width: 400px;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.more--1 a {
  display: block;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  background: #404040;
  border-radius: 35px;
  color: #fff;
}
.more--1 a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 38px;
  margin-top: -10px;
  width: 11px;
  height: 20px;
  background: url(../img/icon/arrow3.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .more--1 {
    margin-top: 28px;
    max-width: 300px;
    font-size: 16px;
  }
  .more--1 a {
    padding: 16px 10px;
  }
  .more--1 a:after {
    right: 25px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}

/* btn
------------------------------------------------ */
.btn--normal {
  position: relative;
  margin: 54px auto 0;
  max-width: 420px;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.btn--normal a {
  display: block;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  background: #0168b7;
  border: solid #0168b7 1px;
  border-radius: 35px;
  color: #fff;
}
.btn--normal a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 24px;
  margin-top: -11px;
  width: 11px;
  height: 22px;
  background: url(../img/icon/arrow3.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .btn--normal {
    margin-top: 18px;
    font-size: 16px;
    max-width: 310px;
  }
  .btn--normal a {
    padding: 15px 10px;
  }
  .btn--normal a:after {
    right: 20px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}
.btn--back {
  position: relative;
  margin: 72px auto 0;
  max-width: 300px;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
}
.btn--back a {
  display: block;
  position: relative;
  padding: 14px 10px;
  background: #404040;
  border: solid #404040 1px;
  border-radius: 25px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .btn--back {
    margin-top: 34px;
    font-size: 16px;
  }
}

/* icon
------------------------------------------------ */
.icon-news {
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  vertical-align: top;
  font-size: 12px;
  font-style: normal;
}
.icon-news--news {
  background: #0168b7;
  color: #fff;
}
.icon-news--exhibition {
  background: #f6f033;
}
.icon-news--update {
  background: #404040;
  color: #fff;
}
.icon-news--mailmagazine {
  background: #000;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .icon-news {
    padding: 3px 0;
  }
}
@media screen and (min-width: 769px) {
  .icon-news {
    font-size: 16px;
  }
}
.icon-new {
  display: inline-block;
  min-width: 38px;
  height: 15px;
  background: #aa111d;
  border-radius: 8px;
  text-align: center;
  vertical-align: top;
  font-size: 10.5px;
  font-weight: 700;
  font-style: normal;
  line-height: 15px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .icon-new {
    min-width: 50px;
    height: 20px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}
.icon-form {
  display: inline-block;
  min-width: 54px;
  padding: 2px 0 3px;
  text-align: center;
  vertical-align: top;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.2;
  color: #fff;
}
.icon-form--required {
  background: #b4161f;
}
.icon-form--any {
  background: #0068b7;
}
.icon-pdf {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 20px;
  vertical-align: top;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.icon-pdf:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/icon/pdf1.png) no-repeat center center;
  background-size: 100%;
}
.icon-blank {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  vertical-align: top;
  text-indent: -9999px;
  white-space: nowrap;
}
@media screen and (min-width: 769px) {
  .icon-blank {
    width: 25px;
    height: 25px;
  }
}
.icon-blank:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/icon/blank1.png) no-repeat center center;
  background-size: 100%;
}

/* lists
------------------------------------------------ */
.lists-news--item {
  border-bottom: solid #ccc 1px;
}
.lists-news--item > a, .lists-news--item > span {
  display: flex;
  position: relative;
  padding: 20px 2px;
}
.lists-news--cat {
  width: 90px;
}
.lists-news--cat .icon-news {
  width: 100%;
}
.lists-news--date {
  margin-left: 15px;
  width: 110px;
  font-size: 18px;
  font-weight: 700;
  color: #0068b7;
}
.lists-news--title {
  width: calc(100% - 215px);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}
@media screen and (max-width: 768px) {
  .lists-news {
    margin: 0 20px;
  }
  .lists-news--item > a {
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .lists-news--cat {
    width: 65px;
  }
  .lists-news--cat .icon-news {
    width: 100%;
  }
  .lists-news--date {
    margin-left: 10px;
    width: auto;
    font-size: 15px;
  }
  .lists-news--title {
    margin-top: 15px;
    width: 100%;
    font-size: 14px;
    line-height: 1.6;
  }
}

/* style
------------------------------------------------ */
.style {
  /* tab */
  /* introduce */
  /* section */
  /* sitemap */
  /* links */
  /* search */
  /* index */
}
.style-tab {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .style-tab {
    margin: 0 20px;
  }
}
.style-tab-item {
  position: relative;
  height: 45px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .style-tab-item {
    margin: 2px 2px 0 0;
    width: calc(50% - 1px);
  }
  .style-tab-item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-tab-item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-tab-item {
    margin: 2px 2px 0 0;
    width: calc(25% - 1.5px);
    height: 90px;
    font-size: 24px;
  }
  .style-tab-item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-tab-item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.style-tab-item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  background: #fff;
  border: solid #0168b7 1px;
  color: #0168b7;
}
.style-tab-item > a.active {
  background: #0168b7;
  color: #fff;
}
.style-tab-item > a.active:after {
  background-image: url(../img/icon/arrow3.png);
}
@media screen and (min-width: 769px) {
  .style-tab-item > a {
    padding: 35px 30px;
  }
}
.style-tab-item > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -5px;
  width: 5px;
  height: 10px;
  background: url(../img/icon/arrow4.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .style-tab-item > a:after {
    right: 20px;
    margin-top: -9px;
    width: 9px;
    height: 19px;
  }
}
.style-tab-item > a .min {
  display: block;
  font-size: 11px;
}
@media screen and (min-width: 769px) {
  .style-tab-item > a .min {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .style-introduce {
    padding: 10px 20px 30px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce {
    display: flex;
    justify-content: space-between;
    padding: 60px 20px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce-info {
    width: 45%;
  }
}
.style-introduce .title--5 {
  margin-bottom: 8px;
}
@media screen and (min-width: 769px) {
  .style-introduce .title--5 {
    margin-bottom: 25px;
  }
}
.style-introduce-text {
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (min-width: 769px) {
  .style-introduce-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .style-introduce-photo {
    margin-top: 26px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce-photo {
    width: 43.3333333333%;
  }
}
.style-section {
  position: relative;
  margin-top: 1px;
  padding: 30px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .style-section {
    margin-top: 2px;
    padding: 70px 0 112px;
  }
}
.style-section-block {
  margin-top: 24px;
}
.style-section-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-block {
    margin-top: 50px;
  }
}
.style-section-title {
  margin-bottom: 16px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-section-title {
    margin-bottom: 34px;
    font-size: 42px;
  }
}
.style-section-lists--item {
  position: relative;
  margin-top: 10px;
  padding: 15px 20px 20px;
  background: #fff;
}
.style-section-lists--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-lists--item {
    margin-top: 20px;
    padding: 28px 65px;
  }
}
.style-section-lists--title {
  padding-bottom: 8px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-section-lists--title {
    padding-bottom: 18px;
    font-size: 28px;
  }
}
.style-section-lists__content {
  margin-top: 18px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content {
    padding-top: 5px;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__content {
    margin-top: 32px;
    font-size: 15px;
  }
}
.style-section-lists__content > * + * {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content > * + * {
    margin-top: 1.5em;
  }
}
.style-section-lists__content .image-center {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  text-align: center;
}
.style-section-lists__content .image-lists {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.style-section-lists__content .image-lists:first-child {
  margin-top: 0;
}
.style-section-lists__content .image-lists-item {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content .image-lists-item {
    margin-top: 20px;
    font-size: 10px;
  }
  .style-section-lists__content .image-lists-item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists-item {
    margin: 40px 2% 0;
    width: 28%;
    font-size: 12px;
  }
  .style-section-lists__content .image-lists-item:nth-of-type(-n+3) {
    margin-top: 0;
  }
}
.style-section-lists__content .image-lists-item figure {
  display: block;
  position: relative;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content .image-lists-item figure img {
    width: 50%;
  }
}
.style-section-lists__content .image-lists-item figcaption {
  display: block;
  text-align: center;
  margin-top: 10px;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists-item figcaption {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .style-section-lists__titles {
    margin-top: 14px;
  }
  .style-section-lists__titles:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__titles {
    display: flex;
    flex-wrap: wrap;
  }
}
.style-section-lists__titles--item {
  font-size: 14px;
  line-height: 1.2;
}
.style-section-lists__titles--item > a {
  display: inline-block;
  position: relative;
  text-decoration: underline;
  vertical-align: top;
  color: #0168b7;
}
.device--click .style-section-lists__titles--item > a:hover {
  text-decoration: none;
}
.style-section-lists__titles--item > a.link-icon {
  padding-left: 26px;
}
.style-section-lists__titles--item > a.link-icon i {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .style-section-lists__titles--item {
    margin-top: 14px;
  }
  .style-section-lists__titles--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__titles--item {
    margin: 18px 60px 0 0;
    width: calc(25% - 45px);
    font-size: 15px;
  }
  .style-section-lists__titles--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-section-lists__titles--item:nth-of-type(4n) {
    margin-right: 0;
  }
  .style-section-lists__titles--item.streight {
    margin-right: 0;
    width: 100%;
  }
}
.style-section-lists__links--item {
  margin-top: 18px;
  font-size: 14px;
}
.style-section-lists__links--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-lists__links--item {
    margin-top: 28px;
    font-size: 15px;
  }
}
.style-section-lists__links--item a,
.style-section-lists__links--item span {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-section-lists__links--item a,
.style-section-lists__links--item span {
    padding-left: 32px;
  }
}
.style-section-lists__links--item a:before,
.style-section-lists__links--item span:before {
  content: "▶︎";
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  color: #0168b7;
}
.style-section-lists__links--item a {
  text-decoration: underline;
  color: #0168b7;
}
.device--click .style-section-lists__links--item a:hover {
  text-decoration: none;
}
.style-section-lists__links--item .no-arrow {
  padding-left: 0;
}
.style-section-lists__links--item .no-arrow:before {
  display: none;
}
.style-section-lists__links--item .icon-pdf {
  margin-right: 10px;
}
.style-section-lists__links--item .icon-new {
  margin-left: 10px;
}
.style-sitemap__box {
  margin-top: 42px;
}
.style-sitemap__box:first-child {
  margin-top: 0;
}
.style-sitemap--title {
  position: relative;
  padding-bottom: 18px;
  border-bottom: solid #a5a5a5 2px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
.style-sitemap--title:before, .style-sitemap--title:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 60px;
  width: 10px;
  height: 2px;
}
.style-sitemap--title:before {
  background: #f8fbfd;
}
.style-sitemap--title:after {
  transform-origin: right top;
  transform: rotate(-45deg);
  background: #a5a5a5;
}
.style-sitemap--title a {
  display: block;
  position: relative;
}
.style-sitemap--title span {
  font-size: 14px;
}
.style-sitemap__content {
  margin-top: 32px;
}
.style-sitemap__links--item {
  position: relative;
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.2;
}
.style-sitemap__links--item:first-child {
  margin-top: 0;
}
.style-sitemap__links--item a {
  display: block;
  position: relative;
  padding-left: 16px;
}
.style-sitemap__links--item a:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 2px;
  width: 5px;
  height: 8px;
  background: url(../img/icon/arrow1.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-sitemap__content {
    display: block !important;
  }
}
@media screen and (max-width: 1200px) {
  .style-sitemap {
    width: calc(100% - 200px);
  }
  .style-sitemap__line {
    width: 24%;
  }
  .style-sitemap--title {
    font-size: 14px;
  }
  .style-sitemap--title span {
    font-size: 12px;
  }
  .style-sitemap__links--item {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .style-sitemap {
    display: block;
    width: auto;
    margin-top: 10px;
  }
  .style-sitemap__line {
    width: auto;
  }
  .style-sitemap__line:last-child {
    display: flex;
    flex-wrap: wrap;
  }
  .style-sitemap__line:last-child .style-sitemap__box {
    width: 50%;
    border-bottom: solid #a5a5a5 1px;
  }
  .style-sitemap__line:last-child .style-sitemap__box:nth-of-type(2n) {
    border-left: solid #a5a5a5 1px;
  }
  .style-sitemap__line:last-child .style-sitemap--title {
    display: flex;
    align-items: center;
    border-bottom: none;
    height: 100%;
  }
  .style-sitemap__line:last-child .style-sitemap--title a {
    width: 100%;
  }
  .style-sitemap__box {
    margin-top: 0;
  }
  .style-sitemap--title {
    padding: 0;
    border-bottom-width: 1px;
    font-size: 16px;
  }
  .style-sitemap--title:before, .style-sitemap--title:after {
    display: none;
  }
  .style-sitemap--title a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    height: 100%;
  }
  .style-sitemap--title a.has--child:before, .style-sitemap--title a.has--child:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 18px;
    transition: ease 0.3s;
    width: 18px;
    height: 3px;
    background: #404040;
  }
  .style-sitemap--title a.has--child:after {
    transform: rotate(90deg);
  }
  .style-sitemap--title a.has--child.active:after {
    transform: rotate(0);
  }
  .style-sitemap--title span {
    display: block;
    font-size: 14px;
  }
  .style-sitemap__content {
    display: none;
    margin-top: 0;
    padding: 25px 20px 5px;
  }
  .style-sitemap__links--item {
    margin-top: 16px;
    font-size: 16px;
  }
  .style-sitemap__links--item a {
    padding-left: 22px;
  }
}
.style-links--1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.style-links--1--item {
  margin-left: 16px;
  font-size: 14px;
  line-height: 1.2;
}
.style-links--1--item:first-child {
  margin-left: 0;
}
@media screen and (max-width: 1200px) {
  .style-links--1--item {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .style-links--1 {
    justify-content: flex-start;
    margin-top: 0;
  }
  .style-links--1--item {
    margin-left: 0;
    width: 50%;
    border-bottom: solid #a5a5a5 1px;
    font-size: 14px;
    font-weight: 700;
  }
  .style-links--1--item:nth-of-type(2n) {
    border-left: solid #a5a5a5 1px;
  }
  .style-links--1--item a {
    display: block;
    padding: 20px;
  }
  .style-links--1--item.visible--pc + .style-links--1--item:last-child {
    width: calc(50% + 1px);
    border-left: none;
    border-right: solid #a5a5a5 1px;
  }
}
.style-search--1__tab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.style-search--1__tab--item {
  position: relative;
  width: 33.1666666667%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.style-search--1__tab--item a {
  display: block;
  position: relative;
  padding: 16px 0;
  width: 100%;
  background: #404040;
  color: #fff;
}
.style-search--1__tab--item a.active {
  background: #0168b7;
}
.style-search--1__tab--item a.active:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -12px;
  margin-left: -8px;
  width: 0;
  height: 0;
  border: solid transparent 8px;
  border-top: solid #0168b7 12px;
  border-bottom: none;
  overflow: hidden;
}
.style-search--1__content {
  display: none;
  margin-top: 12px;
  padding: 36px 0;
  border: solid #eee 4px;
  background: #fff;
}
.style-search--1__from {
  margin: 0 auto;
  width: 58.3333333333%;
}
.style-search--1__from input,
.style-search--1__from button,
.style-search--1__from select {
  appearance: none;
  height: 68px;
  background: none;
  border: none;
  border-radius: 0;
  font-family: inherit;
  font-size: 24px;
}
.style-search--1--select select {
  padding: 0 30px;
  width: 100%;
  background: url(../img/icon/select_arrow1.png) no-repeat right 20px center #fff;
  background-size: 20px;
  border: solid #ccc 2px;
}
.style-search--1__keyword {
  display: flex;
  margin-top: 18px;
}
.style-search--1__keyword--input {
  width: calc(100% - 68px);
}
.style-search--1__keyword--input input {
  padding: 0 30px;
  width: 100%;
  background: #eee;
  border: solid #ccc 2px;
  border-right: none;
}
.style-search--1__keyword--submit {
  position: relative;
  width: 68px;
  height: 68px;
}
.style-search--1__keyword--submit button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/icon/search1.png) no-repeat center center #404040;
  background-size: 37px;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .style-search--1 {
    margin: 0 20px;
  }
  .style-search--1__tab--item {
    font-size: 16px;
  }
  .style-search--1__tab--item a {
    padding: 14px 0;
  }
  .style-search--1__tab--item a.active:after {
    bottom: -8px;
    margin-left: -3px;
    border-width: 6px;
    border-top-width: 8px;
  }
  .style-search--1__tab--item a span {
    display: block;
    font-size: 12px;
  }
  .style-search--1__content {
    margin-top: 8px;
    padding: 16px;
    border-width: 2px;
  }
  .style-search--1__from {
    width: auto;
  }
  .style-search--1__from input,
.style-search--1__from button,
.style-search--1__from select {
    height: 60px;
    font-size: 16px;
  }
  .style-search--1--select select {
    padding: 0 60px 0 12px;
    border-width: 1px;
  }
  .style-search--1__keyword {
    margin-top: 12px;
  }
  .style-search--1__keyword--input {
    width: calc(100% - 60px);
  }
  .style-search--1__keyword--input input {
    padding: 0 12px;
    border-width: 1px;
  }
  .style-search--1__keyword--submit {
    width: 60px;
    height: 60px;
  }
  .style-search--1__keyword--submit button {
    background-size: 34px;
  }
}
.style-search--1__links {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style-search--1__links {
    margin: 40px 46px 0;
  }
}
.style-search--1__links__box {
  margin-top: 10px;
  padding: 25px;
  background: #f8fbfd;
}
.style-search--1__links__box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__box {
    margin-top: 20px;
    padding: 40px 50px;
  }
}
.style-search--1__links--title {
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-search--1__links--title {
    font-size: 28px;
  }
}
.style-search--1__links__content {
  margin-top: 15px;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__content {
    margin-top: 25px;
  }
}
.style-search--1__links__titles {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.style-search--1__links__titles--item {
  border-bottom: solid #c6dbec 1px;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .style-search--1__links__titles--item {
    margin: 10px 30px 0 0;
    width: calc(50% - 15px);
  }
  .style-search--1__links__titles--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-search--1__links__titles--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-search--1__links__titles--item {
    margin: 38px 40px 0 0;
    width: calc(25% - 30px);
    font-size: 16px;
  }
  .style-search--1__links__titles--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-search--1__links__titles--item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.style-search--1__links__titles--item > a {
  display: block;
  padding: 6px 0;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__titles--item > a {
    padding: 8px;
  }
}
.style-index {
  margin-bottom: 96px;
}
.style-index__lists--item {
  position: relative;
  margin-top: 60px;
  padding: 40px 44px;
  background: #f8fbfd;
}
.style-index__lists--item:nth-of-type(even) {
  background: #e5f0f8;
}
.style-index__lists--item:first-child {
  margin-top: 0;
}
.style-index__lists--title {
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
  vertical-align: top;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168b7;
}
.style-index__lists--title:after {
  content: "";
  display: block;
  margin-top: 6px;
  width: 100%;
  height: 4px;
  background: #0168b7;
}
.style-index__lists__detail__box--title {
  font-size: 18px;
  font-weight: 700;
  color: #0168b7;
}
.style-index__lists__detail__box--title a {
  display: inline-block;
  vertical-align: top;
  color: #0168b7;
}
.style-index__lists__detail__box--title a:before {
  content: "";
  display: inline-block;
  margin: 6px 4px 0 0;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
.style-index__lists__detail__box--text {
  margin-top: 10px;
  font-size: 15px;
}
.style-index__lists__links--item {
  font-size: 18px;
  font-weight: 700;
  color: #0168b7;
}
.style-index__lists__links--item a {
  display: inline-block;
  vertical-align: top;
  color: #0168b7;
}
.style-index__lists__links--item a:before {
  content: "";
  display: inline-block;
  margin: 6px 4px 0 0;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-index__lists__detail {
    display: flex;
    flex-wrap: wrap;
  }
  .style-index__lists__detail__box {
    margin: 40px 2.8776978418% 0 0;
    width: 48.5611510791%;
  }
  .style-index__lists__detail__box:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-index__lists__detail__box:nth-of-type(2n) {
    margin-right: 0;
  }
  .style-index__lists__links {
    display: flex;
    flex-wrap: wrap;
  }
  .style-index__lists__links--item {
    margin-top: 12px;
    padding-right: 20px;
    width: 25%;
  }
  .style-index__lists__links--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .style-index {
    margin-bottom: 50px;
  }
  .style-index__lists {
    margin: 0 20px;
  }
  .style-index__lists--item {
    margin-top: 30px;
    padding: 24px 20px;
  }
  .style-index__lists--title {
    margin-bottom: 24px;
    font-size: 21px;
  }
  .style-index__lists--title:after {
    margin-top: 6px;
    height: 2px;
  }
  .style-index__lists__detail__box {
    margin-top: 18px;
  }
  .style-index__lists__detail__box:first-child {
    margin-top: 0;
  }
  .style-index__lists__detail__box--text {
    margin-top: 8px;
  }
  .style-index__lists__links--item {
    margin-top: 18px;
  }
  .style-index__lists__links--item:first-child {
    margin-top: 0;
  }
}

/* block
------------------------------------------------ */
.block {
  /* contact */
}
.block-contact {
  padding: 120px 0 100px;
  background: url(../img/share/contact_bg.png) no-repeat center center;
  background-size: cover;
}
.block-contact .title--3 {
  color: #fff;
}
.block-contact .title--3:before, .block-contact .title--3:after {
  background: #fff;
}
.block-contact--text {
  text-align: center;
  font-size: 28px;
  color: #fff;
}
.block-contact__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.block-contact__links--item {
  position: relative;
  margin: 20px 10px 0;
  font-size: 24px;
  line-height: 1.2;
}
.block-contact__links--item a {
  display: block;
  position: relative;
  padding: 20px 38px;
  background: #fff;
  border-radius: 35px;
  color: #0168b7;
}
.block-contact__links--item a:after {
  content: "";
  display: inline-block;
  margin: 4px 0 0 22px;
  width: 11px;
  height: 22px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .block-contact {
    padding: 60px 25px 48px;
  }
  .block-contact--text {
    font-size: 18px;
  }
  .block-contact__links {
    display: block;
    margin: 24px 8px 0;
  }
  .block-contact__links--item {
    margin: 16px 0 0;
    text-align: center;
    font-size: 16px;
  }
  .block-contact__links--item:first-child {
    margin-top: 0;
  }
  .block-contact__links--item a {
    padding: 12px 10px;
  }
  .block-contact__links--item a:after {
    position: absolute;
    top: 50%;
    right: 20px;
    margin: -6px 0 0;
    width: 7px;
    height: 13px;
  }
}

/* pager
------------------------------------------------ */
.pager-1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .pager-1 {
    margin-top: 80px;
  }
}
.pager-1-item {
  position: relative;
  margin: 0 2px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 34px;
}
.pager-1-item a,
.pager-1-item span {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.pager-1-item span {
  background: #0168b7;
  color: #fff;
}
.pager-1-item.prev a, .pager-1-item.next a {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.pager-1-item.prev a:after, .pager-1-item.next a:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.pager-1-item.prev a:after {
  background-image: url(../img/icon/pager1_prev.png);
}
.pager-1-item.next a:after {
  background-image: url(../img/icon/pager1_next.png);
}
.pager-single {
  margin-top: 25px;
  padding-top: 40px;
  border-top: solid #ccc 1px;
}
@media screen and (min-width: 769px) {
  .pager-single {
    margin-top: 60px;
    padding-top: 60px;
  }
}
.pager-single-post {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .pager-single-post {
    margin: 0 32px;
  }
}
.pager-single-post--item {
  position: relative;
  margin: 0 4px;
  width: calc(50% - 4px);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
.pager-single-post--item:first-child {
  margin-left: 0;
}
.pager-single-post--item:last-child {
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .pager-single-post--item {
    margin: 0 10px;
    width: 140px;
    font-size: 15px;
  }
}
.pager-single-post--item a {
  display: block;
  position: relative;
  padding: 12px 0;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .pager-single-post--item a {
    padding: 15px 0;
  }
}
.pager-single-post--item a:before, .pager-single-post--item a:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .pager-single-post--item a:before, .pager-single-post--item a:after {
    position: absolute;
    top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item a:before, .pager-single-post--item a:after {
    margin-top: 5px;
  }
}
.pager-single-post--item.prev a:before {
  background-image: url(../img/icon/pager_single_prev.png);
}
@media screen and (max-width: 768px) {
  .pager-single-post--item.prev a:before {
    left: 10px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item.prev a:before {
    margin-right: 18px;
  }
}
.pager-single-post--item.prev a:after {
  display: none;
}
.pager-single-post--item.next a:before {
  display: none;
}
.pager-single-post--item.next a:after {
  background-image: url(../img/icon/pager_single_next.png);
}
@media screen and (max-width: 768px) {
  .pager-single-post--item.next a:after {
    right: 10px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item.next a:after {
    margin-left: 18px;
  }
}
.pager-single-back {
  margin: 12px 32px 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .pager-single-back {
    margin: 40px auto 0;
    width: 400px;
    font-size: 24px;
  }
}
.pager-single-back a {
  display: block;
  padding: 16px 0;
  background: #0168b7;
  border: solid #0168b7 1px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .pager-single-back a {
    padding: 25px 0;
  }
}

div.wrapper {
  margin-bottom: 55px;
}
div.wrapperCorrosion01 {
  text-align: center;
  margin: 0 0 75px 0;
}
div.wrapperCorrosion01 h4 {
  background: #fafbf3;
  font-size: 142%;
  font-weight: normal;
  color: #515151;
  width: 160px;
  margin: 0 auto 0 auto;
  padding: 0;
  border: 1px solid #dbdbdb;
}
div.conttainerOutside01 {
  text-align: left;
  border: 1px solid #d2d1d2;
  margin: 0 auto;
}
div.conttainerInside01 {
  padding: 8px 0 7px 8px;
  border: 4px solid #f5f6fa;
}
div.conttainerInside01 ul {
  margin-top: 10px;
}
div.conttainerInside01 ul li {
  position: relative;
  line-height: 1.4em;
  padding: 0 0 0 20px;
}
div.conttainerInside01 ul li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

dl.first {
  margin: 0 0 37px 15px;
}
dl.first dt {
  background: #fbfbf4;
  padding: 4px 23px;
  border: 1px solid #eaeaea;
}
dl.first dd {
  padding: 10px 0 10px 45px;
}
dl.first dd ul {
  margin: 16px 0 25px 0;
}
dl.first dd ul li {
  position: relative;
  line-height: 1.4em;
  padding: 0 0 0 20px;
}
dl.first dd ul li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
dl.child {
  margin: 15px 0 0 0;
}
dl.child dt {
  background: none;
  border: none;
  padding: 0;
}
dl.child dd {
  margin-bottom: 25px;
  padding: 0;
}
dl.child dd ul {
  margin: 0;
}
dl.child dd ul li {
  line-height: 1.7em;
}
dl.sec {
  margin: 0 0 110px 15px;
}
dl.sec dt {
  background: #fbfbf4;
  padding: 4px 23px;
  border: 1px solid #eaeaea;
}
dl.sec dd {
  padding: 15px 0 15px 30px;
}
dl.sec dd span {
  display: block;
  text-indent: 6.5em;
}
dl.sec dd table {
  margin: 10px 0 15px;
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
dl.sec dd table th,
dl.sec dd table td {
  padding: 10px;
}
dl.sec dd table th {
  background: #f5f5f5;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  padding: 6px 0 3px 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
dl.sec dd table td {
  background: #fff;
  padding: 2px 13px;
  vertical-align: top;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
dl.sec dd table td li {
  position: relative;
  line-height: 1.9em;
  padding: 0 0 0 20px;
}
dl.sec dd table td li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
dl.subRead01 {
  margin: 0 0 0 15px;
}
dl.subRead01 dt {
  margin-bottom: 1px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  dl.subRead01 dt {
    font-size: 17px;
  }
}
dl.subRead01 dd {
  margin-bottom: 10px;
  font-size: 12px;
}
@media screen and (min-width: 769px) {
  dl.subRead01 dd {
    font-size: 15px;
  }
}

ul#plamtCatList {
  margin: 12px 0 23px 0;
}
ul#plamtCatList li {
  float: left;
  width: 33.5%;
}
ul#corrosionCatList {
  margin: 12px 0 23px 0;
}
ul#corrosionCatList li {
  float: left;
  width: 335px;
}
ul.frictionList01 {
  margin: 0 0 52px 0;
  padding: 0 0 0 22px;
}
ul.frictionList01 li {
  position: relative;
  line-height: 1.9em;
  padding: 0 0 0 20px;
}
ul.frictionList01 li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}

.tableHeadTitle {
  text-align: center !important;
  padding: 7px 0 !important;
}

.arrow01 {
  width: 33px;
  height: 30px;
  margin: 15px auto;
}

.pdbrance {
  padding-top: 4px;
  padding-bottom: 7px;
}

.containerOutside01 {
  margin: 0 auto 25px;
  width: 278px;
  border: 1px solid #d1d1d3;
}

.containerInside01 {
  background: #fff;
  border: 4px solid #f5f6fa;
}

.insideList {
  margin: 3px 0 3px 12px;
  list-style-position: outside;
}
.insideList li {
  font-size: 12px;
  line-height: 1.6em;
}

.innerBg {
  margin: 0 auto;
  width: 420px;
  background: url(../../business/corrosion_control/img/pro_bg01.gif) no-repeat center 33px;
}
@media screen and (max-width: 768px) {
  .innerBg {
    width: 100%;
    background-size: 100%;
  }
}

.alCenter {
  text-align: center !important;
}

.borderWrapper {
  text-align: center;
  width: 78px;
  margin: 0 0 0 18px;
  border: 1px solid #d1d1d3;
}
@media screen and (max-width: 768px) {
  .borderWrapper {
    width: 100%;
    margin-left: 0;
  }
}
.borderWrapper p {
  background: #fff;
  border: 4px solid #f5f6fa;
  margin: 0;
  padding: 5px 0 3px 0;
}

.listwrapper {
  float: left;
  margin: 0 0 135px 52px;
  padding: 0 0 25px 0;
}
.listwrapper:first-child {
  margin-left: 0;
}
@media screen and (max-width: 768px) {
  .listwrapper {
    margin-left: 7.5vw;
    width: 21vw;
  }
}
.listwrapper ul {
  margin: 9px 0 0 17px;
}
@media screen and (max-width: 768px) {
  .listwrapper ul {
    margin: 8px 4px 0;
  }
}
.listwrapper ul li {
  font-size: 11px;
  line-height: 1.5em;
}

.lHbrance01 {
  line-height: 1.55em;
}

/* title
------------------------------------------------ */
.title--page {
  position: relative;
  margin-bottom: 5px;
  padding: 38px 0;
  background-position: center bottom;
  background-repeat: no-repeat;
  background-size: auto 100%;
  background-color: #e5f0f8;
  text-align: center;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
.title--page .min {
  display: block;
  margin-top: 6px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .title--page {
    margin-bottom: 16px;
    padding: 75px 0;
    font-size: 72px;
  }
  .title--page .min {
    margin-top: 10px;
    font-size: 28px;
  }
}
.title--image {
  position: relative;
  margin: 0 auto 42px;
  padding-top: 60px;
  height: 225px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 30px;
  font-weight: 700;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .title--image {
    margin-bottom: 80px;
    padding-top: 130px;
    max-width: 1200px;
    height: 450px;
    font-size: 60px;
  }
}
.title--ribbon {
  position: relative;
  margin-bottom: 4px;
  height: 225px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
@media screen and (min-width: 769px) {
  .title--ribbon {
    margin-bottom: 14px;
    height: 500px;
  }
}
.title--ribbon--name {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 24px;
  width: 320px;
  height: 100px;
  background: url(../img/icon/title_ribbon1.png) no-repeat center center;
  background-size: 100%;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .title--ribbon--name {
    padding-top: 55px;
    width: 712px;
    height: 220px;
    font-size: 80px;
  }
}
.title--1 {
  position: relative;
  margin-bottom: 20px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-weight: 700;
  line-height: 1.2;
}
.title--1--inline {
  display: inline-block;
  position: relative;
  text-align: right;
  vertical-align: top;
}
.title--1 .min,
.title--1 .normal {
  display: block;
  position: relative;
}
.title--1 .min {
  font-size: 18px;
  color: #0168b7;
}
.title--1 .min + .normal {
  margin-top: -14px;
}
.title--1 .normal {
  font-size: 36px;
}
.title--1 .normal .l {
  font-size: 48px;
}
.title--1 .normal + .min {
  margin-top: -5px;
}
@media screen and (min-width: 769px) {
  .title--1 .min {
    font-size: 30px;
  }
  .title--1 .min + .normal {
    margin-top: -28px;
  }
  .title--1 .normal {
    font-size: 60px;
  }
  .title--1 .normal .l {
    font-size: 80px;
  }
  .title--1 .normal + .min {
    margin-top: -10px;
  }
}
.title--2 {
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}
.title--2 .min {
  font-size: 26px;
}
@media screen and (min-width: 769px) {
  .title--2 {
    font-size: 60px;
  }
  .title--2 .min {
    font-size: 40px;
  }
}
.title--3 {
  position: relative;
  margin-bottom: 40px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 10px;
  line-height: 1.2;
}
.title--3:before, .title--3:after {
  content: "";
  position: absolute;
  left: 50%;
  background: #404040;
  width: 25px;
  height: 2px;
}
.title--3:before {
  top: 0;
  transform: rotate(-45deg);
  margin-left: 25px;
}
.title--3:after {
  bottom: -18px;
  transform: rotate(-45deg);
  margin-left: -50px;
}
.title--3 .en,
.title--3 .ja {
  display: block;
}
.title--3 .en {
  font-size: 36px;
  font-weight: 700;
}
.title--3 .ja {
  margin-top: -2px;
  font-size: 14.4px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .title--3 {
    margin-bottom: 74px;
  }
  .title--3:before, .title--3:after {
    width: 40px;
  }
  .title--3:before {
    margin-left: 35px;
  }
  .title--3:after {
    bottom: -24px;
    margin-left: -90px;
  }
  .title--3 .en {
    font-size: 60px;
  }
  .title--3 .ja {
    font-size: 24px;
  }
}
.title--4 {
  position: relative;
  margin-bottom: 24px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168b7;
}
.title--4:after {
  content: "";
  display: block;
  margin: 12px auto 0;
  width: 30px;
  height: 3px;
  background: #0068b7;
}
@media screen and (min-width: 769px) {
  .title--4 {
    margin-bottom: 58px;
    font-size: 52px;
  }
  .title--4:after {
    margin-top: 24px;
    width: 60px;
    height: 6px;
  }
}
.title--5 {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .title--5 {
    font-size: 52px;
  }
}
.title--5 .l,
.title--5 .min {
  display: inline-block;
  vertical-align: middle;
}
.title--5 .l {
  margin-right: 16px;
  padding: 15px 0;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .title--5 .l {
    margin-right: 25px;
    padding: 24px 0;
  }
}
.title--5 .min {
  font-size: 12px;
}
@media screen and (min-width: 769px) {
  .title--5 .min {
    font-size: 18px;
  }
}
.title--6 {
  position: relative;
  margin-bottom: 35px;
  padding-bottom: 16px;
  border-bottom: solid #a5a5a5 1px;
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .title--6 {
    margin-bottom: 80px;
    border-bottom-width: 2px;
    font-size: 36px;
  }
}
.title--6:before, .title--6:after {
  content: "";
  bottom: -1px;
  position: absolute;
}
@media screen and (min-width: 769px) {
  .title--6:before, .title--6:after {
    bottom: -2px;
    height: 2px;
  }
}
.title--6:before {
  left: 95px;
  width: 10px;
  background: #fff;
}
@media screen and (min-width: 769px) {
  .title--6:before {
    left: 280px;
    width: 20px;
  }
}
.title--6:after {
  left: 93px;
  transform-origin: right;
  transform: rotate(-45deg);
  width: 12px;
  background: #a5a5a5;
}
@media screen and (min-width: 769px) {
  .title--6:after {
    left: 277px;
    width: 24px;
  }
}
.title--7 {
  position: relative;
  margin-bottom: 16px;
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .title--7 {
    margin-bottom: 26px;
    border-bottom-width: 3px;
    padding-bottom: 14px;
    font-size: 26px;
  }
}

/* more
------------------------------------------------ */
.more--1 {
  position: relative;
  margin: 56px auto 0;
  max-width: 400px;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.more--1 a {
  display: block;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  background: #404040;
  border-radius: 35px;
  color: #fff;
}
.more--1 a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 38px;
  margin-top: -10px;
  width: 11px;
  height: 20px;
  background: url(../img/icon/arrow3.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .more--1 {
    margin-top: 28px;
    max-width: 300px;
    font-size: 16px;
  }
  .more--1 a {
    padding: 16px 10px;
  }
  .more--1 a:after {
    right: 25px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}
.more--2 {
  position: relative;
  margin: 56px auto 0;
  max-width: 400px;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.more--2 a {
  display: block;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  background: #fff;
  border: 1px solid #404040;
  border-radius: 35px;
  color: #404040;
}
.more--2 a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 38px;
  margin-top: -10px;
  width: 11px;
  height: 20px;
  background: url(../img/icon/arrow9.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .more--2 {
    margin-top: 28px;
    max-width: 300px;
    font-size: 16px;
  }
  .more--2 a {
    padding: 16px 10px;
  }
  .more--2 a:after {
    right: 25px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}

/* btn
------------------------------------------------ */
.btn--normal {
  position: relative;
  margin: 54px auto 0;
  max-width: 420px;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.btn--normal a {
  display: block;
  position: relative;
  padding: 20px 10px;
  width: 100%;
  background: #0168b7;
  border: solid #0168b7 1px;
  border-radius: 35px;
  color: #fff;
}
.btn--normal a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 24px;
  margin-top: -11px;
  width: 11px;
  height: 22px;
  background: url(../img/icon/arrow3.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .btn--normal {
    margin-top: 18px;
    font-size: 16px;
    max-width: 310px;
  }
  .btn--normal a {
    padding: 15px 10px;
  }
  .btn--normal a:after {
    right: 20px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}
.btn--back {
  position: relative;
  margin: 72px auto 0;
  max-width: 300px;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
}
.btn--back a {
  display: block;
  position: relative;
  padding: 14px 10px;
  background: #404040;
  border: solid #404040 1px;
  border-radius: 25px;
  vertical-align: top;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .btn--back {
    margin-top: 34px;
    font-size: 16px;
  }
}

/* icon
------------------------------------------------ */
.icon-news {
  display: inline-block;
  border-radius: 15px;
  text-align: center;
  vertical-align: top;
  font-size: 12px;
  font-style: normal;
}
.icon-news--news {
  background: #0168b7;
  color: #fff;
}
.icon-news--exhibition {
  background: #f6f033;
}
.icon-news--update {
  background: #404040;
  color: #fff;
}
.icon-news--mailmagazine {
  background: #000;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .icon-news {
    padding: 3px 0;
  }
}
@media screen and (min-width: 769px) {
  .icon-news {
    font-size: 16px;
  }
}
.icon-new {
  display: inline-block;
  min-width: 38px;
  height: 15px;
  background: #aa111d;
  border-radius: 8px;
  text-align: center;
  vertical-align: top;
  font-size: 10.5px;
  font-weight: 700;
  font-style: normal;
  line-height: 15px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .icon-new {
    min-width: 50px;
    height: 20px;
    border-radius: 10px;
    font-size: 14px;
    line-height: 20px;
  }
}
.icon-form {
  display: inline-block;
  min-width: 54px;
  padding: 2px 0 3px;
  text-align: center;
  vertical-align: top;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 14px;
  font-style: normal;
  line-height: 1.2;
  color: #fff;
}
.icon-form--required {
  background: #b4161f;
}
.icon-form--any {
  background: #0068b7;
}
.icon-pdf {
  display: inline-block;
  position: relative;
  width: 17px;
  height: 20px;
  vertical-align: top;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.icon-pdf:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/icon/pdf1.png) no-repeat center center;
  background-size: 100%;
}
.icon-blank {
  position: absolute;
  top: 0;
  right: 0;
  width: 20px;
  height: 20px;
  vertical-align: top;
  text-indent: -9999px;
  white-space: nowrap;
}
@media screen and (min-width: 769px) {
  .icon-blank {
    width: 25px;
    height: 25px;
  }
}
.icon-blank:after {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: url(../img/icon/blank1.png) no-repeat center center;
  background-size: 100%;
}

/* lists
------------------------------------------------ */
.lists-news--item {
  border-bottom: solid #ccc 1px;
}
.lists-news--item > a, .lists-news--item > span {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: 20px 2px;
}
.lists-news--cat {
  width: 90px;
}
.lists-news--cat .icon-news {
  width: 100%;
}
.lists-news--date {
  margin-left: 15px;
  width: 110px;
  font-size: 18px;
  font-weight: 700;
  color: #0068b7;
}
.lists-news--title {
  width: calc(100% - 215px);
  font-size: 16px;
  font-weight: 400;
  line-height: 1.8;
}
.lists-news-btns {
  margin-top: 15px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .lists-news-btns {
    padding: 0 60px;
  }
}
@media screen and (min-width: 769px) {
  .lists-news-btns {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 0 -10px;
    padding-left: 215px;
  }
}
.lists-news-btns--item {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .lists-news-btns--item + .lists-news-btns--item {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .lists-news-btns--item {
    margin: 10px 0 0 10px;
    width: 220px;
  }
}
.lists-news-btns--item > a {
  display: block;
  padding: 14px 0;
  border-radius: 30px;
}
@media screen and (min-width: 769px) {
  .lists-news-btns--item > a {
    padding: 7px 0;
  }
}
.lists-news-btns--item.btn-applicate > a {
  background-color: #F6F033;
}
.lists-news-btns--item.btn-applicated > a {
  background-color: #0168B7;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .lists-news {
    margin: 0 20px;
  }
  .lists-news--item > a {
    flex-wrap: wrap;
    padding: 20px 0;
  }
  .lists-news--cat {
    width: 65px;
  }
  .lists-news--cat .icon-news {
    width: 100%;
  }
  .lists-news--date {
    margin-left: 10px;
    width: auto;
    font-size: 15px;
  }
  .lists-news--title {
    margin-top: 15px;
    width: 100%;
    font-size: 14px;
    line-height: 1.6;
  }
}

/* style
------------------------------------------------ */
.style {
  /* tab */
  /* introduce */
  /* section */
  /* sitemap */
  /* links */
  /* search */
  /* index */
}
.style-tab {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .style-tab {
    margin: 0 20px;
  }
}
.style-tab-item {
  position: relative;
  height: 45px;
  text-align: center;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .style-tab-item {
    margin: 2px 2px 0 0;
    width: calc(50% - 1px);
  }
  .style-tab-item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-tab-item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-tab-item {
    margin: 2px 2px 0 0;
    width: calc(25% - 1.5px);
    height: 90px;
    font-size: 24px;
  }
  .style-tab-item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-tab-item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.style-tab-item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 100%;
  background: #fff;
  border: solid #0168b7 1px;
  color: #0168b7;
}
.style-tab-item > a.active {
  background: #0168b7;
  color: #fff;
}
.style-tab-item > a.active:after {
  background-image: url(../img/icon/arrow3.png);
}
@media screen and (min-width: 769px) {
  .style-tab-item > a {
    padding: 35px 30px;
  }
}
.style-tab-item > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 8px;
  margin-top: -5px;
  width: 5px;
  height: 10px;
  background: url(../img/icon/arrow4.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .style-tab-item > a:after {
    right: 20px;
    margin-top: -9px;
    width: 9px;
    height: 19px;
  }
}
.style-tab-item > a .min {
  display: block;
  font-size: 11px;
}
@media screen and (min-width: 769px) {
  .style-tab-item > a .min {
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .style-introduce {
    padding: 10px 20px 30px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce {
    display: flex;
    justify-content: space-between;
    padding: 60px 20px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce-info {
    width: 45%;
  }
}
.style-introduce .title--5 {
  margin-bottom: 8px;
}
@media screen and (min-width: 769px) {
  .style-introduce .title--5 {
    margin-bottom: 25px;
  }
}
.style-introduce-text {
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (min-width: 769px) {
  .style-introduce-text {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .style-introduce-photo {
    margin-top: 26px;
  }
}
@media screen and (min-width: 769px) {
  .style-introduce-photo {
    width: 43.3333333333%;
  }
}
.style-section {
  position: relative;
  margin-top: 1px;
  padding: 30px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .style-section {
    margin-top: 2px;
    padding: 70px 0 112px;
  }
}
.style-section-block {
  margin-top: 24px;
}
.style-section-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-block {
    margin-top: 50px;
  }
}
@media screen and (max-width: 768px) {
  .style-section-block .style-tab {
    margin: 0 0 10px;
  }
}
@media screen and (min-width: 769px) {
  .style-section-block .style-tab {
    margin-bottom: 20px;
  }
}
.style-section-block .style-section-lists {
  margin-top: 10px;
}
.style-section-block .style-section-lists:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-block .style-section-lists {
    margin-top: 20px;
  }
}
.style-section-title {
  margin-bottom: 16px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-section-title {
    margin-bottom: 34px;
    font-size: 42px;
  }
}
.style-section-lists--item {
  position: relative;
  margin-top: 10px;
  padding: 15px 20px 20px;
  background: #fff;
}
.style-section-lists--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-lists--item {
    margin-top: 20px;
    padding: 28px 65px;
  }
}
.style-section-lists__box {
  position: relative;
}
.style-section-lists--title {
  padding-bottom: 8px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-section-lists--title {
    padding-bottom: 18px;
    font-size: 28px;
  }
}
.style-section-lists__content {
  position: relative;
  margin-top: 18px;
  font-size: 14px;
}
.style-section-lists__content:first-child {
  margin-top: 0;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content {
    padding-top: 5px;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__content {
    margin-top: 32px;
    font-size: 15px;
  }
}
.style-section-lists__content > * + * {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content > * + * {
    margin-top: 1.5em;
  }
}
.style-section-lists__content .image-center {
  margin-right: auto;
  margin-left: auto;
  width: 50%;
  text-align: center;
}
.style-section-lists__content .image-lists {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 40px;
  }
}
.style-section-lists__content .image-lists:first-child {
  margin-top: 0;
}
.style-section-lists__content .image-lists-item {
  position: relative;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content .image-lists-item {
    margin-top: 20px;
    font-size: 10px;
  }
  .style-section-lists__content .image-lists-item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists-item {
    margin: 40px 2% 0;
    width: 28%;
    font-size: 12px;
  }
  .style-section-lists__content .image-lists-item:nth-of-type(-n+3) {
    margin-top: 0;
  }
}
.style-section-lists__content .image-lists-item figure {
  display: block;
  position: relative;
}
@media screen and (max-width: 768px) {
  .style-section-lists__content .image-lists-item figure img {
    width: 50%;
  }
}
.style-section-lists__content .image-lists-item figcaption {
  display: block;
  text-align: center;
  margin-top: 10px;
}
@media screen and (min-width: 769px) {
  .style-section-lists__content .image-lists-item figcaption {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .style-section-lists__titles {
    margin-top: 14px;
  }
  .style-section-lists__titles:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__titles {
    display: flex;
    flex-wrap: wrap;
  }
}
.style-section-lists__titles--item {
  font-size: 14px;
  line-height: 1.2;
}
.style-section-lists__titles--item > a {
  display: inline-block;
  position: relative;
  text-decoration: underline;
  vertical-align: top;
  color: #0168b7;
}
.device--click .style-section-lists__titles--item > a:hover {
  text-decoration: none;
}
.style-section-lists__titles--item > a.link-icon {
  padding-left: 26px;
}
.style-section-lists__titles--item > a.link-icon i {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .style-section-lists__titles--item {
    margin-top: 14px;
  }
  .style-section-lists__titles--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-section-lists__titles--item {
    margin: 18px 60px 0 0;
    width: calc(25% - 45px);
    font-size: 15px;
  }
  .style-section-lists__titles--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-section-lists__titles--item:nth-of-type(4n) {
    margin-right: 0;
  }
  .style-section-lists__titles--item.streight {
    margin-right: 0;
    width: 100%;
  }
}
.style-section-lists__links--item {
  margin-top: 18px;
  font-size: 14px;
}
.style-section-lists__links--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-section-lists__links--item {
    margin-top: 28px;
    font-size: 15px;
  }
}
.style-section-lists__links--item a,
.style-section-lists__links--item span {
  display: inline-block;
  position: relative;
  padding-left: 22px;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-section-lists__links--item a,
.style-section-lists__links--item span {
    padding-left: 32px;
  }
}
.style-section-lists__links--item a:before,
.style-section-lists__links--item span:before {
  content: "▶︎";
  position: absolute;
  top: 4px;
  left: 0;
  font-size: 10px;
  color: #0168b7;
}
.style-section-lists__links--item a {
  text-decoration: underline;
  color: #0168b7;
}
.device--click .style-section-lists__links--item a:hover {
  text-decoration: none;
}
.style-section-lists__links--item .no-arrow {
  padding-left: 0;
}
.style-section-lists__links--item .no-arrow:before {
  display: none;
}
.style-section-lists__links--item .icon-pdf {
  margin-right: 10px;
}
.style-section-lists__links--item .icon-new {
  margin-left: 10px;
}
.style-sitemap__box {
  margin-top: 42px;
}
.style-sitemap__box:first-child {
  margin-top: 0;
}
.style-sitemap--title {
  position: relative;
  padding-bottom: 18px;
  border-bottom: solid #a5a5a5 2px;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.2;
}
.style-sitemap--title:before, .style-sitemap--title:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 60px;
  width: 10px;
  height: 2px;
}
.style-sitemap--title:before {
  background: #f8fbfd;
}
.style-sitemap--title:after {
  transform-origin: right top;
  transform: rotate(-45deg);
  background: #a5a5a5;
}
.style-sitemap--title a {
  display: block;
  position: relative;
}
.style-sitemap--title span {
  font-size: 14px;
}
.style-sitemap__content {
  margin-top: 32px;
}
.style-sitemap__links--item {
  position: relative;
  margin-top: 25px;
  font-size: 16px;
  line-height: 1.2;
}
.style-sitemap__links--item:first-child {
  margin-top: 0;
}
.style-sitemap__links--item a {
  display: block;
  position: relative;
  padding-left: 16px;
}
.style-sitemap__links--item a:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 2px;
  width: 5px;
  height: 8px;
  background: url(../img/icon/arrow1.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-sitemap__content {
    display: block !important;
  }
}
@media screen and (max-width: 1200px) {
  .style-sitemap {
    width: calc(100% - 200px);
  }
  .style-sitemap__line {
    width: 24%;
  }
  .style-sitemap--title {
    font-size: 14px;
  }
  .style-sitemap--title span {
    font-size: 12px;
  }
  .style-sitemap__links--item {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .style-sitemap {
    display: block;
    width: auto;
    margin-top: 10px;
  }
  .style-sitemap__line {
    width: auto;
  }
  .style-sitemap__line:last-child {
    display: flex;
    flex-wrap: wrap;
  }
  .style-sitemap__line:last-child .style-sitemap__box {
    width: 50%;
    border-bottom: solid #a5a5a5 1px;
  }
  .style-sitemap__line:last-child .style-sitemap__box:nth-of-type(2n) {
    border-left: solid #a5a5a5 1px;
  }
  .style-sitemap__line:last-child .style-sitemap--title {
    display: flex;
    align-items: center;
    border-bottom: none;
    height: 100%;
  }
  .style-sitemap__line:last-child .style-sitemap--title a {
    width: 100%;
  }
  .style-sitemap__box {
    margin-top: 0;
  }
  .style-sitemap--title {
    padding: 0;
    border-bottom-width: 1px;
    font-size: 16px;
  }
  .style-sitemap--title:before, .style-sitemap--title:after {
    display: none;
  }
  .style-sitemap--title a {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 20px;
    height: 100%;
  }
  .style-sitemap--title a.has--child:before, .style-sitemap--title a.has--child:after {
    content: "";
    position: absolute;
    top: 50%;
    right: 18px;
    transition: ease 0.3s;
    width: 18px;
    height: 3px;
    background: #404040;
  }
  .style-sitemap--title a.has--child:after {
    transform: rotate(90deg);
  }
  .style-sitemap--title a.has--child.active:after {
    transform: rotate(0);
  }
  .style-sitemap--title span {
    display: block;
    font-size: 14px;
  }
  .style-sitemap__content {
    display: none;
    margin-top: 0;
    padding: 25px 20px 5px;
  }
  .style-sitemap__links--item {
    margin-top: 16px;
    font-size: 16px;
  }
  .style-sitemap__links--item a {
    padding-left: 22px;
  }
}
.style-links--1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.style-links--1--item {
  margin-left: 16px;
  font-size: 14px;
  line-height: 1.2;
}
.style-links--1--item:first-child {
  margin-left: 0;
}
@media screen and (max-width: 1200px) {
  .style-links--1--item {
    font-size: 12px;
  }
}
@media screen and (max-width: 768px) {
  .style-links--1 {
    justify-content: flex-start;
    margin-top: 0;
  }
  .style-links--1--item {
    margin-left: 0;
    width: 50%;
    border-bottom: solid #a5a5a5 1px;
    font-size: 14px;
    font-weight: 700;
  }
  .style-links--1--item:nth-of-type(2n) {
    border-left: solid #a5a5a5 1px;
  }
  .style-links--1--item a {
    display: block;
    padding: 20px;
  }
  .style-links--1--item.visible--pc + .style-links--1--item:last-child {
    width: calc(50% + 1px);
    border-left: none;
    border-right: solid #a5a5a5 1px;
  }
}
.style-search--1__tab {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.style-search--1__tab--item {
  position: relative;
  width: 33.1666666667%;
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
.style-search--1__tab--item a {
  display: block;
  position: relative;
  padding: 16px 0;
  width: 100%;
  background: #404040;
  color: #fff;
}
.style-search--1__tab--item a.active {
  background: #0168b7;
}
.style-search--1__tab--item a.active:after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: -12px;
  margin-left: -8px;
  width: 0;
  height: 0;
  border: solid transparent 8px;
  border-top: solid #0168b7 12px;
  border-bottom: none;
  overflow: hidden;
}
.style-search--1__content {
  display: none;
  margin-top: 12px;
  padding: 36px 0;
  border: solid #eee 4px;
  background: #fff;
}
.style-search--1__from {
  margin: 0 auto;
  width: 58.3333333333%;
}
.style-search--1__from input,
.style-search--1__from button,
.style-search--1__from select {
  appearance: none;
  height: 68px;
  background: none;
  border: none;
  border-radius: 0;
  font-family: inherit;
  font-size: 24px;
}
.style-search--1--select select {
  padding: 0 30px;
  width: 100%;
  background: url(../img/icon/select_arrow1.png) no-repeat right 20px center #fff;
  background-size: 20px;
  border: solid #ccc 2px;
}
.style-search--1__keyword {
  display: flex;
  margin-top: 18px;
}
.style-search--1__keyword--input {
  width: calc(100% - 68px);
}
.style-search--1__keyword--input input {
  padding: 0 30px;
  width: 100%;
  background: #eee;
  border: solid #ccc 2px;
  border-right: none;
}
.style-search--1__keyword--submit {
  position: relative;
  width: 68px;
  height: 68px;
}
.style-search--1__keyword--submit button {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../img/icon/search1.png) no-repeat center center #404040;
  background-size: 37px;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .style-search--1 {
    margin: 0 20px;
  }
  .style-search--1__tab--item {
    font-size: 16px;
  }
  .style-search--1__tab--item a {
    padding: 14px 0;
  }
  .style-search--1__tab--item a.active:after {
    bottom: -8px;
    margin-left: -3px;
    border-width: 6px;
    border-top-width: 8px;
  }
  .style-search--1__tab--item a span {
    display: block;
    font-size: 12px;
  }
  .style-search--1__content {
    margin-top: 8px;
    padding: 16px;
    border-width: 2px;
  }
  .style-search--1__from {
    width: auto;
  }
  .style-search--1__from input,
.style-search--1__from button,
.style-search--1__from select {
    height: 60px;
    font-size: 16px;
  }
  .style-search--1--select select {
    padding: 0 60px 0 12px;
    border-width: 1px;
  }
  .style-search--1__keyword {
    margin-top: 12px;
  }
  .style-search--1__keyword--input {
    width: calc(100% - 60px);
  }
  .style-search--1__keyword--input input {
    padding: 0 12px;
    border-width: 1px;
  }
  .style-search--1__keyword--submit {
    width: 60px;
    height: 60px;
  }
  .style-search--1__keyword--submit button {
    background-size: 34px;
  }
}
.style-search--1__links {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style-search--1__links {
    margin: 40px 46px 0;
  }
}
.style-search--1__links__box {
  margin-top: 10px;
  padding: 25px;
  background: #f8fbfd;
}
.style-search--1__links__box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__box {
    margin-top: 20px;
    padding: 40px 50px;
  }
}
.style-search--1__links--title {
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-search--1__links--title {
    font-size: 28px;
  }
}
.style-search--1__links__content {
  margin-top: 15px;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__content {
    margin-top: 25px;
  }
}
.style-search--1__links__titles {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.style-search--1__links__titles--item {
  border-bottom: solid #c6dbec 1px;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .style-search--1__links__titles--item {
    margin: 10px 30px 0 0;
    width: calc(50% - 15px);
  }
  .style-search--1__links__titles--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-search--1__links__titles--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-search--1__links__titles--item {
    margin: 38px 40px 0 0;
    width: calc(25% - 30px);
    font-size: 16px;
  }
  .style-search--1__links__titles--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .style-search--1__links__titles--item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.style-search--1__links__titles--item > a {
  display: block;
  padding: 6px 0;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .style-search--1__links__titles--item > a {
    padding: 8px;
  }
}
.style-index {
  margin-bottom: 96px;
}
.style-index__lists--item {
  position: relative;
  margin-top: 60px;
  padding: 40px 44px;
  background: #f8fbfd;
}
.style-index__lists--item:nth-of-type(even) {
  background: #e5f0f8;
}
.style-index__lists--item:first-child {
  margin-top: 0;
}
.style-index__lists--title {
  display: inline-block;
  position: relative;
  margin-bottom: 40px;
  vertical-align: top;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168b7;
}
.style-index__lists--title:after {
  content: "";
  display: block;
  margin-top: 6px;
  width: 100%;
  height: 4px;
  background: #0168b7;
}
.style-index__lists__detail__box--title {
  font-size: 18px;
  font-weight: 700;
  color: #0168b7;
}
.style-index__lists__detail__box--title a {
  display: inline-block;
  vertical-align: top;
  color: #0168b7;
}
.style-index__lists__detail__box--title a:before {
  content: "";
  display: inline-block;
  margin: 6px 4px 0 0;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
.style-index__lists__detail__box--text {
  margin-top: 10px;
  font-size: 15px;
}
.style-index__lists__links--item {
  font-size: 18px;
  font-weight: 700;
  color: #0168b7;
}
.style-index__lists__links--item a {
  display: inline-block;
  vertical-align: top;
  color: #0168b7;
}
.style-index__lists__links--item a:before {
  content: "";
  display: inline-block;
  margin: 6px 4px 0 0;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .style-index__lists__detail {
    display: flex;
    flex-wrap: wrap;
  }
  .style-index__lists__detail__box {
    margin: 40px 2.8776978418% 0 0;
    width: 48.5611510791%;
  }
  .style-index__lists__detail__box:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-index__lists__detail__box:nth-of-type(2n) {
    margin-right: 0;
  }
  .style-index__lists__links {
    display: flex;
    flex-wrap: wrap;
  }
  .style-index__lists__links--item {
    margin-top: 12px;
    padding-right: 20px;
    width: 25%;
  }
  .style-index__lists__links--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .style-index {
    margin-bottom: 50px;
  }
  .style-index__lists {
    margin: 0 20px;
  }
  .style-index__lists--item {
    margin-top: 30px;
    padding: 24px 20px;
  }
  .style-index__lists--title {
    margin-bottom: 24px;
    font-size: 21px;
  }
  .style-index__lists--title:after {
    margin-top: 6px;
    height: 2px;
  }
  .style-index__lists__detail__box {
    margin-top: 18px;
  }
  .style-index__lists__detail__box:first-child {
    margin-top: 0;
  }
  .style-index__lists__detail__box--text {
    margin-top: 8px;
  }
  .style-index__lists__links--item {
    margin-top: 18px;
  }
  .style-index__lists__links--item:first-child {
    margin-top: 0;
  }
}
.style-relation {
  position: relative;
}
@media screen and (max-width: 768px) {
  .style-relation {
    margin: 40px 20px;
  }
}
@media screen and (min-width: 769px) {
  .style-relation {
    margin: 65px 0;
  }
}
.style-relation-lists {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style-relation-lists {
    margin-top: 40px;
  }
}
.style-relation-lists--item {
  position: relative;
}
@media screen and (max-width: 768px) {
  .style-relation-lists--item {
    margin: 18px 4.4776119402% 0 0;
    width: 47.7611940299%;
  }
  .style-relation-lists--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-relation-lists--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .style-relation-lists--item {
    margin: 20px 6.6666666668% 0 0;
    width: 23.3333333333%;
  }
  .style-relation-lists--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style-relation-lists--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
.style-relation-lists--photo {
  position: relative;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .style-relation-lists--photo {
    margin-bottom: 12px;
  }
}
.style-relation-lists--photo > a {
  display: block;
  position: relative;
  width: 100%;
}
.style-relation-lists__info {
  position: relative;
}
.style-relation-lists--title {
  font-size: 15px;
  font-weight: 500;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .style-relation-lists--title {
    font-size: 18px;
  }
}
.style-relation-lists--title > a {
  color: #0168B7;
}

/* style1
------------------------------------------------ */
.style1 {
  position: relative;
  padding-top: 30px;
  background-color: #E5F0F8;
}
@media screen and (min-width: 769px) {
  .style1 {
    padding-top: 40px;
  }
}
.style1-hd {
  position: relative;
}
@media screen and (max-width: 768px) {
  .style1-hd {
    margin: 0 20px 10px;
  }
}
@media screen and (min-width: 769px) {
  .style1-hd {
    padding-bottom: 15px;
  }
}
.style1-hd-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 30px;
  font-weight: normal;
  line-height: 1.3333333333;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .style1-hd-title {
    margin-bottom: 30px;
    font-size: 52px;
    line-height: 1.1923076923;
  }
}
@media screen and (min-width: 769px) {
  .style1 .topicpath {
    margin-bottom: 15px;
  }
}
.style1-content {
  position: relative;
}
@media screen and (max-width: 768px) {
  .style1-content {
    margin: 0 20px;
  }
}
.style1-block {
  position: relative;
  padding: 20px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .style1-block {
    padding: 40px 60px;
  }
}
.style1-block + .style1-block {
  margin-top: 20px;
}
.style1-block-title {
  margin-bottom: 20px;
  padding-bottom: 8px;
  border-bottom: 1px solid #ccc;
  font-size: 19px;
  font-weight: bold;
  line-height: 1.5263157895;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .style1-block-title {
    margin-bottom: 30px;
    padding-bottom: 16px;
    font-size: 28px;
  }
}
.style1-block-content {
  position: relative;
}
.style1-block-content figure {
  display: block;
  width: 100%;
}
.style1-block-content figure figcaption {
  margin-top: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .style1-block-content figure figcaption {
    margin-top: 14px;
    font-size: 18px;
  }
}
.style1-sub {
  position: relative;
}
.style1-sub + .style1-sub {
  margin-top: 40px;
}
.style1-sub-title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6111111111;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .style1-sub-title {
    margin-bottom: 18px;
    font-size: 24px;
  }
}
.style1-sub-content {
  position: relative;
}
.style1-text1 {
  margin: 20px 0;
  font-size: 15px;
  line-height: 1.6666666667;
}
@media screen and (min-width: 769px) {
  .style1-text1 {
    margin: 40px 0 30px;
  }
}
.style1-text1:first-child {
  margin-top: 0;
}
.style1-image1 {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style1-image1 {
    margin: 30px 90px 0;
  }
}
.style1-images1 {
  margin: 20px 0;
}
@media screen and (min-width: 769px) {
  .style1-images1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .style1-images1 + .style1-text1 {
    margin-top: 40px;
  }
}
@media screen and (max-width: 768px) {
  .style1-images1 > figure {
    margin: 0 auto;
  }
}
@media screen and (min-width: 769px) {
  .style1-images1 > figure {
    margin-left: 2.7777777778%;
  }
  .style1-images1 > figure:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 768px) {
  .style1-images1 > figure + figure {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .style1-images1 > figure.w-sp-262 {
    width: 88.813559322%;
  }
  .style1-images1 > figure.w-sp-256 {
    width: 86.7796610169%;
  }
  .style1-images1 > figure.w-sp-212 {
    width: 71.8644067797%;
  }
}
@media screen and (min-width: 769px) {
  .style1-images1 > figure.w-pc-874 {
    width: 80.9259259259%;
  }
  .style1-images1 > figure.w-pc-852 {
    width: 78.8888888889%;
  }
  .style1-images1 > figure.w-pc-744 {
    width: 68.8888888889%;
  }
  .style1-images1 > figure.w-pc-723 {
    width: 66.9444444444%;
  }
  .style1-images1 > figure.w-pc-600 {
    width: 55.5555555556%;
  }
  .style1-images1 > figure.w-pc-494 {
    width: 45.7407407407%;
  }
  .style1-images1 > figure.w-pc-483 {
    width: 44.7222222222%;
  }
  .style1-images1 > figure.w-pc-463 {
    width: 42.8703703704%;
  }
  .style1-images1 > figure.w-pc-457 {
    width: 42.3148148148%;
  }
  .style1-images1 > figure.w-pc-452 {
    width: 41.8518518519%;
  }
  .style1-images1 > figure.w-pc-440 {
    width: 40.7407407407%;
  }
  .style1-images1 > figure.w-pc-421 {
    width: 38.9814814815%;
  }
  .style1-images1 > figure.w-pc-414 {
    width: 38.3333333333%;
  }
  .style1-images1 > figure.w-pc-400 {
    width: 37.037037037%;
  }
  .style1-images1 > figure.w-pc-388 {
    width: 35.9259259259%;
  }
  .style1-images1 > figure.w-pc-355 {
    width: 32.8703703704%;
  }
  .style1-images1 > figure.w-pc-352 {
    width: 32.5925925926%;
  }
  .style1-images1 > figure.w-pc-344 {
    width: 31.8518518519%;
  }
  .style1-images1 > figure.w-pc-341 {
    width: 31.5740740741%;
  }
  .style1-images1 > figure.w-pc-326 {
    width: 30.1851851852%;
  }
  .style1-images1 > figure.w-pc-300 {
    width: 27.7777777778%;
  }
  .style1-images1 > figure.w-pc-290 {
    width: 26.8518518519%;
  }
  .style1-images1 > figure.w-pc-275 {
    width: 25.462962963%;
  }
  .style1-images1 > figure.w-pc-268 {
    width: 24.8148148148%;
  }
  .style1-images1 > figure.w-pc-262 {
    width: 24.2592592593%;
  }
  .style1-images1 > figure.w-pc-256 {
    width: 23.7037037037%;
  }
  .style1-images1 > figure.w-pc-212 {
    width: 19.6296296296%;
  }
  .style1-images1 > figure.w-pc-132 {
    width: 12.2222222222%;
  }
}
@media screen and (min-width: 769px) {
  .style1-images1 > figure.space-pc-10 {
    margin-left: 0.9259259259%;
  }
}
.style1-lists1 {
  position: relative;
}
@media screen and (min-width: 769px) {
  .style1-lists1 {
    display: flex;
    flex-wrap: wrap;
  }
}
.style1-lists1-item {
  position: relative;
  padding: 0 30px 0 75px;
  background-color: #E5F0F8;
  border-radius: 50px;
  font-size: 14px;
  font-weight: bold;
  line-height: 1.7142857143;
}
@media screen and (max-width: 768px) {
  .style1-lists1-item {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100px;
  }
}
@media screen and (min-width: 769px) {
  .style1-lists1-item {
    margin: 0.9259259259% 0.9259259259% 0 0;
    padding: 80px 24px 10px;
    width: 19.2592592593%;
    min-height: 180px;
    text-align: center;
    font-size: 15px;
  }
  .style1-lists1-item:nth-of-type(-n+5) {
    margin-top: 0;
  }
  .style1-lists1-item:nth-of-type(5n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .style1-lists1-item + .style1-lists1-item {
    margin-top: 10px;
  }
}
.style1-lists1-item::after {
  content: "";
  position: absolute;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .style1-lists1-item::after {
    top: 50%;
    left: 20px;
    transform: translateY(-50%);
  }
}
@media screen and (min-width: 769px) {
  .style1-lists1-item::after {
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
  }
}
.style1-lists1-item.i-light::after {
  width: 45px;
  height: 48px;
  background-image: url(../img/icon/light1.png);
}
.style1-lists1-item.i-brain::after {
  width: 40px;
  height: 41px;
  background-image: url(../img/icon/brain1.png);
}
@media screen and (min-width: 769px) {
  .style1-lists1-item.i-brain::after {
    top: 29px;
    width: 33px;
    height: 34px;
  }
}
.style1-lists2 {
  position: relative;
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .style1-lists2 {
    display: flex;
    flex-wrap: wrap;
    margin: -20px 0 30px -20px;
  }
}
.style1-lists2-item {
  position: relative;
  padding: 18px 15px;
  background-color: #F8F8F8;
  border: 1px solid #ccc;
  text-align: center;
  font-size: 15px;
  line-height: 1.4666666667;
}
@media screen and (min-width: 769px) {
  .style1-lists2-item {
    margin: 20px 0 0 20px;
    padding: 14px 15px;
    width: calc(33.3333333% - 20px);
    font-size: 18px;
  }
  .style1-lists2-item.w-double {
    width: calc(50% - 20px);
  }
}
@media screen and (max-width: 768px) {
  .style1-lists2-item + .style1-lists2-item {
    margin-top: 15px;
  }
}
.style1-layout1 {
  position: relative;
}
@media screen and (min-width: 769px) {
  .style1-layout1 {
    display: flex;
  }
}
.style1-layout1-text {
  font-size: 15px;
  line-height: 1.6666666667;
}
@media screen and (min-width: 769px) {
  .style1-layout1-text {
    margin-left: 10px;
    width: calc(100% - 310px);
  }
}
@media screen and (max-width: 768px) {
  .style1-layout1-image {
    margin: 20px 13px 0;
  }
}
@media screen and (min-width: 769px) {
  .style1-layout1-image {
    margin-left: 30px;
    width: 270px;
  }
}
.style1-layout2 {
  margin-top: 30px;
}
.style1-layout2-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.3888888889;
}
@media screen and (min-width: 769px) {
  .style1-layout2-title {
    font-size: 20px;
    line-height: 1.25;
  }
}
.style1-layout2-content {
  position: relative;
}
@media screen and (min-width: 769px) {
  .style1-layout2-content {
    display: flex;
    flex-wrap: wrap;
  }
}
.style1-layout2-content > dl {
  padding: 20px 10px;
  background-color: #F8F8F8;
}
@media screen and (min-width: 769px) {
  .style1-layout2-content > dl {
    margin: 2.7777777778% 2.7777777778% 0 0;
    padding: 30px 52px;
    width: 48.6111111111%;
  }
  .style1-layout2-content > dl:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .style1-layout2-content > dl:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .style1-layout2-content > dl + dl {
    margin-top: 10px;
  }
}
.style1-layout2-content > dl > dt {
  margin-bottom: 10px;
  text-align: center;
  font-size: 15px;
  font-weight: bold;
  line-height: 1.4666666667;
}
@media screen and (min-width: 769px) {
  .style1-layout2-content > dl > dt {
    font-size: 18px;
    line-height: 1.5;
  }
}
.style1-layout2-content > dl > dd + dt {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .style1-layout2-content > dl > dd + dt {
    margin-top: 30px;
  }
}
.style1-layout2-content > dl > dd figure figcaption {
  font-weight: normal;
  line-height: 1.4666666667;
}
.style1-box1 {
  position: relative;
  margin-top: 20px;
  padding: 20px;
  background-color: #F8F8F8;
}
@media screen and (min-width: 769px) {
  .style1-box1 {
    margin-top: 30px;
    padding: 24px 40px;
  }
}
.style1-box1-title {
  margin-bottom: 15px;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.4;
}
.style1-box1-content {
  position: relative;
}
@media screen and (min-width: 769px) {
  .style1-box1-content {
    display: flex;
  }
}
.style1-box1-lists {
  position: relative;
}
@media screen and (min-width: 769px) {
  .style1-box1-lists {
    width: 50%;
  }
}
@media screen and (max-width: 768px) {
  .style1-box1-lists + .style1-box1-lists {
    margin-top: 10px;
  }
}
.style1-box1-lists--item {
  font-size: 15px;
  line-height: 1.6666666667;
}
.style1-box1-lists--item + .style1-box1-lists--item {
  margin-top: 10px;
}
.style1-box1-lists--item > a, .style1-box1-lists--item > span {
  display: inline-block;
  position: relative;
  padding-left: 25px;
  vertical-align: top;
}
.style1-box1-lists--item > a::before, .style1-box1-lists--item > span::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 12px;
  height: 9px;
  background: url(../img/icon/check1.png) no-repeat center center;
  background-size: 100%;
}
.style1-box1-lists--item > a {
  text-decoration: underline;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .style1-merit1 {
    display: flex;
    flex-wrap: wrap;
  }
}
.style1-merit1-item {
  position: relative;
  padding-bottom: 42.3728813559%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .style1-merit1-item {
    margin: 0.9259259259% 0.9259259259% 0 0;
    padding-bottom: 19.2592592593%;
    width: 19.2592592593%;
    text-align: center;
  }
  .style1-merit1-item:nth-of-type(-n+5) {
    margin-top: 0;
  }
  .style1-merit1-item:nth-of-type(5n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .style1-merit1-item + .style1-merit1-item {
    margin-top: 10px;
  }
}
.style1-merit1-item > .frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3284C4;
  border-radius: 63px;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .style1-merit1-item > .frame {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 769px) {
  .style1-merit1-item > .frame {
    border-radius: 50%;
  }
}
.style1-merit1-top {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .style1-merit1-top {
    width: 100px;
  }
}
@media screen and (min-width: 769px) {
  .style1-merit1-top {
    margin-bottom: 10px;
    padding-top: 28px;
  }
}
.style1-merit1-name {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .style1-merit1-name {
    font-size: 18px;
  }
}
.style1-merit1-num {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 32px;
  line-height: 1.0625;
}
@media screen and (min-width: 769px) {
  .style1-merit1-num {
    font-size: 34px;
  }
}
.style1-merit1-text {
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5333333333;
}
@media screen and (max-width: 768px) {
  .style1-merit1-text {
    padding-right: 30px;
    width: calc(100% - 100px);
  }
}
@media screen and (min-width: 769px) {
  .style1-merit1-text {
    padding: 0 18px;
  }
}
@media screen and (min-width: 769px) {
  .style1-merit2 {
    display: flex;
    flex-wrap: wrap;
  }
}
.style1-merit2-item {
  position: relative;
  padding-bottom: 42.3728813559%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .style1-merit2-item {
    margin: 0.9722222222% 0.9722222222% 0 0;
    padding-bottom: 11.5740740741%;
    width: 32.6851851852%;
  }
  .style1-merit2-item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .style1-merit2-item:nth-of-type(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .style1-merit2-item + .style1-merit2-item {
    margin-top: 10px;
  }
}
.style1-merit2-item > .frame {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #3284C4;
  border-radius: 63px;
  color: #fff;
}
.style1-merit2-top {
  text-align: center;
  width: 100px;
}
@media screen and (min-width: 769px) {
  .style1-merit2-top {
    padding-left: 20px;
    width: 138px;
  }
}
.style1-merit2-name {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .style1-merit2-name {
    font-size: 18px;
  }
}
.style1-merit2-num {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 32px;
  line-height: 1.0625;
}
@media screen and (min-width: 769px) {
  .style1-merit2-num {
    font-size: 34px;
  }
}
.style1-merit2-text {
  padding-right: 30px;
  width: calc(100% - 100px);
  font-size: 15px;
  font-weight: bold;
  line-height: 1.5333333333;
}
@media screen and (min-width: 769px) {
  .style1-merit2-text {
    padding-right: 50px;
    width: calc(100% - 138px);
  }
}
.style1-back-link {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .style1-back-link {
    margin-top: 60px;
    font-size: 28px;
  }
}
.style1-back-link > a {
  display: inline-block;
  text-decoration: underline;
  vertical-align: top;
  color: #0168B7;
}
.style1-back-link + .style1-flow-btn {
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .style1-back-link + .style1-flow-btn {
    margin-top: 68px;
  }
}
.style1-flow-btn {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .style1-flow-btn {
    margin: 85px auto 0;
    width: 83.3333333333%;
  }
}
.style1-flow-btn > a {
  display: block;
  position: relative;
  padding: 22px 0 18px;
  background-color: #2B9AB6;
  border-radius: 6px;
}
@media screen and (min-width: 769px) {
  .style1-flow-btn > a {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 45px 0;
    border-radius: 10px;
  }
}
.style1-flow-btn > a::before {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
  border: 2px solid #fff;
  border-radius: 6px;
}
@media screen and (min-width: 769px) {
  .style1-flow-btn > a::before {
    border-radius: 10px;
  }
}
.style1-flow-btn > a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 22px;
  transform: translateY(-50%);
  width: 12px;
  height: 25px;
  background: url(../img/icon/arrow8.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .style1-flow-btn > a::after {
    right: 75px;
    width: 14px;
    height: 29px;
  }
}
.style1-flow-btn--icon {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 769px) {
  .style1-flow-btn--icon {
    width: 46.9%;
  }
}
.style1-flow-btn--icon i {
  width: 35px;
}
@media screen and (max-width: 768px) {
  .style1-flow-btn--icon i {
    margin: 0 4px;
  }
}
@media screen and (min-width: 769px) {
  .style1-flow-btn--icon i {
    width: 18.5501066098%;
  }
  .style1-flow-btn--icon i:not(:first-child) {
    margin-left: 1.8123667378%;
  }
}
.style1-flow-btn--text {
  font-size: 26px;
  font-weight: bold;
  line-height: 1.6923076923;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .style1-flow-btn--text {
    margin-top: 2px;
    text-align: center;
  }
}
@media screen and (min-width: 769px) {
  .style1-flow-btn--text {
    margin-left: 32px;
    font-size: 40px;
    line-height: 1.1;
  }
}
.style1 .block-contact-btm {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .style1 .block-contact-btm {
    margin-top: 80px;
  }
}
/* block
------------------------------------------------ */
.block {
  /* contact */
}
.block-contact {
  padding: 120px 0 100px;
  background: url(../img/share/contact_bg.png) no-repeat center center;
  background-size: cover;
}
.block-contact .title--3 {
  color: #fff;
}
.block-contact .title--3:before, .block-contact .title--3:after {
  background: #fff;
}
.block-contact--text {
  text-align: center;
  font-size: 28px;
  color: #fff;
}
.block-contact__links {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
}
.block-contact__links--item {
  position: relative;
  margin: 20px 10px 0;
  font-size: 24px;
  line-height: 1.2;
}
.block-contact__links--item a {
  display: block;
  position: relative;
  padding: 20px 38px;
  background: #fff;
  border-radius: 35px;
  color: #0168b7;
}
.block-contact__links--item a:after {
  content: "";
  display: inline-block;
  margin: 4px 0 0 22px;
  width: 11px;
  height: 22px;
  background: url(../img/icon/arrow2.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .block-contact {
    padding: 60px 25px 48px;
  }
  .block-contact--text {
    font-size: 18px;
  }
  .block-contact__links {
    display: block;
    margin: 24px 8px 0;
  }
  .block-contact__links--item {
    margin: 16px 0 0;
    text-align: center;
    font-size: 16px;
  }
  .block-contact__links--item:first-child {
    margin-top: 0;
  }
  .block-contact__links--item a {
    padding: 12px 10px;
  }
  .block-contact__links--item a:after {
    position: absolute;
    top: 50%;
    right: 20px;
    margin: -6px 0 0;
    width: 7px;
    height: 13px;
  }
}
.block-contact-btm {
  padding: 48px 0 54px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .block-contact-btm {
    background-image: url(../img/share/contact_btm_bg.png);
  }
  .block-contact-btm > .wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .block-contact-btm {
    padding: 60px 0;
    background-image: url(../img/share/contact_btm_bg-pc.png);
  }
}
.block-contact-btm--title {
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .block-contact-btm--title {
    font-size: 52px;
  }
}
.block-contact-btm--text {
  margin-top: 20px;
  text-align: center;
  font-size: 14px;
  line-height: 1.7142857143;
}
@media screen and (min-width: 769px) {
  .block-contact-btm--text {
    margin-top: 26px;
    font-size: 28px;
    line-height: 1.2142857143;
  }
}
.block-contact-btm--btn {
  margin: 30px auto 0;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .block-contact-btm--btn {
    margin-top: 35px;
    width: 420px;
    font-size: 24px;
    font-weight: 500;
  }
}
.block-contact-btm--btn > a {
  display: block;
  position: relative;
  padding: 22px 10px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 35px;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .block-contact-btm--btn > a {
    padding: 18px 10px;
  }
}
.block-contact-btm--btn > a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  width: 10px;
  height: 17px;
  background: url(../img/icon/arrow7.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .block-contact-btm--btn > a::after {
    right: 40px;
  }
}

/* pager
------------------------------------------------ */
.pager-1 {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .pager-1 {
    margin-top: 80px;
  }
}
.pager-1-item {
  position: relative;
  margin: 0 2px;
  width: 34px;
  height: 34px;
  text-align: center;
  font-family: Arial, sans-serif;
  font-size: 16px;
  line-height: 34px;
}
.pager-1-item a,
.pager-1-item span {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
.pager-1-item span {
  background: #0168b7;
  color: #fff;
}
.pager-1-item.active a {
  pointer-events: none;
  background: #0168b7;
  color: #fff;
}
.pager-1-item.prev a, .pager-1-item.next a {
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.pager-1-item.prev a:after, .pager-1-item.next a:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -6px 0 0 -6px;
  width: 12px;
  height: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.pager-1-item.prev a:after {
  background-image: url(../img/icon/pager1_prev.png);
}
.pager-1-item.next a:after {
  background-image: url(../img/icon/pager1_next.png);
}
.pager-1 .disabled {
  display: none;
}
.pager-1 .hide {
  display: none;
}
.pager-single {
  margin-top: 25px;
  padding-top: 40px;
  border-top: solid #ccc 1px;
}
@media screen and (min-width: 769px) {
  .pager-single {
    margin-top: 60px;
    padding-top: 60px;
  }
}
.pager-single-post {
  display: flex;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .pager-single-post {
    margin: 0 32px;
  }
}
.pager-single-post--item {
  position: relative;
  margin: 0 4px;
  width: calc(50% - 4px);
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
.pager-single-post--item:first-child {
  margin-left: 0;
}
.pager-single-post--item:last-child {
  margin-right: 0;
}
@media screen and (min-width: 769px) {
  .pager-single-post--item {
    margin: 0 10px;
    width: 140px;
    font-size: 15px;
  }
}
.pager-single-post--item a {
  display: block;
  position: relative;
  padding: 12px 0;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .pager-single-post--item a {
    padding: 15px 0;
  }
}
.pager-single-post--item a:before, .pager-single-post--item a:after {
  content: "";
  display: inline-block;
  width: 7px;
  height: 10px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: top;
}
@media screen and (max-width: 768px) {
  .pager-single-post--item a:before, .pager-single-post--item a:after {
    position: absolute;
    top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item a:before, .pager-single-post--item a:after {
    margin-top: 5px;
  }
}
.pager-single-post--item.prev a:before {
  background-image: url(../img/icon/pager_single_prev.png);
}
@media screen and (max-width: 768px) {
  .pager-single-post--item.prev a:before {
    left: 10px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item.prev a:before {
    margin-right: 18px;
  }
}
.pager-single-post--item.prev a:after {
  display: none;
}
.pager-single-post--item.next a:before {
  display: none;
}
.pager-single-post--item.next a:after {
  background-image: url(../img/icon/pager_single_next.png);
}
@media screen and (max-width: 768px) {
  .pager-single-post--item.next a:after {
    right: 10px;
  }
}
@media screen and (min-width: 769px) {
  .pager-single-post--item.next a:after {
    margin-left: 18px;
  }
}
.pager-single-back {
  margin: 12px 32px 0;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .pager-single-back {
    margin: 40px auto 0;
    width: 400px;
    font-size: 24px;
  }
}
.pager-single-back a {
  display: block;
  padding: 16px 0;
  background: #0168b7;
  border: solid #0168b7 1px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .pager-single-back a {
    padding: 25px 0;
  }
}

/* table
------------------------------------------------ */
table.temperature {
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
table.temperature th,
table.temperature td {
  padding: 10px;
}
table.temperature th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.temperature td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.25em;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.test {
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
table.test th,
table.test td {
  padding: 10px;
}
table.test th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.test td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.overcharge {
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
table.overcharge th,
table.overcharge td {
  padding: 10px;
}
table.overcharge th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.overcharge td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.fall {
  width: 100%;
  border-top: 1px solid #e5e5e5;
  border-left: 1px solid #e5e5e5;
}
table.fall th,
table.fall td {
  padding: 10px;
}
table.fall th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.fall td {
  text-align: center;
  vertical-align: middle;
  line-height: 1.45em;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
}
table.chemi01 {
  width: 100%;
  border-top: 1px solid #f1f1ee;
  border-left: 1px solid #f1f1ee;
}
table.chemi01 th,
table.chemi01 td {
  padding: 10px;
}
table.chemi01 th {
  background: #fbfbf4;
  font-weight: normal;
  padding: 5px 7px 5px 10px;
  border-right: 1px solid #f1f1ee;
}
table.chemi01 td {
  border-right: 1px solid #f1f1ee;
  border-bottom: 1px solid #f1f1ee;
}
table.chemi01 td ul {
  margin: 10px 0 10px 10px;
}
table.acousmato {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.acousmato th,
table.acousmato td {
  padding: 10px;
}
table.acousmato th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  padding: 7px 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.acousmato td {
  background: #fff;
  padding: 7px 5px 3px 22px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.petroleum01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.petroleum01 th,
table.petroleum01 td {
  padding: 10px;
}
table.petroleum01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  font-size: 109%;
  padding: 5px 0 5px 23px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.petroleum01 td {
  background: #fff;
  padding: 9px 40px;
  line-height: 1.4em;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.petroleum02 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.petroleum02 th,
table.petroleum02 td {
  padding: 10px;
}
table.petroleum02 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  font-size: 109%;
  padding: 5px 0 5px 23px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.petroleum02 td {
  background: #fff;
  padding: 7px 40px;
  line-height: 1.6em;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.plant01 th,
table.plant01 td {
  padding: 10px;
}
table.plant01 th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  padding: 10px 0 10px 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant01 td {
  background: #fff;
  padding: 2px 5px 0px 25px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant02 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.plant02 th,
table.plant02 td {
  padding: 10px;
}
table.plant02 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  font-size: 109%;
  padding: 15px 0 10px 23px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant02 td {
  background: #fff;
  padding: 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant02 td ul {
  margin: 14px 0 7px 30px;
}
table.plant02 td ul li {
  position: relative;
  line-height: 1.4em;
  padding: 0 0 0 20px;
}
table.plant02 td ul li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
table.plant02 td ul li span {
  display: block;
  text-indent: 1em;
}
table.plant03 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.plant03 th,
table.plant03 td {
  padding: 10px;
}
table.plant03 th {
  background: #fbfbf4;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  padding: 10px 0 10px 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant03 td {
  background: #fff;
  vertical-align: top;
  padding: 3px 0 0 18px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.plant03 td ul li {
  position: relative;
  line-height: 1.9em;
  padding: 0 0 0 20px;
}
table.plant03 td ul li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
table.gas_turbine01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.gas_turbine01 th,
table.gas_turbine01 td {
  padding: 10px;
}
table.gas_turbine01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  font-size: 109%;
  padding: 5px 0 5px 23px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.gas_turbine01 td {
  background: #fff;
  padding: 0;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.gas_turbine01 td ol {
  margin: 14px 0 20px 18px;
}
table.gas_turbine01 td ol li {
  line-height: 1.4em;
  padding: 0 0 0 10px;
}
table.analysis {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.analysis th,
table.analysis td {
  padding: 10px;
}
table.analysis .tableHeadTitle {
  text-align: center !important;
  padding: 7px 0 !important;
}
table.analysis th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  padding: 7px 10px 7px 18px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.analysis td {
  background: #fff;
  line-height: 1.6em;
  padding: 7px 5px 3px 15px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.analysis .contentsTitle02 {
  font-size: 150%;
  color: #255791;
  border-left: 7px solid #3b499f;
  height: 30px;
  margin-bottom: 20px;
  padding: 4px 0 0 14px;
}
table.film {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.film th,
table.film td {
  padding: 10px;
}
table.film th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-size: 109%;
  font-weight: bold;
  padding: 7px 10px 7px 18px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.film td {
  background: #fff;
  text-align: center;
  padding: 10px 5px 7px 5px;
  width: 125px;
  border-right: 1px solid #e1e1db;
}
table.film td .bgColorBlue {
  background: #aad0de !important;
}
table.film td .bdB_dot {
  border-bottom: 1px dotted #e1e1db !important;
}
table.film td .bdG_dol {
  border-bottom: 1px solid #e1e1db;
}
table.case01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.case01 th,
table.case01 td {
  padding: 10px;
}
table.case01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  width: 115px;
  padding: 6px 5px 6px 5px;
  border-right: 1px solid #e1e1db;
}
table.case01 td {
  background: #fff;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.case01 td ul {
  margin: 11px 0 10px 17px;
}
table.case01 td ul li {
  position: relative;
  line-height: 1.9em;
  padding: 0 0 0 20px;
}
table.case01 td ul li:before {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
table.frictio01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.frictio01 th,
table.frictio01 td {
  padding: 10px;
}
table.frictio01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: top;
  font-weight: normal;
  width: 115px;
  padding: 6px 10px 6px 10px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.frictio01 td {
  background: #fff;
  line-height: 1.4em;
  vertical-align: top;
  font-size: 92%;
  padding: 7px 5px 7px 7px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.tableInner01, table.tableInner02 {
  background: #fff;
  width: 100%;
  border-top: 3px solid #fff;
  border-left: 3px solid #fff;
  border-bottom: 3px solid #fff;
  border-right: 3px solid #fff;
}
table.tableInner01 th,
table.tableInner01 td, table.tableInner02 th,
table.tableInner02 td {
  padding: 10px;
  border-bottom: 2px solid #fff;
}
table.tableInner01 td,
table.tableInner01 td, table.tableInner02 td,
table.tableInner02 td {
  font-size: 100%;
  text-align: center;
  vertical-align: middle;
  background: #f1f1f1;
  border: none;
  padding: 9px 0;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
}
table .tableTitleC {
  text-align: center !important;
}
table.corrosionCase01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.corrosionCase01 th,
table.corrosionCase01 td {
  padding: 10px;
}
table.corrosionCase01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: top;
  font-size: 92%;
  font-weight: normal;
  width: 245px;
  padding: 10px 10px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.corrosionCase01 td {
  background: #fff;
  padding: 11px 5px 11px 22px;
  line-height: 1.4em;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.consulting {
  width: 100%;
  border-top: 1px solid #f2f2f2;
  border-left: 1px solid #f2f2f2;
}
table.consulting th,
table.consulting td {
  padding: 10px;
}
table.consulting th {
  background: #fbfaf5;
  font-weight: normal;
  width: 118px;
  padding: 6px 10px;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
table.consulting td {
  padding: 6px 10px;
  border-right: 1px solid #f2f2f2;
  border-bottom: 1px solid #f2f2f2;
}
table.corrosion01 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.corrosion01 th,
table.corrosion01 td {
  padding: 10px;
}
table.corrosion01 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  width: 115px;
  padding: 0 5px 0 20px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.corrosion01 td {
  background: #fff;
  padding: 11px 5px 11px 22px;
  line-height: 1.4em;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.corrosion02 {
  width: 100%;
  border-top: 1px solid #e1e1db;
  border-left: 1px solid #e1e1db;
}
table.corrosion02 th,
table.corrosion02 td {
  padding: 10px;
}
table.corrosion02 th {
  background: #fbfbf4;
  text-align: left;
  vertical-align: middle;
  font-weight: normal;
  width: 115px;
  padding: 0 5px 0 20px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.corrosion02 td {
  background: #fff;
  padding: 11px 5px 11px 25px;
  border-right: 1px solid #e1e1db;
  border-bottom: 1px solid #e1e1db;
}
table.facilities {
  width: 100%;
}
table.facilities th h3 {
  margin-top: 25px;
}
table.facilities td {
  padding: 13px 0 10px 0;
}
table.facilities td dl {
  margin-left: 20px;
}
table.facilities td dt {
  background: url(../../common/img/listIcom01.gif) no-repeat left 8px;
  font-size: 117%;
  padding: 0 0 0 20px;
}
table.facilities td dd {
  font-size: 92%;
  padding-bottom: 11px;
}
table.facilities .table_bdB {
  border-bottom: 1px dotted #b2b2b2;
}

/* table */
.table_style1 {
  margin: 18px auto;
  border-collapse: collapse;
  border-spacing: 0;
  border-top: 1px solid #29639b;
  border-left: 1px solid #29639b;
}
.table_style1:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .table_style1 {
    margin-top: 32px;
  }
}
.table_style1 th,
.table_style1 td {
  padding: 4px 6px;
  border-right: 1px solid #29639b;
  border-bottom: 1px solid #29639b;
  vertical-align: top;
}
.table_style1 .vAlignM {
  vertical-align: middle;
}
.table_style1 th {
  background-color: #ffea04;
  text-align: left;
}
.table_style1 td p:last-child {
  margin-bottom: 0;
}

.semTable {
  width: 100%;
  margin: 0 auto;
  border: 1px solid #333;
}
@media screen and (min-width: 769px) {
  .semTable {
    max-width: 250px;
  }
}
.semTable tr,
.semTable td,
.semTable th {
  border: 1px solid #333;
}
.semTable td {
  text-align: right;
}
.semTable thead {
  text-align: center;
}

/* business
------------------------------------------------ */
.business {
  /* top */
  /* industry */
  /* solution */
  /* detail */
  /* page */
}
.business-index {
  position: relative;
}
@media screen and (max-width: 768px) {
  .business-index-tab {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .business-index-tab {
    display: flex;
    flex-wrap: wrap;
  }
}
.business-index-tab--item {
  position: relative;
  text-align: center;
  font-size: 19px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .business-index-tab--item {
    margin-top: 2px;
  }
  .business-index-tab--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .business-index-tab--item {
    margin: 2px 2px 0 0;
    width: calc(33.333333% - 1.5px);
    font-size: 24px;
  }
  .business-index-tab--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .business-index-tab--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.business-index-tab--item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px 0;
  height: 100%;
  background: #fff;
  border: solid #0168b7 1px;
  color: #0168b7;
}
.business-index-tab--item > a.active {
  background: #0168b7;
  color: #fff;
}
.business-index-tab--item > a.active:after {
  background-image: url(../img/icon/arrow3.png);
}
@media screen and (min-width: 769px) {
  .business-index-tab--item > a {
    padding: 35px 30px;
  }
}
.business-index-tab--item > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -8px;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow4.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .business-index-tab--item > a:after {
    right: 20px;
    margin-top: -9px;
    width: 9px;
    height: 19px;
  }
}
@media screen and (max-width: 768px) {
  .business-index .title--5 {
    padding: 10px 20px 26px;
  }
}
@media screen and (min-width: 769px) {
  .business-index .title--5 {
    padding: 28px 20px;
  }
}
.business-index-content {
  position: relative;
  margin-top: 1px;
  padding: 30px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .business-index-content {
    margin-top: 2px;
    padding: 70px 0 112px;
  }
}
.business-index-title {
  margin-bottom: 80px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .business-index-title {
    margin-bottom: 150px;
    font-size: 52px;
  }
}
.business-index-lists {
  display: flex;
  flex-wrap: wrap;
}
.business-index-lists--item {
  position: relative;
}
@media screen and (max-width: 768px) {
  .business-index-lists--item {
    margin: 86px 4.4776119403% 0 0;
  }
  .business-index-lists--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .business-index-lists--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .business-index-lists--item {
    margin: 180px 5% 0 0;
  }
  .business-index-lists--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .business-index-lists--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .business-index-lists--item.w-sp-single {
    width: 47.7611940299%;
  }
  .business-index-lists--item.w-sp-double {
    margin-right: 0;
    width: 100%;
  }
  .business-index-lists--item.w-sp-double .business-index-lists__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .business-index-lists--item.w-sp-double .business-index-lists__titles {
    width: calc(50% - 12.5px);
  }
  .business-index-lists--item.w-sp-double .business-index-lists__titles:nth-of-type(-n+2) {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .business-index-lists--item.w-pc-single {
    width: 30%;
  }
  .business-index-lists--item.w-pc-double {
    width: 65%;
  }
  .business-index-lists--item.w-pc-double .business-index-lists__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .business-index-lists--item.w-pc-double .business-index-lists__titles {
    width: calc(50% - 15px);
  }
  .business-index-lists--item.w-pc-triple {
    margin-right: 0;
    width: 100%;
  }
  .business-index-lists--item.w-pc-triple .business-index-lists__content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .business-index-lists--item.w-pc-triple .business-index-lists__titles {
    margin-right: 30px;
    width: calc(25% - 22.5px);
  }
  .business-index-lists--item.w-pc-triple .business-index-lists__titles:nth-of-type(4n) {
    margin-right: 0;
  }
}
.business-index-lists--photo {
  position: absolute;
  top: -56px;
  left: 50%;
  margin-left: -46px;
  width: 92px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}
@media screen and (min-width: 769px) {
  .business-index-lists--photo {
    top: -115px;
    margin-left: -92px;
    width: 185px;
  }
}
.business-index-lists__info {
  position: relative;
  padding: 42px 15px 68px;
  height: 100%;
  background: #fff;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .business-index-lists__info {
    padding: 95px 35px 120px;
  }
}
.business-index-lists--title {
  margin-bottom: 10px;
  font-size: 20px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .business-index-lists--title {
    margin-bottom: 22px;
    font-size: 28px;
  }
}
.business-index-lists__content {
  position: relative;
}
.business-index-lists--text {
  font-size: 14px;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .business-index-lists--text {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .business-index-lists__titles {
    margin-top: 14px;
  }
  .business-index-lists__titles:first-child {
    margin-top: 0;
  }
}
.business-index-lists__titles--item {
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.2;
}
.business-index-lists__titles--item:first-child {
  margin-top: 0;
}
.business-index-lists__titles--item > a {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.business-index-lists__titles--item > a.link-icon {
  padding-left: 26px;
}
.business-index-lists__titles--item > a.link-icon i {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 769px) {
  .business-index-lists__titles--item {
    margin-top: 15px;
    font-size: 15px;
  }
}
.business-index-lists--btn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  max-width: 130px;
  font-size: 12px;
}
.business-index-lists--btn a {
  padding: 7px 10px;
}
.business-index-lists--btn a:after {
  right: 10px;
  margin-top: -4px;
  width: 5px;
  height: 9px;
}
@media screen and (min-width: 769px) {
  .business-index-lists--btn {
    bottom: 40px;
    width: calc(100% - 100px);
    max-width: 258px;
    font-size: 16px;
  }
  .business-index-lists--btn a {
    padding: 12px 10px;
  }
  .business-index-lists--btn a:after {
    right: 24px;
    margin-top: -8px;
    width: 8px;
    height: 16px;
  }
}
.business-top-copy {
  margin: 30px 20px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .business-top-copy {
    margin: 56px 20px;
    font-size: 52px;
  }
}
.business-top .business-index {
  margin-top: 42px;
}
@media screen and (min-width: 769px) {
  .business-top .business-index {
    margin-top: 80px;
  }
}
.business-top .business-index-tab--item > a {
  padding: 34px 0;
}
@media screen and (min-width: 769px) {
  .business-top .business-index-tab--item > a {
    padding: 56px 30px;
  }
}
.business-top .business-index-title {
  font-size: 30px;
}
@media screen and (min-width: 769px) {
  .business-top .business-index-title {
    font-size: 60px;
  }
}
@media screen and (min-width: 769px) {
  .business-top .business-index-lists--item {
    margin: 180px 5% 0 0;
  }
  .business-top .business-index-lists--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .business-top .business-index-lists--item:nth-of-type(3n), .business-top .business-index-lists--item:nth-of-type(14) {
    margin-right: 0;
  }
  .business-top .business-index-lists--item:nth-of-type(13) {
    margin-right: 5%;
  }
}
.business-solution .business-index-lists {
  padding-top: 98px;
}
@media screen and (min-width: 769px) {
  .business-solution .business-index-lists {
    padding-top: 196px;
  }
}
@media screen and (max-width: 768px) {
  .business-solution .business-index-lists--item {
    margin: 86px 0 0;
  }
  .business-solution .business-index-lists--item:nth-of-type(1) {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .business-solution .business-index-lists--item {
    margin: 180px 5% 0 0;
  }
  .business-solution .business-index-lists--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .business-solution .business-index-lists--item:nth-of-type(3n) {
    margin-right: 5%;
  }
  .business-solution .business-index-lists--item:nth-of-type(2n) {
    margin-right: 0;
  }
  .business-solution .business-index-lists--item:nth-of-type(3) {
    margin-top: 180px;
  }
}
@media screen and (max-width: 768px) {
  .business-solution .business-index-lists--item.w-sp-single {
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .business-solution .business-index-lists--item.w-pc-single {
    width: 47.5%;
  }
  .business-solution .business-index-lists--item.w-pc-double {
    margin-right: 0;
    width: 100%;
  }
}
.business-detail .business-index-tab {
  margin: 40px 20px;
}
@media screen and (min-width: 769px) {
  .business-detail .business-index-tab {
    margin: 80px auto;
  }
}
.business-page {
  position: relative;
  padding-top: 5px;
}
@media screen and (min-width: 769px) {
  .business-page {
    padding-top: 16px;
  }
}
.business-page-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .business-page-title {
    margin: 0 20px 20px;
  }
}
@media screen and (min-width: 769px) {
  .business-page-title {
    margin-bottom: 54px;
    font-size: 52px;
  }
}
.business-page-content {
  position: relative;
  padding: 45px 20px 40px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .business-page-content {
    padding: 80px 0;
  }
}
.business-page-block {
  position: relative;
  margin-top: 10px;
  padding: 15px 20px 20px;
  background: #fff;
}
.business-page-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-block {
    margin-top: 20px;
    padding: 28px 65px 40px;
  }
}
.business-page-block--title {
  padding-bottom: 8px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .business-page-block--title {
    padding-bottom: 18px;
    font-size: 28px;
  }
}
.business-page-block--subtitle {
  position: relative;
  margin: 25px auto 0;
  padding: 4px 6px;
  max-width: 900px;
  background: #0168b7;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4285714286;
  color: #ffde00;
}
@media screen and (min-width: 769px) {
  .business-page-block--subtitle {
    margin-top: 60px;
    padding: 16px 10px;
    border-radius: 10px;
    font-size: 28px;
  }
}
.business-page-block__content {
  position: relative;
  margin-top: 20px;
}
.business-page-block--lead {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.2;
  color: #0168b7;
}
.business-page-block--lead:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-block--lead {
    font-size: 18px;
    line-height: 1.6111111111;
  }
}
.business-page-block--image {
  margin: 10px auto 0;
  max-width: 900px;
}
.business-page-block--image:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-block--image {
    margin-top: 30px;
  }
}
@media screen and (max-width: 768px) {
  .business-page-block--illustration {
    margin: 15px auto 0;
  }
}
@media screen and (min-width: 769px) {
  .business-page-block__row {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 769px) {
  .business-page-block__row .row-text {
    width: 77.358490566%;
  }
}
@media screen and (min-width: 769px) {
  .business-page-block__row .row-illustration {
    width: 18.8679245283%;
  }
}
@media screen and (max-width: 768px) {
  .business-page-block__row .row-illustration-1 {
    width: 61.0169491525%;
  }
}
@media screen and (max-width: 768px) {
  .business-page-block__row .row-illustration-2 {
    width: 48.1355932203%;
  }
}
@media screen and (max-width: 768px) {
  .business-page-block__row .row-illustration-3 {
    width: 77.9661016949%;
  }
}
@media screen and (max-width: 768px) {
  .business-page-block__row .row-illustration-4 {
    width: 72.5423728814%;
  }
}
.business-page-subblock {
  position: relative;
  margin-top: 35px;
}
.business-page-subblock:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-subblock {
    margin-top: 90px;
  }
}
.business-page-subblock--title {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .business-page-subblock--title {
    font-size: 24px;
  }
}
.business-page-subblock__content {
  position: relative;
  margin-top: 20px;
}
.business-page-subblock--lead {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.2;
}
.business-page-subblock--lead:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-subblock--lead {
    font-size: 15px;
  }
}
.business-page-subblock--lead > p {
  text-indent: 1em;
}
.business-page-subblock--subtitle {
  position: relative;
  margin: 25px auto 0;
  padding: 4px 6px;
  max-width: 900px;
  background: #44719b;
  border-radius: 5px;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.4285714286;
  color: #fff;
}
.business-page-subblock--subtitle span {
  display: inline-block;
  font-size: 10px;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .business-page-subblock--subtitle span {
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .business-page-subblock--subtitle {
    margin-top: 60px;
    padding: 16px 10px;
    border-radius: 10px;
    font-size: 28px;
  }
}
.business-page-subblock--image {
  margin: 10px auto 0;
  max-width: 900px;
}
.business-page-subblock--image:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-subblock--image {
    margin-top: 30px;
  }
}
.business-page-box {
  position: relative;
  margin-top: 25px;
}
.business-page-box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-box {
    margin-top: 35px;
  }
}
.business-page-box--title {
  position: relative;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .business-page-box--title {
    font-size: 18px;
  }
}
.business-page-box__content {
  position: relative;
  margin-top: 16px;
}
.business-page-check {
  margin-top: 25px;
}
.business-page-check:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-check {
    margin-top: 35px;
  }
}
.business-page-check--item {
  position: relative;
  margin-top: 20px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 1.2;
}
.business-page-check--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-check--item {
    margin-top: 15px;
    padding-left: 28px;
    font-size: 15px;
  }
}
.business-page-check--item:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 2px;
  width: 10px;
  height: 8px;
  background: url(../img/icon/check1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .business-page-check--item:before {
    top: 5px;
    width: 12px;
    height: 9px;
  }
}
.business-page-lists {
  margin-top: 25px;
}
.business-page-lists:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-lists {
    margin-top: 35px;
  }
}
.business-page-lists--item {
  position: relative;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.2;
}
.business-page-lists--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .business-page-lists--item {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.625;
  }
}
.business-page-lists--item > a {
  display: inline-block;
  position: relative;
  padding-left: 26px;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .business-page-lists--item > a {
    padding-left: 38px;
  }
}
.business-page-lists--item .icon-pdf {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 769px) {
  .business-page-lists--item .icon-pdf {
    top: 4px;
  }
}
.business-page-lists--item .icon-new {
  vertical-align: baseline;
}
@media screen and (min-width: 769px) {
  .business-page-lists--item .icon-new {
    margin-left: 6px;
  }
}
.business-page .informatics-image-1 {
  position: relative;
  padding-bottom: 78.5555555556%;
  width: 100%;
  height: 0;
  background: url(../img/business/chemistry_environment/informatics1_bg.png) no-repeat 4.5261669024% center;
  background-size: 99.6666666667%;
  overflow: hidden;
}
.business-page .informatics-image-1--title {
  position: absolute;
  top: 34.7949080622%;
  left: 38.6666666667%;
  width: 22.2222222222%;
}
.business-page .informatics-image-1--illust {
  position: absolute;
}
.business-page .informatics-image-1--illust.illust-1 {
  top: 0;
  left: 0.2222222222%;
  width: 33.3333333333%;
}
.business-page .informatics-image-1--illust.illust-2 {
  top: 2.1216407355%;
  left: 66.4444444444%;
  width: 33.3333333333%;
}
.business-page .informatics-image-1--illust.illust-3 {
  top: 61.2446958982%;
  left: 65.2222222222%;
  width: 34.5555555556%;
}
.business-page .informatics-image-1--illust.illust-4 {
  top: 61.2446958982%;
  left: 0.2222222222%;
  width: 33.3333333333%;
}
.business-page .informatics-image-1--text {
  position: absolute;
  width: 29.3333333333%;
}
.business-page .informatics-image-1--text.text-1 {
  top: 11.5983026874%;
  left: 35.1111111111%;
}
.business-page .informatics-image-1--text.text-2 {
  top: 39.7454031117%;
  left: 66.4444444444%;
}
.business-page .informatics-image-1--text.text-3 {
  top: 68.3168316832%;
  left: 35.1111111111%;
}
.business-page .informatics-image-1--text.text-4 {
  top: 39.7454031117%;
  left: 4.2222222222%;
}

/* company
------------------------------------------------ */
.company {
  /* top */
  /* idea */
  /* company */
  /* list */
}
@media screen and (max-width: 768px) {
  .company-tab {
    margin: 0 20px 10px;
  }
}
@media screen and (min-width: 769px) {
  .company-tab {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 26px;
  }
}
.company-tab--item {
  position: relative;
  text-align: center;
  font-size: 19px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .company-tab--item {
    margin-top: 2px;
  }
  .company-tab--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .company-tab--item {
    margin: 2px 2px 0 0;
    width: calc(25% - 1.5px);
    font-size: 24px;
  }
  .company-tab--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .company-tab--item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.company-tab--item > a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 24px 0;
  height: 100%;
  background: #fff;
  border: solid #0168b7 1px;
  color: #0168b7;
}
.company-tab--item > a.active {
  background: #0168b7;
  color: #fff;
}
.company-tab--item > a.active:after {
  background-image: url(../img/icon/arrow3.png);
}
@media screen and (min-width: 769px) {
  .company-tab--item > a {
    padding: 35px 30px;
  }
}
.company-tab--item > a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -8px;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow4.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .company-tab--item > a:after {
    right: 20px;
    margin-top: -9px;
    width: 9px;
    height: 19px;
  }
}
.company-greeting {
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .company-greeting {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .company-greeting {
    padding-bottom: 120px;
  }
}
.company-greeting-content {
  position: relative;
  margin-top: 8px;
}
@media screen and (min-width: 769px) {
  .company-greeting-content {
    display: flex;
    flex-flow: row-reverse;
    justify-content: space-between;
  }
}
@media screen and (min-width: 769px) {
  .company-greeting-info {
    width: 70%;
  }
}
.company-greeting-copy {
  margin-bottom: 20px;
  font-size: 16px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-greeting-copy {
    margin-bottom: 25px;
    font-size: 32px;
  }
}
.company-greeting-text {
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .company-greeting-text {
    font-size: 15px;
  }
}
.company-greeting-text > p + p {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .company-greeting-text > p + p {
    margin-top: 1.5em;
  }
}
@media screen and (max-width: 768px) {
  .company-greeting-photo {
    margin: 30px auto 0;
    width: 74.6268656716%;
  }
}
@media screen and (min-width: 769px) {
  .company-greeting-photo {
    width: 22.5%;
  }
}
.company-philosophy {
  position: relative;
  padding-bottom: 60px;
}
@media screen and (max-width: 768px) {
  .company-philosophy {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .company-philosophy {
    padding-bottom: 125px;
  }
}
@media screen and (max-width: 768px) {
  .company-philosophy-lists {
    margin: 20px auto 0;
    width: calc(100% - 50px);
  }
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists {
    display: flex;
    margin-top: 30px;
  }
}
.company-philosophy-lists--item {
  position: relative;
}
@media screen and (max-width: 768px) {
  .company-philosophy-lists--item {
    margin-top: 20px;
  }
  .company-philosophy-lists--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--item {
    margin-right: 60px;
    width: calc(33.333333% - 30px);
  }
  .company-philosophy-lists--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.company-philosophy-lists__box {
  position: relative;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists__box {
    height: 100%;
  }
}
.company-philosophy-lists--title {
  display: flex;
  align-items: center;
  position: relative;
  margin: 0 auto;
  width: 167px;
  height: 167px;
  background: #0168b7;
  border-radius: 50%;
  color: #fff;
  z-index: 2;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--title {
    width: 210px;
    height: 210px;
  }
}
.company-philosophy-lists--title .frame {
  position: relative;
  width: 100%;
}
.company-philosophy-lists--title .l,
.company-philosophy-lists--title .min {
  display: block;
  position: relative;
  text-align: center;
  line-height: 1.2;
}
.company-philosophy-lists--title .l {
  font-size: 25px;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--title .l {
    font-size: 32px;
  }
}
.company-philosophy-lists--title .min {
  margin: 8px auto 0;
  width: 90px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--title .min {
    margin-top: 10px;
    width: 114px;
    font-size: 18px;
  }
}
.company-philosophy-lists--title .min:before, .company-philosophy-lists--title .min:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 16px;
  height: 1px;
  background: #fff;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--title .min:before, .company-philosophy-lists--title .min:after {
    width: 20px;
  }
}
.company-philosophy-lists--title .min:before {
  right: 0;
}
.company-philosophy-lists--title .min:after {
  left: 0;
}
.company-philosophy-lists--text {
  position: relative;
  margin-top: -82px;
  padding: 90px 28px 18px;
  background: #fff;
  border: solid #0168b7 1px;
  font-size: 14px;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .company-philosophy-lists--text {
    margin-top: -104px;
    padding: 120px 35px 30px;
    font-size: 15px;
    border-width: 2px;
    height: calc(100% - 104px);
  }
}
.company-philosophy-pic {
  margin: 30px auto 0;
  width: calc(100% - 50px);
  text-align: center;
}
@media screen and (min-width: 769px) {
  .company-philosophy-pic {
    margin-top: 52px;
    width: 420px;
  }
}
.company-about {
  position: relative;
}
@media screen and (max-width: 768px) {
  .company-about {
    margin: 0 20px;
  }
}
.company-about-cell__box {
  position: relative;
}
@media screen and (max-width: 768px) {
  .company-about-cell__box {
    margin-top: 35px;
  }
  .company-about-cell__box:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .company-about-cell__box {
    display: flex;
    align-items: center;
    padding: 36px 0;
    border-bottom: solid #ccc 1px;
  }
  .company-about-cell__box:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 200px;
    height: 3px;
    background: #0168b7;
  }
}
.company-about-cell--title {
  position: relative;
  font-size: 21px;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .company-about-cell--title {
    padding-bottom: 14px;
    border-bottom: solid #ccc 1px;
  }
  .company-about-cell--title:after {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 126px;
    height: 3px;
    background: #0168b7;
  }
}
@media screen and (min-width: 769px) {
  .company-about-cell--title {
    width: 200px;
    text-align: center;
    font-size: 22px;
  }
}
.company-about-cell__content {
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (max-width: 768px) {
  .company-about-cell__content {
    margin-top: 28px;
  }
}
@media screen and (min-width: 769px) {
  .company-about-cell__content {
    padding: 0 80px;
    width: calc(100% - 200px);
    font-size: 16px;
  }
}
.company-about-cell--map {
  position: relative;
  margin-top: 24px;
  padding-bottom: 44.776119403%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .company-about-cell--map {
    padding-bottom: 42.8571428571%;
    max-width: 750px;
  }
}
.company-about-cell--map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.company-about-cell__lists--item {
  line-height: 2.2;
}
.company-about-cell__lists--item dl {
  display: flex;
}
.company-about-cell__lists--item dl dt {
  width: 180px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-about-cell__lists--item dl dt {
    width: 210px;
  }
}
.company-about-cell__lists--item dl dd {
  width: calc(100% - 180px);
}
@media screen and (min-width: 769px) {
  .company-about-cell__lists--item dl dd {
    width: calc(100% - 210px);
  }
}
.company-about-cell--note {
  margin-top: 25px;
  text-align: right;
  font-size: 14px;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .company-about-cell--note {
    padding-top: 10px;
    border-top: solid #ccc 1px;
  }
}
@media screen and (min-width: 769px) {
  .company-about-cell--note {
    margin-top: 5px;
    font-size: 15px;
  }
}
.company-about-note {
  margin-top: 40px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .company-about-note {
    margin-top: 30px;
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .company-about-note p:nth-of-type(2) {
    margin-top: 1.4em;
  }
}
@media screen and (min-width: 769px) {
  .company-about-note p:nth-of-type(2), .company-about-note p:nth-of-type(3) {
    margin-left: 4em;
  }
  .company-about-note p:nth-of-type(4) {
    margin-left: 8em;
  }
}
.company-organization {
  position: relative;
  margin-top: 40px;
  background: #e5f0f8;
}
@media screen and (max-width: 768px) {
  .company-organization {
    padding: 20px 20px 40px;
  }
}
@media screen and (min-width: 769px) {
  .company-organization {
    padding: 50px 0 100px;
  }
}
.company-organization-chart {
  margin-top: 12px;
  padding: 16px;
  background: #fff;
}
@media screen and (min-width: 769px) {
  .company-organization-chart {
    margin-top: 32px;
    padding: 60px;
  }
}
.company-information {
  position: relative;
}
@media screen and (max-width: 768px) {
  .company-information {
    padding: 20px 20px 50px;
  }
}
@media screen and (min-width: 769px) {
  .company-information {
    padding: 50px 20px 100px;
  }
}
.company-information-box {
  position: relative;
  margin-top: 32px;
}
.company-information-box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .company-information-box {
    margin-top: 70px;
  }
}
.company-information-box--title {
  margin-bottom: 10px;
  padding-bottom: 12px;
  border-bottom: solid #c6dbec 3px;
  font-size: 19px;
  font-weight: 700;
  line-height: 1.2631578947;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-information-box--title {
    margin-bottom: 30px;
    padding-bottom: 15px;
    border-bottom-width: 4px;
    font-size: 26px;
  }
}
.company-information-lists {
  margin-top: 6px;
}
.company-information-lists--item {
  border-bottom: solid #ccc 1px;
  font-size: 14px;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .company-information-lists--item {
    font-size: 18px;
  }
}
.company-information-lists--item a {
  display: block;
  position: relative;
  padding: 10px 35px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-information-lists--item a {
    padding: 28px 62px;
  }
}
.company-information-lists--item a span {
  padding-left: 1em;
  font-size: 12px;
  color: #404040;
}
@media screen and (min-width: 769px) {
  .company-information-lists--item a span {
    font-size: 15px;
  }
}
.company-information-lists--item .icon-pdf {
  position: absolute;
  top: 10px;
  left: 0;
  width: 20px;
  height: 24px;
}
@media screen and (min-width: 769px) {
  .company-information-lists--item .icon-pdf {
    top: 25px;
    width: 30px;
    height: 36px;
  }
}
.company-information-lists--item .icon-blank {
  top: 10px;
}
@media screen and (min-width: 769px) {
  .company-information-lists--item .icon-blank {
    top: 30px;
    width: 25px;
    height: 25px;
  }
}
@media screen and (max-width: 768px) {
  .company-office .title--5 {
    margin: 0 20px;
  }
}
.company-office-name {
  font-size: 21px;
  font-weight: 400;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-office-name {
    font-size: 28px;
  }
}
.company-office-address {
  margin-top: 6px;
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (min-width: 769px) {
  .company-office-address {
    margin-top: 14px;
    font-size: 16px;
  }
}
.company-office-business {
  margin-top: 10px;
}
@media screen and (min-width: 769px) {
  .company-office-business {
    margin-top: 20px;
  }
}
.company-office-business--title {
  font-size: 16px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-office-business--title {
    font-size: 18px;
  }
}
.company-office-business--text {
  margin-top: 2px;
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (min-width: 769px) {
  .company-office-business--text {
    margin-top: 4px;
    font-size: 16px;
  }
}
.company-office-tab {
  display: flex;
}
@media screen and (max-width: 768px) {
  .company-office-tab {
    margin: 0 20px;
    flex-wrap: wrap;
  }
}
@media screen and (min-width: 769px) {
  .company-office-tab {
    justify-content: space-between;
  }
}
.company-office-tab--item {
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .company-office-tab--item {
    margin: 1px 1px 0 0;
    width: calc(50% - 0.5px);
  }
  .company-office-tab--item:nth-of-type(1) > a {
    border-radius: 20px 0 0 0;
  }
  .company-office-tab--item:nth-of-type(2) > a {
    border-radius: 0 20px 0 0;
  }
  .company-office-tab--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .company-office-tab--item {
    margin-left: 1px;
    width: 100%;
    font-size: 24px;
  }
  .company-office-tab--item:first-child {
    margin-left: 0;
  }
  .company-office-tab--item:first-child > a {
    border-radius: 30px 0 0 0;
  }
  .company-office-tab--item:last-child > a {
    border-radius: 0 30px 0 0;
  }
}
.company-office-tab--item > a {
  display: block;
  padding: 16px 0 13px;
  height: 100%;
  background: #0168b7;
  color: #fff;
}
.company-office-tab--item > a:after {
  content: "";
  display: block;
  margin: 8px auto 0;
  width: 14px;
  height: 8px;
  background: url(../img/icon/arrow_down1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .company-office-tab--item > a {
    padding: 30px 0 23px;
  }
  .company-office-tab--item > a:after {
    margin-top: 10px;
    width: 18px;
    height: 10px;
  }
}
.company-office-lists--item {
  padding: 25px 0 30px;
  border-bottom: solid #ccc 1px;
}
@media screen and (min-width: 769px) {
  .company-office-lists--item {
    display: flex;
    padding: 44px 0;
  }
}
@media screen and (min-width: 769px) {
  .company-office-lists__info {
    padding-right: 40px;
    width: 58.3333333333%;
  }
}
.company-office-lists--map {
  position: relative;
  padding-bottom: 44.776119403%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .company-office-lists--map {
    margin-top: 15px;
  }
}
@media screen and (min-width: 769px) {
  .company-office-lists--map {
    padding-bottom: 18.3333333333%;
    width: 41.6666666667%;
  }
}
.company-office-lists--map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.company-office-group {
  background: #f8fbfd;
}
@media screen and (max-width: 768px) {
  .company-office-group {
    padding: 35px 20px 50px;
  }
}
@media screen and (min-width: 769px) {
  .company-office-group {
    padding: 70px 0 100px;
  }
}
.company-office-group__box {
  margin-top: 30px;
}
.company-office-group__box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .company-office-group__box {
    margin-top: 62px;
  }
}
.company-office-group--title {
  position: relative;
  padding-bottom: 14px;
  font-size: 26px;
  font-weight: 400;
  border-bottom: solid #ccc 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .company-office-group--title {
    padding-bottom: 18px;
    font-size: 42px;
  }
}
.company-office-group--title:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 126px;
  height: 3px;
  background: #0168b7;
}
.company-office-head {
  background: #e5f0f8;
}
@media screen and (max-width: 768px) {
  .company-office-head {
    padding: 25px 20px 30px;
  }
}
@media screen and (min-width: 769px) {
  .company-office-head {
    margin-top: 30px;
    padding: 60px 0;
  }
  .company-office-head .company-office-name {
    font-size: 32px;
  }
}
@media screen and (min-width: 769px) {
  .company-office-head__content {
    display: flex;
    flex-flow: row-reverse;
  }
}
@media screen and (min-width: 769px) {
  .company-office-head__info {
    padding-left: 60px;
    width: 62.5%;
  }
}
@media screen and (max-width: 768px) {
  .company-office-head--photo {
    margin-top: 22px;
  }
}
@media screen and (min-width: 769px) {
  .company-office-head--photo {
    width: 37.5%;
  }
}
.company-office-head--map {
  position: relative;
  margin-top: 20px;
  padding-bottom: 44.776119403%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .company-office-head--map {
    margin-top: 40px;
    padding-bottom: 33.3333333333%;
  }
}
.company-office-head--map iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.company-office-prefecture {
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .company-office-prefecture {
    margin-top: 70px;
  }
}
/* contact
------------------------------------------------ */
.contact {
  /* top */
  /* inq */
}
.contact__btns {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.contact__btns--item > a {
  display: block;
  position: relative;
  padding: 30px 0 35px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  text-align: center;
  color: #fff;
}
.contact__btns--contract > a {
  background-image: url(../img/contact/btn_contract.jpg);
}
.contact__btns--target > a {
  background-image: url(../img/contact/btn_target.jpg);
}
.contact__btns--leo > a {
  background-image: url(../img/contact/btn_leo.jpg);
}
.contact__btns--recruit > a {
  background-image: url(../img/contact/btn_recruit.jpg);
}
.contact__btns--english > a {
  background: #3284c4;
}
.contact__btns--title {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.2;
}
.contact__btns--text {
  margin-top: 10px;
  font-size: 14px;
}
.contact__btns--btn {
  position: relative;
  margin: 24px auto 0;
  width: 77.8947368421%;
  font-size: 18px;
  line-height: 1.2;
}
.contact__btns--btn span {
  display: block;
  position: relative;
  padding: 18px 0;
  width: 100%;
  background: #0168b7;
  border-radius: 28px;
  color: #fff;
}
.contact__btns--btn span:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 16px;
  margin-top: -8px;
  width: 9px;
  height: 15px;
  background: url(../img/icon/arrow3.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .contact__btns--item {
    margin: 2.5% 2.5% 0 0;
    width: 31.6666666667%;
  }
  .contact__btns--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .contact__btns--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .contact__btns--item {
    margin: 4% 4% 0 0;
    width: 48%;
  }
  .contact__btns--item:nth-of-type(2n) {
    margin-right: 0;
  }
  .contact__btns--item:nth-of-type(3n) {
    margin-top: 4%;
  }
}
@media screen and (max-width: 768px) {
  .contact__btns {
    display: block;
    margin: 0 20px;
  }
  .contact__btns--item {
    margin-top: 24px;
  }
  .contact__btns--item:first-child {
    margin-top: 0;
  }
  .contact__btns--item > a {
    padding: 28px 0 30px;
  }
  .contact__btns--title {
    font-size: 24px;
  }
  .contact__btns--text {
    margin-top: 10px;
    font-size: 12px;
  }
  .contact__btns--btn {
    margin-top: 22px;
    width: 77.6119402985%;
    font-size: 16px;
  }
  .contact__btns--btn span {
    padding: 16px 0;
  }
  .contact__btns--btn span:after {
    right: 15px;
    margin-top: -6px;
    width: 7px;
    height: 13px;
  }
}
.contact-top__btns {
  position: relative;
}
.contact-top__other {
  position: relative;
  margin: 90px 0 2px;
}
.contact-top__other .wrap {
  display: flex;
}
.contact-top__other__box {
  padding: 86px 0 90px;
  width: 50%;
}
@media screen and (min-width: 769px) {
  .contact-top__other:before, .contact-top__other:after {
    content: "";
    position: absolute;
    top: 0;
    width: 50%;
    height: 100%;
    z-index: -1;
  }
  .contact-top__other:before {
    left: 0;
    background: #e5f0f8;
  }
  .contact-top__other:after {
    right: 0;
    background: #f8fbfd;
  }
  .contact-top__other .contact__btns--item {
    width: 63.3333333333%;
  }
}
@media screen and (max-width: 1000px) {
  .contact-top__other:before, .contact-top__other:after {
    display: none;
  }
  .contact-top__other .wrap {
    display: block;
  }
  .contact-top__other__box {
    padding: 40px 0;
    width: auto;
  }
}
@media screen and (max-width: 768px) {
  .contact-top__other {
    margin-top: 42px;
    padding-bottom: 48px;
  }
  .contact-top__other__box {
    padding: 0;
    margin-top: 44px;
  }
  .contact-top__other__box:first-child {
    margin-top: 0;
  }
}
.contact-inq {
  position: relative;
  padding-bottom: 96px;
}
.contact-inq__flow {
  display: flex;
  justify-content: center;
  margin-bottom: 86px;
}
.contact-inq__flow--item {
  position: relative;
  width: 33.333333%;
  padding-top: 90px;
  text-align: center;
}
.contact-inq__flow--item:after {
  content: "";
  position: absolute;
  top: 40px;
  width: 100%;
  height: 1px;
  background: #404040;
  z-index: -1;
}
.contact-inq__flow--item:last-child:after {
  display: none;
}
.contact-inq__flow--item.active .num {
  background: #404040;
  color: #fff;
}
.contact-inq__flow--item.active .text {
  color: inherit;
}
.contact-inq__flow--item .num {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  background: #fff;
  border: solid #404040 1px;
  border-radius: 50%;
  font-size: 38px;
  line-height: 78px;
}
.contact-inq__flow--item .text {
  font-size: 20px;
  color: #a5a5a5;
}
.contact-inq__form input,
.contact-inq__form textarea,
.contact-inq__form select,
.contact-inq__form button {
  font-family: inherit;
  font-size: 16px;
}
.contact-inq__form input[type=text],
.contact-inq__form input[type=email],
.contact-inq__form input[type=tel],
.contact-inq__form input[type=password],
.contact-inq__form input[type=submit],
.contact-inq__form textarea,
.contact-inq__form button {
  appearance: none;
  background: none;
  border: none;
  border-radius: 0;
}
.contact-inq__form input[type=text],
.contact-inq__form input[type=email],
.contact-inq__form input[type=tel],
.contact-inq__form input[type=password],
.contact-inq__form input[type=submit],
.contact-inq__form textarea {
  padding: 14px 16px;
  width: 100%;
  height: 40px;
  background: #fff;
  border: solid #ccc 1px;
}
.contact-inq__form textarea {
  height: 155px;
}
.contact-inq__form label {
  display: inline-block;
  position: relative;
  padding-left: 30px;
  vertical-align: baseline;
}
.contact-inq__form label input[type=checkbox],
.contact-inq__form label input[type=radio] {
  position: absolute;
  top: 7px;
  left: 0;
}
.contact-inq__form .label-note {
  display: inline-block;
  padding-left: 30px;
  vertical-align: baseline;
  white-space: nowrap;
}
.contact-inq__form .label-note:first-child {
  padding-left: 0;
}
.contact-inq__form__parts {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.contact-inq__form__parts:first-child {
  margin-top: 0;
}
.contact-inq__form__parts--item.w100 {
  width: 100%;
}
.contact-inq__form__parts--item.w48 {
  margin-right: 2.5165562914%;
  width: 48.7417218543%;
}
.contact-inq__form__parts--item.w10 {
  width: 10.0662251656%;
  min-width: 76px;
}
.contact-inq__form__parts--item:last-child {
  margin-right: 0;
}
.contact-inq__form__parts--item .head {
  display: inline-block;
  padding-right: 26px;
  width: 74px;
  text-align: right;
  vertical-align: top;
  line-height: 40px;
}
.contact-inq__form__parts--item .head + input {
  width: calc(100% - 74px);
}
.contact-inq__form__parts--btn {
  margin-left: 15px;
  max-width: 130px;
}
.contact-inq__form__parts--btn a,
.contact-inq__form__parts--btn button {
  display: block;
  padding: 10px 4px;
  width: 100%;
  background: #eee;
  border: solid #ccc 1px;
  border-radius: 5px;
  vertical-align: top;
  font-size: 12px;
  cursor: pointer;
}
.contact-inq__form__parts .zip,
.contact-inq__form__parts .hyphen {
  display: inline-block;
  vertical-align: top;
  font-size: 14px;
  line-height: 40px;
}
.contact-inq__form__parts .zip {
  margin-right: 4px;
}
.contact-inq__form__parts .hyphen {
  margin: 0 4px;
}
.contact-inq__form__parts .subtitle {
  margin-bottom: 20px;
  width: 100%;
  font-size: 22px;
  font-weight: 700;
  color: #0068b7;
}
.contact-inq__form__parts .multi {
  display: flex;
  position: relative;
  width: 100%;
}
.contact-inq__form__parts .multi--line--2 .multi--box {
  margin-right: 2%;
  width: 49%;
}
.contact-inq__form__parts .multi--line--2 .multi--box:nth-of-type(2n) {
  margin-right: 0;
}
.contact-inq__form__parts .multi--line--3 .multi--box {
  margin-right: 2%;
  width: 32%;
}
.contact-inq__form__parts .multi--line--3 .multi--box:nth-of-type(3n) {
  margin-right: 0;
}
.contact-inq__form__parts .multi--box dt {
  margin-bottom: 24px;
  font-size: 18px;
  font-weight: 700;
}
.contact-inq__form__parts .horizontal {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  width: 100%;
}
.contact-inq__form__parts .horizontal li {
  position: relative;
}
.contact-inq__form__parts .horizontal li.other {
  display: flex;
  align-items: center;
}
.contact-inq__form__parts .horizontal li.other label {
  width: 106px;
}
.contact-inq__form__parts .horizontal li.other label + input {
  width: calc(100% - 106px);
}
.contact-inq__form__parts .horizontal--line--liquid {
  margin-top: -18px;
  width: 100%;
}
.contact-inq__form__parts .horizontal--line--liquid li {
  margin: 18px 18px 0 0;
}
.contact-inq__form__parts .horizontal--line--1 {
  display: block;
}
.contact-inq__form__parts .horizontal--line--1 li {
  margin-top: 24px;
}
.contact-inq__form__parts .horizontal--line--1 li:first-child {
  margin-top: 0;
}
.contact-inq__form__parts .horizontal--line--2 li {
  margin-top: 24px;
  width: 50%;
}
.contact-inq__form__parts .horizontal--line--2 li:nth-of-type(-n+2) {
  margin-top: 0;
}
.contact-inq__form--text {
  margin-bottom: 54px;
  font-size: 18px;
}
.contact-inq__form--text .required {
  color: #b4161f;
}
.contact-inq__form__lists--item {
  display: flex;
  border-top: solid #e4e4e4 1px;
  border-bottom: solid #e7e7e7 1px;
}
.contact-inq__form__lists--title {
  position: relative;
  padding: 36px 90px 36px 30px;
  width: 386px;
  background: #f8fbfd;
  font-size: 22px;
}
.contact-inq__form__lists--title .icon-form {
  position: absolute;
  top: 46px;
  right: 25px;
}
.contact-inq__form__lists__content {
  padding: 46px 30px;
  width: calc(100% - 386px);
  font-size: 16px;
}
.contact-inq__form__lists__content .confirm--list li {
  margin-top: 24px;
}
.contact-inq__form__lists__content .confirm--list li:first-child {
  margin-top: 0;
}
.contact-inq__form__agree {
  margin-top: 50px;
  text-align: center;
}
.contact-inq__form__agree--text {
  font-size: 18px;
}
.contact-inq__form__agree--text a {
  text-decoration: underline;
}
.device--click .contact-inq__form__agree--text a:hover {
  text-decoration: none;
}
.contact-inq__form__agree--check {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}
.contact-inq__form__agree--check label {
  margin-right: 15px;
  font-size: 16px;
}
.contact-inq__form__btns {
  display: flex;
  justify-content: center;
  margin-top: 46px;
}
.contact-inq__form__btns--item {
  position: relative;
  margin: 0 10px;
  width: 300px;
  text-align: center;
}
.contact-inq__form__btns--item a,
.contact-inq__form__btns--item button,
.contact-inq__form__btns--item input[type=submit] {
  display: block;
  position: relative;
  padding: 10px 0;
  width: 100%;
  border: solid #404040 1px;
  border-radius: 25px;
  font-size: 18px;
  cursor: pointer;
}
.contact-inq__form__btns--item a:after,
.contact-inq__form__btns--item button:after,
.contact-inq__form__btns--item input[type=submit]:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -6px;
  width: 7px;
  height: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
  vertical-align: top;
}
.contact-inq__form__btns--submit a,
.contact-inq__form__btns--submit button,
.contact-inq__form__btns--submit input[type=submit] {
  background: #404040;
  color: #fff;
}
.contact-inq__form__btns--submit a:after,
.contact-inq__form__btns--submit button:after,
.contact-inq__form__btns--submit input[type=submit]:after {
  right: 25px;
  background-image: url(../img/icon/arrow3.png);
}
.contact-inq__form__btns--edit a,
.contact-inq__form__btns--edit button,
.contact-inq__form__btns--edit input[type=submit] {
  background: #fff;
}
.contact-inq__form__btns--edit a:after,
.contact-inq__form__btns--edit button:after,
.contact-inq__form__btns--edit input[type=submit]:after {
  left: 25px;
  transform: rotate(180deg);
  background-image: url(../img/icon/arrow5.png);
}
.contact-inq__complete--title {
  margin-bottom: 64px;
  text-align: center;
  font-size: 36px;
  font-weight: 700;
  line-height: 1.2;
}
.contact-inq__complete--lead {
  text-align: center;
  font-size: 18px;
}
@media screen and (max-width: 840px) {
  .contact-inq__form__lists--title {
    width: 300px;
  }
  .contact-inq__form__lists__content {
    width: calc(100% - 300px);
  }
}
@media screen and (max-width: 768px) {
  .contact-inq {
    padding-bottom: 48px;
  }
  .contact-inq__flow {
    margin-bottom: 30px;
  }
  .contact-inq__flow--item {
    padding-top: 55px;
  }
  .contact-inq__flow--item:after {
    top: 25px;
  }
  .contact-inq__flow--item .num {
    margin-left: -25px;
    width: 50px;
    height: 50px;
    font-size: 26px;
    line-height: 48px;
  }
  .contact-inq__flow--item .text {
    font-size: 14px;
    line-height: 1.4;
  }
  .contact-inq__form {
    margin: 0 20px;
  }
  .contact-inq__form input,
.contact-inq__form textarea,
.contact-inq__form select,
.contact-inq__form button {
    font-size: 15px;
  }
  .contact-inq__form input[type=text],
.contact-inq__form input[type=email],
.contact-inq__form input[type=tel],
.contact-inq__form input[type=password],
.contact-inq__form input[type=submit],
.contact-inq__form textarea {
    padding: 8px 10px;
    height: 40px;
  }
  .contact-inq__form textarea {
    height: 135px;
  }
  .contact-inq__form label {
    padding-left: 24px;
  }
  .contact-inq__form .label-note {
    display: block;
    margin-top: 10px;
    padding-left: 24px;
  }
  .contact-inq__form__parts--item .head {
    display: block;
    padding: 0 0 8px;
    width: auto;
    text-align: left;
    line-height: 1.2;
  }
  .contact-inq__form__parts--item .head + input {
    width: 100%;
  }
  .contact-inq__form__parts--btn {
    margin-left: 14px;
  }
  .contact-inq__form__parts .zip {
    margin-right: 2px;
  }
  .contact-inq__form__parts .hyphen {
    margin: 0 2px;
  }
  .contact-inq__form__parts .multi {
    display: block;
  }
  .contact-inq__form__parts .multi--line--2 .multi--box, .contact-inq__form__parts .multi--line--3 .multi--box {
    margin-right: 0;
    width: auto;
  }
  .contact-inq__form__parts .multi--box {
    margin-top: 25px;
  }
  .contact-inq__form__parts .multi--box:first-child {
    margin-top: 0;
  }
  .contact-inq__form__parts .multi--box dt {
    margin-bottom: 20px;
    font-size: 19px;
  }
  .contact-inq__form__parts .horizontal li.other {
    display: block;
  }
  .contact-inq__form__parts .horizontal li.other label {
    width: auto;
  }
  .contact-inq__form__parts .horizontal li.other label + input {
    margin-top: 10px;
    width: 100%;
  }
  .contact-inq__form__parts .horizontal--line--1 li {
    margin-top: 20px;
  }
  .contact-inq__form__parts .horizontal--line--2 li {
    margin-top: 20px;
  }
  .contact-inq__form__parts .horizontal--line--sp--2 {
    display: flex;
  }
  .contact-inq__form__parts .horizontal--line--sp--2 li {
    width: 50%;
  }
  .contact-inq__form__parts .horizontal--line--sp--2 li:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .contact-inq__form--text {
    margin-bottom: 25px;
    font-size: 15px;
  }
  .contact-inq__form__lists--item {
    display: block;
    padding: 28px 0;
    border-top: none;
    border-bottom: solid #ccc 1px;
  }
  .contact-inq__form__lists--item:first-child {
    border-top: solid #ccc 1px;
  }
  .contact-inq__form__lists--title {
    padding: 0;
    width: auto;
    background: none;
    font-size: 21px;
  }
  .contact-inq__form__lists--title .icon-form {
    position: relative;
    top: auto;
    right: auto;
    margin: 6px 0 0 10px;
  }
  .contact-inq__form__lists__content {
    margin-top: 16px;
    padding: 0;
    width: auto;
    font-size: 15px;
  }
  .contact-inq__form__lists__content .confirm--list li {
    margin-top: 12px;
  }
  .contact-inq__form__agree {
    margin-top: 45px;
  }
  .contact-inq__form__agree--text {
    font-size: 16px;
  }
  .contact-inq__form__agree--check {
    margin-top: 20px;
  }
  .contact-inq__form__agree--check label {
    font-size: 15px;
  }
  .contact-inq__form__btns {
    display: block;
    margin-top: 45px;
  }
  .contact-inq__form__btns--item {
    margin: 10px 0 0;
    width: 100%;
  }
  .contact-inq__form__btns--item:first-child {
    margin-top: 0;
  }
  .contact-inq__form__btns--item a,
.contact-inq__form__btns--item button,
.contact-inq__form__btns--item input[type=submit] {
    padding: 12px 0;
    font-size: 16px;
  }
  .contact-inq__complete--title {
    margin-bottom: 28px;
    font-size: 24px;
  }
  .contact-inq__complete--lead {
    margin: 0 20px;
    font-size: 15px;
  }
}

/* contract
------------------------------------------------ */
.contract {
  /* top */
  /* category */
  /* detail */
}
.contract-introduce-tab {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .contract-introduce-tab {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab {
    justify-content: space-between;
  }
}
.contract-introduce-tab--item {
  text-align: center;
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .contract-introduce-tab--item {
    margin: 1px 1px 0 0;
    width: calc(50% - 0.5px);
  }
  .contract-introduce-tab--item:nth-of-type(1) {
    margin-top: 0;
    width: 100%;
  }
  .contract-introduce-tab--item:nth-of-type(1) > a {
    border-radius: 20px 20px 0 0;
  }
  .contract-introduce-tab--item:nth-of-type(2n+1) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab--item {
    width: calc(14.2857142857% - 1px);
    font-size: 24px;
  }
  .contract-introduce-tab--item:first-child > a {
    border-radius: 30px 0 0 0;
  }
  .contract-introduce-tab--item:last-child > a {
    border-radius: 0 30px 0 0;
  }
}
.contract-introduce-tab--item > a {
  display: block;
  padding: 16px 0 13px;
  height: 100%;
  background: #0168b7;
  color: #fff;
}
.contract-introduce-tab--item > a:after {
  content: "";
  display: block;
  margin: 8px auto 0;
  width: 14px;
  height: 8px;
  background: url(../img/icon/arrow_down1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab--item > a {
    padding: 30px 0 23px;
  }
  .contract-introduce-tab--item > a:after {
    margin-top: 10px;
    width: 18px;
    height: 10px;
  }
}
.contract-introduce-tab2 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .contract-introduce-tab2 {
    margin: 0 20px 35px;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2 {
    margin-bottom: 76px;
  }
}
.contract-introduce-tab2--item {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .contract-introduce-tab2--item {
    margin: 4.4776119403% 1.4925373134% 0 0;
    width: 49.2537313433%;
  }
  .contract-introduce-tab2--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .contract-introduce-tab2--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2--item {
    margin: 6.3333333333% 1.6666666667% 0 0;
    width: 49.1666666667%;
    font-size: 32px;
  }
  .contract-introduce-tab2--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .contract-introduce-tab2--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
.contract-introduce-tab2--item > a {
  display: block;
  position: relative;
  padding: 10px 0 18px;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2--item > a {
    padding: 38px 0 36px;
    border-width: 2px;
  }
}
.contract-introduce-tab2--item > a.active {
  background: #0168b7;
  color: #fff;
}
.contract-introduce-tab2--item > a.active:before {
  background-image: url(../img/icon/door2.png);
}
.contract-introduce-tab2--item > a.active:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border: solid transparent 6px;
  border-top: solid #0168b7 7px;
  border-bottom: none;
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2--item > a.active:after {
    bottom: -16px;
    margin-left: -12px;
    border-width: 12px;
    border-top-width: 14px;
  }
}
.contract-introduce-tab2--item > a:before {
  content: "";
  display: block;
  width: 15px;
  height: 25px;
  background: url(../img/icon/door1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .contract-introduce-tab2--item > a:before {
    margin: 0 auto 8px;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2--item > a:before {
    position: absolute;
    top: 50%;
    left: 80px;
    margin-top: -40px;
    width: 48px;
    height: 81px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1100px) {
  .contract-introduce-tab2--item > a:before {
    left: 6.6666666667vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 980px) {
  .contract-introduce-tab2--item > a:before {
    left: 2vw;
  }
}
.contract-introduce-tab2--item span {
  display: block;
  margin-top: 6px;
  font-size: 10px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-tab2--item span {
    margin-top: 6px;
    font-size: 15px;
  }
}
.contract-introduce-content {
  padding: 58px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .contract-introduce-content {
    padding: 106px 0 140px;
  }
}
.contract-introduce .business-index-lists {
  padding-top: 70px;
}
@media screen and (min-width: 769px) {
  .contract-introduce .business-index-lists {
    padding-top: 130px;
  }
}
.contract-introduce-lists {
  margin-top: 58px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists {
    margin-top: 94px;
  }
}
.contract-introduce-lists--item {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  position: relative;
  margin-top: 34px;
}
.contract-introduce-lists--item:first-child {
  margin-top: 0;
}
.contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists--photo:before {
  top: -4px;
  left: -4px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists--photo:before {
    top: -8px;
    left: -8px;
  }
}
.contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists--photo:after {
  bottom: -4px;
  right: -4px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists--photo:after {
    bottom: -8px;
    right: -8px;
  }
}
.contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists--photo:before {
  top: -4px;
  right: -4px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists--photo:before {
    top: -8px;
    right: -8px;
  }
}
.contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists--photo:after {
  bottom: -4px;
  left: -4px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists--photo:after {
    bottom: -8px;
    left: -8px;
  }
}
@media screen and (max-width: 768px) {
  .contract-introduce-lists--item {
    flex-flow: column-reverse;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--item {
    margin-top: 70px;
  }
  .contract-introduce-lists--item:nth-of-type(odd) {
    flex-flow: row-reverse;
  }
  .contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists__info {
    margin-left: auto;
    padding-left: 13.3333333333%;
  }
  .contract-introduce-lists--item:nth-of-type(odd) .contract-introduce-lists--photo {
    margin-right: -6.5%;
  }
  .contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists__info {
    padding-right: 13.3333333333%;
  }
  .contract-introduce-lists--item:nth-of-type(even) .contract-introduce-lists--photo {
    margin-left: -6.5%;
  }
}
.contract-introduce-lists__info {
  position: relative;
  padding: 22% 25px 20px;
  width: 100%;
  background: #c6dbec;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists__info {
    margin-top: 44px;
    padding: 40px 60px 45px;
    width: 62.5%;
  }
}
.contract-introduce-lists--title {
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
.contract-introduce-lists--title .l,
.contract-introduce-lists--title .min {
  display: block;
}
.contract-introduce-lists--title .l {
  font-size: 27px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--title .l {
    font-size: 32px;
  }
}
.contract-introduce-lists--title .min {
  margin-top: 6px;
  font-size: 13px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--title .min {
    margin-top: 8px;
    font-size: 16px;
  }
}
.contract-introduce-lists--text {
  margin-top: 14px;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--text {
    margin-top: 32px;
    font-size: 15px;
  }
}
.contract-introduce-lists--btn {
  margin: 16px 20px 0;
  font-size: 14px;
}
.contract-introduce-lists--btn a {
  padding: 12px 10px;
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--btn {
    margin: 34px 0 0;
    max-width: 240px;
  }
  .contract-introduce-lists--btn a:after {
    right: 24px;
    margin-top: -6px;
    width: 7px;
    height: 12px;
  }
}
.contract-introduce-lists--photo {
  position: relative;
  width: 77.6119402985%;
  z-index: 2;
}
@media screen and (max-width: 768px) {
  .contract-introduce-lists--photo {
    margin: 0 auto -17.2727272727%;
  }
}
@media screen and (min-width: 769px) {
  .contract-introduce-lists--photo {
    width: 43.3333333333%;
  }
}
.contract-introduce-lists--photo img {
  position: relative;
  z-index: 1;
}
.contract-introduce-lists--photo:before, .contract-introduce-lists--photo:after {
  content: "";
  position: absolute;
  padding-bottom: 14.4230769231%;
  width: 14.4230769231%;
  background: #0068b7;
  z-index: -1;
}
.contract-search {
  position: relative;
  padding: 60px 0 48px;
  background: url(../img/top/search_bg.jpg) no-repeat center center;
  background-size: cover;
}
@media screen and (min-width: 769px) {
  .contract-search {
    padding: 124px 0 90px;
  }
}
.contract-index {
  position: relative;
}
.contract-top .title--image {
  background-image: url(../img/contract/title.jpg);
}
.contract-top .title--ribbon {
  background-image: url(../img/contract/title2_bg.jpg);
}
.contract-top-outline {
  position: relative;
}
@media screen and (max-width: 768px) {
  .contract-top-outline {
    padding: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .contract-top-outline {
    padding: 90px 0 136px;
  }
}
@media screen and (min-width: 769px) {
  .contract-top-outline__info {
    margin-left: 41.6666666667%;
  }
}
.contract-top-outline--title {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 30px;
  font-weight: 700;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .contract-top-outline--title {
    font-size: 60px;
  }
}
.contract-top-outline--copy {
  margin-top: 6px;
  font-size: 18px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .contract-top-outline--copy {
    margin-top: 32px;
    font-size: 28px;
  }
}
.contract-top-outline--text {
  margin-top: 10px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .contract-top-outline--text {
    margin-top: 28px;
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .contract-top-outline--photo {
    margin-top: 28px;
  }
}
@media screen and (min-width: 769px) {
  .contract-top-outline--photo {
    position: absolute;
    top: 50%;
    left: -29.0322580645%;
    transform: translateY(-50%);
    width: 66.3709677419%;
  }
}
@media screen and (max-width: 768px) {
  .contract-category .title--page {
    background-image: url(../img/contract/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .contract-category .title--page {
    background-image: url(../img/contract/title_bg-pc.jpg);
  }
}
@media screen and (max-width: 768px) {
  .contract-detail .title--page {
    background-image: url(../img/contract/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .contract-detail .title--page {
    background-image: url(../img/contract/title_bg-pc.jpg);
  }
}
.contract-detail-title {
  font-size: 30px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .contract-detail-title {
    margin: 0 20px 20px;
  }
}
@media screen and (min-width: 769px) {
  .contract-detail-title {
    margin-bottom: 54px;
    font-size: 52px;
  }
}
.contract-detail-content {
  position: relative;
  padding: 45px 20px 40px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .contract-detail-content {
    padding: 80px 0;
  }
}
.contract-detail-block {
  position: relative;
  margin-top: 10px;
  padding: 15px 20px 20px;
  background: #fff;
}
.contract-detail-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-block {
    margin-top: 20px;
    padding: 28px 65px 40px;
  }
}
.contract-detail-block--title {
  padding-bottom: 8px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  font-weight: 400;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .contract-detail-block--title {
    padding-bottom: 18px;
    font-size: 28px;
  }
}
.contract-detail-block__content {
  position: relative;
  margin-top: 20px;
}
.contract-detail-block--lead {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.2;
  color: #0168b7;
}
.contract-detail-block--lead:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-block--lead {
    font-size: 18px;
    line-height: 1.6111111111;
  }
}
.contract-detail-block--image {
  margin: 32px auto 0;
  max-width: 900px;
}
.contract-detail-block--image:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-block--image {
    margin-top: 60px;
  }
}
.contract-detail-subblock {
  position: relative;
  margin-top: 35px;
}
.contract-detail-subblock:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-subblock {
    margin-top: 90px;
  }
}
.contract-detail-subblock--title {
  font-size: 17px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .contract-detail-subblock--title {
    font-size: 24px;
  }
}
.contract-detail-subblock__content {
  position: relative;
  margin-top: 20px;
}
.contract-detail-subblock--lead {
  margin-top: 32px;
  font-size: 14px;
  line-height: 1.2;
}
.contract-detail-subblock--lead:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-subblock--lead {
    font-size: 15px;
  }
}
.contract-detail-subblock--lead > p {
  text-indent: 1em;
}
.contract-detail-subblock--image {
  margin: 32px auto 0;
  max-width: 900px;
}
.contract-detail-subblock--image:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-subblock--image {
    margin-top: 60px;
  }
}
.contract-detail-box {
  position: relative;
  margin-top: 25px;
}
.contract-detail-box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-box {
    margin-top: 35px;
  }
}
.contract-detail-box--title {
  position: relative;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .contract-detail-box--title {
    font-size: 18px;
  }
}
.contract-detail-box__content {
  position: relative;
  margin-top: 16px;
}
.contract-detail-check {
  margin-top: 25px;
}
.contract-detail-check:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-check {
    margin-top: 35px;
  }
}
.contract-detail-check--item {
  position: relative;
  margin-top: 20px;
  padding-left: 24px;
  font-size: 14px;
  line-height: 1.2;
}
.contract-detail-check--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-check--item {
    margin-top: 15px;
    padding-left: 28px;
    font-size: 15px;
  }
}
.contract-detail-check--item:before {
  content: "";
  position: absolute;
  top: 6px;
  left: 2px;
  width: 10px;
  height: 8px;
  background: url(../img/icon/check1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .contract-detail-check--item:before {
    top: 5px;
    width: 12px;
    height: 9px;
  }
}
.contract-detail-lists {
  margin-top: 25px;
}
.contract-detail-lists:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-lists {
    margin-top: 35px;
  }
}
.contract-detail-lists--item {
  position: relative;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.2;
}
.contract-detail-lists--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-lists--item {
    margin-top: 30px;
    font-size: 16px;
    line-height: 1.625;
  }
}
.contract-detail-lists--item > a {
  display: inline-block;
  position: relative;
  padding-left: 26px;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .contract-detail-lists--item > a {
    padding-left: 38px;
  }
}
.contract-detail-lists--item .icon-pdf {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 769px) {
  .contract-detail-lists--item .icon-pdf {
    top: 4px;
  }
}
.contract-detail-lists--item .icon-new {
  vertical-align: baseline;
}
@media screen and (min-width: 769px) {
  .contract-detail-lists--item .icon-new {
    margin-left: 6px;
  }
}

/* flow
------------------------------------------------ */
.flow {
  /* top */
  /* step */
}
.flow-top {
  padding-bottom: 100px;
}
@media screen and (max-width: 768px) {
  .flow-top {
    padding-bottom: 50px;
  }
}
.flow__step__lists--item {
  display: flex;
  align-items: center;
  position: relative;
  padding: 20px 50px;
  background: #e5f0f8;
  min-height: 260px;
}
.flow__step__lists--item:nth-of-type(even) {
  background: #f8fbfd;
}
.flow__step__lists--response {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  width: 50px;
  height: 100%;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
.flow__step__lists--response:after {
  content: "";
  position: absolute;
  top: 50%;
  width: 0;
  height: 0;
  margin-top: -14px;
  border: solid transparent 14px;
}
.flow__step__lists--response.guest {
  left: 0;
  background: #0168b7;
}
.flow__step__lists--response.guest:after {
  right: -16px;
  border-right: none;
  border-left: solid #0168b7 16px;
}
.flow__step__lists--response.company {
  right: 0;
  background: #7cafd7;
}
.flow__step__lists--response.company:after {
  left: -16px;
  border-left: none;
  border-right: solid #7cafd7 16px;
}
.flow__step__lists__symbol {
  width: 308px;
  text-align: center;
}
.flow__step__lists--name {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 50px;
  line-height: 1.2;
  color: #404040;
}
.flow__step__lists--name .num {
  display: inline-block;
  vertical-align: baseline;
  font-size: 65px;
}
.flow__step__lists--icon {
  display: block;
  margin: 10px auto 0;
  width: 80px;
}
.flow__step__lists__info {
  position: relative;
  width: calc(100% - 400px);
}
.flow__step__lists--title {
  font-size: 40px;
  font-weight: 700;
  color: #0168b7;
}
.flow__step__lists--text {
  font-size: 24px;
}
.flow__step__contact {
  margin-top: 86px;
}
.flow__step__contact--text {
  text-align: center;
  font-size: 24px;
}
@media screen and (max-width: 768px) {
  .flow__step__lists {
    margin: 0 20px;
  }
  .flow__step__lists--item {
    display: block;
    padding: 12px 50px;
    min-height: inherit;
  }
  .flow__step__lists--response {
    width: 30px;
    font-size: 15px;
  }
  .flow__step__lists--response:after {
    margin-top: -8px;
    border: solid transparent 8px;
  }
  .flow__step__lists--response.guest:after {
    right: -10px;
    border-right: none;
    border-left: solid #0168b7 10px;
  }
  .flow__step__lists--response.company:after {
    left: -10px;
    border-left: none;
    border-right: solid #7cafd7 10px;
  }
  .flow__step__lists__symbol {
    width: auto;
    text-align: left;
  }
  .flow__step__lists--name {
    font-size: 25px;
  }
  .flow__step__lists--name .num {
    font-size: 30px;
  }
  .flow__step__lists--icon {
    display: none;
  }
  .flow__step__lists__info {
    width: auto;
  }
  .flow__step__lists--title {
    font-size: 20px;
  }
  .flow__step__lists--text {
    font-size: 15px;
  }
  .flow__step__contact {
    margin: 42px 20px 0;
  }
  .flow__step__contact--text {
    font-size: 15px;
  }
}

/* common
------------------------------------------------ */
@media screen and (max-width: 768px) {
  .page--leo .title--ribbon--name {
    padding-top: 20px;
    width: 302px;
    height: 124px;
    background-image: url(../img/leo/title_ribbon.png);
  }
}
@media screen and (min-width: 769px) {
  .page--leo .title--ribbon--name {
    width: 975px;
    background-image: url(../img/leo/title_ribbon-pc.png);
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page--leo .title--ribbon--name {
    padding-top: 5.5vw;
    width: 97.5vw;
    height: 22vw;
    font-size: 8vw;
  }
}

/* leo
------------------------------------------------ */
.leo {
  /* parts */
  /* top */
  /* index */
  /* introduce */
  /* search */
  /* measurement */
  /* company */
  /* product */
  /* field */
  /* detail */
}
.leo-title .title--page {
  background-size: cover;
}
@media screen and (max-width: 768px) {
  .leo-title .title--page {
    background-image: url(../img/leo/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .leo-title .title--page {
    background-image: url(../img/leo/title_bg-pc.jpg);
  }
}
.leo-link {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .leo-link {
    margin-top: 40px;
    font-size: 28px;
  }
}
.leo-link > a {
  display: inline-block;
  text-decoration: underline;
  vertical-align: top;
  color: #0168B7;
}
.leo-top .title--image {
  background-image: url(../img/leo/title.jpg);
}
.leo-top .title--ribbon {
  background-image: url(../img/leo/title2_bg.jpg);
}
.leo-top-outline {
  position: relative;
}
@media screen and (max-width: 768px) {
  .leo-top-outline {
    padding: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .leo-top-outline {
    display: grid;
    padding: 0 calc((100% - 1200px) / 2) 36px 0;
    grid-column-gap: 66px;
    grid-template-columns: 41.6666666667%;
  }
}
@media screen and (min-width: 769px) and (max-width: 1240px) {
  .leo-top-outline {
    padding-right: 20px;
  }
}
.leo-top-outline--title {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 28px;
  font-weight: 700;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .leo-top-outline--title {
    grid-row: 1;
    grid-column: 2/3;
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  .leo-top-outline--photo {
    margin-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-top-outline--photo {
    grid-row: 1/4;
    grid-column: 1;
    height: calc(100% + 36px);
  }
  .leo-top-outline--photo img {
    width: 100%;
    height: 100%;
    max-width: inherit;
    object-fit: cover;
  }
}
.leo-top-outline--text {
  margin-top: 16px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (min-width: 769px) {
  .leo-top-outline--text {
    grid-row: 2;
    grid-column: 2/3;
    margin-top: 28px;
    font-size: 18px;
    line-height: 2.4888888889;
  }
}
.leo-top-outline__btm {
  margin-top: 16px;
}
@media screen and (min-width: 769px) {
  .leo-top-outline__btm {
    display: flex;
    align-items: center;
    grid-row: 3;
    grid-column: 2/3;
    margin-top: 74px;
  }
}
.leo-top-outline--btn {
  text-align: center;
  font-size: 24px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .leo-top-outline--btn {
    padding: 0 18px;
  }
}
@media screen and (min-width: 769px) {
  .leo-top-outline--btn {
    width: 54.1786743516%;
    font-size: 28px;
  }
}
.leo-top-outline--btn > a {
  display: block;
  padding: 14px 10px;
  background-color: #0168B7;
  border-radius: 28px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .leo-top-outline--btn > a {
    padding: 24px 10px;
    max-width: 376px;
    border-radius: 40px;
  }
}
.leo-top-outline--lng {
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .leo-top-outline--lng {
    margin-top: 22px;
    padding: 0 48px;
  }
}
@media screen and (min-width: 769px) {
  .leo-top-outline--lng {
    margin-left: 8.6455331412%;
    width: 34.5821325648%;
    font-size: 20px;
  }
}
.leo-top-outline--lng > a {
  display: block;
  padding: 10px;
  border: 1px solid #707070;
  color: #404040;
}
@media screen and (min-width: 769px) {
  .leo-top-outline--lng > a {
    padding: 18px 12px;
  }
}
.leo-index {
  position: relative;
}
.leo-index-content {
  position: relative;
  margin-top: 1px;
  padding: 30px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .leo-index-content {
    margin-top: 2px;
    padding: 70px 0 112px;
  }
}
.leo-index-title {
  margin-bottom: 80px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .leo-index-title {
    margin-bottom: 150px;
    font-size: 52px;
  }
}
.leo-index-lists {
  display: flex;
  flex-wrap: wrap;
  padding-top: 42px;
}
@media screen and (min-width: 769px) {
  .leo-index-lists {
    padding-top: 110px;
  }
}
.leo-index-lists--item {
  position: relative;
}
@media screen and (max-width: 768px) {
  .leo-index-lists--item {
    margin: 62px 4.4776119403% 0 0;
    width: 47.7611940299%;
  }
  .leo-index-lists--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .leo-index-lists--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .leo-index-lists--item {
    margin: 180px 5% 0 0;
    width: 30%;
  }
  .leo-index-lists--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .leo-index-lists--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.leo-index-lists--photo {
  position: absolute;
  top: -42px;
  left: 50%;
  margin-left: -46px;
  width: 92px;
  border-radius: 50%;
  overflow: hidden;
  z-index: 2;
}
@media screen and (min-width: 769px) {
  .leo-index-lists--photo {
    top: -110px;
    margin-left: -92px;
    width: 185px;
  }
}
.leo-index-lists__info {
  position: relative;
  padding: 64px 15px 68px;
  height: 100%;
  background: #fff;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .leo-index-lists__info {
    padding: 95px 35px 120px;
  }
}
.leo-index-lists--title {
  margin-bottom: 10px;
  font-size: 20px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .leo-index-lists--title {
    margin-bottom: 22px;
    font-size: 28px;
  }
}
.leo-index-lists__content {
  position: relative;
}
.leo-index-lists--text {
  font-size: 14px;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .leo-index-lists--text {
    font-size: 15px;
  }
}
@media screen and (max-width: 768px) {
  .leo-index-lists__titles {
    margin-top: 14px;
  }
  .leo-index-lists__titles:first-child {
    margin-top: 0;
  }
}
.leo-index-lists__titles--item {
  margin-top: 14px;
  font-size: 14px;
  line-height: 1.2;
}
.leo-index-lists__titles--item:first-child {
  margin-top: 0;
}
.leo-index-lists__titles--item > a {
  display: inline-block;
  position: relative;
  vertical-align: top;
}
.leo-index-lists__titles--item > a.link-icon {
  padding-left: 26px;
}
.leo-index-lists__titles--item > a.link-icon i {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (min-width: 769px) {
  .leo-index-lists__titles--item {
    margin-top: 15px;
    font-size: 15px;
  }
}
.leo-index-lists--btn {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  width: calc(100% - 30px);
  max-width: 130px;
  font-size: 12px;
}
.leo-index-lists--btn a {
  padding: 7px 10px;
}
.leo-index-lists--btn a:after {
  right: 10px;
  margin-top: -4px;
  width: 5px;
  height: 9px;
}
@media screen and (min-width: 769px) {
  .leo-index-lists--btn {
    bottom: 40px;
    width: calc(100% - 100px);
    max-width: 258px;
    font-size: 16px;
  }
  .leo-index-lists--btn a {
    padding: 12px 10px;
  }
  .leo-index-lists--btn a:after {
    right: 24px;
    margin-top: -8px;
    width: 8px;
    height: 16px;
  }
}
.leo-index-measurement {
  position: relative;
  margin-top: 50px;
  padding: 40px 20px;
  background-color: #fff;
  border: 1px solid #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-index-measurement {
    display: grid;
    grid-column-gap: 96px;
    grid-template-columns: 39.9246704331%;
    margin-top: 96px;
    padding: 40px 70px 50px;
  }
}
.leo-index-measurement__title {
  margin-bottom: 22px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 32px;
  font-weight: 700;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__title {
    grid-row: 1;
    grid-column: 1/3;
    margin-bottom: 40px;
    font-size: 32px;
  }
}
.leo-index-measurement__photos {
  position: relative;
}
@media screen and (max-width: 768px) {
  .leo-index-measurement__photos {
    margin-bottom: 36px;
  }
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__photos {
    grid-row: 2;
    grid-column: 1;
    aspect-ratio: 1/0.8561320755;
  }
}
@media screen and (max-width: 768px) {
  .leo-index-measurement__photos__item:not(:first-child) {
    margin-top: 4px;
  }
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__photos__item {
    position: absolute;
    width: 77.8301886792%;
  }
  .leo-index-measurement__photos__item:nth-of-type(1) {
    top: 0;
    left: 0;
  }
  .leo-index-measurement__photos__item:nth-of-type(2) {
    bottom: 0;
    right: 0;
  }
}
@media screen and (max-width: 768px) {
  .leo-index-measurement__lists {
    margin: 0 14px;
  }
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__lists {
    grid-row: 2;
    grid-column: 2;
  }
}
.leo-index-measurement__lists__item {
  position: relative;
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__lists__item {
    display: flex;
    align-items: center;
  }
}
.leo-index-measurement__lists__item:not(:first-child) {
  margin-top: 36px;
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__lists__item:not(:first-child) {
    margin-top: 24px;
  }
}
.leo-index-measurement__lists__title {
  padding-left: 5px;
  border-left: 4px solid #0168B7;
  font-size: 23px;
  font-weight: 400;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__lists__title {
    padding: 0 10px 0 8px;
    width: calc(100% - 258px);
    font-size: 23px;
  }
}
.leo-index-measurement__lists__btn {
  margin: 0;
}
@media screen and (max-width: 768px) {
  .leo-index-measurement__lists__btn {
    margin-top: 12px;
  }
}
@media screen and (min-width: 769px) {
  .leo-index-measurement__lists__btn {
    width: 258px;
    font-size: 16px;
  }
  .leo-index-measurement__lists__btn a {
    padding: 14px 10px;
  }
  .leo-index-measurement__lists__btn a::after {
    right: 20px;
    margin-top: -8px;
    width: 10px;
    height: 18px;
  }
}
.leo-introduce {
  margin-top: 48px;
}
@media screen and (min-width: 769px) {
  .leo-introduce {
    margin-top: 90px;
  }
}
.leo-introduce-tab2 {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  .leo-introduce-tab2 {
    margin: 0 20px 35px;
  }
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2 {
    margin-bottom: 76px;
  }
}
.leo-introduce-tab2--item {
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .leo-introduce-tab2--item {
    margin: 4.4776119403% 1.4925373134% 0 0;
    width: 49.2537313433%;
  }
  .leo-introduce-tab2--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .leo-introduce-tab2--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2--item {
    margin: 6.3333333333% 1.6666666667% 0 0;
    width: 49.1666666667%;
    font-size: 32px;
  }
  .leo-introduce-tab2--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .leo-introduce-tab2--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
.leo-introduce-tab2--item > a {
  display: block;
  position: relative;
  padding: 20px 0 28px;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2--item > a {
    padding: 50px 0 48px;
    border-width: 2px;
  }
}
.leo-introduce-tab2--item > a.active {
  background: #0168b7;
  color: #fff;
}
.leo-introduce-tab2--item > a.active:before {
  background-image: url(../img/icon/door2.png);
}
.leo-introduce-tab2--item > a.active:after {
  content: "";
  position: absolute;
  bottom: -7px;
  left: 50%;
  margin-left: -3px;
  width: 0;
  height: 0;
  border: solid transparent 6px;
  border-top: solid #0168b7 7px;
  border-bottom: none;
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2--item > a.active:after {
    bottom: -16px;
    margin-left: -12px;
    border-width: 12px;
    border-top-width: 14px;
  }
}
.leo-introduce-tab2--item > a:before {
  content: "";
  display: block;
  width: 15px;
  height: 25px;
  background: url(../img/icon/door1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (max-width: 768px) {
  .leo-introduce-tab2--item > a:before {
    margin: 0 auto 8px;
  }
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2--item > a:before {
    position: absolute;
    top: 50%;
    left: 80px;
    margin-top: -40px;
    width: 48px;
    height: 81px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1100px) {
  .leo-introduce-tab2--item > a:before {
    left: 6.6666666667vw;
  }
}
@media screen and (min-width: 769px) and (max-width: 980px) {
  .leo-introduce-tab2--item > a:before {
    left: 2vw;
  }
}
.leo-introduce-tab2--item span {
  display: block;
  margin-top: 6px;
  font-size: 10px;
}
@media screen and (min-width: 769px) {
  .leo-introduce-tab2--item span {
    margin-top: 6px;
    font-size: 15px;
  }
}
.leo-introduce-content {
  padding: 34px 20px 50px;
  background: #e5f0f8;
}
@media screen and (min-width: 769px) {
  .leo-introduce-content {
    padding: 80px 0 140px;
  }
}
.leo-introduce-target {
  position: relative;
}
.leo-search {
  position: relative;
  margin-top: 45px;
}
@media screen and (min-width: 769px) {
  .leo-search {
    margin-top: 90px;
  }
}
.leo-search-title {
  margin-bottom: 24px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-search-title {
    margin-bottom: 26px;
    font-size: 60px;
  }
}
@media screen and (max-width: 768px) {
  .leo-search .style-search--1 {
    margin: 0;
  }
}
.leo-page {
  position: relative;
}
.leo-measurement-lists__item {
  padding: 20px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__item {
    display: grid;
    grid-column-gap: 86px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 360px;
    padding: 40px 60px;
  }
}
.leo-measurement-lists__item:not(:first-child) {
  margin-top: 20px;
}
.leo-measurement-lists__above {
  margin-bottom: 16px;
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__above {
    display: flex;
    align-items: center;
    grid-row: 1;
    grid-column: 1/3;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #C6DBEC;
  }
}
.leo-measurement-lists__title {
  position: relative;
  padding-left: 12px;
  font-size: 24px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__title {
    width: 410px;
    padding-left: 20px;
    font-size: 33px;
  }
}
.leo-measurement-lists__title::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 22px;
  background-color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__title::after {
    width: 3px;
    height: 46px;
  }
}
.leo-measurement-lists__text {
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (max-width: 768px) {
  .leo-measurement-lists__text {
    margin-top: 10px;
    padding-top: 16px;
    border-top: 1px solid #C6DBEC;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__text {
    margin-left: 36px;
    width: calc(100% - 446px);
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__photo {
    grid-row: 2;
    grid-column: 1;
  }
}
.leo-measurement-lists__btn {
  margin-top: 16px;
}
@media screen and (min-width: 769px) {
  .leo-measurement-lists__btn {
    grid-row: 3;
    grid-column: 1;
    margin: 24px 0 0;
    width: 308px;
    font-size: 16px;
  }
  .leo-measurement-lists__btn a {
    padding: 12px 10px;
  }
  .leo-measurement-lists__btn a:after {
    right: 20px;
    margin-top: -8px;
    width: 8px;
    height: 16px;
  }
}
@media screen and (max-width: 768px) {
  .leo-measurement-example {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-example {
    grid-row: 2/4;
    grid-column: 2;
  }
}
.leo-measurement-example__title {
  margin-bottom: 20px;
  font-size: 19px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__title {
    margin-bottom: 20px;
    font-size: 28px;
  }
}
.leo-measurement-example__text {
  margin-bottom: 24px;
  font-size: 14px;
  line-height: 1.7857142857;
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__text {
    margin-bottom: 30px;
    font-size: 15px;
  }
}
.leo-measurement-example__gif {
  margin-bottom: 10px;
  text-align: center;
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__gif {
    margin-bottom: 12px;
    max-width: 520px;
  }
}
.leo-measurement-example__gif:not(:first-child) {
  margin-top: 24px;
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__gif:not(:first-child) {
    margin-top: 36px;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__photos {
    max-width: 520px;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__photos[data-column="2"] {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .leo-measurement-example__photos[data-column="2"] .leo-measurement-example__photos__item {
    margin: 16px 16px 0 0;
    width: calc(50% - 8px);
  }
  .leo-measurement-example__photos[data-column="2"] .leo-measurement-example__photos__item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .leo-measurement-example__photos[data-column="2"] .leo-measurement-example__photos__item:nth-of-type(2n) {
    margin-right: 0;
  }
}
.leo-measurement-example__photos[data-column="3"] {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
@media screen and (max-width: 768px) {
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item {
    margin: 16px 8px 0 0;
    width: calc(50% - 4px);
  }
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item {
    margin: 8px 4px 0 0;
    width: calc(33.3333333333% - 6px);
  }
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .leo-measurement-example__photos[data-column="3"] .leo-measurement-example__photos__item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.leo-measurement-example__photos__item figure {
  display: block;
  text-align: center;
}
@media screen and (max-width: 768px) {
  .leo-measurement-example__photos__item figure img {
    width: 100%;
    max-width: inherit;
  }
}
.leo-measurement-example__photos__item figure figcaption {
  display: block;
  margin-top: 8px;
  text-align: center;
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  .leo-measurement-example__photos__item figure figcaption {
    margin-top: 8px;
    font-size: 15px;
  }
}
.leo-company-block {
  position: relative;
}
.leo-company-block:not(:first-child) {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .leo-company-block:not(:first-child) {
    margin-top: 80px;
  }
}
.leo-company-block__content {
  padding: 20px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .leo-company-block__content {
    padding: 40px 60px;
  }
}
.leo-company-block__text {
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (min-width: 769px) {
  .leo-company-block__text {
    margin-bottom: 40px;
    font-size: 18px;
  }
}
.leo-company-block__multi {
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .leo-company-block__multi {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 48.1481481481%;
    margin-bottom: 40px;
  }
}
.leo-company-block__multi:last-child {
  margin-bottom: 0;
}
@media screen and (max-width: 768px) {
  .leo-company-block__multi__photo {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-block__multi__photo {
    grid-row: 1;
    grid-column: 1;
  }
}
.leo-company-block__multi__text {
  font-size: 16px;
  line-height: 1.6625;
}
@media screen and (min-width: 769px) {
  .leo-company-block__multi__text {
    grid-row: 1;
    grid-column: 2;
    font-size: 18px;
  }
}
.leo-company-outline__table {
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.leo-company-outline__table th,
.leo-company-outline__table td {
  padding: 8px 24px;
  min-width: 68px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  color: #707070;
}
@media screen and (min-width: 769px) {
  .leo-company-outline__table th,
.leo-company-outline__table td {
    padding: 20px 32px;
    min-width: 220px;
    font-size: 18px;
  }
}
.leo-company-outline__table th.is-align-cnter,
.leo-company-outline__table td.is-align-cnter {
  padding-right: 2px;
  padding-left: 2px;
  text-align: center;
}
.leo-company-outline__table th {
  background-color: #F2F2F2;
}
@media screen and (min-width: 769px) {
  .leo-company-outline__table th {
    min-width: 200px;
  }
}
.leo-company-outline__table .link-map {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 90px;
  height: 48px;
  background-color: #0168B7;
  border-radius: 32px;
  text-align: center;
  font-size: 14px;
  line-height: 1.2;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .leo-company-outline__table .link-map {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-outline__table .link-map {
    margin: -36px 0 0 auto;
    font-size: 16px;
  }
}
.leo-company-outline__table .link-map::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow6.png) no-repeat center center;
  background-size: 100% 100%;
}
.leo-company-history__lists {
  border-top: 1px solid #ccc;
}
.leo-company-history__lists__item {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ccc;
}
.leo-company-history__lists__year {
  padding: 2px;
  width: 98px;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .leo-company-history__lists__year {
    padding-left: 64px;
    width: 242px;
    font-size: 18px;
  }
}
.leo-company-history__lists__text {
  display: flex;
  align-items: center;
  padding: 6px;
  width: calc(100% - 98px);
  min-height: 76px;
  border-left: 1px solid #ccc;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .leo-company-history__lists__text {
    padding: 6px 45px;
    width: calc(100% - 242px);
    min-height: 54px;
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-element__lists {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: repeat(2, calc(50% - 10px));
  }
}
.leo-company-element__lists__item {
  padding: 8px 8px 16px;
  border: 1px solid #707070;
}
@media screen and (max-width: 768px) {
  .leo-company-element__lists__item:not(:first-child) {
    margin-top: 8px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-element__lists__item {
    display: grid;
    grid-gap: 24px;
    grid-template-columns: auto 167px;
    padding: 24px;
  }
}
.leo-company-element__lists__title {
  padding-bottom: 8px;
  border-bottom: 1px solid #707070;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (max-width: 768px) {
  .leo-company-element__lists__title {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-element__lists__title {
    grid-row: 1;
    grid-column: 1/3;
    padding-bottom: 16px;
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .leo-company-element__lists__photo {
    margin: 0 auto 8px;
    width: 60.071942446%;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-element__lists__photo {
    grid-row: 2;
    grid-column: 2;
  }
}
.leo-company-element__lists__text {
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .leo-company-element__lists__text {
    margin: 0 auto;
    width: 60.071942446%;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-element__lists__text {
    grid-row: 2;
    grid-column: 1;
    font-size: 16px;
    line-height: 1.875;
  }
}
.leo-company-product__lists {
  border-top: 1px solid #ccc;
}
.leo-company-product__lists__item {
  padding: 12px 12px 16px;
  border-bottom: 1px solid #ccc;
}
@media screen and (min-width: 769px) {
  .leo-company-product__lists__item {
    display: flex;
    align-items: center;
    padding: 6px 0;
  }
}
.leo-company-product__lists__title {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .leo-company-product__lists__title {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-product__lists__title {
    width: 322px;
    font-size: 18px;
    line-height: 1.6;
  }
}
.leo-company-product__lists__text {
  padding: 0 12px;
  font-size: 14px;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .leo-company-product__lists__text {
    display: flex;
    align-items: center;
    padding: 10px 40px;
    width: calc(100% - 580px);
    min-height: 80px;
    border-left: 1px solid #ccc;
    font-size: 18px;
    line-height: 1.6;
  }
}
@media screen and (max-width: 768px) {
  .leo-company-product__lists__btn {
    margin-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-company-product__lists__btn {
    margin: 0;
    width: 258px;
    font-size: 16px;
  }
  .leo-company-product__lists__btn a {
    padding: 12px 10px;
  }
  .leo-company-product__lists__btn a:after {
    right: 20px;
    margin-top: -8px;
    width: 8px;
    height: 16px;
  }
}
.leo-product-lists__item {
  padding: 20px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .leo-product-lists__item {
    display: grid;
    grid-column-gap: 80px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 29.0740740741%;
    padding: 40px 60px;
  }
}
.leo-product-lists__item:not(:first-child) {
  margin-top: 20px;
}
.leo-product-lists__title {
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #C6DBEC;
  font-size: 24px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-product-lists__title {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 16px;
    padding-bottom: 16px;
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .leo-product-lists__photo {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-product-lists__photo {
    grid-row: 1/4;
    grid-column: 1;
  }
}
.leo-product-lists__text {
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (min-width: 769px) {
  .leo-product-lists__text {
    grid-row: 2;
    grid-column: 2;
    font-size: 18px;
  }
}
.leo-product-lists__btn {
  margin-top: 16px;
}
@media screen and (min-width: 769px) {
  .leo-product-lists__btn {
    grid-row: 3;
    grid-column: 2;
    margin: 42px 0 0;
    width: 258px;
    font-size: 16px;
  }
  .leo-product-lists__btn a {
    padding: 12px 10px;
  }
  .leo-product-lists__btn a:after {
    right: 24px;
    margin-top: -8px;
    width: 8px;
    height: 16px;
  }
}
.leo-field-lists__item {
  padding: 20px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .leo-field-lists__item {
    display: grid;
    grid-column-gap: 22px;
    grid-template-rows: auto auto 1fr;
    grid-template-columns: 29.0740740741%;
    padding: 40px 60px;
  }
}
.leo-field-lists__item:not(:first-child) {
  margin-top: 20px;
}
.leo-field-lists__title {
  margin-bottom: 16px;
  padding-bottom: 4px;
  border-bottom: 1px solid #C6DBEC;
  font-size: 24px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-field-lists__title {
    grid-row: 1;
    grid-column: 2;
    margin-bottom: 36px;
    padding-bottom: 16px;
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .leo-field-lists__photo {
    margin-bottom: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-field-lists__photo {
    grid-row: 1/4;
    grid-column: 1;
  }
}
@media screen and (min-width: 769px) {
  .leo-field-lists__links {
    display: grid;
    grid-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
    grid-row: 2;
    grid-column: 2;
    font-size: 18px;
  }
}
.leo-field-lists__links__item {
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (max-width: 768px) {
  .leo-field-lists__links__item:not(:first-child) {
    margin-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .leo-field-lists__links__item {
    font-size: 18px;
  }
}
.leo-field-lists__links__item > a {
  display: block;
  position: relative;
  padding: 16px 20px 16px 12px;
  border: 1px solid #0168B7;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-field-lists__links__item > a {
    padding: 14px 15px;
  }
}
.leo-field-lists__links__item > a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  margin-top: -8px;
  width: 8px;
  height: 16px;
  background: url(../img/icon/arrow7.png) no-repeat center center;
  background-size: 100% 100%;
}
@media screen and (max-width: 768px) {
  .leo-detail > .wrap {
    padding: 0 20px;
  }
}
.leo-detail-hd {
  position: relative;
}
.leo-detail-title {
  border-bottom: solid #ccc 2px;
  font-size: 21px;
  font-weight: 400;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .leo-detail-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 769px) {
  .leo-detail-title {
    font-size: 42px;
  }
}
.leo-detail-content {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.625;
}
@media screen and (min-width: 769px) {
  .leo-detail-content {
    margin-top: 36px;
    font-size: 16px;
  }
}
.leo-detail-content p + p {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .leo-detail-content p + p {
    margin-top: 1.6em;
  }
}
.leo-detail-content h2,
.leo-detail-content h4 {
  position: relative;
  margin-bottom: 20px;
  padding-left: 12px;
  font-size: 20px;
  font-weight: 400;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-detail-content h2,
.leo-detail-content h4 {
    margin-bottom: 40px;
    padding-left: 20px;
    font-size: 28px;
  }
}
.leo-detail-content h2:not(:first-child),
.leo-detail-content h4:not(:first-child) {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .leo-detail-content h2:not(:first-child),
.leo-detail-content h4:not(:first-child) {
    margin-top: 60px;
  }
}
.leo-detail-content h2::after,
.leo-detail-content h4::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 2px;
  height: 22px;
  background-color: #0168B7;
}
@media screen and (min-width: 769px) {
  .leo-detail-content h2::after,
.leo-detail-content h4::after {
    width: 3px;
    height: 46px;
  }
}
.leo-detail-content h3 {
  margin-bottom: 16px;
  font-size: 16px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .leo-detail-content h3 {
    margin-bottom: 32px;
    font-size: 20px;
  }
}
.leo-detail-content h3:not(:first-child) {
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .leo-detail-content h3:not(:first-child) {
    margin-top: 50px;
  }
}
.leo-detail-content ul {
  margin-left: 20px;
  list-style: disc;
}
.leo-detail .leo-link {
  margin: 60px 0;
}
@media screen and (min-width: 769px) {
  .leo-detail .leo-link {
    margin: 80px 0;
  }
}

.page-lng-en .title--ribbon--name {
  font-size: 35px;
}
@media screen and (max-width: 768px) {
  .page-lng-en .title--ribbon--name {
    padding: 12px 10px 0;
    width: 302px;
    height: 159px;
    background-image: url(../img/leo/en/title_ribbon.png);
  }
}
@media screen and (min-width: 769px) {
  .page-lng-en .title--ribbon--name {
    padding-top: 20px;
    font-size: 72px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1000px) {
  .page-lng-en .title--ribbon--name {
    padding-top: 2vw;
    font-size: 7.2vw;
  }
}
.page-lng-en .leo-field-lists__links__item > a {
  display: flex;
  align-items: center;
  min-height: 84px;
}
@media screen and (min-width: 769px) {
  .page-lng-en .leo-field-lists__links__item > a {
    min-height: 86px;
  }
}
@media screen and (max-width: 768px) {
  .page-lng-en .leo-measurement-lists__btn {
    max-width: 100%;
    font-size: 14px;
    line-height: 1.6;
  }
}
@media screen and (min-width: 769px) {
  .page-lng-en .leo-measurement-lists__btn {
    line-height: 1.5625;
  }
}
.page-lng-en .leo-measurement-lists__btn > a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 34px;
  min-height: 60px;
}
@media screen and (min-width: 769px) {
  .page-lng-en .leo-measurement-lists__btn > a {
    min-height: 72px;
  }
}

/* news
------------------------------------------------ */
.news {
  /* top */
  /* lists */
  /* nav */
  /* single */
  /* detail */
}
.news-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .news-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .news-lists .title--5 {
    margin: 0 20px;
  }
  .news-lists .pager-1 {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.news-nav {
  margin: 12px 20px 0;
}
@media screen and (min-width: 769px) {
  .news-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0;
  }
}
.news-nav-tab {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.news-nav-tab--item {
  margin: 0 0.2985074627% 0.2985074627% 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
}
.news-nav-tab--item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .news-nav-tab--item {
    width: 24.776119403%;
  }
}
@media screen and (min-width: 769px) {
  .news-nav-tab--item {
    margin: 0 2px 2px 0;
    min-width: 200px;
    font-size: 24px;
  }
}
.news-nav-tab--item a {
  display: block;
  padding: 10px 2px;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .news-nav-tab--item a {
    padding: 14px 2px;
  }
}
.news-nav-tab--item a.active {
  background: #0168b7;
  color: #fff;
}
.news-nav-archive {
  margin-left: auto;
  width: 150px;
  height: 40px;
}
@media screen and (min-width: 769px) {
  .news-nav-archive {
    width: 300px;
    height: 58px;
  }
}
.news-nav-archive select {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  .news-nav-archive select {
    padding: 0 20px;
    font-size: 24px;
  }
}
.news-single {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .news-single {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .news-detail {
    margin: 0 20px;
  }
}
.news-detail-hd__info {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .news-detail-hd__info {
    margin-top: 40px;
  }
}
.news-detail-title {
  border-bottom: solid #ccc 2px;
  font-size: 21px;
  font-weight: 400;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .news-detail-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 769px) {
  .news-detail-title {
    font-size: 42px;
  }
}
.news-detail-cat {
  margin-right: 12px;
}
@media screen and (min-width: 769px) {
  .news-detail-cat {
    margin-right: 20px;
  }
}
.news-detail-cat .icon-news {
  padding: 2px 10px 4px;
}
@media screen and (min-width: 769px) {
  .news-detail-cat .icon-news {
    padding: 2px 14px;
  }
}
.news-detail-date {
  font-size: 15px;
  font-weight: 600;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .news-detail-date {
    font-size: 18px;
  }
}
.news-detail-content {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.625;
}
@media screen and (min-width: 769px) {
  .news-detail-content {
    margin-top: 36px;
    font-size: 16px;
  }
}
.news-detail-content p + p {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .news-detail-content p + p {
    margin-top: 1.6em;
  }
}
/* notfound
------------------------------------------------ */
.notfound-top {
  padding-bottom: 40px;
}
@media screen and (min-width: 769px) {
  .notfound-top {
    padding-bottom: 86px;
  }
}
@media screen and (max-width: 768px) {
  .notfound-top .title--page {
    background-image: url(../img/404/title_bg.jpg);
    background-size: cover;
    font-size: 26px;
  }
}
@media screen and (min-width: 769px) {
  .notfound-top .title--page {
    background-image: url(../img/404/title_bg-pc.jpg);
  }
}
.notfound-content {
  padding-top: 40px;
}
@media screen and (max-width: 768px) {
  .notfound-content {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .notfound-content {
    padding-top: 100px;
  }
}
.notfound-content .more--1 {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .notfound-content .more--1 {
    margin-top: 86px;
  }
}
.notfound-content .more--1 > a {
  background-color: #0168B7;
}
.notfound-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: normal;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .notfound-title {
    margin-bottom: 40px;
    font-size: 48px;
  }
}
.notfound-box {
  padding: 18px 30px;
  background-color: #F8F8F8;
}
@media screen and (min-width: 769px) {
  .notfound-box {
    margin: 0 auto;
    padding: 34px 60px;
    max-width: 950px;
  }
}
.notfound-box-title {
  padding-bottom: 12px;
  font-size: 12px;
  line-height: 1.8;
  border-bottom: 1px solid #707070;
}
@media screen and (min-width: 769px) {
  .notfound-box-title {
    padding-bottom: 18px;
    font-size: 23px;
  }
}
.notfound-box-content {
  padding-top: 16px;
}
@media screen and (min-width: 769px) {
  .notfound-box-content {
    padding-top: 20px;
  }
}
.notfound-box-lists--item {
  position: relative;
  font-size: 12px;
  line-height: 2;
}
@media screen and (min-width: 769px) {
  .notfound-box-lists--item {
    font-size: 16px;
    line-height: 3.0625;
  }
}
.notfound-box-lists--item::before {
  content: "・";
}

/* privacy
------------------------------------------------ */
.privacy {
  /* top */
  /* lists */
  /* block */
}
.privacy-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .privacy-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .privacy-content {
    margin: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .privacy-content {
    max-width: 1140px;
  }
}
.privacy-block {
  position: relative;
  margin-top: 35px;
}
.privacy-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .privacy-block {
    margin-top: 64px;
  }
}
.privacy-block-content {
  font-size: 14px;
  line-height: 1.7;
}
@media screen and (min-width: 769px) {
  .privacy-block-content {
    font-size: 15px;
  }
}
.privacy-block-content a {
  text-decoration: underline;
  color: #0168b7;
}
.device--click .privacy-block-content a:hover {
  text-decoration: none;
}
.privacy-block-content p + p {
  margin-top: 26px;
}
@media screen and (min-width: 769px) {
  .privacy-block-content p + p {
    margin-top: 28px;
  }
}
.privacy-block-content ul {
  margin: 26px 0;
}
@media screen and (min-width: 769px) {
  .privacy-block-content ul {
    margin: 28px 0;
  }
}
.privacy-block-content ul:first-child {
  margin-top: 0;
}
.privacy-block-content ul:last-child {
  margin-bottom: 0;
}
.privacy-block-content ul > li {
  position: relative;
  padding-left: 1.6em;
}
.privacy-block-content ul > li::before {
  content: "■";
  position: absolute;
  top: 0;
  left: 0;
}
.privacy-block-content ol {
  margin-top: 26px;
  list-style: decimal inside;
}
@media screen and (min-width: 769px) {
  .privacy-block-content ol {
    margin-top: 28px;
  }
}
.privacy-block-content ol:first-child {
  margin-top: 0;
}
.privacy-block-content ol > li + li {
  margin-top: 26px;
}
@media screen and (min-width: 769px) {
  .privacy-block-content ol > li + li {
    margin-top: 28px;
  }
}

/* search
------------------------------------------------ */
.search {
  /* top */
  /* result */
}
.search-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .search-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .search-result {
    margin: 0 20px;
  }
}
.search-result-num {
  margin-bottom: 25px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.2;
}
.search-result-lists--item {
  display: flex;
  align-items: flex-start;
  position: relative;
  margin-top: 35px;
}
.search-result-lists--item:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .search-result-lists--item {
    margin-top: 50px;
  }
}
.search-result-lists--photo {
  position: relative;
  width: 27.5297619048%;
  border: solid #ccc 1px;
}
@media screen and (min-width: 769px) {
  .search-result-lists--photo {
    width: 20.4166666667%;
  }
}
.search-result-lists--photo > a {
  display: block;
  width: 100%;
}
.search-result-lists--photo + .search-result-lists__info {
  margin-left: 5.5059523809%;
  width: 66.9642857143%;
}
@media screen and (min-width: 769px) {
  .search-result-lists--photo + .search-result-lists__info {
    margin-left: 4.25%;
    width: 75.3333333333%;
  }
}
.search-result-lists__info {
  position: relative;
  width: 100%;
}
.search-result-lists--title {
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 3px;
  font-size: 19px;
  font-weight: 500;
  line-height: 1.2631578947;
}
@media screen and (min-width: 769px) {
  .search-result-lists--title {
    margin-bottom: 25px;
    padding-bottom: 12px;
    font-size: 26px;
  }
}
.search-result-lists--title > a {
  display: inline-block;
  vertical-align: top;
  color: #0168b7;
}
.search-result-lists--folder {
  margin-bottom: 15px;
  font-size: 14px;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .search-result-lists--folder {
    margin-bottom: 25px;
    font-size: 15px;
  }
}
.search-result-lists--excerpt {
  font-size: 14px;
  line-height: 1.7;
}
@media screen and (min-width: 769px) {
  .search-result-lists--excerpt {
    font-size: 15px;
  }
}

/* seminar
------------------------------------------------ */
.seminar {
  /* single */
  /* stream */
  /* detail */
  /* related */
}
@media screen and (max-width: 768px) {
  .seminar-content {
    padding: 0 20px;
  }
}
.seminar-single {
  position: relative;
}
@media screen and (max-width: 768px) {
  .seminar-single .title--page {
    background-image: url(../img/seminar/title_bg.jpg);
    font-size: 26px;
  }
}
@media screen and (min-width: 769px) {
  .seminar-single .title--page {
    background-image: url(../img/seminar/title_bg-pc.jpg);
  }
}
.seminar-single .style1-flow-btn {
  margin-bottom: 40px;
}
@media screen and (min-width: 769px) {
  .seminar-single .style1-flow-btn {
    margin-bottom: 80px;
  }
}
.seminar-stream {
  position: relative;
  margin-bottom: 36px;
}
@media screen and (min-width: 769px) {
  .seminar-stream {
    display: flex;
    margin-bottom: 44px;
  }
}
.seminar-stream-video {
  position: relative;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .seminar-stream-video {
    width: 73.0833333333%;
  }
}
.seminar-stream-video input {
  margin-top: 10px;
  padding: 10px;
  font-size: 16px;
}
.seminar-stream-video input[type=button] {
  margin-left: 10px;
  cursor: pointer;
}
.seminar-stream-video > div {
  position: relative;
  padding-bottom: 65.0746268657%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.seminar-stream-video > div > div {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.seminar-stream-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.seminar-stream-video .eqp-minimum {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
}
.seminar-stream-chat {
  position: relative;
  padding-bottom: 135.52238806%;
  height: 0;
  overflow: hidden;
}
.seminar-stream-chat iframe {
  position: absolute;
  top: 0;
  left: 0;
}
@media screen and (max-width: 768px) {
  .seminar-stream-chat {
    margin: 20px 28px 0;
  }
}
@media screen and (min-width: 769px) {
  .seminar-stream-chat {
    margin-left: 2.5833333334%;
    padding-bottom: 39.5%;
    width: 24.3333333333%;
  }
}
.seminar-detail-hd {
  position: relative;
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .seminar-detail-hd {
    margin-bottom: 50px;
  }
}
.seminar-detail-title {
  padding-bottom: 10px;
  border-bottom: solid #ccc 2px;
  font-size: 21px;
  font-weight: 400;
  line-height: 1.4761904762;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .seminar-detail-title {
    padding-bottom: 8px;
    font-size: 42px;
  }
}
.seminar-detail-content {
  font-size: 14px;
  line-height: 1.4285714286;
  color: #0168B7;
}
.seminar-detail-content p + .seminar-detail-content p {
  margin-top: 1.4285714286em;
}
.seminar-detail-content h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
}
@media screen and (min-width: 769px) {
  .seminar-detail-content h2 {
    font-size: 28px;
  }
}
.seminar-detail-ft {
  position: relative;
}
.seminar-related {
  position: relative;
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .seminar-related {
    margin-top: 80px;
  }
}
.seminar-related-title {
  margin-bottom: 18px;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.5;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .seminar-related-title {
    margin-bottom: 44px;
    font-size: 42px;
  }
}
@media screen and (min-width: 769px) {
  .seminar-related-lists {
    display: flex;
    flex-wrap: wrap;
  }
}
.seminar-related-lists--item {
  position: relative;
}
.seminar-related-lists--item > a {
  display: block;
  position: relative;
}
@media screen and (max-width: 768px) {
  .seminar-related-lists--item + .seminar-related-lists--item {
    margin-top: 40px;
  }
}
@media screen and (min-width: 769px) {
  .seminar-related-lists--item {
    margin: 8.1249999999% 8.1249999999% 0 0;
    width: 27.9166666667%;
  }
  .seminar-related-lists--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .seminar-related-lists--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.seminar-related-lists--photo {
  position: relative;
  margin-bottom: 18px;
  border: 1px solid #ccc;
  padding-bottom: 71.6417910448%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.seminar-related-lists--photo img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  max-width: inherit;
  object-fit: cover;
}
.seminar-related-lists__info {
  position: relative;
  color: #0168B7;
}
.seminar-related-lists--title {
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.5;
}
.seminar-related-lists--btn {
  margin: 18px 20px 0;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
.seminar-related-lists--btn > span {
  display: block;
  position: relative;
  padding: 20px 0;
  background-color: #0168B7;
  border-radius: 32px;
  color: #fff;
}
.seminar-related-lists--btn > span::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -8px;
  width: 10px;
  height: 16px;
  background: url(../img/icon/arrow6.png) no-repeat center center;
  background-size: 100%;
}

/* sitemap
------------------------------------------------ */
.sitemap {
  /* top */
  /* lists */
}
.sitemap-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .sitemap-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .sitemap-lists {
    margin: 0 20px;
  }
}
.sitemap-lists-block {
  position: relative;
  margin-top: 45px;
}
.sitemap-lists-block:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-block {
    margin-top: 70px;
  }
}
.sitemap-lists-box {
  position: relative;
  margin: 0 15px 42px;
}
.sitemap-lists-box:last-child {
  margin-bottom: 0;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-box {
    margin: 0 50px 80px;
  }
}
.sitemap-lists-box--title {
  position: relative;
  margin-bottom: 10px;
  padding-bottom: 10px;
  border-bottom: solid #c6dbec 1px;
  font-size: 19px;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-box--title {
    margin-bottom: 24px;
    padding-bottom: 12px;
    border-bottom-width: 2px;
    font-size: 26px;
  }
}
.sitemap-lists-box--title a {
  display: block;
  color: #0168b7;
}
.sitemap-lists-links {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}
.sitemap-lists-links--item {
  position: relative;
  border-bottom: solid #c6dbec 1px;
  font-size: 14px;
  line-height: 1.2857142857;
}
@media screen and (max-width: 768px) {
  .sitemap-lists-links--item {
    margin: 8px 8.1967213114% 0 0;
    width: 45.9016393443%;
  }
  .sitemap-lists-links--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .sitemap-lists-links--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .sitemap-lists-links--item {
    margin: 22px 4.2424242424% 0 0;
    width: 21.8181818182%;
    font-size: 16px;
  }
  .sitemap-lists-links--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .sitemap-lists-links--item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.sitemap-lists-links--item a {
  display: block;
  position: relative;
  padding: 8px 0;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-links--item a {
    padding: 14px 0;
  }
}
.sitemap-lists-pages {
  display: flex;
  flex-wrap: wrap;
}
.sitemap-lists-pages--item {
  position: relative;
  border-bottom: solid #a5a5a5 1px;
  font-size: 18px;
  line-height: 1.3333333333;
}
@media screen and (max-width: 768px) {
  .sitemap-lists-pages--item {
    margin: 40px 11.9402985074% 0 0;
    width: 44.0298507463%;
  }
  .sitemap-lists-pages--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .sitemap-lists-pages--item:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item {
    margin: 90px 10% 0 0;
    width: 17.5%;
    border-bottom-width: 2px;
    font-weight: 700;
  }
  .sitemap-lists-pages--item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .sitemap-lists-pages--item:nth-of-type(4n) {
    margin-right: 0;
  }
}
.sitemap-lists-pages--item a {
  display: block;
  position: relative;
  padding: 14px 0;
  height: 100%;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item a {
    padding: 16px 0;
  }
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item span {
    font-size: 14px;
  }
}
.sitemap-lists-pages--item:before, .sitemap-lists-pages--item:after {
  content: "";
  bottom: -1px;
  position: absolute;
  height: 1px;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item:before, .sitemap-lists-pages--item:after {
    bottom: -2px;
    height: 2px;
  }
}
.sitemap-lists-pages--item:before {
  left: 40px;
  width: 10px;
  background: #fff;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item:before {
    left: 60px;
  }
}
.sitemap-lists-pages--item:after {
  left: 38px;
  transform-origin: right;
  transform: rotate(-45deg);
  width: 12px;
  background: #a5a5a5;
}
@media screen and (min-width: 769px) {
  .sitemap-lists-pages--item:after {
    left: 58px;
  }
}

/* target
------------------------------------------------ */
.target {
  /* parts */
  /* top */
  /* page */
  /* title */
  /* introduce */
  /* search */
  /* flow */
  /* main */
  /* sputtering */
  /* c3 */
  /* special */
  /* alloy */
  /* sds */
}
.target-link {
  margin-top: 30px;
  text-align: center;
  font-size: 20px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .target-link {
    margin-top: 40px;
    font-size: 28px;
  }
}
.target-link > a {
  display: inline-block;
  text-decoration: underline;
  vertical-align: top;
  color: #0168B7;
}
.target-top {
  position: relative;
}
.target-top .title--ribbon {
  background-image: url(../img/target/top_title.jpg);
}
.target-top .topicpath {
  margin-bottom: 25px;
}
@media screen and (min-width: 769px) {
  .target-top .topicpath {
    margin-bottom: 18px;
  }
}
.target-top-outline {
  position: relative;
  margin-bottom: 30px;
}
@media screen and (min-width: 769px) {
  .target-top-outline {
    margin-bottom: 60px;
  }
}
@media screen and (max-width: 768px) {
  .target-top-outline {
    padding: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .target-top-outline {
    padding: 115px 0;
  }
}
@media screen and (min-width: 769px) {
  .target-top-outline__info {
    margin-left: 43.3333333333%;
  }
}
.target-top-outline--title {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 28px;
  font-weight: 700;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .target-top-outline--title {
    font-size: 60px;
  }
}
.target-top-outline--text {
  margin-top: 10px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (min-width: 769px) {
  .target-top-outline--text {
    margin-top: 28px;
    font-size: 18px;
  }
}
@media screen and (max-width: 768px) {
  .target-top-outline--photo {
    margin-top: 28px;
  }
}
@media screen and (min-width: 769px) {
  .target-top-outline--photo {
    position: absolute;
    top: 50%;
    left: -340px;
    transform: translateY(-50%);
    width: 64.5161290323%;
  }
}
.target-top-sds {
  position: relative;
  margin-top: 34px;
}
@media screen and (min-width: 769px) {
  .target-top-sds {
    display: flex;
    margin: 60px auto 0;
    max-width: 836px;
  }
}
.target-top-sds--title {
  padding: 12px 0;
  background-color: #0168B7;
  border-radius: 10px 10px 0 0;
  text-align: center;
  font-size: 22px;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .target-top-sds--title {
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    width: 236px;
    border-radius: 8px 0 0 8px;
    font-size: 25px;
  }
}
@media screen and (max-width: 768px) {
  .target-top-sds--title > span {
    font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  }
}
.target-top-sds__info {
  position: relative;
  padding: 13px 20px 18px;
  background-color: #3486C5;
  border-radius: 0 0 10px 10px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .target-top-sds__info {
    display: flex;
    align-items: center;
    padding: 18px 35px 18px 30px;
    border-radius: 0 8px 8px 0;
    width: calc(100% - 220px);
  }
}
.target-top-sds--text {
  font-size: 14px;
  line-height: 1.7857142857;
}
@media screen and (max-width: 768px) {
  .target-top-sds--text {
    text-align: center;
  }
}
@media screen and (min-width: 769px) {
  .target-top-sds--text {
    margin-right: 30px;
    width: calc(100% - 230px);
    font-size: 16px;
  }
}
.target-top-sds--btn {
  max-width: 258px;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .target-top-sds--btn {
    margin: 15px auto 0;
  }
}
@media screen and (min-width: 769px) {
  .target-top-sds--btn {
    width: 200px;
  }
}
.target-top-sds--btn > a {
  display: block;
  position: relative;
  padding: 10px;
  background-color: #fff;
  border: 1px solid #fff;
  border-radius: 32px;
  color: #0168B7;
}
.target-top-sds--btn > a::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 48px;
  margin-top: -8px;
  width: 10px;
  height: 17px;
  background: url(../img/icon/arrow7.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .target-top-sds--btn > a::after {
    right: 22px;
  }
}
.target-page .topicpath {
  margin-bottom: 15px;
}
@media screen and (min-width: 769px) {
  .target-page .topicpath {
    margin-bottom: 18px;
  }
}
.target-title .title--page {
  color: #fff;
}
@media screen and (max-width: 768px) {
  .target-title .title--page {
    background-image: url(../img/target/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .target-title .title--page {
    background-image: url(../img/target/title_bg-pc.jpg);
  }
}
.target-introduce {
  background-color: #E5F0F8;
  padding: 30px 0 50px;
}
@media screen and (max-width: 768px) {
  .target-introduce > .wrap {
    padding: 0 20px;
  }
}
@media screen and (min-width: 769px) {
  .target-introduce {
    padding: 80px 0 95px;
  }
}
.target-introduce .business-index-lists {
  padding-top: 70px;
}
@media screen and (min-width: 769px) {
  .target-introduce .business-index-lists {
    padding-top: 130px;
  }
}
@media screen and (max-width: 768px) {
  .target-introduce .business-index-lists--item {
    margin: 122px 0 0;
  }
  .target-introduce .business-index-lists--item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .target-introduce .business-index-lists--item {
    width: 30%;
    margin: 122px 5% 0 0;
  }
  .target-introduce .business-index-lists--item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .target-introduce .business-index-lists--item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.target-introduce .business-index-lists--photo {
  top: -92px;
  margin-left: -92px;
  width: 185px;
  height: 185px;
}
.target-introduce .business-index-lists__info {
  padding: 112px 20px 105px;
}
@media screen and (min-width: 769px) {
  .target-introduce .business-index-lists__info {
    padding: 112px 30px 105px;
  }
}
.target-introduce .business-index-lists--title {
  font-size: 24px;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .target-introduce .business-index-lists--title {
    font-size: 28px;
  }
}
.target-introduce .business-index-lists--text {
  font-size: 15px;
  line-height: 1.6666666667;
}
.target-introduce .business-index-lists--pic {
  margin-top: 20px;
  max-width: 207px;
}
.target-introduce .business-index-lists--btn {
  bottom: 30px;
  max-width: 258px;
  font-size: 16px;
}
.target-introduce .business-index-lists--btn > a {
  padding: 14px 10px;
}
.target-introduce .business-index-lists--btn > a::after {
  right: 20px;
  margin-top: -8px;
  width: 10px;
  height: 17px;
  background-image: url(../img/icon/arrow6.png);
}
.target-search {
  position: relative;
  margin-top: 45px;
}
@media screen and (min-width: 769px) {
  .target-search {
    margin-top: 90px;
  }
}
.target-search-title {
  margin-bottom: 24px;
  text-align: center;
  font-size: 30px;
  font-weight: 700;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .target-search-title {
    margin-bottom: 26px;
    font-size: 60px;
  }
}
.target-flow {
  margin-top: 55px;
}
@media screen and (min-width: 769px) {
  .target-flow {
    margin-top: 90px;
  }
}
.target-flow-title {
  margin-bottom: 20px;
  text-align: center;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .target-flow-title {
    margin-bottom: 86px;
  }
}
.target-flow-title strong,
.target-flow-title span {
  display: block;
  line-height: 1.2;
}
.target-flow-title strong {
  font-size: 30px;
  font-weight: 700;
}
@media screen and (min-width: 769px) {
  .target-flow-title strong {
    font-size: 60px;
  }
}
.target-flow-title span {
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .target-flow-title span {
    font-size: 34px;
  }
}
@media screen and (max-width: 768px) {
  .target-flow-lists {
    margin-left: 30px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists {
    display: flex;
    flex-wrap: wrap;
  }
}
.target-flow-lists--item {
  position: relative;
  border: 1px solid #fff;
  z-index: 1;
  height: 100px;
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item {
    width: 20%;
    height: 260px;
  }
}
.target-flow-lists--item:nth-of-type(1) {
  background-color: #0A65B7;
}
.target-flow-lists--item:nth-of-type(1) .target-flow-lists--num {
  color: #0A65B7;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item:nth-of-type(1) .target-flow-lists--icon {
    width: 70px;
    height: 70px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item:nth-of-type(1) .target-flow-lists--icon {
    padding-bottom: 50%;
    width: 50%;
  }
}
.target-flow-lists--item:nth-of-type(2) {
  background-color: #3682C4;
}
.target-flow-lists--item:nth-of-type(2) .target-flow-lists--num {
  color: #3682C4;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item:nth-of-type(2) .target-flow-lists--icon {
    width: 100px;
    height: 77px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item:nth-of-type(2) .target-flow-lists--icon {
    padding-bottom: 62.5%;
    width: 81.25%;
  }
}
.target-flow-lists--item:nth-of-type(3) {
  background-color: #7EAED7;
}
.target-flow-lists--item:nth-of-type(3) .target-flow-lists--num {
  color: #7EAED7;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item:nth-of-type(3) .target-flow-lists--icon {
    width: 60px;
    height: 80px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item:nth-of-type(3) .target-flow-lists--icon {
    padding-bottom: 50%;
    width: 37.5%;
  }
}
.target-flow-lists--item:nth-of-type(4) {
  background-color: #21C8BA;
}
.target-flow-lists--item:nth-of-type(4) .target-flow-lists--num {
  color: #21C8BA;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item:nth-of-type(4) .target-flow-lists--icon {
    width: 87px;
    height: 70px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item:nth-of-type(4) .target-flow-lists--icon {
    padding-bottom: 43.3333333333%;
    width: 53.75%;
  }
}
.target-flow-lists--item:nth-of-type(5) {
  background-color: #FA960B;
}
.target-flow-lists--item:nth-of-type(5) .target-flow-lists--num {
  color: #FA960B;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item.flow-5-1 .target-flow-lists--icon {
    width: 87px;
    height: 70px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item.flow-5-1 .target-flow-lists--icon {
    padding-bottom: 45.4166666667%;
    width: 57.5%;
  }
}
@media screen and (max-width: 768px) {
  .target-flow-lists--item.flow-5-2 .target-flow-lists--icon {
    width: 96px;
    height: 70px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--item.flow-5-2 .target-flow-lists--icon {
    padding-bottom: 40%;
    width: 55%;
  }
}
.target-flow-lists--num {
  position: absolute;
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 50%;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 36px;
  line-height: 80px;
}
@media screen and (max-width: 768px) {
  .target-flow-lists--num {
    top: 50%;
    left: -30px;
    margin-top: -40px;
  }
}
@media screen and (min-width: 769px) {
  .target-flow-lists--num {
    top: -17.3076923077%;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 50%;
    width: 50%;
    height: 0;
    font-size: 55px;
    line-height: 108px;
    overflow: hidden;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .target-flow-lists--num {
    font-size: 4.5833333333vw;
    line-height: 9vw;
  }
}
.target-flow-lists--name {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  width: 100%;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.6;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .target-flow-lists--name {
    padding-top: 50px;
    text-align: center;
    font-size: 24px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .target-flow-lists--name {
    font-size: 2vw;
  }
}
.target-flow-lists--icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;
}
@media screen and (min-width: 769px) {
  .target-flow-lists--icon {
    top: calc(50% + 30px);
    height: 0;
    overflow: hidden;
  }
  .target-flow-lists--icon img {
    position: absolute;
    top: 0;
    left: 0;
  }
}
.target-main-title {
  text-align: center;
  font-size: 28px;
  font-weight: 700;
  line-height: 1.4285714286;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .target-main-title {
    font-size: 60px;
  }
}
.target-main-title + .target-main-text {
  margin-top: 18px;
}
@media screen and (min-width: 769px) {
  .target-main-title + .target-main-text {
    margin-top: 24px;
  }
}
.target-main-title + .target-main-copy {
  margin-top: 18px;
}
@media screen and (min-width: 769px) {
  .target-main-title + .target-main-copy {
    margin-top: 30px;
  }
}
.target-main-en {
  text-align: center;
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .target-main-en {
    font-size: 34px;
  }
}
.target-main-en + .target-main-copy {
  margin-top: 12px;
}
@media screen and (min-width: 769px) {
  .target-main-en + .target-main-copy {
    margin-top: 32px;
  }
}
.target-main-copy {
  margin-bottom: 16px;
  text-align: center;
  font-size: 18px;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .target-main-copy {
    margin-bottom: 20px;
    font-size: 24px;
  }
}
.target-main-text {
  text-align: center;
  font-size: 16px;
  line-height: 1.5625;
}
@media screen and (min-width: 769px) {
  .target-main-text.has-pc-margin {
    margin-right: 60px;
    margin-left: 60px;
  }
}
.target-main-lng {
  margin-top: 20px;
  text-align: right;
  font-size: 16px;
  line-height: 1.2;
}
.target-main-lng > a {
  display: inline-block;
  padding: 8px 24px 12px 50px;
  background-color: #fff;
  border: 1px solid #404040;
  border-radius: 32px;
  text-align: center;
  vertical-align: top;
}
.target-main-lng > a::after {
  content: "→";
  padding-left: 16px;
}
.target-main-visual {
  margin-top: 20px;
}
.target-sputtering {
  margin-top: 37px;
}
@media screen and (min-width: 769px) {
  .target-sputtering {
    margin-top: 90px;
  }
}
.target-sputtering-lists--item {
  position: relative;
  padding: 80px 20px 28px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--item {
    display: flex;
    flex-wrap: wrap;
    padding: 64px 50px 50px;
  }
}
.target-sputtering-lists--item:not(:first-child) {
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--item:not(:first-child) {
    margin-top: 40px;
  }
}
.target-sputtering-lists--num {
  position: absolute;
  top: 0;
  left: 0;
  width: 80px;
  height: 63px;
  background-color: #3486C5;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 40px;
  line-height: 63px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--num {
    width: 130px;
    height: 110px;
    font-size: 64px;
    line-height: 110px;
  }
}
.target-sputtering-lists--title {
  position: relative;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--title {
    margin-bottom: 50px;
    width: 100%;
    font-size: 33px;
  }
}
.target-sputtering-lists--photo {
  position: relative;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--photo {
    width: 45.4545454545%;
  }
  .target-sputtering-lists--photo + .target-sputtering-lists__info {
    margin-left: 3.6363636364%;
    width: 50.9090909091%;
  }
}
@media screen and (max-width: 768px) {
  .target-sputtering-lists__info {
    margin-top: 20px;
  }
}
.target-sputtering-lists__info table {
  width: 100%;
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-spacing: 0;
  border-collapse: collapse;
}
.target-sputtering-lists__info table th,
.target-sputtering-lists__info table td {
  padding: 12px;
  border-right: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  vertical-align: top;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists__info table th,
.target-sputtering-lists__info table td {
    padding: 20px 12px;
  }
}
.target-sputtering-lists__info table th {
  width: 100px;
  background-color: #E5F0F8;
  font-size: 15px;
  font-weight: 400;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists__info table th {
    width: 180px;
  }
}
.target-sputtering-lists__info table td {
  font-size: 14px;
}
.target-sputtering-lists--pdf {
  margin-top: 20px;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .target-sputtering-lists--pdf {
    margin-top: 27px;
    max-width: 420px;
  }
}
.target-sputtering-lists--pdf > a {
  display: block;
  padding: 20px 0 15px;
  background-color: #0168B7;
  border-radius: 32px;
  color: #fff;
}
.target-sputtering-lists--pdf > a::before {
  content: "";
  display: inline-block;
  margin: -5px 16px 0 0;
  width: 26px;
  height: 31px;
  background: url(../img/icon/pdf3.png) no-repeat center center;
  background-size: 100%;
  vertical-align: top;
}
.target-c3 {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .target-c3 {
    margin-top: 50px;
  }
}
.target-c3-post {
  position: relative;
  padding: 40px 20px 30px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .target-c3-post {
    padding: 42px 50px 48px;
  }
}
.target-c3-hd {
  position: relative;
  margin-bottom: 20px;
}
@media screen and (min-width: 769px) {
  .target-c3-hd {
    margin-bottom: 30px;
  }
}
.target-c3-copy {
  margin-bottom: 20px;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .target-c3-copy {
    margin-bottom: 30px;
  }
}
.target-c3-photo {
  margin: 0 auto;
  max-width: 693px;
}
.target-c3-content {
  font-size: 15px;
  line-height: 1.6;
}
.target-c3-content > p + p {
  margin-top: 1.6em;
}
.target-c3-content .photos {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .target-c3-content .photos {
    display: flex;
    flex-wrap: wrap;
    margin: 30px auto 0;
    max-width: 943px;
  }
}
@media screen and (max-width: 768px) and (max-width: 768px) {
  .target-c3-content .photos > li + .target-c3-content .photos > li {
    margin-top: 30px;
  }
}
@media screen and (min-width: 769px) {
  .target-c3-content .photos > li {
    margin: 2.4390243902% 2.4390243902% 0 0;
    width: 48.7804878049%;
  }
  .target-c3-content .photos > li:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .target-c3-content .photos > li:nth-of-type(2n) {
    margin-right: 0;
  }
}
.target-special {
  position: relative;
  margin-top: 20px;
  padding-bottom: 110.471641791%;
  height: 0;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .target-special {
    margin-top: 40px;
    padding-bottom: 57.9391666667%;
  }
}
.target-special-lists {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.target-special-lists--item {
  position: absolute;
  height: 0;
  overflow: hidden;
}
@media screen and (max-width: 768px) {
  .target-special-lists--item:nth-of-type(1) {
    top: 5.4042369217%;
    left: 0;
    padding-bottom: 44.7552238806%;
    width: 41.5611940299%;
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--balloon {
    top: 0;
    right: 0;
    padding-top: 14.3647202471%;
    width: 76.104287869%;
    height: 71.9735876742%;
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon1.png);
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--character {
    bottom: 0;
    left: 0;
    width: 46.8720821662%;
  }
}
@media screen and (min-width: 769px) {
  .target-special-lists--item:nth-of-type(1) {
    top: 5.3216735944%;
    left: 0;
    padding-bottom: 26.7525%;
    width: 29.255%;
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--balloon {
    top: 0;
    right: 0;
    padding-top: 19.3699082778%;
    width: 60.9753318521%;
    height: 67.9064261907%;
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon1-pc.png);
  }
  .target-special-lists--item:nth-of-type(1) .target-special-lists--character {
    bottom: 0;
    left: 0;
    width: 49.4587819746%;
  }
}
@media screen and (max-width: 768px) {
  .target-special-lists--item:nth-of-type(2) {
    top: 0;
    right: 0;
    padding-bottom: 48.3582089552%;
    width: 45.671641791%;
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--balloon {
    top: 0;
    left: 0;
    padding-top: 13.0718954248%;
    width: 78.6928104575%;
    height: 75.6913580247%;
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon2.png);
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--character {
    right: 0;
    bottom: 0;
    width: 43.3333333333%;
  }
}
@media screen and (min-width: 769px) {
  .target-special-lists--item:nth-of-type(2) {
    top: 9.9978383052%;
    right: 0;
    padding-bottom: 22.17%;
    width: 37.8208333333%;
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--balloon {
    top: 0;
    left: 0;
    padding-top: 9.2541588631%;
    width: 70.4748264845%;
    height: 61.2276349421%;
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon2-pc.png);
  }
  .target-special-lists--item:nth-of-type(2) .target-special-lists--character {
    right: 0;
    bottom: 0;
    width: 35.2958025779%;
  }
}
@media screen and (max-width: 768px) {
  .target-special-lists--item:nth-of-type(3) {
    bottom: 0;
    left: 0;
    padding-bottom: 48.952238806%;
    width: 45.7313432836%;
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--balloon {
    top: 0;
    right: 0;
    padding-top: 13.0548302872%;
    width: 78.590078329%;
    height: 74.7728520032%;
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon3.png);
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--character {
    bottom: 0;
    left: 0;
    width: 42.4347258486%;
  }
}
@media screen and (min-width: 769px) {
  .target-special-lists--item:nth-of-type(3) {
    bottom: 0;
    left: 0;
    padding-bottom: 25.3516666667%;
    width: 39.4041666667%;
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--balloon {
    top: 0;
    right: 0;
    padding-top: 8.4593422861%;
    width: 67.6430157555%;
    height: 53.5434882651%;
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon3-pc.png);
  }
  .target-special-lists--item:nth-of-type(3) .target-special-lists--character {
    bottom: 0;
    left: 0;
    width: 39.4247647245%;
  }
}
@media screen and (max-width: 768px) {
  .target-special-lists--item:nth-of-type(4) {
    right: 0;
    bottom: 0;
    padding-bottom: 38.5343283582%;
    width: 47.4626865672%;
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--balloon {
    top: 0;
    left: 0;
    padding-top: 6.2893081761%;
    width: 88.679245283%;
    height: 55.6278565342%;
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon4.png);
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--character {
    right: 0;
    bottom: 0;
    width: 42.1194968553%;
  }
}
@media screen and (min-width: 769px) {
  .target-special-lists--item:nth-of-type(4) {
    right: 0;
    bottom: 0;
    padding-bottom: 26.9866666667%;
    width: 29.005%;
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--balloon {
    top: 0;
    left: 0;
    padding-top: 18.3876343159%;
    width: 61.500890651%;
    height: 67.3171936759%;
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--balloon::after {
    background-image: url(../img/target/special_balloon4-pc.png);
  }
  .target-special-lists--item:nth-of-type(4) .target-special-lists--character {
    right: 0;
    bottom: 0;
    width: 49.1150950985%;
  }
}
.target-special-lists--balloon {
  position: absolute;
  text-align: center;
  font-size: 3.4666666667vw;
  font-weight: 700;
  line-height: 1.4769230769;
  color: #fff;
  overflow: hidden;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .target-special-lists--balloon {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) and (max-width: 1200px) {
  .target-special-lists--balloon {
    font-size: 1.6666666667vw;
  }
}
.target-special-lists--balloon::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  z-index: -1;
}
.target-special-lists--character {
  position: absolute;
  z-index: 2;
}
.target-special-visual {
  position: absolute;
  top: 17.5637699957%;
  left: 10px;
  width: calc(100% - 20px);
  border-radius: 50px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .target-special-visual {
    top: 0;
    left: 8.3333333334%;
    width: 83.3333333333%;
  }
}
.target-alloy {
  margin-top: 40px;
}
@media screen and (min-width: 769px) {
  .target-alloy {
    margin-top: 60px;
  }
}
.target-alloy-detail {
  padding: 32px 20px 28px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .target-alloy-detail {
    padding: 42px 50px 48px;
  }
}
.target-alloy-title {
  margin-bottom: 20px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .target-alloy-title {
    margin-bottom: 28px;
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .target-alloy-title span {
    display: block;
    font-size: 18px;
  }
}
@media screen and (min-width: 769px) {
  .target-alloy-title span {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: top;
  }
}
.target-alloy-content > dl {
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
}
.target-alloy-content > dl:last-child {
  border-bottom: 1px solid #ccc;
}
.target-alloy-content > dl dt,
.target-alloy-content > dl dd {
  border-right: 1px solid #ccc;
  padding: 15px 20px;
}
@media screen and (min-width: 769px) {
  .target-alloy-content > dl dt,
.target-alloy-content > dl dd {
    padding: 16px 20px;
  }
}
.target-alloy-content > dl dt {
  background-color: #E5F0F8;
  font-size: 15px;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .target-alloy-content > dl dt {
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (min-width: 769px) {
  .target-alloy-content > dl dt {
    line-height: 2;
  }
}
.target-alloy-content > dl dd {
  font-size: 14px;
  line-height: 1.6;
}
@media screen and (min-width: 769px) {
  .target-alloy-content > dl dd {
    line-height: 2.1428571429;
  }
}
.target-alloy-note {
  margin-top: 20px;
  font-size: 15px;
  line-height: 1.6666666667;
}
.target-sds {
  margin-top: 20px;
}
@media screen and (min-width: 769px) {
  .target-sds {
    margin-top: 52px;
  }
}
.target-sds-download {
  padding: 40px 20px 26px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .target-sds-download {
    padding: 42px 52px 82px;
  }
}
.target-sds-download--title {
  margin-bottom: 32px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .target-sds-download--title {
    margin-bottom: 50px;
    font-size: 33px;
  }
}
@media screen and (max-width: 768px) {
  .target-sds-download--title span {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download--title span {
    display: inline-block;
    margin-left: 0.5em;
    vertical-align: top;
  }
}
.target-sds-download--subtitle {
  margin-bottom: 30px;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .target-sds-download--subtitle {
    margin-bottom: 24px;
    font-size: 28px;
  }
}
.target-sds-download__block {
  position: relative;
}
.target-sds-download__block + .target-sds-download__block {
  margin-top: 42px;
}
@media screen and (min-width: 769px) {
  .target-sds-download__block + .target-sds-download__block {
    margin-top: 54px;
  }
}
.target-sds-download__lists--item {
  position: relative;
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item {
    border-top: 1px solid #ccc;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item {
    display: grid;
    column-gap: 5px;
    grid-template-columns: 24.5% 24.5% 51%;
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item + .target-sds-download__lists--item {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item:not(:first-child) dt {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item dl {
    display: flex;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl:nth-of-type(1) dd {
    justify-content: center;
  }
  .target-sds-download__lists--item dl:nth-of-type(1) dd .name {
    justify-content: center;
  }
  .target-sds-download__lists--item dl:nth-of-type(2) dd {
    padding-left: 32px;
  }
}
.target-sds-download__lists--item dl dt,
.target-sds-download__lists--item dl dd {
  padding: 10px;
  font-weight: 400;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item dl dt,
.target-sds-download__lists--item dl dd {
    border-bottom: 1px solid #ccc;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dt,
.target-sds-download__lists--item dl dd {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.target-sds-download__lists--item dl dt {
  background-color: #E5F0F8;
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item dl dt {
    width: 130px;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dt {
    text-align: center;
    font-size: 18px;
  }
}
.target-sds-download__lists--item dl dd {
  text-align: center;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item dl dd {
    width: calc(100% - 130px);
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dd {
    display: flex;
    align-items: center;
    font-size: 18px;
    min-height: 80px;
  }
}
.target-sds-download__lists--item dl dd .name {
  display: flex;
  align-items: baseline;
  width: 100%;
}
.target-sds-download__lists--item dl dd .btn {
  font-size: 14px;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dd .btn {
    margin: 0 auto;
    width: 100%;
    max-width: 340px;
    font-size: 16px;
  }
}
.target-sds-download__lists--item dl dd .btn > a {
  display: block;
  padding: 8px 10px 10px;
  border: 1px solid #ccc;
  border-radius: 32px;
}
@media screen and (max-width: 768px) {
  .target-sds-download__lists--item dl dd .btn > a span {
    display: none;
  }
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dd .btn > a {
    padding: 13px 10px 11px;
  }
}
.target-sds-download__lists--item dl dd .btn > a::before {
  content: "";
  display: block;
  margin: 0 auto 4px;
  width: 13px;
  height: 16px;
  background: url(../img/icon/pdf2.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .target-sds-download__lists--item dl dd .btn > a::before {
    display: inline-block;
    margin: -2px 30px 0 0;
    width: 22px;
    height: 26px;
    vertical-align: top;
  }
}
.target-sds-note {
  margin-top: 20px;
  font-size: 15px;
  line-height: 1.6666666667;
}

/* Tech Library
------------------------------------------------ */
.techlib {
  /* top */
  /* lists */
  /* nav */
  /* archive */
  /* single */
  /* detail */
}
.techlib-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .techlib-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .techlib-top .title--page {
    background-image: url(../img/techlibrary/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .techlib-top .title--page {
    background-image: url(../img/techlibrary/title_bg-pc.jpg);
  }
}
@media screen and (max-width: 768px) {
  .techlib-lists {
    margin: 0 20px;
  }
  .techlib-lists .title--5 {
    display: block;
    margin-bottom: 14px;
  }
  .techlib-lists .title--5 .l,
.techlib-lists .title--5 .min {
    display: inline;
  }
}
.techlib-lists-lead {
  font-size: 14px;
  line-height: 1.75;
}
@media screen and (min-width: 769px) {
  .techlib-lists-lead {
    font-size: 15px;
  }
}
.techlib-lists-lead span {
  display: inline-block;
  vertical-align: top;
  font-size: 11px;
}
@media screen and (min-width: 769px) {
  .techlib-lists-lead span {
    font-size: 12px;
  }
}
.techlib-nav {
  margin: 12px 20px 0;
}
@media screen and (min-width: 769px) {
  .techlib-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0;
  }
}
.techlib-nav-archive {
  margin-left: auto;
  width: 150px;
  height: 40px;
}
@media screen and (min-width: 769px) {
  .techlib-nav-archive {
    width: 300px;
    height: 58px;
  }
}
.techlib-nav-archive select {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  .techlib-nav-archive select {
    padding: 0 20px;
    font-size: 24px;
  }
}
.techlib-archive {
  margin-top: 25px;
}
@media screen and (min-width: 769px) {
  .techlib-archive {
    margin-top: 32px;
  }
}
.techlib-archive-box {
  margin-top: 30px;
}
.techlib-archive-box:first-child {
  margin-top: 0;
}
@media screen and (min-width: 769px) {
  .techlib-archive-box {
    margin-top: 52px;
  }
}
.techlib-archive-box--title {
  position: relative;
  padding-bottom: 12px;
  border-bottom: solid #ccc 1px;
  font-size: 26px;
  line-height: 1.2;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .techlib-archive-box--title {
    padding-bottom: 20px;
    font-size: 42px;
  }
}
.techlib-archive-box--title:after {
  content: "";
  position: absolute;
  bottom: -2px;
  left: 0;
  width: 126px;
  height: 3px;
  background: #0168b7;
}
@media screen and (min-width: 769px) {
  .techlib-archive-box--title:after {
    width: 200px;
  }
}
.techlib-archive-box__content {
  position: relative;
}
.techlib-archive-lists--item {
  border-bottom: solid #ccc 1px;
}
.techlib-archive-lists--item > a, .techlib-archive-lists--item > span {
  padding: 15px 0;
}
@media screen and (max-width: 768px) {
  .techlib-archive-lists--item > a, .techlib-archive-lists--item > span {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists--item > a, .techlib-archive-lists--item > span {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding: 30px 20px;
  }
}
.techlib-archive-lists--date {
  font-size: 15px;
  font-weight: 600;
  color: #0068b7;
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists--date {
    width: 120px;
    font-size: 18px;
  }
}
.techlib-archive-lists--title {
  font-size: 14px;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .techlib-archive-lists--title {
    margin-top: 16px;
  }
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists--title {
    width: calc(100% - 120px);
    font-size: 16px;
  }
}
.techlib-archive-lists__btns {
  margin-top: 15px;
  width: 100%;
}
@media screen and (max-width: 768px) {
  .techlib-archive-lists__btns {
    padding: 0 60px;
  }
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists__btns {
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0 0 -10px;
    padding-left: 120px;
  }
}
.techlib-archive-lists__btns--item {
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .techlib-archive-lists__btns--item + .techlib-archive-lists__btns--item {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists__btns--item {
    margin: 10px 0 0 10px;
    width: 220px;
  }
}
.techlib-archive-lists__btns--item > a {
  display: block;
  padding: 14px 0;
  border-radius: 30px;
}
@media screen and (min-width: 769px) {
  .techlib-archive-lists__btns--item > a {
    padding: 7px 0;
  }
}
.techlib-archive-lists__btns--item.btn-applicate > a {
  background-color: #F6F033;
}
.techlib-archive-lists__btns--item.btn-applicated > a {
  background-color: #0168B7;
  color: #fff;
}
.techlib-single {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .techlib-single {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .techlib-single .title--page {
    background-image: url(../img/techlibrary/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .techlib-single .title--page {
    background-image: url(../img/techlibrary/title_bg-pc.jpg);
  }
}
@media screen and (max-width: 768px) {
  .techlib-detail {
    margin: 0 20px;
  }
}
.techlib-detail-hd__info {
  position: relative;
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .techlib-detail-hd__info {
    margin-top: 40px;
    padding-left: 180px;
  }
}
.techlib-detail-title {
  border-bottom: solid #ccc 2px;
  font-size: 21px;
  font-weight: 400;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .techlib-detail-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 769px) {
  .techlib-detail-title {
    font-size: 42px;
  }
}
@media screen and (max-width: 768px) {
  .techlib-detail-photo {
    margin: 0 auto 20px;
    width: 35.7142857143%;
  }
}
@media screen and (min-width: 769px) {
  .techlib-detail-photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 120px;
  }
}
.techlib-detail-date {
  font-size: 15px;
  font-weight: 600;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .techlib-detail-date {
    font-size: 18px;
  }
}
.techlib-detail-content {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.625;
}
@media screen and (min-width: 769px) {
  .techlib-detail-content {
    margin-top: 24px;
    padding-left: 180px;
    min-height: 120px;
    font-size: 16px;
  }
}
.techlib-detail-content .linkPDF {
  display: inline-block;
  position: relative;
  vertical-align: top;
  color: #0168b7;
}
.techlib-detail-content .linkPDF:before {
  content: "";
  position: absolute;
  top: 2px;
  left: -25px;
  width: 15px;
  height: 18px;
  background: url(../img/icon/pdf1.png) no-repeat center center;
  background-size: 100%;
}
@media screen and (min-width: 769px) {
  .techlib-detail-content .linkPDF:before {
    left: -40px;
    width: 20px;
    height: 24px;
  }
}
.techlib-detail-content p:not(.btn) {
  position: relative;
  padding-left: 25px;
}
@media screen and (min-width: 769px) {
  .techlib-detail-content p:not(.btn) {
    padding-left: 40px;
  }
}
.techlib-detail-content p:not(.btn) + p:not(.btn) {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .techlib-detail-content p:not(.btn) + p:not(.btn) {
    margin-top: 1.6em;
  }
}
.techlib-detail-content p:not(.btn).no-space {
  padding-left: 0;
}
.techlib-detail-content .btn {
  margin-top: 8px;
}
@media screen and (max-width: 768px) {
  .techlib-detail-content .btn {
    display: grid;
    gap: 20px;
  }
}
@media screen and (min-width: 769px) {
  .techlib-detail-content .btn {
    display: flex;
    gap: 24px;
  }
}
.techlib-detail-content .link-pdf, .techlib-detail-content .link-html {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  font-size: 14px;
  letter-spacing: 0.1em;
  color: #0168B7;
}
.techlib-detail-content .link-pdf::before, .techlib-detail-content .link-html::before {
  content: "";
  margin-right: 10px;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.techlib-detail-content .link-pdf::before {
  position: relative;
  top: auto;
  left: auto;
  width: 17px;
  background-image: url(../img/icon/pdf1.png);
}
.techlib-detail-content .link-html::before {
  width: 17px;
  background-image: url(../img/icon/html1.png);
}
.techlib-detail-content dl:not(:first-child) {
  margin-top: 40px;
}
.techlib-detail-content dl + dl {
  margin-top: 40px;
}
.techlib-detail-content dl dt {
  text-decoration: underline;
  font-size: 18px;
  font-weight: bold;
}
.techlib-detail-content dl dd {
  padding-left: 20px;
}
@media screen and (min-width: 769px) {
  .techlib-detail-content dl dd {
    padding-left: 38px;
  }
}
.techlib-detail-content dl dd + dd {
  margin-top: 26px;
}
.techlib-detail-content ul:not(:first-child) {
  margin-top: 62px;
}
.techlib-detail-content ul li + li {
  margin-top: 40px;
}
.techlib-detail-ft {
  position: relative;
}

/* Tech Library2
------------------------------------------------ */
.page--tech_library2 .title--page {
  margin-bottom: 0;
  background-image: url(../img/techlibrary2/title_bg_page.jpg);
}

.techlib2 {
  /* search */
  /* lists */
  /* detail */
  /* post */
  /* related */
  /* btns */
}
.techlib2-content {
  padding: 20px 0 40px;
  background: url(../img/techlibrary2/bg1.png) repeat left top;
  background-size: 14px;
}
@media screen and (max-width: 768px) {
  .techlib2-content > .wrap:not(.topicpath) {
    padding-right: 20px;
    padding-left: 20px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-content {
    padding: 40px 0 80px;
  }
}
.techlib2-search {
  margin-bottom: 40px;
  background-color: #F8F7F6;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-search {
    margin-bottom: 80px;
  }
}
.techlib2-search-title {
  padding: 12px 0;
  border-bottom: 1px solid #E5F0F8;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.8;
}
.techlib2-search form {
  display: block;
  width: 100%;
}
.techlib2-search-form {
  display: flex;
  position: relative;
  margin: 0 10px;
  padding: 18px 0;
}
@media screen and (min-width: 769px) {
  .techlib2-search-form {
    margin: 0 74px;
  }
}
.techlib2-search-form__input {
  margin-right: 3px;
  width: calc(100% - 83px);
  height: 44px;
}
@media screen and (min-width: 769px) {
  .techlib2-search-form__input {
    width: calc(100% - 143px);
  }
}
.techlib2-search-form__input input {
  appearance: none;
  padding: 0 8px;
  width: 100%;
  height: 44px;
  background-color: #fff;
  border: none;
  border-radius: 5px;
  box-shadow: none;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .techlib2-search-form__input input {
    padding: 0 25px;
  }
}
.techlib2-search-form__submit {
  display: flex;
  justify-content: center;
  align-items: center;
  appearance: none;
  width: 80px;
  height: 44px;
  background-color: #0168B7;
  border: none;
  border-radius: 0 5px 5px 0;
  box-shadow: none;
  text-align: center;
  font-family: inherit;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-search-form__submit {
    width: 140px;
  }
}
.techlib2-search-form__submit::after {
  content: "";
  display: block;
  margin-left: 12px;
  width: 17px;
  height: 17px;
  background: url(../img/icon/search3.png) no-repeat center center;
  background-size: 100%;
}
.techlib2-lists {
  display: grid;
}
@media screen and (max-width: 768px) {
  .techlib2-lists {
    row-gap: 40px;
    column-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}
@media screen and (min-width: 769px) {
  .techlib2-lists {
    row-gap: 80px;
    column-gap: 24px;
    grid-template-columns: repeat(4, calc(25% - 18px));
  }
}
.techlib2-lists-item {
  display: grid;
  gap: 0;
  grid-template-rows: subgrid;
  grid-row: span 4;
  position: relative;
  padding: 16px;
  background-color: #fff;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-item {
    padding: 24px;
  }
}
.techlib2-lists-no {
  margin-bottom: 8px;
  font-size: 20px;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-no {
    font-size: 24px;
  }
}
.techlib2-lists-no > span {
  display: inline-block;
  padding: 5px 16px;
  border: 1px solid #0168B7;
  border-radius: 32px;
  vertical-align: top;
}
.techlib2-lists-no > span > span {
  padding-right: 0.2em;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-no > span > span {
    font-size: 20px;
  }
}
.techlib2-lists-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-title {
    font-size: 20px;
  }
}
.techlib2-lists-excerpt {
  display: -webkit-box;
  margin-top: 8px;
  padding-top: 8px;
  border-top: 1px solid #404040;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 1.4;
  max-height: 94px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-excerpt {
    font-size: 15px;
  }
}
.techlib2-lists-btn {
  margin: 24px auto 0;
  text-align: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
}
@media screen and (min-width: 769px) {
  .techlib2-lists-btn {
    width: 160px;
    font-size: 20px;
  }
}
.techlib2-lists-btn > a {
  display: block;
  padding: 4px 0;
  background: linear-gradient(90.39deg, #125797 0.34%, #0A60A7 20.78%, #0168B7 100.03%);
  border-radius: 20px;
  color: #fff;
}
.techlib2-detail-title {
  position: relative;
  padding: 38px 0;
  background: url(../img/techlibrary2/title_bg_page.jpg) no-repeat center bottom;
  background-size: auto 100%;
  background-color: #e5f0f8;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 82px 0;
  }
}
.techlib2-detail-title__name {
  text-align: center;
}
@media screen and (max-width: 768px) {
  .techlib2-detail-title__name {
    margin-bottom: 20px;
  }
}
.techlib2-detail-title__sub {
  margin-bottom: 8px;
  font-size: 25px;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__sub {
    font-size: 34px;
  }
}
.techlib2-detail-title__main {
  font-size: 54px;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__main {
    font-size: 72px;
  }
}
.techlib2-detail-title__num {
  position: relative;
}
@media screen and (max-width: 768px) {
  .techlib2-detail-title__num {
    text-align: center;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__num {
    margin-left: 20px;
  }
}
.techlib2-detail-title__vol {
  display: inline-flex;
  align-items: baseline;
  position: absolute;
  top: 10px;
  left: 0;
  margin-left: 18px;
  padding: 1px 10px 3px;
  font-size: 20px;
  line-height: 1.2;
  background-color: #0168B7;
  border-radius: 27px;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__vol {
    top: 14px;
    margin-left: 26px;
    font-size: 25px;
  }
}
.techlib2-detail-title__vol > span {
  font-size: 14px;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__vol > span {
    font-size: 19px;
  }
}
.techlib2-detail-title__no {
  display: inline-flex;
  align-items: baseline;
  padding: 6px 32px 14px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 58px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 70px;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__no {
    font-size: 96px;
  }
}
.techlib2-detail-title__no > span {
  font-size: 34px;
}
@media screen and (min-width: 769px) {
  .techlib2-detail-title__no > span {
    font-size: 50px;
  }
}
.techlib2-post-title {
  position: relative;
  margin-bottom: 16px;
  padding: 12px 18px;
  min-height: 80px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding: 25px 32px;
    min-height: 210px;
  }
}
.techlib2-post-title--en {
  position: relative;
  padding-right: 20px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #C6E4FC;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title--en {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--en {
    margin-right: 45px;
    padding-right: 40px;
    font-size: 38px;
  }
}
.techlib2-post-title--en::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(20deg);
  width: 1px;
  height: 28px;
  background-color: #C6E4FC;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--en::after {
    width: 2px;
    height: 98px;
  }
}
.techlib2-post-title--ja {
  position: relative;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--ja {
    font-size: 35px;
  }
}
.techlib2-post-title--name {
  position: relative;
  padding-right: 20px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title--name {
    display: inline-block;
    margin-bottom: 10px;
    vertical-align: top;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--name {
    margin-right: 45px;
    padding-right: 40px;
    font-size: 38px;
  }
}
.techlib2-post-title--name::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%) rotate(20deg);
  width: 1px;
  height: 28px;
  background-color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--name::after {
    width: 2px;
    height: 98px;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-post-title--sub {
    margin-bottom: 10px;
  }
}
.techlib2-post-title--sub > span {
  display: inline-block;
  padding: 4px 18px;
  background-color: #fff;
  border-radius: 24px;
  vertical-align: top;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #404040;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--sub > span {
    padding: 6px 28px;
    font-size: 25px;
  }
}
.techlib2-post-title--text {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.6;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title--text {
    margin-bottom: 10px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--text {
    font-size: 28px;
    line-height: 2.2142857143;
  }
}
.techlib2-post-title--author {
  padding: 8px 18px;
  background-color: #02508B;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0.04em;
  line-height: 1.4;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title--author {
    display: inline-block;
    margin-top: 12px;
    vertical-align: top;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title--author {
    position: absolute;
    bottom: -8px;
    right: 28px;
    transform: translateY(50%);
    padding: 12px 32px;
    font-size: 20px;
  }
}
.techlib2-post-title.is-introduce {
  background-image: url(../img/techlibrary2/title_bg1.png);
  color: #fff;
}
.techlib2-post-title.is-report {
  background-image: url(../img/techlibrary2/title_bg2.png);
  color: #fff;
}
.techlib2-post-title.is-report .techlib2-post-title--en {
  display: inline-flex;
  align-items: center;
  font-size: 54px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-report .techlib2-post-title--en {
    font-size: 80px;
  }
}
.techlib2-post-title.is-report .techlib2-post-title--en > span {
  font-size: 18px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-report .techlib2-post-title--en > span {
    font-size: 24px;
  }
}
.techlib2-post-title.is-chemistry {
  background-image: url(../img/techlibrary2/title_bg3.png);
  color: #fff;
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub {
  width: 100%;
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span {
  display: inline-flex;
  align-items: center;
  background-color: #E5F0F8;
  padding: 4px 30px;
  vertical-align: top;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 0.04em;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span {
    padding: 4px 34px;
    font-size: 32px;
  }
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::before, .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::after {
  content: "";
  width: 0;
  height: 30px;
  border: 1px solid #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::before, .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::after {
    border-width: 2px;
    height: 40px;
  }
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::before {
  transform: rotate(-30deg);
  margin-right: 14px;
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span::after {
  transform: rotate(30deg);
  margin-left: 14px;
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span > span {
  margin-left: 8px;
  font-size: 28px;
  letter-spacing: 0;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span > span {
    font-size: 35px;
  }
}
.techlib2-post-title.is-chemistry .techlib2-post-title--sub > span > span > span {
  font-size: 20px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-chemistry .techlib2-post-title--sub > span > span > span {
    font-size: 26px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-chemistry {
    flex-wrap: wrap;
  }
}
.techlib2-post-title.is-technology {
  background-image: url(../img/techlibrary2/title_bg4.png);
  color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-technology {
    margin-bottom: 54px;
    flex-wrap: wrap;
  }
  .techlib2-post-title.is-technology .techlib2-post-title--sub {
    margin-right: 18px;
  }
}
.techlib2-post-title.is-record {
  background-image: url(../img/techlibrary2/title_bg5.png);
  color: #fff;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title.is-record {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-record {
    align-items: flex-end;
  }
}
.techlib2-post-title.is-product-above {
  background-image: url(../img/techlibrary2/title_bg6.png);
  color: #fff;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title.is-product-above {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-product-above {
    align-items: flex-end;
  }
}
.techlib2-post-title.is-product-below {
  background-image: url(../img/techlibrary2/title_bg7.png);
  color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-product-below {
    margin-bottom: 54px;
    min-height: 164px;
  }
}
.techlib2-post-title.is-product-below .techlib2-post-title--name::after {
  content: "";
  width: 1px;
  height: 28px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-product-below .techlib2-post-title--name::after {
    width: 2px;
    height: 62px;
  }
}
.techlib2-post-title.is-office {
  background-image: url(../img/techlibrary2/title_bg8.png);
  color: #fff;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title.is-office {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-office {
    align-items: flex-end;
  }
}
.techlib2-post-title.is-column {
  background-image: url(../img/techlibrary2/title_bg9.png);
  color: #fff;
}
@media screen and (max-width: 768px) {
  .techlib2-post-title.is-column {
    display: flex;
    align-items: center;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-title.is-column {
    align-items: flex-end;
  }
}
.techlib2-post-block {
  display: grid;
  gap: 30px;
  position: relative;
  background-color: #fff;
  border: 1px solid #fff;
  padding: 20px 30px;
  border-radius: 10px;
  font-size: 15px;
  line-height: 1.8;
  /* techlib2 post block */
}
@media screen and (min-width: 769px) {
  .techlib2-post-block {
    padding: 40px 70px;
    gap: 40px;
  }
}
.techlib2-post-block + .techlib2-post-block {
  margin-top: 16px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block + .techlib2-post-block {
    margin-top: 20px;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-post-block img {
    max-width: 100% !important;
  }
}
.techlib2-post-block a {
  text-decoration: underline;
  color: #0168b7;
}
.techlib2-post-block.bg-gray {
  background-color: #F8F7F6;
  border-color: #F8F7F6;
}
.techlib2-post-block.bg-lightyellow {
  background-color: #FFFBED;
  border-color: #FFFBED;
}
.techlib2-post-block.bd-blue {
  border-color: #0168B7;
}
.techlib2-post-block.bdr-0 {
  border-radius: 0;
}
.techlib2-post-block .color-blue {
  color: #0168B7;
}
.techlib2-post-block .align-center {
  text-align: center;
}
.techlib2-post-block .align-right {
  text-align: right;
}
.techlib2-post-block .m-t-1em {
  margin-top: 1em;
}
.techlib2-post-block .m-t-2em {
  margin-top: 2em;
}
.techlib2-post-block .m-t-3em {
  margin-top: 3em;
}
.techlib2-post-block .m-t-4em {
  margin-top: 4em;
}
.techlib2-post-block .m-t-5em {
  margin-top: 5em;
}
.techlib2-post-block .bd-top {
  padding-top: 30px;
  border-top: 1px solid #D9D9D9;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .bd-top {
    padding-top: 40px;
  }
}
.techlib2-post-block .bd-bottom {
  padding-bottom: 30px;
  border-bottom: 1px solid #D9D9D9;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .bd-bottom {
    padding-bottom: 40px;
  }
}
.techlib2-post-block .bd-top-dotted {
  padding-top: 18px;
  border-top: 1px dotted #404040;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .bd-top-dotted {
    padding-top: 24px;
  }
}
.techlib2-post-block .bd-bottom-dotted {
  padding-bottom: 18px;
  border-bottom: 1px dotted #404040;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .bd-bottom-dotted {
    padding-bottom: 24px;
  }
}
.techlib2-post-block .t2pb-title1 {
  display: flex;
  align-items: center;
  padding: 0 8px 8px;
  border-bottom: 3px solid #6FB6ED;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title1 {
    font-size: 30px;
  }
}
.techlib2-post-block .t2pb-title1 span {
  margin-right: 20px;
  display: inline-flex;
  vertical-align: top;
  white-space: nowrap;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 40px;
  font-weight: 500;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title1 span {
    margin-right: 56px;
    font-size: 70px;
  }
}
.techlib2-post-block .t2pb-title2 {
  display: flex;
  align-items: center;
  position: relative;
  margin-top: 30px;
  border-left: 4px solid #404040;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title2 {
    margin-top: 50px;
    border-left-width: 7px;
    font-size: 30px;
  }
}
.techlib2-post-block .t2pb-title2::before {
  content: "";
  display: block;
  margin: 0 20px 0 2px;
  width: 2px;
  height: 100%;
  background-color: #404040;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title2::before {
    width: 4px;
    margin: 0 30px 0 4px;
  }
}
.techlib2-post-block .t2pb-title2.color-blue {
  border-left-color: #0168B7;
}
.techlib2-post-block .t2pb-title2.color-blue::before {
  background-color: #0168B7;
}
.techlib2-post-block .t2pb-title3 {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title3 {
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-title3 > span {
  display: inline-block;
  padding: 6px 14px;
  background-color: #F8F7F6;
  vertical-align: top;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-title3 > span {
    padding: 8px 18px;
  }
}
.techlib2-post-block .t2pb-text p {
  text-indent: 1em;
}
.techlib2-post-block .t2pb-text ul,
.techlib2-post-block .t2pb-text ol {
  margin: 10px 1em;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-text ul,
.techlib2-post-block .t2pb-text ol {
    margin: 16px 1em;
  }
}
.techlib2-post-block .t2pb-text img {
  margin: 10px 0;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-text img {
    margin: 16px 0;
  }
}
.techlib2-post-block .t2pb-note {
  padding: 12px 0;
  border-top: 1px solid #0168B7;
  border-bottom: 1px solid #0168B7;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-note {
    padding: 18px 0;
    font-size: 17px;
  }
}
.techlib2-post-block .t2pb-image {
  padding: 20px 10px;
  background-color: #fff;
  border: 1px solid #404040;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-image {
    min-width: 444px;
  }
}
.techlib2-post-block .t2pb-image-center {
  margin: 0 auto;
  max-width: 944px;
}
.techlib2-post-block .t2pb-image-title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.2;
}
.techlib2-post-block .t2pb-image-title span {
  display: inline-block;
  margin: -2px 4px 0 0;
  padding: 4px 18px;
  background-color: #00518F;
  border-radius: 14px;
  vertical-align: top;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  color: #fff;
}
.techlib2-post-block .t2pb-image-title sup {
  position: relative;
  top: -0.4ex;
  vertical-align: top;
}
.techlib2-post-block .t2pb-image-title sub {
  position: relative;
  bottom: -0.4ex;
  vertical-align: top;
}
.techlib2-post-block .t2pb-image-photo {
  position: relative;
  text-align: center;
}
.techlib2-post-block .t2pb-box1 {
  padding: 16px;
  border: 1px solid #404040;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-box1 {
    padding: 24px;
  }
}
.techlib2-post-block .t2pb-type1 {
  display: grid;
  gap: 16px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type1 {
    gap: 24px;
  }
}
.techlib2-post-block .t2pb-type1-block {
  padding: 18px 26px;
  background-color: #F8F7F6;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type1-block {
    padding: 24px 32px;
  }
}
.techlib2-post-block .t2pb-type1-block__title {
  margin-bottom: 18px;
  padding-left: 12px;
  border-left: 4px solid #6FB6ED;
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type1-block__title {
    margin-bottom: 24px;
    padding-left: 16px;
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-type2 {
  display: grid;
  gap: 16px;
  padding: 18px 20px 20px;
  background-color: #F8F7F6;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type2 {
    gap: 24px;
    padding: 24px 32px 32px;
  }
}
.techlib2-post-block .t2pb-type2-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type2-title {
    font-size: 20px;
  }
}
.techlib2-post-block .t2pb-type3 {
  display: grid;
  gap: 8px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3 {
    gap: 16px;
  }
}
.techlib2-post-block .t2pb-type3-block {
  padding: 18px 26px;
  background-color: #fff;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block {
    padding: 24px 32px;
  }
}
.techlib2-post-block .t2pb-type3-block__title {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #6FB6ED;
  font-size: 20px;
  font-weight: 400;
  line-height: 1.2;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block__title {
    margin-bottom: 16px;
    padding-bottom: 16px;
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-type3-block__title > span {
  margin-right: 8px;
  padding: 1px 8px;
  background-color: #0168B7;
  border-radius: 50px;
  white-space: nowrap;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.2;
  color: #fff;
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-type3-block__title > span {
    margin-top: 3px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block__title > span {
    margin-right: 16px;
    padding: 1px 17px;
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-type3 {
  display: grid;
  gap: 32px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3 {
    gap: 56px;
  }
}
.techlib2-post-block .t2pb-type3-block {
  display: grid;
  gap: 16px;
  padding: 18px 26px;
  background-color: #fff;
  border-radius: 10px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block {
    gap: 24px;
    padding: 24px 32px;
  }
}
.techlib2-post-block .t2pb-type3-block__title {
  position: relative;
  padding: 0 0 10px 16px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block__title {
    padding: 0 0 16px 20px;
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-type3-block__title::before, .techlib2-post-block .t2pb-type3-block__title::after {
  content: "";
  position: absolute;
  left: 0;
}
.techlib2-post-block .t2pb-type3-block__title::before {
  top: 0;
  width: 2px;
  height: calc(100% - 10px);
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block__title::before {
    width: 4px;
    height: calc(100% - 16px);
  }
}
.techlib2-post-block .t2pb-type3-block__title::after {
  bottom: 0;
  width: 100%;
  height: 2px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-block__title::after {
    height: 4px;
  }
}
.techlib2-post-block .t2pb-type3-block__text p {
  text-indent: 1em;
}
.techlib2-post-block .t2pb-type3-block.type3-1 .t2pb-type3-block__title {
  color: #005D35;
}
.techlib2-post-block .t2pb-type3-block.type3-1 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-1 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-1 .t2pb-type3-sub__title::before {
  background-color: #005D35;
}
.techlib2-post-block .t2pb-type3-block.type3-2 .t2pb-type3-block__title {
  color: #74201C;
}
.techlib2-post-block .t2pb-type3-block.type3-2 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-2 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-2 .t2pb-type3-sub__title::before {
  background-color: #74201C;
}
.techlib2-post-block .t2pb-type3-block.type3-3 .t2pb-type3-block__title {
  color: #00326C;
}
.techlib2-post-block .t2pb-type3-block.type3-3 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-3 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-3 .t2pb-type3-sub__title::before {
  background-color: #00326C;
}
.techlib2-post-block .t2pb-type3-block.type3-4 .t2pb-type3-block__title {
  color: #016368;
}
.techlib2-post-block .t2pb-type3-block.type3-4 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-4 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-4 .t2pb-type3-sub__title::before {
  background-color: #016368;
}
.techlib2-post-block .t2pb-type3-block.type3-5 .t2pb-type3-block__title {
  color: #846A01;
}
.techlib2-post-block .t2pb-type3-block.type3-5 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-5 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-5 .t2pb-type3-sub__title::before {
  background-color: #846A01;
}
.techlib2-post-block .t2pb-type3-block.type3-6 .t2pb-type3-block__title {
  color: #5C015E;
}
.techlib2-post-block .t2pb-type3-block.type3-6 .t2pb-type3-block__title::before,
.techlib2-post-block .t2pb-type3-block.type3-6 .t2pb-type3-block__title::after,
.techlib2-post-block .t2pb-type3-block.type3-6 .t2pb-type3-sub__title::before {
  background-color: #5C015E;
}
.techlib2-post-block .t2pb-type3-sub {
  position: relative;
  padding-left: 18px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-sub {
    padding-left: 26px;
  }
}
.techlib2-post-block .t2pb-type3-sub__title {
  position: relative;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.8;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-sub__title {
    font-size: 17px;
  }
}
.techlib2-post-block .t2pb-type3-sub__title::before {
  content: "";
  position: absolute;
  top: 8px;
  left: -18px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type3-sub__title::before {
    top: 7px;
    left: -26px;
    width: 18px;
    height: 18px;
  }
}
.techlib2-post-block .t2pb-type4 {
  display: grid;
  gap: 28px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type4 {
    gap: 48px;
  }
}
.techlib2-post-block .t2pb-type4-block__title {
  margin-bottom: 18px;
  padding: 6px 20px;
  background-color: #F8F7F6;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type4-block__title {
    margin-bottom: 24px;
    padding: 8px 32px;
    font-size: 24px;
  }
}
.techlib2-post-block .t2pb-type4-block__text {
  padding: 0 20px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-type4-block__text {
    padding: 0 32px;
  }
}
.techlib2-post-block .t2pb-type4-block__text p {
  text-indent: 1em;
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-introduce > * + * {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-introduce {
    display: flex;
  }
  .techlib2-post-block .t2pb-introduce .t2pb-people {
    margin-left: 43px;
  }
}
.techlib2-post-block .t2pb-people {
  display: flex;
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-people {
    justify-content: center;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-people > li {
    margin: 20px 20px 0 0;
    width: calc(50% - 10px);
  }
  .techlib2-post-block .t2pb-people > li:nth-of-type(-n+2) {
    margin-top: 0;
  }
  .techlib2-post-block .t2pb-people > li:last-child, .techlib2-post-block .t2pb-people > li:nth-of-type(2n) {
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-people > li + li {
    margin-top: 10px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-people > li + li {
    margin-left: 8px;
  }
}
.techlib2-post-block .t2pb-people > li.is-flex {
  display: flex;
  align-items: flex-end;
  width: 100%;
}
.techlib2-post-block .t2pb-people > li.is-flex .t2pb-people-text {
  margin: 0 0 0 8px;
  white-space: nowrap;
}
.techlib2-post-block .t2pb-people-photo {
  min-width: 155px;
}
.techlib2-post-block .t2pb-people-text {
  margin-top: 8px;
  font-size: 12px;
  line-height: 1.2;
}
.techlib2-post-block .t2pb-people-text ruby {
  font-size: 15px;
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-flex > * + * {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-flex {
    display: flex;
    gap: 40px;
  }
  .techlib2-post-block .t2pb-flex.is-align-start {
    align-items: flex-start;
  }
  .techlib2-post-block .t2pb-flex.is-align-end {
    align-items: flex-end;
  }
  .techlib2-post-block .t2pb-flex.is-align-center {
    align-items: center;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-grid > * + * {
    margin-top: 20px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-grid {
    display: grid;
    gap: 40px;
  }
  .techlib2-post-block .t2pb-grid .is-row-1 {
    grid-row: 1;
  }
  .techlib2-post-block .t2pb-grid .is-row-1-3 {
    grid-row: 1/3;
  }
  .techlib2-post-block .t2pb-grid .is-row-2 {
    grid-row: 2;
  }
  .techlib2-post-block .t2pb-grid .is-column-1 {
    grid-column: 1;
  }
  .techlib2-post-block .t2pb-grid .is-column-2 {
    grid-column: 2;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-literature {
    display: flex;
    flex-wrap: wrap;
  }
}
.techlib2-post-block .t2pb-literature-title {
  margin-bottom: 16px;
  padding-bottom: 16px;
  border-bottom: 1px solid #D9D9D9;
  font-size: 15px;
  font-weight: 400;
  line-height: 1;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-literature-title {
    width: 100%;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-literature ol {
    padding-right: 40px;
    width: 50%;
  }
}
.techlib2-post-block .t2pb-literature ol li {
  font-size: 12px;
  line-height: 1.8;
}
.techlib2-post-block .t2pb-office-title {
  margin-bottom: 2px;
  padding: 12px 20px;
  background-color: #0168B7;
  font-size: 20px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-office-title {
    margin-bottom: 4px;
    padding: 16px 40px;
    font-size: 26px;
  }
}
.techlib2-post-block .t2pb-office-cell table {
  width: 100%;
  border-top: 1px solid #6FB6ED;
  border-spacing: 0;
  border-collapse: collapse;
}
.techlib2-post-block .t2pb-office-cell table tr {
  border-bottom: 1px solid #6FB6ED;
}
@media screen and (max-width: 768px) {
  .techlib2-post-block .t2pb-office-cell table tr {
    display: block;
  }
  .techlib2-post-block .t2pb-office-cell table tr th,
.techlib2-post-block .t2pb-office-cell table tr td {
    display: block;
    width: 100%;
  }
}
.techlib2-post-block .t2pb-office-cell table th,
.techlib2-post-block .t2pb-office-cell table td {
  padding: 10px 8px;
  text-align: left;
  font-size: 13px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-office-cell table th,
.techlib2-post-block .t2pb-office-cell table td {
    padding: 16px 40px;
    font-size: 15px;
  }
}
.techlib2-post-block .t2pb-office-cell table th.name,
.techlib2-post-block .t2pb-office-cell table td.name {
  background-color: #F8F7F6;
  font-weight: 500;
}
.techlib2-post-block .t2pb-office-cell table th.address,
.techlib2-post-block .t2pb-office-cell table td.address {
  font-weight: 400;
}
.techlib2-post-block .t2pb-office-cell table th.tel,
.techlib2-post-block .t2pb-office-cell table td.tel {
  background-color: #F8F7F6;
  font-weight: 500;
}
.techlib2-post-block .t2pb-office-qr {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin-top: 26px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-office-qr {
    margin-top: 32px;
  }
}
.techlib2-post-block .t2pb-office-qr p {
  margin-right: 6px;
  text-align: right;
  font-size: 12px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-office-qr p {
    margin-right: 8px;
    font-size: 15px;
    line-height: 1.8;
  }
}
.techlib2-post-block .t2pb-office-qr img {
  width: 85px;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-office-qr img {
    width: 105px;
  }
}
.techlib2-post-block .t2pb-column-title {
  margin-bottom: 18px;
  padding-bottom: 18px;
  border-bottom: 1px dotted #404040;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 26px;
  font-weight: 500;
  line-height: 1.2;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-column-title {
    margin-bottom: 24px;
    padding-bottom: 24px;
    font-size: 35px;
  }
}
.techlib2-post-block .t2pb-column-text {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 13px;
  line-height: 1.8;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-column-text {
    font-size: 15px;
    line-height: 2.2;
  }
}
.techlib2-post-block .t2pb-column-text p {
  text-indent: 1em;
}
.techlib2-post-block .t2pb-column-author {
  margin-top: 18px;
  padding-top: 18px;
  border-top: 1px dotted #404040;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 13px;
  line-height: 1.8;
}
@media screen and (min-width: 769px) {
  .techlib2-post-block .t2pb-column-author {
    margin-top: 24px;
    padding-top: 24px;
    font-size: 15px;
    line-height: 2.2;
  }
}
.techlib2-post-attention {
  margin-top: 20px;
  font-size: 12px;
  line-height: 1.8;
}
.techlib2-related {
  margin-top: 40px;
}
.techlib2-related-title {
  margin-bottom: 32px;
  padding: 0 8px 10px;
  border-bottom: 1px solid #6FB6ED;
  font-size: 24px;
  font-weight: 500;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-related-title {
    margin-bottom: 65px;
    font-size: 30px;
  }
}
.techlib2-related-lists {
  display: grid;
}
@media screen and (max-width: 768px) {
  .techlib2-related-lists {
    row-gap: 40px;
    column-gap: 16px;
    grid-template-columns: repeat(2, calc(50% - 8px));
  }
}
@media screen and (min-width: 769px) {
  .techlib2-related-lists {
    gap: 80px;
    grid-template-columns: repeat(4, calc(25% - 60px));
  }
}
.techlib2-related-lists__item {
  display: grid;
  gap: 0;
  grid-template-rows: subgrid;
  grid-row: span 2;
  position: relative;
}
.techlib2-related-lists__item > a {
  display: grid;
  gap: 0;
  grid-template-rows: subgrid;
  grid-row: span 2;
  position: relative;
  width: 100%;
}
.techlib2-related-lists__photo {
  position: relative;
  margin-bottom: 16px;
  aspect-ratio: 1/0.625;
  border-radius: 10px;
  overflow: hidden;
}
.techlib2-related-lists__photo img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.techlib2-related-lists__title {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.4;
  color: #0168B7;
}
@media screen and (min-width: 769px) {
  .techlib2-related-lists__title {
    font-size: 20px;
  }
}
.techlib2-related-lists__excerpt {
  display: -webkit-box;
  margin-top: 12px;
  padding-top: 8px;
  border-top: 1px solid #404040;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  line-clamp: 4;
  font-size: 13px;
  letter-spacing: 0.1em;
  line-height: 1.4;
  max-height: 94px;
  overflow: hidden;
}
@media screen and (min-width: 769px) {
  .techlib2-related-lists__excerpt {
    font-size: 15px;
  }
}
.techlib2-btns {
  margin-top: 80px;
}
@media screen and (min-width: 769px) {
  .techlib2-btns {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    margin-top: 160px;
  }
}
@media screen and (max-width: 768px) {
  .techlib2-btns-item:not(:first-child) {
    margin-top: 30px;
  }
}
@media screen and (min-width: 769px) {
  .techlib2-btns-item {
    margin: 60px 32px 0 0;
    width: calc(33.3333333333% - 21.3333333333px);
  }
  .techlib2-btns-item:nth-of-type(-n+3) {
    margin-top: 0;
  }
  .techlib2-btns-item:nth-of-type(3n) {
    margin-right: 0;
  }
}
.techlib2-btns-item > a {
  display: flex;
  width: 100%;
  height: 78px;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 48px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 769px) {
  .techlib2-btns-item > a {
    height: 96px;
    font-size: 28px;
  }
}
.techlib2-btns-item > a.is-pdf {
  background-color: #fff;
  border: 4px solid #A42811;
  color: #A42811;
}
.techlib2-btns-item > a.is-back {
  background: linear-gradient(90.39deg, #125797 0.34%, #0A60A7 20.78%, #0168B7 100.03%);
  color: #fff;
}
.techlib2-btns-item > a.is-next {
  background: linear-gradient(90.39deg, #017BEB 0.34%, #1A8CEB 20.78%, #0577CE 100.03%);
  color: #fff;
}
.techlib2-btns-note {
  margin: 8px 10px 0;
  font-size: 10px;
  line-height: 1.4;
}
@media screen and (min-width: 769px) {
  .techlib2-btns-note {
    font-size: 12px;
  }
}

/* top
------------------------------------------------ */
.top {
  /* visual */
  /* business */
  /* pickup */
  /* news */
  /* search */
  /* flow */
  /* techlibrary */
  /* know */
  /* recruit */
  /* blog */
}
.top-visual {
  position: relative;
  opacity: 0;
}
.top-visual__frame {
  position: relative;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.top-visual__move {
  position: relative;
  width: 100%;
}
.top-visual--item {
  float: left;
  max-width: 1700px;
}
.top-visual--item > a {
  display: block;
  position: relative;
  width: 100%;
}
.top-visual--item--thumbnail {
  position: relative;
  width: 100%;
}
.top-visual--item img {
  width: 100%;
  max-width: inherit;
}
.top-visual--direct {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #404040;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
  z-index: 2;
}
.top-visual--direct:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 15px;
  height: 29px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.top-visual--direct--prev {
  left: 50%;
  margin-left: -880px;
}
.top-visual--direct--prev:after {
  left: 20px;
  background-image: url(../img/icon/slide_prev1.png);
}
.top-visual--direct--next {
  right: 50%;
  margin-right: -880px;
}
.top-visual--direct--next:after {
  right: 20px;
  background-image: url(../img/icon/slide_next1.png);
}
@media screen and (max-width: 1760px) {
  .top-visual--direct--prev {
    left: 20px;
    margin-left: 0;
  }
  .top-visual--direct--next {
    right: 20px;
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .top-visual--direct {
    display: none;
  }
}
.top-visual-announce {
  padding: 16px;
  background-color: #fff;
  border: 1px solid #2f528f;
  border-radius: 6px;
  font-size: 14px;
}
@media screen and (max-width: 768px) {
  .top-visual-announce {
    margin: 20px;
  }
}
@media screen and (min-width: 769px) {
  .top-visual-announce {
    position: absolute;
    bottom: 0;
    left: 36px;
    transform: translateY(50%);
    width: calc(100% - 72px);
    text-align: center;
    z-index: 2;
  }
}
.top-business {
  position: relative;
  padding: 90px 0 88px;
}
.top-business:before, .top-business:after {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: -1;
}
.top-business:before {
  top: 0;
  height: 42.5925925926%;
  background: #e5f0f8;
}
.top-business:after {
  bottom: 0;
  height: 57.4074074074%;
  background: #f8fbfd;
}
.top-business__lists {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}
.top-business__lists--item:nth-of-type(1) {
  background: #0168b7;
}
.top-business__lists--item:nth-of-type(2) {
  background: #7cafd7;
}
.top-business__lists--item:nth-of-type(3) {
  background: #3284c4;
}
.top-business__lists--item > a {
  display: block;
  position: relative;
}
.top-business__lists__info {
  position: relative;
  padding: 50px 28px 20px;
  min-height: 230px;
  text-align: center;
  color: #fff;
}
.top-business__lists--num {
  position: absolute;
  top: -0.7em;
  left: 0;
  width: 100%;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 80px;
  line-height: 1.2;
}
.top-business__lists--title {
  margin-bottom: 16px;
  font-size: 26px;
  font-weight: 400;
}
.top-business__lists--copy {
  margin-bottom: 1.6em;
  font-size: 16px;
}
.top-business__lists--text {
  text-align: left;
  font-size: 16px;
}
@media screen and (min-width: 769px) {
  .top-business__lists--item {
    width: 30%;
  }
  .top-business__lists--item:nth-of-type(2n) {
    margin-top: 60px;
  }
}
@media screen and (max-width: 768px) {
  .top-business {
    padding: 36px 25px 48px;
  }
  .top-business__lists {
    display: block;
  }
  .top-business__lists--item {
    margin-top: 24px;
  }
  .top-business__lists--item:first-child {
    margin-top: 0;
  }
  .top-business__lists__info {
    padding: 38px 24px 26px;
    min-height: inherit;
  }
  .top-business__lists--num {
    font-size: 70px;
  }
  .top-business__lists--title {
    margin-bottom: 18px;
    font-size: 22.75px;
  }
  .top-business__lists--copy {
    margin-bottom: 1.4em;
    font-size: 14px;
  }
  .top-business__lists--text {
    font-size: 14px;
  }
}
.top-pickup {
  position: relative;
  padding: 90px 0 96px;
}
.top-pickup .title--2 {
  margin-bottom: 54px;
}
.top-pickup:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 59.5369349504%;
  background: #cce0f0;
  overflow: hidden;
  z-index: -1;
}
.top-pickup__slide {
  position: relative;
  opacity: 0;
}
.top-pickup__slide__frame {
  position: relative;
  width: 100%;
  padding-top: 36px;
  overflow: hidden;
}
.top-pickup__slide__move {
  position: relative;
}
.top-pickup__slide--item {
  position: relative;
  float: left;
  width: 338px;
}
.top-pickup__slide--item .frame {
  position: relative;
  transition: ease 0.3s;
  margin: 100px auto 0;
  width: 100%;
  background: #0168b7;
  box-shadow: 0 5px 7px rgba(11, 22, 43, 0.35);
}
.top-pickup__slide--item .frame:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.top-pickup__slide--item.active {
  width: 516px;
}
.top-pickup__slide--item.active .frame {
  margin-top: 0;
  transform: scale(0.8, 0.8);
}
.top-pickup__slide--item.expand .frame {
  transform: scale(1, 1);
}
.top-pickup__slide--item.expand .frame:before {
  display: none;
}
.top-pickup__slide--item.expand .top-pickup__slide--new {
  top: -36px;
  width: 150px;
  height: 36px;
  font-size: 19.8px;
  line-height: 36px;
}
.top-pickup__slide--item.expand .top-pickup__slide--new:before {
  display: none;
}
.top-pickup__slide--item.expand .top-pickup__slide--new:after {
  right: -36px;
  border-right-width: 36px;
  border-bottom-width: 36px;
}
.top-pickup__slide--item.expand .top-pickup__slide__info {
  padding: 42px 48px 36px;
}
.top-pickup__slide--item.expand .top-pickup__slide--title {
  font-size: 30px;
}
.top-pickup__slide--item.expand .top-pickup__slide__content {
  margin-top: 30px;
  font-size: 18px;
}
.top-pickup__slide--item.expand .top-pickup__slide__content a {
  color: #fff;
}
.top-pickup__slide--item.expand .top-pickup__slide__content ul {
  margin-top: 32px;
}
.top-pickup__slide--item.expand .top-pickup__slide__content ul li {
  font-size: 18px;
}
.top-pickup__slide--item.expand .top-pickup__slide--text {
  margin-top: 30px;
  font-size: 18px;
}
.top-pickup__slide--item.expand .top-pickup__slide__links {
  margin-top: 32px;
}
.top-pickup__slide--item.expand .top-pickup__slide__links--item {
  font-size: 18px;
}
.top-pickup__slide--item.expand .top-pickup__slide--btn {
  margin-top: 38px;
  font-size: 22px;
}
.top-pickup__slide--item.expand .top-pickup__slide--btn a {
  padding: 12px 0;
  min-width: 286px;
}
.top-pickup__slide--item.expand .top-pickup__slide--btn a:after {
  right: 26px;
  margin-top: -8px;
  width: 9px;
  height: 16px;
}
.top-pickup__slide--new {
  position: absolute;
  top: -22px;
  left: 0;
  width: 92px;
  height: 22px;
  background: #aa111d;
  text-align: center;
  font-size: 12.96px;
  line-height: 22px;
  color: #fff;
}
.top-pickup__slide--new:before, .top-pickup__slide--new:after {
  content: "";
  position: absolute;
  top: 0;
}
.top-pickup__slide--new:before {
  left: 0;
  width: calc(100% + 22px);
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 2;
}
.top-pickup__slide--new:after {
  right: -22px;
  width: 0;
  height: 0;
  border-right: solid transparent 22px;
  border-bottom: solid #aa111d 22px;
  overflow: hidden;
}
.top-pickup__slide--mdeia {
  position: relative;
  padding-bottom: 55.6201550388%;
  width: 100%;
  height: 0;
  overflow: hidden;
}
.top-pickup__slide--mdeia iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.top-pickup__slide--mdeia img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: inherit;
}
.top-pickup__slide__info {
  padding: 26px 32px 22px;
  color: #fff;
}
.top-pickup__slide--title {
  text-align: center;
  font-size: 20.16px;
  font-weight: 700;
}
.top-pickup__slide__content {
  margin-top: 20px;
  font-size: 12.96px;
}
.top-pickup__slide__content a {
  color: #fff;
}
.top-pickup__slide__content ul {
  margin-top: 20px;
}
.top-pickup__slide__content ul li {
  position: relative;
  padding-left: 1em;
  font-size: 12.96px;
}
.top-pickup__slide__content ul li:after {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
.top-pickup__slide__content ul li a {
  text-decoration: underline;
  color: #fff;
}
.device--click .top-pickup__slide__content ul li a:hover {
  text-decoration: none;
}
.top-pickup__slide--text {
  margin-top: 20px;
  font-size: 12.96px;
}
.top-pickup__slide__links {
  margin-top: 20px;
}
.top-pickup__slide__links--item {
  position: relative;
  padding-left: 1em;
  font-size: 12.96px;
  color: #fff;
}
.top-pickup__slide__links--item:after {
  content: "・";
  position: absolute;
  top: 0;
  left: 0;
}
.top-pickup__slide__links--item a {
  text-decoration: underline;
  color: #fff;
}
.device--click .top-pickup__slide__links--item a:hover {
  text-decoration: none;
}
.top-pickup__slide--btn {
  margin-top: 20px;
  text-align: center;
  font-size: 14.98px;
  line-height: 1.2;
}
.top-pickup__slide--btn a {
  display: inline-block;
  position: relative;
  padding: 12px 0;
  min-width: 188px;
  background: #fff;
  border-radius: 24px;
  vertical-align: top;
  color: #0168b7;
}
.top-pickup__slide--btn a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 18px;
  margin-top: -5px;
  width: 7px;
  height: 10px;
  background: url(../img/icon/arrow4.png) no-repeat center center;
  background-size: 100%;
}
.top-pickup__slide--direct {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #0168b7;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.top-pickup__slide--direct:after {
  content: "";
  position: absolute;
  top: 50%;
  margin-top: -15px;
  width: 15px;
  height: 29px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.top-pickup__slide--direct--prev {
  left: 50%;
  margin-left: -660px;
}
.top-pickup__slide--direct--prev:after {
  left: 20px;
  background-image: url(../img/icon/slide_prev1.png);
}
.top-pickup__slide--direct--next {
  right: 50%;
  margin-right: -660px;
}
.top-pickup__slide--direct--next:after {
  right: 20px;
  background-image: url(../img/icon/slide_next1.png);
}
@media screen and (max-width: 1320px) {
  .top-pickup__slide--direct--prev {
    left: 20px;
    margin-left: 0;
  }
  .top-pickup__slide--direct--next {
    right: 20px;
    margin-right: 0;
  }
}
@media screen and (max-width: 768px) {
  .top-pickup {
    padding: 40px 0 48px;
  }
  .top-pickup .title--2 {
    margin-bottom: 50px;
  }
  .top-pickup:after {
    height: 68.8073394495%;
  }
  .top-pickup__slide--item {
    width: 194px;
  }
  .top-pickup__slide--item .frame {
    margin-top: 55px;
  }
  .top-pickup__slide--item.active {
    width: 288px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--new {
    top: -20px;
    width: 84px;
    height: 20px;
    font-size: 11.065px;
    line-height: 20px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--new:after {
    right: -22px;
    border-right-width: 22px;
    border-bottom-width: 20px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__info {
    padding: 22px 28px 20px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--title {
    font-size: 17px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__content {
    margin-top: 15px;
    font-size: 14px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__content ul {
    margin-top: 16px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__content ul li {
    font-size: 14px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--text {
    margin-top: 15px;
    font-size: 14px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__links {
    margin-top: 16px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide__links--item {
    font-size: 14px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--btn {
    margin-top: 15px;
    font-size: 14px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--btn a {
    padding: 14px 0;
    min-width: 200px;
  }
  .top-pickup__slide--item.expand .top-pickup__slide--btn a:after {
    right: 18px;
    margin-top: -6px;
    width: 6px;
    height: 12px;
  }
  .top-pickup__slide--new {
    top: -13px;
    width: 52px;
    height: 13px;
    font-size: 7.45px;
    line-height: 13px;
  }
  .top-pickup__slide--new:before {
    width: calc(100% + 13px);
  }
  .top-pickup__slide--new:after {
    right: -13px;
    border-right-width: 13px;
    border-bottom-width: 13px;
  }
  .top-pickup__slide__info {
    padding: 15px 18px 40px;
  }
  .top-pickup__slide--title {
    font-size: 11.09px;
  }
  .top-pickup__slide--text {
    margin-top: 12px;
    font-size: 7.45px;
  }
  .top-pickup__slide__links {
    margin-top: 12px;
  }
  .top-pickup__slide__links--item {
    font-size: 7.45px;
  }
  .top-pickup__slide--btn {
    margin-top: 12px;
    font-size: 8.61px;
  }
  .top-pickup__slide--btn a {
    padding: 4px 0;
    min-width: 108px;
  }
  .top-pickup__slide--btn a:after {
    right: 10px;
    margin-top: -4px;
    width: 4px;
    height: 7px;
  }
  .top-pickup__slide--direct {
    display: none;
  }
}
.top-news {
  position: relative;
}
.top-news .wrap {
  display: flex;
  align-items: center;
}
@media screen and (max-width: 940px) and (min-width: 769px) {
  .top-news .wrap {
    display: block;
    padding: 0;
  }
}
.top-news__head {
  width: 33.3333333333%;
  min-width: 330px;
}
@media screen and (max-width: 940px) and (min-width: 769px) {
  .top-news__head {
    padding: 60px 0 30px;
    width: auto;
    background: url(../img/top/news_bg.jpg) no-repeat center center;
    background-size: cover;
  }
}
.top-news__head .title--3 {
  margin-bottom: 60px;
}
.top-news__cat--item {
  position: relative;
  margin-top: 10px;
  text-align: center;
  font-size: 18px;
  line-height: 1.2;
}
.top-news__cat--item:first-child {
  margin-top: 0;
}
.top-news__cat--item a {
  display: inline-block;
  position: relative;
  min-width: 330px;
  padding: 10px 6px;
  border-radius: 30px;
  vertical-align: top;
}
.top-news__cat--item a:after {
  content: "";
  position: absolute;
  top: 50%;
  right: 14px;
  margin-top: -6px;
  width: 8px;
  height: 13px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: 100%;
}
.top-news__cat--news a {
  background: #404040;
  color: #fff;
}
.top-news__cat--news a:after {
  background-image: url(../img/icon/arrow3.png);
}
.top-news__cat--exhibition a {
  background: #f6f033;
}
.top-news__cat--exhibition a:after {
  background-image: url(../img/icon/arrow5.png);
}
.top-news__content {
  padding: 85px 0 85px 90px;
  width: 66.6666666667%;
}
@media screen and (max-width: 940px) and (min-width: 769px) {
  .top-news__content {
    width: auto;
  }
}
@media screen and (max-width: 1200px) {
  .top-news:before {
    width: 33.3333333333%;
  }
  .top-news:after {
    width: 66.6666666667%;
  }
}
@media screen and (min-width: 769px) {
  .top-news:before, .top-news:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
  }
  .top-news:before {
    left: 0;
    width: calc((100% - 1200px) / 2 + 400px);
    background: url(../img/top/news_bg.jpg) no-repeat center center;
    background-size: cover;
  }
  .top-news:after {
    right: 0;
    width: calc((100% - 1200px) / 2 + 800px);
    background: #fff;
    background-size: cover;
  }
  .top-news__lists--item {
    margin-left: 5%;
    width: 25.25%;
  }
  .top-news__lists--item:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .top-news:before {
    width: calc(33.3333333333% + 40px);
  }
  .top-news:after {
    width: calc(66.6666666667% - 40px);
  }
}
@media screen and (max-width: 940px) and (min-width: 769px) {
  .top-news:before, .top-news:after {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .top-news .wrap {
    display: block;
  }
  .top-news__head {
    padding: 60px 0 30px;
    width: auto;
    background: url(../img/top/news_bg-sp.jpg) no-repeat center center;
    background-size: cover;
  }
  .top-news__head .title--3 {
    margin-bottom: 32px;
  }
  .top-news__cat--item {
    font-size: 14px;
  }
  .top-news__cat--item a {
    min-width: 200px;
    padding: 14px 34px;
  }
  .top-news__cat--item a:after {
    right: 18px;
    margin-top: -5px;
    width: 6px;
    height: 11px;
  }
  .top-news__content {
    padding: 10px 0 46px;
    width: auto;
    background: #fff;
  }
}
.top-search {
  position: relative;
  padding: 60px 0 48px;
  background: url(../img/top/search_bg.jpg) no-repeat center center;
  background-size: cover;
}
@media screen and (min-width: 769px) {
  .top-search {
    padding: 124px 0 90px;
  }
}
.top-flow {
  position: relative;
  padding: 90px 0;
  background: #cce0f0;
}
.top-flow .title--2 {
  margin-bottom: 82px;
}
.top-flow__lists {
  display: flex;
}
.top-flow__lists--item {
  position: relative;
  padding-bottom: 30px;
  width: 20%;
  text-align: center;
  color: #fff;
}
.top-flow__lists--item:after {
  content: "";
  position: absolute;
  right: -12px;
  bottom: 62px;
  width: 0;
  height: 0;
  border: solid transparent 12px;
  border-right: none;
  overflow: hidden;
  z-index: 2;
}
.top-flow__lists--item:nth-of-type(1) {
  background: #0168b7;
}
.top-flow__lists--item:nth-of-type(1):after {
  border-left-color: #0168b7;
}
.top-flow__lists--item:nth-of-type(1) .top-flow__lists--icon {
  border-color: #0168b7;
}
.top-flow__lists--item:nth-of-type(2) {
  background: #3284c4;
}
.top-flow__lists--item:nth-of-type(2):after {
  border-left-color: #3284c4;
}
.top-flow__lists--item:nth-of-type(2) .top-flow__lists--icon {
  border-color: #3284c4;
}
.top-flow__lists--item:nth-of-type(3) {
  background: #7cafd7;
}
.top-flow__lists--item:nth-of-type(3):after {
  border-left-color: #7cafd7;
}
.top-flow__lists--item:nth-of-type(3) .top-flow__lists--icon {
  border-color: #7cafd7;
}
.top-flow__lists--item:nth-of-type(4) {
  background: #02c6ba;
}
.top-flow__lists--item:nth-of-type(4):after {
  border-left-color: #02c6ba;
}
.top-flow__lists--item:nth-of-type(4) .top-flow__lists--icon {
  border-color: #02c6ba;
}
.top-flow__lists--item:nth-of-type(5) {
  background: #fc9604;
}
.top-flow__lists--item:nth-of-type(5):after {
  display: none;
}
.top-flow__lists--item:nth-of-type(5) .top-flow__lists--icon {
  border-color: #fc9604;
}
.top-flow__lists--icon {
  display: block;
  position: relative;
  margin: -36px auto 0;
  padding-bottom: 58.3333333333%;
  width: 58.3333333333%;
  height: 0;
  border-radius: 50%;
  background: #fff;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
}
.top-flow__lists--icon img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.top-flow__lists--text {
  margin-top: 12px;
  min-height: 78px;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 24px;
  line-height: 1.4;
}
.top-flow__lists--num {
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 80px;
  line-height: 1.2;
}
@media screen and (max-width: 768px) {
  .top-flow {
    padding: 40px 0 32px;
  }
  .top-flow .title--2 {
    margin-bottom: 25px;
  }
  .top-flow__lists {
    display: block;
    margin: 0 45px;
  }
  .top-flow__lists--item {
    display: flex;
    align-items: center;
    padding: 10px 0;
    width: auto;
    text-align: center;
    color: #fff;
  }
  .top-flow__lists--item:after {
    right: 36px;
    bottom: -18px;
    transform: rotate(90deg);
  }
  .top-flow__lists--icon {
    margin: 0 0 0 -25px;
    padding-bottom: 27.4193548387%;
    width: 27.4193548387%;
  }
  .top-flow__lists--text {
    margin-top: 0;
    width: 50.6451612903%;
    min-height: inherit;
    text-align: center;
    font-size: 20px;
  }
  .top-flow__lists--num {
    width: 21.935483871%;
    font-size: 40px;
  }
}
.top-techlibrary {
  position: relative;
}
.top-techlibrary .wrap {
  display: flex;
  align-items: center;
}
.top-techlibrary__top {
  padding: 20px 0;
  width: 33.3333333333%;
}
.top-techlibrary--title {
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-weight: 700;
  line-height: 1.2;
}
.top-techlibrary--title .normal,
.top-techlibrary--title .min {
  display: block;
}
.top-techlibrary--title .normal {
  font-size: 60px;
}
.top-techlibrary--title .min {
  font-size: 50px;
}
.top-techlibrary .more--1, .top-techlibrary .more--2 {
  max-width: 248px;
  font-size: 18px;
}
.top-techlibrary .more--1 a, .top-techlibrary .more--2 a {
  padding: 10px;
}
.top-techlibrary .more--1 a:after, .top-techlibrary .more--2 a:after {
  right: 16px;
  margin-top: -6px;
  width: 8px;
  height: 13px;
}
.top-techlibrary .more--1 {
  margin-top: 34px;
}
.top-techlibrary .more--2 {
  margin-top: 16px;
}
.top-techlibrary__lists {
  display: flex;
  justify-content: flex-end;
  padding: 90px 0;
  width: 66.6666666667%;
}
.top-techlibrary__lists--item {
  box-shadow: 5px 5px 10px rgba(116, 116, 116, 0.2);
}
@media screen and (max-width: 1200px) {
  .top-techlibrary:before {
    width: 33.3333333333%;
  }
  .top-techlibrary:after {
    width: 66.6666666667%;
  }
}
@media screen and (min-width: 769px) {
  .top-techlibrary:before, .top-techlibrary:after {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
  }
  .top-techlibrary:before {
    left: 0;
    width: calc((100% - 1200px) / 2 + 400px);
    background: #e5f0f8;
  }
  .top-techlibrary:after {
    right: 0;
    width: calc((100% - 1200px) / 2 + 800px);
    background: url(../img/top/techlibrary_bg.jpg) no-repeat center center;
    background-size: cover;
  }
  .top-techlibrary__lists--item {
    margin-left: 5%;
    width: 25.25%;
  }
  .top-techlibrary__lists--item:first-child {
    margin-left: 0;
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .top-techlibrary:before {
    width: calc(33.3333333333% + 40px);
  }
  .top-techlibrary:after {
    width: calc(66.6666666667% - 40px);
  }
}
@media screen and (max-width: 768px) {
  .top-techlibrary .wrap {
    display: block;
  }
  .top-techlibrary__top {
    padding: 36px 20px 33px;
    width: auto;
    background: #e5f0f8;
  }
  .top-techlibrary--title .normal {
    font-size: 36px;
  }
  .top-techlibrary--title .min {
    font-size: 31px;
  }
  .top-techlibrary .more--1 {
    margin-top: 22px;
    font-size: 14px;
  }
  .top-techlibrary .more--1 a {
    padding: 14px 10px;
    min-width: 200px;
  }
  .top-techlibrary .more--1 a:after {
    right: 22px;
    margin-top: -5px;
    width: 6px;
    height: 11px;
  }
  .top-techlibrary__lists {
    padding: 46px 20px;
    width: auto;
    background: url(../img/top/techlibrary_bg.jpg) no-repeat center center;
    background-size: cover;
  }
  .top-techlibrary__lists--item {
    margin-left: 7.3239436619%;
    width: 28.4507042254%;
  }
  .top-techlibrary__lists--item:first-child {
    margin-left: 0;
  }
}
.top-know {
  position: relative;
  padding: 92px 0;
  background: url(../img/top/know_bg.jpg) no-repeat center center;
  background-size: cover;
}
.top-know__lists {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.top-know__lists--item {
  position: relative;
  padding: 34px 0 14px;
  text-align: center;
  color: #fff;
}
.top-know__lists--item:nth-of-type(1) {
  background: #0168b7;
}
.top-know__lists--item:nth-of-type(2) {
  background: #7cafd7;
}
.top-know__lists--item:nth-of-type(3) {
  background: #3077ae;
}
.top-know__lists--item:nth-of-type(4) {
  background: #3284c4;
}
.top-know__lists--icon {
  display: block;
  margin: 0 auto;
  width: 37.7777777778%;
}
.top-know__lists--title {
  margin-top: 10px;
  font-size: 28px;
  font-weight: 400;
}
.top-know__lists--note {
  display: inline-block;
  position: relative;
  margin-top: 22px;
  padding: 2px 4px 4px;
  width: 180px;
  background: #333;
  border-radius: 15px;
  vertical-align: top;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  font-size: 20px;
  line-height: 1.2;
}
.top-know__lists--count {
  position: relative;
  margin-top: -4px;
  text-align: center;
  font-family: "Noto Serif JP", "HiraMinProN-W3", "ヒラギノ明朝 ProN W3", "MS P Mincho", "ＭＳ Ｐ明朝", "MS 明朝", "HG明朝E", "Century", "Times New Roman", serif;
  line-height: 1.2;
}
.top-know__lists--count .num,
.top-know__lists--count .unit {
  display: inline-block;
  vertical-align: baseline;
}
.top-know__lists--count .num {
  font-size: 74px;
}
.top-know__lists--count .unit {
  font-size: 30px;
}
@media screen and (min-width: 769px) {
  .top-know__lists--item {
    width: 22.5%;
  }
  .top-know__lists--item:nth-of-type(1) {
    margin-top: 12px;
  }
  .top-know__lists--item:nth-of-type(2) {
    margin-top: 72px;
  }
  .top-know__lists--item:nth-of-type(4) {
    margin-top: 72px;
  }
}
@media screen and (max-width: 1000px) and (min-width: 769px) {
  .top-know__lists--title {
    margin-top: 1vw;
    font-size: 2.8vw;
  }
  .top-know__lists--note {
    margin-top: 2.2vw;
    padding: 0.2vw 0.4vw 0.4vw;
    width: 18vw;
    border-radius: 1.5vw;
    font-size: 2vw;
  }
  .top-know__lists--count {
    margin-top: -0.4vw;
  }
  .top-know__lists--count .num {
    font-size: 7.4vw;
  }
  .top-know__lists--count .unit {
    font-size: 3vw;
  }
}
@media screen and (max-width: 768px) {
  .top-know {
    padding: 92px 0;
  }
  .top-know__lists {
    display: block;
    margin: 0 auto;
    width: calc(100% - 95px);
  }
  .top-know__lists--item {
    margin-top: 24px;
    padding: 18px 0 6px;
  }
  .top-know__lists--item:first-child {
    margin-top: 0;
  }
  .top-know__lists--icon {
    width: 31.0714285714%;
  }
  .top-know__lists--title {
    margin-top: 6px;
    font-size: 28px;
  }
  .top-know__lists--note {
    margin-top: 16px;
    width: 150px;
    font-size: 15px;
  }
  .top-know__lists--count {
    margin-top: -10px;
  }
}
.top-recruit {
  padding-bottom: 104px;
}
.top-recruit__head {
  position: relative;
  padding: 118px 0 45px;
  background: url(../img/top/recruit_title_bg.jpg) no-repeat center center;
  background-size: cover;
}
.top-recruit__head--lead {
  text-align: center;
}
.top-recruit__head--lead .min {
  font-size: 20px;
}
.top-recruit__head--lead .normal {
  font-size: 28px;
}
.top-recruit__content {
  margin-top: 48px;
}
.top-recruit__info {
  padding: 20px 70px;
  height: 175px;
  border: solid #ccc 1px;
  overflow: auto;
}
.top-recruit__info--item {
  position: relative;
  border-bottom: solid #ccc 1px;
}
.top-recruit__info--item > a {
  display: flex;
  position: relative;
  padding: 16px 0;
}
.top-recruit__info--date {
  width: 120px;
  font-size: 18px;
  font-weight: 700;
  color: #0068b7;
}
.top-recruit__info--title {
  width: calc(100% - 120px);
  font-size: 16px;
  font-weight: 400;
}
.top-recruit__bnr {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 60px;
}
.top-recruit__bnr--item > a {
  display: block;
  position: relative;
}
@media screen and (min-width: 769px) {
  .top-recruit__bnr--item {
    margin: 5% 2.5% 0 0;
    width: 45%;
  }
  .top-recruit__bnr--item:nth-of-type(-n+2) {
    margin-top: 0;
  }
}
@media screen and (max-width: 768px) {
  .top-recruit {
    padding-bottom: 60px;
  }
  .top-recruit__head {
    padding: 64px 20px 32px;
  }
  .top-recruit__head--lead .min {
    font-size: 14px;
  }
  .top-recruit__head--lead .normal {
    font-size: 18px;
    font-weight: 700;
  }
  .top-recruit__content {
    margin: 25px 20px 0;
  }
  .top-recruit__info {
    padding: 20px 14px;
    height: 275px;
  }
  .top-recruit__info--item > a {
    display: block;
    padding: 20px 0;
  }
  .top-recruit__info--date {
    width: auto;
    font-size: 15px;
  }
  .top-recruit__info--title {
    margin-top: 14px;
    width: auto;
    font-size: 14px;
  }
  .top-recruit__bnr {
    margin: 28px 10px 0;
  }
  .top-recruit__bnr--item {
    margin-top: 20px;
  }
  .top-recruit__bnr--item:first-child {
    margin-top: 0;
  }
}
.top-blog {
  position: relative;
  padding-bottom: 60px;
  z-index: 1;
}
@media screen and (min-width: 769px) {
  .top-blog {
    padding-bottom: 105px;
  }
}
.top-blog__head {
  position: relative;
  padding: 50px 0 30px;
}
@media screen and (min-width: 769px) {
  .top-blog__head {
    padding: 100px 0 60px;
  }
}
.top-blog__head:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 24.8vw);
  background: #E5F0F8;
  z-index: -1;
}
@media screen and (min-width: 769px) {
  .top-blog__head:after {
    height: calc(100% + 94px);
  }
}
@media screen and (max-width: 1200px) and (min-width: 769px) {
  .top-blog__head:after {
    height: calc(100% + 7.8333333333vw);
  }
}
.top-blog__content {
  position: relative;
}
@media screen and (max-width: 768px) {
  .top-blog .lists-blog {
    margin: 0 auto;
    width: calc(100% - 104px);
  }
}
@media screen and (max-width: 768px) {
  .top-blog .lists-blog-item {
    margin-top: 30px;
  }
  .top-blog .lists-blog-item:first-child {
    margin-top: 0;
  }
}
@media screen and (min-width: 769px) {
  .top-blog .lists-blog-item {
    margin: 30px 3.3333333333% 0 0;
    width: 22.5%;
  }
  .top-blog .lists-blog-item:nth-of-type(-n+4) {
    margin-top: 0;
  }
  .top-blog .lists-blog-item:nth-of-type(4n) {
    margin-right: 0;
  }
}

/* topics
------------------------------------------------ */
.topics {
  /* top */
  /* lists */
  /* nav */
  /* single */
  /* detail */
}
.topics-top {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .topics-top {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .topics-top .title--page {
    background-image: url(../img/topics/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .topics-top .title--page {
    background-image: url(../img/topics/title_bg-pc.jpg);
  }
}
@media screen and (max-width: 768px) {
  .topics-lists .title--5 {
    margin: 0 20px;
  }
  .topics-lists .pager-1 {
    margin-right: 20px;
    margin-left: 20px;
  }
}
.topics-nav {
  margin: 12px 20px 0;
}
@media screen and (min-width: 769px) {
  .topics-nav {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 30px 0;
  }
}
.topics-nav-tab {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.topics-nav-tab--item {
  margin: 0 0.2985074627% 0.2985074627% 0;
  text-align: center;
  font-size: 16px;
  line-height: 1.2;
}
.topics-nav-tab--item:last-child {
  margin-right: 0;
}
@media screen and (max-width: 768px) {
  .topics-nav-tab--item {
    width: 24.776119403%;
  }
}
@media screen and (min-width: 769px) {
  .topics-nav-tab--item {
    margin: 0 2px 2px 0;
    min-width: 200px;
    font-size: 24px;
  }
}
.topics-nav-tab--item a {
  display: block;
  padding: 10px 2px;
  border: solid #0168b7 1px;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .topics-nav-tab--item a {
    padding: 14px 2px;
  }
}
.topics-nav-tab--item a.active {
  background: #0168b7;
  color: #fff;
}
.topics-nav-archive {
  margin-left: auto;
  width: 150px;
  height: 40px;
}
@media screen and (min-width: 769px) {
  .topics-nav-archive {
    width: 300px;
    height: 58px;
  }
}
.topics-nav-archive select {
  padding: 0 10px;
  width: 100%;
  height: 100%;
  font-family: inherit;
  font-size: 15px;
}
@media screen and (min-width: 769px) {
  .topics-nav-archive select {
    padding: 0 20px;
    font-size: 24px;
  }
}
.topics-single {
  padding-bottom: 50px;
}
@media screen and (min-width: 769px) {
  .topics-single {
    padding-bottom: 100px;
  }
}
@media screen and (max-width: 768px) {
  .topics-single .title--page {
    background-image: url(../img/topics/title_bg.jpg);
  }
}
@media screen and (min-width: 769px) {
  .topics-single .title--page {
    background-image: url(../img/topics/title_bg-pc.jpg);
  }
}
@media screen and (max-width: 768px) {
  .topics-detail {
    margin: 0 20px;
  }
}
.topics-detail-hd__info {
  display: flex;
  align-items: center;
  margin-top: 30px;
}
@media screen and (min-width: 769px) {
  .topics-detail-hd__info {
    margin-top: 40px;
  }
}
.topics-detail-title {
  border-bottom: solid #ccc 2px;
  font-size: 21px;
  font-weight: 400;
  color: #0168b7;
}
@media screen and (max-width: 768px) {
  .topics-detail-title {
    padding-bottom: 12px;
  }
}
@media screen and (min-width: 769px) {
  .topics-detail-title {
    font-size: 42px;
  }
}
.topics-detail-cat {
  margin-right: 12px;
}
@media screen and (min-width: 769px) {
  .topics-detail-cat {
    margin-right: 20px;
  }
}
.topics-detail-cat .icon-news {
  padding: 2px 10px 4px;
}
@media screen and (min-width: 769px) {
  .topics-detail-cat .icon-news {
    padding: 2px 14px;
  }
}
.topics-detail-date {
  font-size: 15px;
  font-weight: 600;
  color: #0168b7;
}
@media screen and (min-width: 769px) {
  .topics-detail-date {
    font-size: 18px;
  }
}
.topics-detail-content {
  margin-top: 18px;
  font-size: 14px;
  line-height: 1.625;
}
@media screen and (min-width: 769px) {
  .topics-detail-content {
    margin-top: 36px;
    font-size: 16px;
  }
}
.topics-detail-content p + p {
  margin-top: 1.4em;
}
@media screen and (min-width: 769px) {
  .topics-detail-content p + p {
    margin-top: 1.6em;
  }
}
/* footer
------------------------------------------------ */
#footer-top {
  position: relative;
  padding: 100px 0 20px;
  background: #f8fbfd;
}
#footer-top .wrap {
  display: flex;
  flex-wrap: wrap;
}
@media screen and (max-width: 768px) {
  #footer-top {
    padding: 40px 0 25px;
  }
  #footer-top .wrap {
    display: block;
  }
}
@media screen and (min-width: 769px) {
  #footer .style-sitemap {
    display: flex;
    justify-content: space-between;
    width: calc(100% - 288px);
  }
  #footer .style-sitemap__line {
    width: 20.8333333333%;
  }
}
@media screen and (max-width: 1000px) {
  #footer .style-sitemap {
    width: 100%;
  }
  #footer .style-sitemap__line {
    width: 24%;
  }
}
@media screen and (max-width: 768px) {
  #footer .style-sitemap {
    display: block;
    width: auto;
    margin-top: 10px;
  }
  #footer .style-sitemap__line {
    width: auto;
  }
}
@media screen and (min-width: 769px) {
  #footer .style-links--1 {
    margin-top: 35px;
    width: 100%;
  }
}

.footer--pagetop {
  position: fixed;
  bottom: 16px;
  right: 20px;
  width: 72px;
  height: 72px;
  z-index: 8;
}
.cookiePolicyBox-none .footer--pagetop {
  margin-bottom: 0 !important;
}
.footer--pagetop a {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  background: #404040;
  border-radius: 50%;
  text-indent: -9999px;
  white-space: nowrap;
  overflow: hidden;
}
.footer--pagetop a:before, .footer--pagetop a:after {
  content: "";
  position: absolute;
  top: 28px;
  width: 18px;
  height: 1px;
  background: #fff;
}
.footer--pagetop a:before {
  left: 50%;
  transform-origin: left top;
  transform: rotate(45deg);
}
.footer--pagetop a:after {
  right: 50%;
  transform-origin: right top;
  transform: rotate(-45deg);
}
@media screen and (max-width: 768px) {
  .footer--pagetop {
    bottom: 20px;
    right: 10px;
    width: 50px;
    height: 50px;
  }
  .footer--pagetop a:before, .footer--pagetop a:after {
    top: 18px;
    width: 12px;
  }
}
.footer--logo {
  margin: 0 92px 40px 0;
  width: 196px;
  font-size: 10px;
  line-height: 1.2;
}
.footer--logo a {
  display: block;
  width: 100%;
}
.footer--logo--kobelco {
  margin-top: 18px;
  width: 100%;
  font-size: 10px;
  line-height: 1.2;
}
.footer--logo--kobelco a {
  display: block;
  margin: 0 auto;
  width: 80px;
}
@media screen and (max-width: 768px) {
  .footer--logo {
    margin: 0 20px;
    width: 195px;
  }
  .footer--logo--kobelco {
    margin-top: 54px;
    width: auto;
  }
  .footer--logo--kobelco a {
    width: 88px;
  }
}
.footer--copyright {
  padding: 12px 0;
  background: #404040;
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.2;
  color: #fff;
}
.footer--copyright small {
  font-size: inherit;
}
@media screen and (max-width: 768px) {
  .footer--copyright {
    font-weight: 400;
  }
}
.footer__btns {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  width: 190px;
  z-index: 6;
}
.footer__btns--item {
  height: 70px;
  font-size: 20px;
  font-weight: 700;
  line-height: 70px;
}
.footer__btns--item a {
  display: block;
  color: #fff;
}
.footer__btns--item a:before {
  content: "";
  display: inline-block;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  vertical-align: top;
}
.footer__btns--item:first-child a {
  border-radius: 15px 0 0 0;
}
.footer__btns--item:last-child a {
  border-radius: 0 0 0 15px;
}
.footer__btns--contact a {
  background: #0168b7;
}
.footer__btns--contact a:before {
  margin: 25px 10px 0 20px;
  width: 26px;
  height: 20px;
  background-image: url(../img/icon/mail1.png);
}
@media screen and (max-width: 768px) {
  .footer__btns {
    bottom: 82px;
    width: 80px;
  }
  .footer__btns--item {
    height: 52px;
    text-align: center;
    font-size: 10px;
    line-height: 1.2;
  }
  .footer__btns--item a {
    height: 100%;
  }
  .footer__btns--item a:before {
    display: block;
    margin: 0 auto;
  }
  .footer__btns--item:first-child a {
    border-radius: 8px 0 0 0;
  }
  .footer__btns--item:last-child a {
    border-radius: 0 0 0 8px;
  }
  .footer__btns--contact a {
    padding-top: 10px;
  }
  .footer__btns--contact a:before {
    margin-bottom: 8px;
    width: 20px;
    height: 16px;
  }
}

/* cookie policy
------------------------------------------------ */
.cookie-policy-box {
  position: fixed;
  bottom: 0;
  left: 0;
  max-width: 100%;
  background: #edf2f0;
  padding: 8px 18px;
  text-align: left;
  font-size: 14px;
  color: #111111;
  z-index: 2147483647;
}
.cookie-policy-box:after {
  content: "";
  display: table;
  clear: both;
}
.cookie-policy-text {
  float: left;
  padding: 5px 0;
  width: calc(100% - 130px);
}
.cookie-policy-button {
  display: block;
  float: right;
  margin-left: 5px;
  padding: 5px 8px;
  width: 120px;
  min-width: 100px;
  background: #000099;
  border-radius: 5px;
  text-align: center;
  color: #f1f1f1;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .cookie-policy-box {
    padding: 6px 10px;
    font-size: 10px;
  }
  .cookie-policy-text {
    float: none;
    width: auto;
    padding: 0;
  }
  .cookie-policy-button {
    float: none;
    margin: 10px 0 0 auto;
  }
}