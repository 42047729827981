@charset 'utf-8';

@import '../variable';

/* style
------------------------------------------------ */
.style {
	/* tab */
	&-tab {
		display: flex;
		flex-wrap: wrap;
		@include device_sp {
			margin: 0 20px;
		}
		@include device_pc {
		}
		&-item {
			position: relative;
			height: 45px;
			text-align: center;
			font-size: 14px;
			line-height: 1.4;
			@include device_sp {
				margin: 2px 2px 0 0;
				width: calc(50% - 1px);
				&:nth-of-type(-n+2) { margin-top: 0; }
				&:nth-of-type(2n) { margin-right: 0; }
			}
			@include device_pc {
				margin: 2px 2px 0 0;
				width: calc(25% - 1.5px);
				height: 90px;
				font-size: 24px;
				&:nth-of-type(-n+4) { margin-top: 0; }
				&:nth-of-type(4n) { margin-right: 0; }
			}
			& > a {
				display: flex;
				justify-content: center;
				align-items: center;
				position: relative;
				height: 100%;
				background: #fff;
				border: solid #0168b7 1px;
				color: #0168b7;
				&.active {
					background: #0168b7;
					color: #fff;
					&:after {
						background-image: url(../img/icon/arrow3.png);
					}
				}
				@include device_pc {
					padding: 35px 30px;
				}
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					right: 8px;
					margin-top: -5px;
					width: 5px;
					height: 10px;
					background: url(../img/icon/arrow4.png) no-repeat center center;
					background-size: 100%;
					@include device_pc {
						right: 20px;
						margin-top: -9px;
						width: 9px;
						height: 19px;
					}
				}
				.min {
					display: block;
					font-size: 11px;
					@include device_pc {
						font-size: 18px;
					}
				}
			}
		}
	}
	/* introduce */
	&-introduce {
		@include device_sp {
			padding: 10px 20px 30px;
		}
		@include device_pc {
			display: flex;
			justify-content: space-between;
			padding: 60px 20px;
		}
		&-info {
			@include device_pc {
				width: 45%;
			}
		}
		.title--5 {
			margin-bottom: 8px;
			@include device_pc {
				margin-bottom: 25px;
			}
		}
		&-text {
			font-size: 14px;
			line-height: 1.75;
			@include device_pc {
				font-size: 15px;
			}
		}
		&-photo {
			@include device_sp {
				margin-top: 26px;
			}
			@include device_pc {
				width: 43.3333333333%;
			}
		}
	}
	/* section */
	&-section {
		position: relative;
		margin-top: 1px;
		padding: 30px 20px 50px;
		background: #e5f0f8;
		@include device_pc {
			margin-top: 2px;
			padding: 70px 0 112px;
		}
		&-block {
			margin-top: 24px;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 50px;
			}
			.style {
				&-tab {
					@include device_sp {
						margin: 0 0 10px;
					}
					@include device_pc {
						margin-bottom: 20px;
					}
				}
				&-section {
					&-lists {
						margin-top: 10px;
						&:first-child { margin-top: 0; }
						@include device_pc {
							margin-top: 20px;
						}
					}
				}
			}
		}
		&-title {
			margin-bottom: 16px;
			font-family: $fontMin;
			font-size: 26px;
			font-weight: 700;
			line-height: 1.4;
			color: #0168b7;
			@include device_pc {
				margin-bottom: 34px;
				font-size: 42px;
			}
		}
		&-lists {
			&--item {
				position: relative;
				margin-top: 10px;
				padding: 15px 20px 20px;
				background: #fff;
				&:first-child { margin-top: 0; }
				@include device_pc {
					margin-top: 20px;
					padding: 28px 65px;
				}
			}
			&__box {
				position: relative;
			}
			&--title {
				padding-bottom: 8px;
				border-bottom: solid #c6dbec 1px;
				font-size: 19px;
				line-height: 1.4;
				color: #0168b7;
				@include device_pc {
					padding-bottom: 18px;
					font-size: 28px;
				}
			}
			&__content {
				position: relative;
				margin-top: 18px;
				font-size: 14px;
				&:first-child { margin-top: 0; }
				@include device_sp {
					padding-top: 5px;
				}
				@include device_pc {
					margin-top: 32px;
					font-size: 15px;
				}
				& > * {
					& + * {
						margin-top: 1.4em;
						@include device_pc {
							margin-top: 1.5em;
						}
					}
				}
				.image {
					&-center {
						margin-right: auto;
						margin-left: auto;
						width: 50%;
						text-align: center;
						@include device_pc {
							// width: 27.5700934579%;
						}
					}
					&-lists {
						margin-top: 20px;
						@include device_pc {
							display: flex;
							justify-content: center;
							flex-wrap: wrap;
							margin-top: 40px;
						}
						&:first-child { margin-top: 0; }
						&-item {
							position: relative;
							text-align: center;
							@include device_sp {
								margin-top: 20px;
								font-size: 10px;
								&:first-child { margin-top: 0; }
							}
							@include device_pc {
								margin: 40px 2% 0;
								width: 28%;
								font-size: 12px;
								&:nth-of-type(-n+3) { margin-top: 0; }
							}
							figure {
								display: block;
								position: relative;
								img {
									@include device_sp {
										width: 50%;
									}
								}
							}
							figcaption {
								display: block;
								text-align: center;
								margin-top: 10px;
								@include device_pc {
									margin-top: 20px;
								}
							}
						}
					}
				}
			}
			&__titles {
				@include device_sp {
					margin-top: 14px;
					&:first-child { margin-top: 0; }
				}
				@include device_pc {
					display: flex;
					flex-wrap: wrap;
				}
				&--item {
					font-size: 14px;
					line-height: 1.2;
					& > a {
						display: inline-block;
						position: relative;
						text-decoration: underline;
						vertical-align: top;
						color: #0168b7;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
						&.link {
							&-icon {
								padding-left: 26px;
								i {
									position: absolute;
									top: 0;
									left: 0;
								}
							}
						}
					}
					@include device_sp {
						margin-top: 14px;
						&:first-child { margin-top: 0; }
					}
					@include device_pc {
						margin: 18px 60px 0 0;
						width: calc(25% - 45px);
						font-size: 15px;
						&:nth-of-type(-n+4) { margin-top: 0; }
						&:nth-of-type(4n) { margin-right: 0; }
						&.streight {
							margin-right: 0;
							width: 100%;
						}
					}
				}
			}
			&__links {
				@include device_sp {
				}
				@include device_pc {
				}
				&--item {
					margin-top: 18px;
					font-size: 14px;
					&:first-child { margin-top: 0; }
					@include device_pc {
						margin-top: 28px;
						font-size: 15px;
					}
					a,
					span {
						display: inline-block;
						position: relative;
						padding-left: 22px;
						vertical-align: top;
						@include device_pc {
							padding-left: 32px;
						}
						&:before {
							content: '\25B6\FE0E';
							position: absolute;
							top: 4px;
							left: 0;
							font-size: 10px;
							color: #0168b7;
						}
					}
					a {
						text-decoration: underline;
						color: #0168b7;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
					}
					.no {
						&-arrow {
							padding-left: 0;
							&:before {
								display: none;
							}
						}
					}
					.icon {
						&-pdf {
							margin-right: 10px;
						}
						&-new {
							margin-left: 10px;
						}
					}
				}
			}
		}
	}
	/* sitemap */
	&-sitemap {
		&__box {
			margin-top: 42px;
			&:first-child { margin-top: 0; }
		}
		&--title {
			position: relative;
			padding-bottom: 18px;
			border-bottom: solid #a5a5a5 2px;
			font-size: 18px;
			font-weight: 700;
			line-height: 1.2;
			&:before,
			&:after {
				content: '';
				position: absolute;
				bottom: -2px;
				left: 60px;
				width: 10px;
				height: 2px;
			}
			&:before {
				background: #f8fbfd;
			}
			&:after {
				transform-origin: right top;
				transform: rotate(-45deg);
				background: #a5a5a5;
			}
			a {
				display: block;
				position: relative;
			}
			span {
				font-size: 14px;
			}
		}
		&__content {
			margin-top: 32px;
		}
		&__links {
			&--item {
				position: relative;
				margin-top: 25px;
				font-size: 16px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				a {
					display: block;
					position: relative;
					padding-left: 16px;
					&:before {
						content: '';
						position: absolute;
						top: 6px;
						left: 2px;
						width: 5px;
						height: 8px;
						background: url(../img/icon/arrow1.png) no-repeat center center;
						background-size: 100%;
						vertical-align: top;
					}
				}
			}
		}
		@include device_pc {
			&__content {
				display: block !important;
			}
		}
		@media screen and (max-width: 1200px) {
			width: calc(100% - 200px);
			&__line {
				width: 24%;
			}
			&--title {
				font-size: 14px;
				span {
					font-size: 12px;
				}
			}
			&__links {
				&--item {
					font-size: 12px;
				}
			}
		}
		@include device_sp {
			display: block;
			width: auto;
			margin-top: 10px;
			&__line {
				width: auto;
				&:last-child {
					display: flex;
					flex-wrap: wrap;
					.style-sitemap {
						&__box {
							width: 50%;
							border-bottom: solid #a5a5a5 1px;
							&:nth-of-type(2n) {
								border-left: solid #a5a5a5 1px;
							}
						}
						&--title {
							display: flex;
							align-items: center;
							border-bottom: none;
							height: 100%;
							a {
								width: 100%;
							}
						}
					}
				}
			}
			&__box {
				margin-top: 0;
			}
			&--title {
				padding: 0;
				border-bottom-width: 1px;
				font-size: 16px;
				&:before,
				&:after {
					display: none;
				}
				a {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					padding: 20px;
					height: 100%;
					&.has--child {
						&:before,
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							right: 18px;
							transition: ease .3s;
							width: 18px;
							height: 3px;
							background: #404040;
						}
						&:after {
							transform: rotate(90deg);
						}
						&.active {
							&:after {
								transform: rotate(0);
							}
						}
					}
				}
				span {
					display: block;
					font-size: 14px;
				}
			}
			&__content {
				display: none;
				margin-top: 0;
				padding: 25px 20px 5px;
			}
			&__links {
				&--item {
					margin-top: 16px;
					font-size: 16px;
					a {
						padding-left: 22px;
					}
				}
			}
		}
	}
	/* links */
	&-links {
		&--1 {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			&--item {
				margin-left: 16px;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-left: 0; }
			}
			@media screen and (max-width: 1200px) {
				&--item {
					font-size: 12px;
				}
			}
			@include device_sp {
				justify-content: flex-start;
				margin-top: 0;
				&--item {
					margin-left: 0;
					width: 50%;
					border-bottom: solid #a5a5a5 1px;
					font-size: 14px;
					font-weight: 700;
					&:nth-of-type(2n) {
						border-left: solid #a5a5a5 1px;
					}
					a {
						display: block;
						padding: 20px;
					}
					&.visible--pc {
						& + .style-links--1--item {
							&:last-child {
								width: calc(50% + 1px);
								border-left: none;
								border-right: solid #a5a5a5 1px;
							}
						}
					}
				}
			}
		}
	}
	/* search */
	&-search {
		&--1 {
			&__tab {
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
				&--item {
					position: relative;
					width: 33.1666666667%;
					text-align: center;
					font-size: 24px;
					line-height: 1.2;
					a {
						display: block;
						position: relative;
						padding: 16px 0;
						width: 100%;
						background: #404040;
						color: #fff;
						&.active {
							background: #0168b7;
							&:after {
								content: '';
								position: absolute;
								left: 50%;
								bottom: -12px;
								margin-left: -8px;
								width: 0;
								height: 0;
								border: solid transparent 8px;
								border-top: solid #0168b7 12px;
								border-bottom: none;
								overflow: hidden;
							}
						}
					}
				}
			}
			&__content {
				display: none;
				margin-top: 12px;
				padding: 36px 0;
				border: solid #eee 4px;
				background: #fff;
			}
			&__from {
				margin: 0 auto;
				width: 58.3333333333%;
				input,
				button,
				select {
					appearance: none;
					height: 68px;
					background: none;
					border: none;
					border-radius: 0;
					font-family: inherit;
					font-size: 24px;
				}
			}
			&--select {
				select {
					padding: 0 30px;
					width: 100%;
					background: url(../img/icon/select_arrow1.png) no-repeat right 20px center #fff;
					background-size: 20px;
					border: solid #ccc 2px;
				}
			}
			&__keyword {
				display: flex;
				margin-top: 18px;
				&--input {
					width: calc(100% - 68px);
					input {
						padding: 0 30px;
						width: 100%;
						background: #eee;
						border: solid #ccc 2px;
						border-right: none;
					}
				}
				&--submit {
					position: relative;
					width: 68px;
					height: 68px;
					button {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url(../img/icon/search1.png) no-repeat center center #404040;
						background-size: 37px;
						text-indent: -9999px;
						white-space: nowrap;
						overflow: hidden;
						cursor: pointer;
					}
				}
			}
			@include device_sp {
				margin: 0 20px;
				&__tab {
					&--item {
						font-size: 16px;
						a {
							padding: 14px 0;
							&.active {
								&:after {
									bottom: -8px;
									margin-left: -3px;
									border-width: 6px;
									border-top-width: 8px;
								}
							}
							span {
								display: block;
								font-size: 12px;
							}
						}
					}
				}
				&__content {
					margin-top: 8px;
					padding: 16px;
					border-width: 2px;
				}
				&__from {
					width: auto;
					input,
					button,
					select {
						height: 60px;
						font-size: 16px;
					}
				}
				&--select {
					select {
						padding: 0 60px 0 12px;
						border-width: 1px;
					}
				}
				&__keyword {
					margin-top: 12px;
					&--input {
						width: calc(100% - 60px);
						input {
							padding: 0 12px;
							border-width: 1px;
						}
					}
					&--submit {
						width: 60px;
						height: 60px;
						button {
							background-size: 34px;
						}
					}
				}
			}
			&__links {
				margin-top: 20px;
				@include device_pc {
					margin: 40px 46px 0;
				}
				&__box {
					margin-top: 10px;
					padding: 25px;
					background: #f8fbfd;
					&:first-child { margin-top: 0; }
					@include device_pc {
						margin-top: 20px;
						padding: 40px 50px;
					}
				}
				&--title {
					padding-bottom: 10px;
					border-bottom: solid #c6dbec 1px;
					font-size: 19px;
					color: #0168b7; 
					@include device_pc {
						font-size: 28px;
					}
				}
				&__content {
					margin-top: 15px;
					@include device_pc {
						margin-top: 25px;
					}
				}
				&__titles {
					display: flex;
					flex-wrap: wrap;
					align-items: flex-start;
					&--item {
						border-bottom: solid #c6dbec 1px;
						font-size: 14px;
						line-height: 1.2;
						@include device_sp {
							margin: 10px 30px 0 0;
							width: calc(50% - 15px);
							&:nth-of-type(-n+2) { margin-top: 0; }
							&:nth-of-type(2n) { margin-right: 0; }
						}
						@include device_pc {
							margin: 38px 40px 0 0;
							width: calc(25% - 30px);
							font-size: 16px;
							&:nth-of-type(-n+4) { margin-top: 0; }
							&:nth-of-type(4n) { margin-right: 0; }
						}
						& > a {
							display: block;
							padding: 6px 0;
							color: #0168b7;
							@include device_pc {
								padding: 8px;
							}
						}
					}
				}
			}
		}
	}
	/* index */
	&-index {
		margin-bottom: 96px;
		&__lists {
			&--item {
				position: relative;
				margin-top: 60px;
				padding: 40px 44px;
				background: #f8fbfd;
				&:nth-of-type(even) {
					background: #e5f0f8;
				}
				&:first-child { margin-top: 0; }
			}
			&--title {
				display: inline-block;
				position: relative;
				margin-bottom: 40px;
				vertical-align: top;
				font-size: 32px;
				font-weight: 700;
				line-height: 1.2;
				color: #0168b7;
				&:after {
					content: '';
					display: block;
					margin-top: 6px;
					width: 100%;
					height: 4px;
					background: #0168b7;
				}
			}
			&__detail {
				&__box {
					&--title {
						font-size: 18px;
						font-weight: 700;
						color: #0168b7;
						a {
							display: inline-block;
							vertical-align: top;
							color: #0168b7;
							&:before {
								content: '';
								display: inline-block;
								margin: 6px 4px 0 0;
								width: 8px;
								height: 16px;
								background: url(../img/icon/arrow2.png) no-repeat center center;
								background-size: 100%;
								vertical-align: top;
							}
						}
					}
					&--text {
						margin-top: 10px;
						font-size: 15px;
					}
				}
			}
			&__links {
				&--item {
					font-size: 18px;
					font-weight: 700;
					color: #0168b7;
					a {
						display: inline-block;
						vertical-align: top;
						color: #0168b7;
						&:before {
							content: '';
							display: inline-block;
							margin: 6px 4px 0 0;
							width: 8px;
							height: 16px;
							background: url(../img/icon/arrow2.png) no-repeat center center;
							background-size: 100%;
							vertical-align: top;
						}
					}
				}
			}
		}
		@include device_pc {
			&__lists {
				&__detail {
					display: flex;
					flex-wrap: wrap;
					&__box {
						margin: 40px 2.8776978418% 0 0;
						width: 48.5611510791%;
						&:nth-of-type(-n+2) { margin-top: 0; }
						&:nth-of-type(2n) { margin-right: 0; }
					}
				}
				&__links {
					display: flex;
					flex-wrap: wrap;
					&--item {
						margin-top: 12px;
						padding-right: 20px;
						width: 25%;
						&:nth-of-type(-n+4) { margin-top: 0; }
					}
				}
			}
		}
		@include device_sp {
			margin-bottom: 50px;
			&__lists {
				margin: 0 20px;
				&--item {
					margin-top: 30px;
					padding: 24px 20px;
				}
				&--title {
					margin-bottom: 24px;
					font-size: 21px;
					&:after {
						margin-top: 6px;
						height: 2px;
					}
				}
				&__detail {
					&__box {
						margin-top: 18px;
						&:first-child { margin-top: 0; }
						&--text {
							margin-top: 8px;
						}
					}
				}
				&__links {
					&--item {
						margin-top: 18px;
						&:first-child { margin-top: 0; }
					}
				}
			}
		}
	}
	&-relation {
		position: relative;
		@include device_sp {
			margin: 40px 20px;
		}
		@include device_pc {
			margin: 65px 0;
		}
		&-lists {
			display: flex;
			flex-wrap: wrap;
			margin-top: 20px;
			@include device_pc {
				margin-top: 40px;
			}
			&--item {
				position: relative;
				@include device_sp {
					margin: 18px 4.4776119402% 0 0;
					width: 47.7611940299%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
				@include device_pc {
					margin: 20px 6.6666666668% 0 0;
					width: 23.3333333333%;
					&:nth-of-type(-n+2) { margin-top: 0; }
					&:nth-of-type(2n) { margin-right: 0; }
				}
			}
			&--photo {
				position: relative;
				margin-bottom: 10px;
				width: 100%;
				text-align: center;
				@include device_pc {
					margin-bottom: 12px;
				}
				& > a {
					display: block;
					position: relative;
					width: 100%;
				}
			}
			&__info {
				position: relative;
			}
			&--title {
				font-size: 15px;
				font-weight: 500;
				color: #0168B7;
				@include device_pc {
					font-size: 18px;
				}
				& > a {
					color: #0168B7;
				}
			}
		}
	}
}

@import './style/1';

/* block
------------------------------------------------ */
.block {
	/* contact */
	&-contact {
		padding: 120px 0 100px;
		background: url(../img/share/contact_bg.png) no-repeat center center;
		background-size: cover;
		.title--3 {
			color: #fff;
			&:before,
			&:after {
				background: #fff;
			}
		}
		&--text {
			text-align: center;
			font-size: 28px;
			color: #fff;
		}
		&__links {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 20px;
			&--item {
				position: relative;
				margin: 20px 10px 0;
				font-size: 24px;
				line-height: 1.2;
				a {
					display: block;
					position: relative;
					padding: 20px 38px;
					background: #fff;
					border-radius: 35px;
					color: #0168b7;
					&:after {
						content: '';
						display: inline-block;
						margin: 4px 0 0 22px;
						width: 11px;
						height: 22px;
						background: url(../img/icon/arrow2.png) no-repeat center center;
						background-size: 100%;
						vertical-align: top;
					}
				}
			}
		}
		@include device_sp {
			padding: 60px 25px 48px;
			&--text {
				font-size: 18px;
			}
			&__links {
				display: block;
				margin: 24px 8px 0;
				&--item {
					margin: 16px 0 0;
					text-align: center;
					font-size: 16px;
					&:first-child { margin-top: 0; }
					a {
						padding: 12px 10px;
						&:after {
							position: absolute;
							top: 50%;
							right: 20px;
							margin: -6px 0 0;
							width: 7px;
							height: 13px;
						}
					}
				}
			}
		}
		&-btm {
			padding: 48px 0 54px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
			color: #fff;
			@include device_sp {
				background-image: url(../img/share/contact_btm_bg.png);
				& > .wrap {
					padding: 0 20px;
				}
			}
			@include device_pc {
				padding: 60px 0;
				background-image: url(../img/share/contact_btm_bg-pc.png);
			}
			&--title {
				text-align: center;
				font-size: 30px;
				font-weight: 700;
				line-height: 1.2;
				@include device_pc {
					font-size: 52px;
				}
			}
			&--text {
				margin-top: 20px;
				text-align: center;
				font-size: 14px;
				line-height: 1.71428571429;
				@include device_pc {
					margin-top: 26px;
					font-size: 28px;
					line-height: 1.21428571429;
				}
			}
			&--btn {
				margin: 30px auto 0;
				text-align: center;
				font-size: 18px;
				line-height: 1.2;
				@include device_pc {
					margin-top: 35px;
					width: 420px;
					font-size: 24px;
					font-weight: 500;
				}
				& > a {
					display: block;
					position: relative;
					padding: 22px 10px;
					background-color: #fff;
					border: 1px solid #fff;
					border-radius: 35px;
					color: #0168B7;
					@include device_pc {
						padding: 18px 10px;
					}
					&::after {
						content: '';
						position: absolute;
						top: 50%;
						right: 20px;
						margin-top: -8px;
						width: 10px;
						height: 17px;
						background: url(../img/icon/arrow7.png) no-repeat center center;
						background-size: 100%;
						@include device_pc {
							right: 40px;
						}
					}
				}
			}
		}
	}
}

/* pager
------------------------------------------------ */
.pager {
	&-1 {
		display: flex;
		justify-content: center;
		margin-top: 40px;
		@include device_pc {
			margin-top: 80px;
		}
		&-item {
			position: relative;
			margin: 0 2px;
			width: 34px;
			height: 34px;
			text-align: center;
			font-family: Arial, sans-serif;
			font-size: 16px;
			line-height: 34px;
			a,
			span {
				display: block;
				position: relative;
				width: 100%;
				height: 100%;
			}
			span {
				background: #0168b7;
				color: #fff;
			}
			&.active {
				a {
					pointer-events: none;
					background: #0168b7;
					color: #fff;
				}
			}
			&.prev,
			&.next {
				a {
					text-indent: -9999px;
					white-space: nowrap;
					overflow: hidden;
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						left: 50%;
						margin: -6px 0 0 -6px;
						width: 12px;
						height: 13px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
					}
				}
			}
			&.prev {
				a {
					&:after {
						background-image: url(../img/icon/pager1_prev.png);
					}
				}
			}
			&.next {
				a {
					&:after {
						background-image: url(../img/icon/pager1_next.png);
					}
				}
			}
		}
		.disabled {
			display: none;
		}
		.hide {
			display: none;
		}
	}
	&-single {
		margin-top: 25px;
		padding-top: 40px;
		border-top: solid #ccc 1px;
		@include device_pc {
			margin-top: 60px;
			padding-top: 60px;
		}
		&-post {
			display: flex;
			justify-content: center;
			@include device_sp {
				margin: 0 32px;
			}
			&--item {
				position: relative;
				margin: 0 4px;
				width: calc(50% - 4px);
				text-align: center;
				font-size: 14px;
				line-height: 1.2;
				&:first-child { margin-left: 0; }
				&:last-child { margin-right: 0; }
				@include device_pc {
					margin: 0 10px;
					width: 140px;
					font-size: 15px;
				}
				a {
					display: block;
					position: relative;
					padding: 12px 0;
					border: solid #0168b7 1px;
					color: #0168b7;
					@include device_pc {
						padding: 15px 0;
					}
					&:before,
					&:after {
						content: '';
						display: inline-block;
						width: 7px;
						height: 10px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
						vertical-align: top;
						@include device_sp {
							position: absolute;
							top: 16px;
						}
						@include device_pc {
							margin-top: 5px;
						}
					}
				}
				&.prev {
					a {
						&:before {
							background-image: url(../img/icon/pager_single_prev.png);
							@include device_sp {
								left: 10px;
							}
							@include device_pc {
								margin-right: 18px;
							}
						}
						&:after {
							display: none;
						}
					}
				}
				&.next {
					a {
						&:before {
							display: none;
						}
						&:after {
							background-image: url(../img/icon/pager_single_next.png);
							@include device_sp {
								right: 10px;
							}
							@include device_pc {
								margin-left: 18px;
							}
						}
					}
				}
			}
		}
		&-back {
			margin: 12px 32px 0;
			text-align: center;
			font-size: 14px;
			line-height: 1.2;
			@include device_pc {
				margin: 40px auto 0;
				width: 400px;
				font-size: 24px;
			}
			a {
				display: block;
				padding: 16px 0;
				background: #0168b7;
				border: solid #0168b7 1px;
				color: #fff;
				@include device_pc {
					padding: 25px 0;
				}
			}
		}
	}
}
