@charset 'utf-8';

@import '../variable';

/* table
------------------------------------------------ */
table {
	&.temperature {
		width: 100%;
		border-top: 1px solid #e5e5e5;
		border-left: 1px solid #e5e5e5;
		@include device_pc {
		}
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
		td {
			text-align: center;
			vertical-align: middle;
			line-height: 1.25em;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
	}
	&.test {
		width: 100%;
		border-top: 1px solid #e5e5e5;
		border-left: 1px solid #e5e5e5;
		@include device_pc {
		}
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
		td {
			text-align: center;
			vertical-align: middle;
			line-height: 1.45em;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
	}
	&.overcharge {
		width: 100%;
		border-top: 1px solid #e5e5e5;
		border-left: 1px solid #e5e5e5;
		@include device_pc {
		}
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
		td {
			text-align: center;
			vertical-align: middle;
			line-height: 1.45em;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
	}
	&.fall {
		width: 100%;
		border-top: 1px solid #e5e5e5;
		border-left: 1px solid #e5e5e5;
		@include device_pc {
		}
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
		td {
			text-align: center;
			vertical-align: middle;
			line-height: 1.45em;
			border-right: 1px solid #e5e5e5;
			border-bottom: 1px solid #e5e5e5;
		}
	}
	&.chemi01 {
		width: 100%;
		border-top: 1px solid #f1f1ee;
		border-left: 1px solid #f1f1ee;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			font-weight: normal;
			padding: 5px 7px 5px 10px;
			border-right: 1px solid #f1f1ee;
		}
		td {
			border-right: 1px solid #f1f1ee;
			border-bottom: 1px solid #f1f1ee;
			ul {
				margin: 10px 0 10px 10px;
			}
		}
	}
	&.acousmato {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			padding: 7px 0;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 7px 5px 3px 22px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.petroleum01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			font-size: 109%;
			padding: 5px 0 5px 23px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 9px 40px;
			line-height: 1.4em;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.petroleum02 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			font-size: 109%;
			padding: 5px 0 5px 23px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 7px 40px;
			line-height: 1.6em;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.plant01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			padding: 10px 0 10px 0;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 2px 5px 0px 25px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.plant02 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			font-size: 109%;
			padding: 15px 0 10px 23px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 0;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
			ul {
				margin: 14px 0 7px 30px;
				li {
					position: relative;
					line-height: 1.4em;
					padding: 0 0 0 20px;
					&:before {
						content: '\30FB';
						position: absolute;
						top: 0;
						left: 0;
					}
					span {
						display: block;
						text-indent: 1em;
					}
				}
			}
		}
	}
	&.plant03 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: center;
			vertical-align: middle;
			font-weight: normal;
			padding: 10px 0 10px 0;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			vertical-align: top;
			padding: 3px 0 0 18px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
			ul {
				li {
					position: relative;
					line-height: 1.9em;
					padding: 0 0 0 20px;
					&:before {
						content: '\30FB';
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	&.gas_turbine01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			font-size: 109%;
			padding: 5px 0 5px 23px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 0;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
			ol {
				margin: 14px 0 20px 18px;
				li {
					line-height: 1.4em;
					padding: 0 0 0 10px;
				}
			}
		}
	}

	&.analysis {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		.tableHeadTitle {
			text-align: center !important;
			padding: 7px 0 !important;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			padding: 7px 10px 7px 18px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			line-height: 1.6em;
			padding: 7px 5px 3px 15px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		.contentsTitle02 {
			font-size: 150%;
			color: #255791;
			border-left: 7px solid #3b499f;
			height: 30px;
			margin-bottom:20px;
			padding: 4px 0 0 14px;
		}
	}
	&.film {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-bottom: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-size: 109%;
			font-weight: bold;
			padding: 7px 10px 7px 18px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			text-align: center;
			padding: 10px 5px 7px 5px;
			width: 125px;
			border-right: 1px solid #e1e1db;
			.bgColorBlue {
				background: #aad0de !important;	
			}
			.bdB_dot{
				border-bottom: 1px dotted #e1e1db !important;
			}
			.bdG_dol {
				border-bottom: 1px solid #e1e1db;
			}
		}
	}
	&.case01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			width: 115px;
			padding: 6px 5px 6px 5px;
			border-right: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
			ul {
				margin: 11px 0 10px 17px;
				li {
					position: relative;
					line-height: 1.9em;
					padding: 0 0 0 20px;
					&:before {
						content: '\30FB';
						position: absolute;
						top: 0;
						left: 0;
					}
				}
			}
		}
	}
	&.frictio01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: top;
			font-weight: normal;
			width: 115px;
			padding: 6px 10px 6px 10px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			line-height: 1.4em;
			vertical-align: top;
			font-size: 92%;
			padding: 7px 5px 7px 7px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.tableInner01,
	&.tableInner02 {
		background: #fff;
		width: 100%;
		border-top: 3px solid #fff;
		border-left: 3px solid #fff;
		border-bottom: 3px solid #fff;
		border-right: 3px solid #fff;
		th,
		td {
			padding: 10px;
			border-bottom: 2px solid #fff;
		}
		td,
		td {
			font-size: 100%;
			text-align: center;
			vertical-align: middle;
			background: #f1f1f1;
			border: none;
			padding: 9px 0;
			border-right: 2px solid #fff;
			border-bottom: 2px solid #fff;
		}
	}
	.tableTitleC {
		text-align: center !important;
	}
	&.corrosionCase01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: top;
			font-size: 92%;
			font-weight: normal;
			width: 245px;
			padding: 10px 10px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 11px 5px 11px 22px;
			line-height: 1.4em;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.consulting {
		width: 100%;
		border-top: 1px solid #f2f2f2;
		border-left: 1px solid #f2f2f2;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfaf5;
			font-weight: normal;
			width: 118px;
			padding: 6px 10px;
			border-right: 1px solid #f2f2f2;
			border-bottom: 1px solid #f2f2f2;
		}
		td {
			padding: 6px 10px;
			border-right: 1px solid #f2f2f2;
			border-bottom: 1px solid #f2f2f2;
		}
	}

	&.corrosion01 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			width: 115px;
			padding: 0 5px 0 20px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		
		td {
			background: #fff;
			padding: 11px 5px 11px 22px;
			line-height: 1.4em;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.corrosion02 {
		width: 100%;
		border-top: 1px solid #e1e1db;
		border-left: 1px solid #e1e1db;
		th,
		td {
			padding: 10px;
		}
		th {
			background: #fbfbf4;
			text-align: left;
			vertical-align: middle;
			font-weight: normal;
			width: 115px;
			padding: 0 5px 0 20px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
		td {
			background: #fff;
			padding: 11px 5px 11px 25px;
			border-right: 1px solid #e1e1db;
			border-bottom: 1px solid #e1e1db;
		}
	}
	&.facilities {
		width: 100%;
		th {
			h3 {
				margin-top:25px;
			}
		}
		td {
			padding: 13px 0 10px 0;
			dl {
				margin-left: 20px;
			}
			dt {
				background: url(../../common/img/listIcom01.gif) no-repeat left 8px;
				font-size: 117%;
				padding: 0 0 0 20px;
			}
			dd {
				font-size: 92%;
				padding-bottom: 11px;
			}
		}
		.table_bdB {
			border-bottom:1px dotted #b2b2b2;
		}
	}
}

/* table */
.table {
	&_style {
		&1 {
			margin: 18px auto;
			border-collapse: collapse;
			border-spacing: 0;
			border-top: 1px solid #29639b;
			border-left: 1px solid #29639b;
			&:first-child { margin-top: 0; }
			@include device_pc {
				margin-top: 32px;
			}
			th,
			td {
				padding: 4px 6px;
				border-right: 1px solid #29639b;
				border-bottom: 1px solid #29639b;
				vertical-align: top;
			}
			.vAlignM {
				vertical-align: middle;
			}
			th {
				background-color: #ffea04;
				text-align: left;
			}
			td {
				p {
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.semTable {
	width: 100%;
	margin: 0 auto;
	border: 1px solid #333;
	@include device_pc {
		max-width: 250px;
	}
	tr,
	td,
	th {
		border: 1px solid #333;
	}
	td {
		text-align: right;
	}
	thead {
		text-align: center;
	}
}
