@charset 'utf-8';

@import '../variable';

/* top
------------------------------------------------ */
.top {
	/* visual */
	&-visual {
		position: relative;
		opacity: 0;
		&__frame {
			position: relative;
			width: 100%;
			overflow: hidden;
			z-index: 1;
		}
		&__move {
			position: relative;
			width: 100%;
		}
		&--item {
			float: left;
			max-width: 1700px;
			& > a {
				display: block;
				position: relative;
				width: 100%;
			}
			&--thumbnail {
				position: relative;
				width: 100%;
			}
			img {
				width: 100%;
				max-width: inherit;
			}
		}
		&--direct {
			position: absolute;
			top: 50%;
			margin-top: -30px;
			width: 60px;
			height: 60px;
			border-radius: 50%;
			background: #404040;
			text-indent: -9999px;
			white-space: nowrap;
			overflow: hidden;
			z-index: 2;
			&:after {
				content: '';
				position: absolute;
				top: 50%;
				margin-top: -15px;
				width: 15px;
				height: 29px;
				background-position: center center;
				background-repeat: no-repeat;
				background-size: 100%;
			}
			&--prev {
				left: 50%;
				margin-left: -880px;
				&:after {
					left: 20px;
					background-image: url(../img/icon/slide_prev1.png);
				}
			}
			&--next {
				right: 50%;
				margin-right: -880px;
				&:after {
					right: 20px;
					background-image: url(../img/icon/slide_next1.png);
				}
			}
		}
		@media screen and (max-width: 1760px) {
			&--direct {
				&--prev {
					left: 20px;
					margin-left: 0;
				}
				&--next {
					right: 20px;
					margin-right: 0;
				}
			}
		}
		@include device_sp {
			&--direct {
				display: none;
			}
		}
		&-announce {
			padding: 16px;
			background-color: #fff;
			border: 1px solid #2f528f;
			border-radius: 6px;
			font-size: 14px;
			@include device_sp {
				margin: 20px;
			}
			@include device_pc {
				position: absolute;
				bottom: 0;
				left: 36px;
				transform: translateY(50%);
				width: calc(100% - 72px);
				text-align: center;
				z-index: 2;
			}
		}
	}
	/* business */
	&-business {
		position: relative;
		padding: 90px 0 88px;
		&:before,
		&:after {
			content: '';
			position: absolute;
			left: 0;
			width: 100%;
			overflow: hidden;
			z-index: -1;
		}
		&:before {
			top: 0;
			height: 42.5925925926%;
			background: #e5f0f8;
		}
		&:after {
			bottom: 0;
			height: 57.4074074074%;
			background: #f8fbfd;
		}
		&__lists {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			flex-wrap: wrap;
			&--item {
				&:nth-of-type(1) { background: #0168b7; }
				&:nth-of-type(2) { background: #7cafd7; }
				&:nth-of-type(3) { background: #3284c4; }
				& > a {
					display: block;
					position: relative;
				}
			}
			&--photo {
			}
			&__info {
				position: relative;
				padding: 50px 28px 20px;
				min-height: 230px;
				text-align: center;
				color: #fff;
			}
			&--num {
				position: absolute;
				top: -.7em;
				left: 0;
				width: 100%;
				font-family: $fontMin;
				font-size: 80px;
				line-height: 1.2;
			}
			&--title {
				margin-bottom: 16px;
				font-size: 26px;
				font-weight: 400;
			}
			&--copy {
				margin-bottom: 1.6em;
				font-size: 16px;
			}
			&--text {
				text-align: left;
				font-size: 16px;
			}
		}
		@include device_pc {
			&__lists {
				&--item {
					width: 30%;
					&:nth-of-type(2n) {
						margin-top: 60px;
					}
				}
			}
		}
		@include device_sp {
			padding: 36px 25px 48px;
			&__lists {
				display: block;
				&--item {
					margin-top: 24px;
					&:first-child { margin-top: 0; }
				}
				&--photo {
				}
				&__info {
					padding: 38px 24px 26px;
					min-height: inherit;
				}
				&--num {
					font-size: 70px;
				}
				&--title {
					margin-bottom: 18px;
					font-size: 22.75px;
				}
				&--copy {
					margin-bottom: 1.4em;
					font-size: 14px;
				}
				&--text {
					font-size: 14px;
				}
			}
		}
	}
	/* pickup */
	&-pickup {
		position: relative;
		padding: 90px 0 96px;
		.title--2 {
			margin-bottom: 54px;
		}
		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100%;
			height: 59.5369349504%;
			background: #cce0f0;
			overflow: hidden;
			z-index: -1;
		}
		&__slide {
			position: relative;
			opacity: 0;
			&__frame {
				position: relative;
				width: 100%;
				padding-top: 36px;
				overflow: hidden;
			}
			&__move {
				position: relative;
			}
			&--item {
				position: relative;
				float: left;
				width: 338px;
				.frame {
					position: relative;
					transition: ease .3s;
					margin: 100px auto 0;
					width: 100%;
					background: #0168b7;
					box-shadow: 0 5px 7px rgba(11,22,43,.35);
					&:before {
						content: '';
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: rgba(255,255,255,.5);
						z-index: 2;
					}
				}
				&.active {
					width: 516px;
					.frame {
						margin-top: 0;
						transform: scale(.8,.8);
					}
				}
				&.expand {
					.frame {
						transform: scale(1,1);
						&:before {
							display: none;
						}
					}
					.top-pickup__slide {
						&--new {
							top: -36px;
							width: 150px;
							height: 36px;
							font-size: 19.8px;
							line-height: 36px;
							&:before {
								display: none;
							}
							&:after {
								right: -36px;
								border-right-width: 36px;
								border-bottom-width: 36px;
							}
						}
						&__info {
							padding: 42px 48px 36px;
						}
						&--title {
							font-size: 30px;
						}
						&__content {
							margin-top: 30px;
							font-size: 18px;
							a {
								color: #fff;
							}
							ul {
								margin-top: 32px;
								li {
									font-size: 18px;
								}
							}
						}
						&--text {
							margin-top: 30px;
							font-size: 18px;
						}
						&__links {
							margin-top: 32px;
							&--item {
								font-size: 18px;
							}
						}
						&--btn {
							margin-top: 38px;
							font-size: 22px;
							a {
								padding: 12px 0;
								min-width: 286px;
								&:after {
									right: 26px;
									margin-top: -8px;
									width: 9px;
									height: 16px;
								}
							}
						}
					}
				}
			}
			&--new {
				position: absolute;
				top: -22px;
				left: 0;
				width: 92px;
				height: 22px;
				background: #aa111d;
				text-align: center;
				font-size: 12.96px;
				line-height: 22px;
				color: #fff;
				&:before,
				&:after {
					content: '';
					position: absolute;
					top: 0;
				}
				&:before {
					left: 0;
					width: calc(100% + 22px);
					height: 100%;
					background: rgba(255,255,255,.5);
					z-index: 2;
				}
				&:after {
					right: -22px;
					width: 0;
					height: 0;
					border-right: solid transparent 22px;
					border-bottom: solid #aa111d 22px;
					overflow: hidden;
				}
			}
			&--mdeia {
				position: relative;
				padding-bottom: 55.6201550388%;
				width: 100%;
				height: 0;
				overflow: hidden;
				iframe {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
				img {
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%,-50%);
					width: 100%;
					max-width: inherit;
				}
			}
			&__info {
				padding: 26px 32px 22px;
				color: #fff;
			}
			&--title {
				text-align: center;
				font-size: 20.16px;
				font-weight: 700;
			}
			&__content {
				margin-top: 20px;
				font-size: 12.96px;
				a {
					color: #fff;
				}
				ul {
					margin-top: 20px;
					li {
						position: relative;
						padding-left: 1em;
						font-size: 12.96px;
						&:after {
							content: '・';
							position: absolute;
							top: 0;
							left: 0;
						}
						a {
							text-decoration: underline;
							color: #fff;
							.device--click & {
								&:hover {
									text-decoration: none;
								}
							}
						}
					}
				}
			}
			&--text {
				margin-top: 20px;
				font-size: 12.96px;
			}
			&__links {
				margin-top: 20px;
				&--item {
					position: relative;
					padding-left: 1em;
					font-size: 12.96px;
					color: #fff;
					&:after {
						content: '・';
						position: absolute;
						top: 0;
						left: 0;
					}
					a {
						text-decoration: underline;
						color: #fff;
						.device--click & {
							&:hover {
								text-decoration: none;
							}
						}
					}
				}
			}
			&--btn {
				margin-top: 20px;
				text-align: center;
				font-size: 14.98px;
				line-height: 1.2;
				a {
					display: inline-block;
					position: relative;
					padding: 12px 0;
					min-width: 188px;
					background: #fff;
					border-radius: 24px;
					vertical-align: top;
					color: #0168b7;
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 18px;
						margin-top: -5px;
						width: 7px;
						height: 10px;
						background: url(../img/icon/arrow4.png) no-repeat center center;
						background-size: 100%;
					}
				}
			}
			&--direct {
				position: absolute;
				top: 50%;
				margin-top: -30px;
				width: 60px;
				height: 60px;
				border-radius: 50%;
				background: #0168b7;
				text-indent: -9999px;
				white-space: nowrap;
				overflow: hidden;
				&:after {
					content: '';
					position: absolute;
					top: 50%;
					margin-top: -15px;
					width: 15px;
					height: 29px;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: 100%;
				}
				&--prev {
					left: 50%;
					margin-left: -660px;
					&:after {
						left: 20px;
						background-image: url(../img/icon/slide_prev1.png);
					}
				}
				&--next {
					right: 50%;
					margin-right: -660px;
					&:after {
						right: 20px;
						background-image: url(../img/icon/slide_next1.png);
					}
				}
			}
		}
		@media screen and (max-width: 1320px) {
			&__slide {
				&--direct {
					&--prev {
						left: 20px;
						margin-left: 0;
					}
					&--next {
						right: 20px;
						margin-right: 0;
					}
				}
			}
		}
		@include device_sp {
			padding: 40px 0 48px;
			.title--2 {
				margin-bottom: 50px;
			}
			&:after {
				height: 68.8073394495%;
			}
			&__slide {
				&--item {
					width: 194px;
					.frame {
						margin-top: 55px;
					}
					&.active {
						width: 288px;
					}
					&.expand {
						.top-pickup__slide {
							&--new {
								top: -20px;
								width: 84px;
								height: 20px;
								font-size: 11.065px;
								line-height: 20px;
								&:after {
									right: -22px;
									border-right-width: 22px;
									border-bottom-width: 20px;
								}
							}
							&__info {
								padding: 22px 28px 20px;
							}
							&--title {
								font-size: 17px;
							}
							&__content {
								margin-top: 15px;
								font-size: 14px;
								ul {
									margin-top: 16px;
									li {
										font-size: 14px;
									}
								}
							}
							&--text {
								margin-top: 15px;
								font-size: 14px;
							}
							&__links {
								margin-top: 16px;
								&--item {
									font-size: 14px;
								}
							}
							&--btn {
								margin-top: 15px;
								font-size: 14px;
								a {
									padding: 14px 0;
									min-width: 200px;
									&:after {
										right: 18px;
										margin-top: -6px;
										width: 6px;
										height: 12px;
									}
								}
							}
						}
					}
				}
				&--new {
					top: -13px;
					width: 52px;
					height: 13px;
					font-size: 7.45px;
					line-height: 13px;
					&:before {
						width: calc(100% + 13px);
					}
					&:after {
						right: -13px;
						border-right-width: 13px;
						border-bottom-width: 13px;
					}
				}
				&__info {
					padding: 15px 18px 40px;
				}
				&--title {
					font-size: 11.09px
				}
				&--text {
					margin-top: 12px;
					font-size: 7.45px;
				}
				&__links {
					margin-top: 12px;
					&--item {
						font-size: 7.45px;
					}
				}
				&--btn {
					margin-top: 12px;
					font-size: 8.61px;
					a {
						padding: 4px 0;
						min-width: 108px;
						&:after {
							right: 10px;
							margin-top: -4px;
							width: 4px;
							height: 7px;
						}
					}
				}
				&--direct {
					display: none;
				}
			}
		}
	}
	/* news */
	&-news {
		position: relative;
		.wrap {
			display: flex;
			align-items: center;
			@media screen and (max-width: 940px) and (min-width: $mediaPC) {
				display: block;
				padding: 0;
			}
		}
		&__head {
			width: 33.3333333333%;
			min-width: 330px;
			@media screen and (max-width: 940px) and (min-width: $mediaPC) {
				padding: 60px 0 30px;
				width: auto;
				background: url(../img/top/news_bg.jpg) no-repeat center center;
				background-size: cover;
			}
			.title--3 {
				margin-bottom: 60px;
			}
		}
		&__cat {
			&--item {
				position: relative;
				margin-top: 10px;
				text-align: center;
				font-size: 18px;
				line-height: 1.2;
				&:first-child { margin-top: 0; }
				a {
					display: inline-block;
					position: relative;
					min-width: 330px;
					padding: 10px 6px;
					border-radius: 30px;
					vertical-align: top;
					&:after {
						content: '';
						position: absolute;
						top: 50%;
						right: 14px;
						margin-top: -6px;
						width: 8px;
						height: 13px;
						background-position: center center;
						background-repeat: no-repeat;
						background-size: 100%;
					}
				}
			}
			&--news {
				a {
					background: #404040;
					color: #fff;
					&:after {
						background-image: url(../img/icon/arrow3.png);
					}
				}
			}
			&--exhibition {
				a {
					background: #f6f033;
					&:after {
						background-image: url(../img/icon/arrow5.png);
					}
				}
			}
		}
		&__content {
			padding: 85px 0 85px 90px;
			width: 66.6666666667%;
			@media screen and (max-width: 940px) and (min-width: $mediaPC) {
				width: auto;
			}
		}
		@media screen and (max-width: 1200px) {
			&:before {
				width: 33.3333333333%;
			}
			&:after {
				width: 66.6666666667%;
			}
		}
		@include device_pc {
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
				z-index: -1;
			}
			&:before {
				left: 0;
				width: calc(((100% - 1200px) / 2) + 400px);
				background: url(../img/top/news_bg.jpg) no-repeat center center;
				background-size: cover;
			}
			&:after {
				right: 0;
				width: calc(((100% - 1200px) / 2) + 800px);
				background: #fff;
				background-size: cover;
			}
			&__lists {
				&--item {
					margin-left: 5%;
					width: 25.25%;
					&:first-child { margin-left: 0; }
				}
			}
		}
		@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
			&:before {
				width: calc(33.3333333333% + 40px);
			}
			&:after {
				width: calc(66.6666666667% - 40px);
			}
		}
		@media screen and (max-width: 940px) and (min-width: $mediaPC) {
			&:before,
			&:after {
				display: none;
			}
		}
		@include device_sp {
			.wrap {
				display: block;
			}
			&__head {
				padding: 60px 0 30px;
				width: auto;
				background: url(../img/top/news_bg-sp.jpg) no-repeat center center;
				background-size: cover;
				.title--3 {
					margin-bottom: 32px;
				}
			}
			&__cat {
				&--item {
					font-size: 14px;
					a {
						min-width: 200px;
						padding: 14px 34px;
						&:after {
							right: 18px;
							margin-top: -5px;
							width: 6px;
							height: 11px;
						}
					}
				}
			}
			&__content {
				padding: 10px 0 46px;
				width: auto;
				background: #fff;
			}
		}
	}
	/* search */
	&-search {
		position: relative;
		padding: 60px 0 48px;
		background: url(../img/top/search_bg.jpg) no-repeat center center;
		background-size: cover;
		@include device_pc {
			padding: 124px 0 90px;
		}
	}
	/* flow */
	&-flow {
		position: relative;
		padding: 90px 0;
		background: #cce0f0;
		.title--2 {
			margin-bottom: 82px;
		}
		&__lists {
			display: flex;
			&--item {
				position: relative;
				padding-bottom: 30px;
				width: 20%;
				text-align: center;
				color: #fff;
				&:after {
					content: '';
					position: absolute;
					right: -12px;
					bottom: 62px;
					width: 0;
					height: 0;
					border: solid transparent 12px;
					border-right: none;
					overflow: hidden;
					z-index: 2;
				}
				&:nth-of-type(1) {
					background: #0168b7;
					&:after { border-left-color: #0168b7; }
					.top-flow__lists--icon { border-color: #0168b7; }
				}
				&:nth-of-type(2) {
					background: #3284c4;
					&:after { border-left-color: #3284c4; }
					.top-flow__lists--icon { border-color: #3284c4; }
				}
				&:nth-of-type(3) {
					background: #7cafd7;
					&:after { border-left-color: #7cafd7; }
					.top-flow__lists--icon { border-color: #7cafd7; }
				}
				&:nth-of-type(4) {
					background: #02c6ba;
					&:after { border-left-color: #02c6ba; }
					.top-flow__lists--icon { border-color: #02c6ba; }
				}
				&:nth-of-type(5) {
					background: #fc9604;
					&:after { display: none; }
					.top-flow__lists--icon { border-color: #fc9604; }
				}
			}
			&--icon {
				display: block;
				position: relative;
				margin: -36px auto 0;
				padding-bottom: 58.3333333333%;
				width: 58.3333333333%;
				height: 0;
				border-radius: 50%;
				background: #fff;
				border-style: solid;
				border-width: 1px;
				overflow: hidden;
				img {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
				}
			}
			&--text {
				margin-top: 12px;
				min-height: 78px;
				font-family: $fontMin;
				font-size: 24px;
				line-height: 1.4;
			}
			&--num {
				font-family: $fontMin;
				font-size: 80px;
				line-height: 1.2;
			}
		}
		@include device_sp {
			padding: 40px 0 32px;
			.title--2 {
				margin-bottom: 25px;
			}
			&__lists {
				display: block;
				margin: 0 45px;
				&--item {
					display: flex;
					align-items: center;
					padding: 10px 0;
					width: auto;
					text-align: center;
					color: #fff;
					&:after {
						right: 36px;
						bottom: -18px;
						transform: rotate(90deg);
					}
				}
				&--icon {
					margin: 0 0 0 -25px;
					padding-bottom: 27.4193548387%;
					width: 27.4193548387%;
				}
				&--text {
					margin-top: 0;
					width: 50.6451612903%;
					min-height: inherit;
					text-align: center;
					font-size: 20px;
				}
				&--num {
					width: 21.935483871%;
					font-size: 40px;
				}
			}
		}
	}
	/* techlibrary */
	&-techlibrary {
		position: relative;
		.wrap {
			display: flex;
			align-items: center;
		}
		&__top {
			padding: 20px 0;
			width: 33.3333333333%;
		}
		&--title {
			text-align: center;
			font-family: $fontMin;
			font-weight: 700;
			line-height: 1.2;
			.normal,
			.min {
				display: block;
			}
			.normal {
				font-size: 60px;
			}
			.min {
				font-size: 50px;
			}
		}
		.more {
			&--1,
			&--2 {
				max-width: 248px;
				font-size: 18px;
				a {
					padding: 10px;
					&:after {
						right: 16px;
						margin-top: -6px;
						width: 8px;
						height: 13px;
					}
				}
			}
			&--1 {
				margin-top: 34px;
			}
			&--2 {
				margin-top: 16px;
			}
		}
		&__lists {
			display: flex;
			justify-content: flex-end;
			padding: 90px 0;
			width: 66.6666666667%;
			&--item {
				box-shadow: 5px 5px 10px rgba(116,116,116,.2);
			}
		}
		@media screen and (max-width: 1200px) {
			&:before {
				width: 33.3333333333%;
			}
			&:after {
				width: 66.6666666667%;
			}
		}
		@include device_pc {
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
				z-index: -1;
			}
			&:before {
				left: 0;
				width: calc(((100% - 1200px) / 2) + 400px);
				background: #e5f0f8;
			}
			&:after {
				right: 0;
				width: calc(((100% - 1200px) / 2) + 800px);
				background: url(../img/top/techlibrary_bg.jpg) no-repeat center center;
				background-size: cover;
			}
			&__lists {
				&--item {
					margin-left: 5%;
					width: 25.25%;
					&:first-child { margin-left: 0; }
				}
			}
		}
		@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
			&:before {
				width: calc(33.3333333333% + 40px);
			}
			&:after {
				width: calc(66.6666666667% - 40px);
			}
		}
		@include device_sp {
			.wrap {
				display: block;
			}
			&__top {
				padding: 36px 20px 33px;
				width: auto;
				background: #e5f0f8;
			}
			&--title {
				.normal {
					font-size: 36px;
				}
				.min {
					font-size: 31px;
				}
			}
			.more--1 {
				margin-top: 22px;
				font-size: 14px;
				a {
					padding: 14px 10px;
					min-width: 200px;
					&:after {
						right: 22px;
						margin-top: -5px;
						width: 6px;
						height: 11px;
					}
				}
			}
			&__lists {
				padding: 46px 20px;
				width: auto;
				background: url(../img/top/techlibrary_bg.jpg) no-repeat center center;
				background-size: cover;
				&--item {
					margin-left: 7.3239436619%;
					width: 28.4507042254%;
					&:first-child { margin-left: 0; }
				}
			}
		}
	}
	/* know */
	&-know {
		position: relative;
		padding: 92px 0;
		background: url(../img/top/know_bg.jpg) no-repeat center center;
		background-size: cover;
		&__lists {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			align-items: flex-start;
			&--item {
				position: relative;
				padding: 34px 0 14px;
				text-align: center;
				color: #fff;
				&:nth-of-type(1) { background: #0168b7; }
				&:nth-of-type(2) { background: #7cafd7; }
				&:nth-of-type(3) { background: #3077ae; }
				&:nth-of-type(4) { background: #3284c4; }
			}
			&--icon {
				display: block;
				margin: 0 auto;
				width: 37.7777777778%;
			}
			&--title {
				margin-top: 10px;
				font-size: 28px;
				font-weight: 400;
			}
			&--note {
				display: inline-block;
				position: relative;
				margin-top: 22px;
				padding: 2px 4px 4px;
				width: 180px;
				background: #333;
				border-radius: 15px;
				vertical-align: top;
				font-family: $fontMin;
				font-size: 20px;
				line-height: 1.2;
			}
			&--count {
				position: relative;
				margin-top: -4px;
				text-align: center;
				font-family: $fontMin;
				line-height: 1.2;
				.num,
				.unit {
					display: inline-block;
					vertical-align: baseline;
				}
				.num {
					font-size: 74px;
				}
				.unit {
					font-size: 30px;
				}
			}
		}
		@include device_pc {
			&__lists {
				&--item {
					width: 22.5%;
					&:nth-of-type(1) { margin-top: 12px; }
					&:nth-of-type(2) { margin-top: 72px; }
					&:nth-of-type(4) { margin-top: 72px; }
				}
			}
		}
		@media screen and (max-width: 1000px) and (min-width: $mediaPC) {
			&__lists {
				&--title {
					margin-top: 1vw;
					font-size: 2.8vw;
				}
				&--note {
					margin-top: 2.2vw;
					padding: 0.2vw 0.4vw 0.4vw;
					width: 18vw;
					border-radius: 1.5vw;
					font-size: 2vw;
				}
				&--count {
					margin-top: -0.4vw;
					.num {
						font-size: 7.4vw;
					}
					.unit {
						font-size: 3vw;
					}
				}
			}
		}
		@include device_sp {
			padding: 92px 0;
			&__lists {
				display: block;
				margin: 0 auto;
				width: calc(100% - 95px);
				&--item {
					margin-top: 24px;
					padding: 18px 0 6px;
					&:first-child { margin-top: 0; }
				}
				&--icon {
					width: 31.0714285714%;
				}
				&--title {
					margin-top: 6px;
					font-size: 28px;
				}
				&--note {
					margin-top: 16px;
					width: 150px;
					font-size: 15px;
				}
				&--count {
					margin-top: -10px;
				}
			}
		}
	}
	/* recruit */
	&-recruit {
		padding-bottom: 104px;
		&__head {
			position: relative;
			padding: 118px 0 45px;
			background: url(../img/top/recruit_title_bg.jpg) no-repeat center center;
			background-size: cover;
			&--lead {
				text-align: center;
				.min {
					font-size: 20px;
				}
				.normal {
					font-size: 28px;
				}
			}
		}
		&__content {
			margin-top: 48px;
		}
		&__info {
			padding: 20px 70px;
			height: 175px;
			border: solid #ccc 1px;
			overflow: auto;
			&--item {
				position: relative;
				border-bottom: solid #ccc 1px;
				& > a {
					display: flex;
					position: relative;
					padding: 16px 0;
				}
			}
			&--date {
				width: 120px;
				font-size: 18px;
				font-weight: 700;
				color: #0068b7;
			}
			&--title {
				width: calc(100% - 120px);
				font-size: 16px;
				font-weight: 400;
			}
		}
		&__bnr {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			margin-top: 60px;
			&--item {
				& > a {
					display: block;
					position: relative;
				}
			}
		}
		@include device_pc {
			&__bnr {
				&--item {
					margin: 5% 2.5% 0 0;
					width: 45%;
					&:nth-of-type(-n+2) { margin-top: 0; }
				}
			}
		}
		@include device_sp {
			padding-bottom: 60px;
			&__head {
				padding: 64px 20px 32px;
				&--lead {
					.min {
						font-size: 14px;
					}
					.normal {
						font-size: 18px;
						font-weight: 700;
					}
				}
			}
			&__content {
				margin: 25px 20px 0;
			}
			&__info {
				padding: 20px 14px;
				height: 275px;
				&--item {
					& > a {
						display: block;
						padding: 20px 0;
					}
				}
				&--date {
					width: auto;
					font-size: 15px;
				}
				&--title {
					margin-top: 14px;
					width: auto;
					font-size: 14px;
				}
			}
			&__bnr {
				margin: 28px 10px 0;
				&--item {
					margin-top: 20px;
					&:first-child { margin-top: 0; }
				}
			}
		}
	}
	/* blog */
	&-blog {
		position: relative;
		padding-bottom: 60px;
		z-index: 1;
		@include device_pc {
			padding-bottom: 105px;
		}
		&__head {
			position: relative;
			padding: 50px 0 30px;
			@include device_pc {
				padding: 100px 0 60px;
			}
			&:after {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: calc(100% + 24.8vw);
				background: #E5F0F8;
				z-index: -1;
				@include device_pc {
					height: calc(100% + 94px);
				}
				@media screen and (max-width: 1200px) and (min-width: $mediaPC) {
					height: calc(100% + 7.83333333333vw);
				}
			}
		}
		&__content {
			position: relative;
		}
		.lists-blog {
			@include device_sp {
				margin: 0 auto;
				width: calc(100% - 104px);
			}
			&-item {
				@include device_sp {
					margin-top: 30px;
					&:first-child { margin-top: 0; }
				}
				@include device_pc {
					margin: 30px 3.33333333333% 0 0;
					width: 22.5%;
					&:nth-of-type(-n+4) { margin-top: 0; }
					&:nth-of-type(4n) { margin-right: 0; }
				}
			}
		}
	}
}
