@charset 'utf-8';

@import '../variable';

/* footer
------------------------------------------------ */
#footer {
	&-top {
		position: relative;
		padding: 100px 0 20px;
		background: #f8fbfd;
		.wrap {
			display: flex;
			flex-wrap: wrap;
		}
		@include device_sp {
			padding: 40px 0 25px;
			.wrap {
				display: block;
			}
		}
	}
	.style-sitemap {
		@include device_pc {
			display: flex;
			justify-content: space-between;
			width: calc(100% - 288px);
			&__line {
				width: 20.8333333333%;
			}
		}
		@media screen and (max-width: 1000px) {
			width: 100%;
			&__line {
				width: 24%;
			}
		}
		@include device_sp {
			display: block;
			width: auto;
			margin-top: 10px;
			&__line {
				width: auto;
			}
		}
	}
	.style-links--1 {
		@include device_pc {
			margin-top: 35px;
			width: 100%;
		}
	}
}

.footer {
	&--pagetop {
		position: fixed;
		bottom: 16px;
		right: 20px;
		width: 72px;
		height: 72px;
		z-index: 8;
		.cookiePolicyBox-none & {
			margin-bottom: 0 !important;
		}
		a {
			display: block;
			position: relative;
			width: 100%;
			height: 100%;
			background: #404040;
			border-radius: 50%;
			text-indent: -9999px;
			white-space: nowrap;
			overflow: hidden;
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 28px;
				width: 18px;
				height: 1px;
				background: #fff;
			}
			&:before {
				left: 50%;
				transform-origin: left top;
				transform: rotate(45deg);
			}
			&:after {
				right: 50%;
				transform-origin: right top;
				transform: rotate(-45deg);
			}
		}
		@include device_sp {
			bottom: 20px;
			right: 10px;
			width: 50px;
			height: 50px;
			a {
				&:before,
				&:after {
					top: 18px;
					width: 12px;
				}
			}
		}
	}
	&--logo {
		margin: 0 92px 40px 0;
		width: 196px;
		font-size: 10px;
		line-height: 1.2;
		a {
			display: block;
			width: 100%;
		}
		&--kobelco {
			margin-top: 18px;
			width: 100%;
			font-size: 10px;
			line-height: 1.2;
			a {
				display: block;
				margin: 0 auto;
				width: 80px;
			}
		}
		@include device_sp {
			margin: 0 20px;
			width: 195px;
			&--kobelco {
				margin-top: 54px;
				width: auto;
				a {
					width: 88px;
				}
			}
		}
	}
	&--copyright {
		padding: 12px 0;
		background: #404040;
		text-align: center;
		font-size: 12px;
		font-weight: 700;
		line-height: 1.2;
		color: #fff;
		small {
			font-size: inherit;
		}
		@include device_sp {
			font-weight: 400;
		}
	}
	&__btns {
		display: none;
		position: fixed;
		top: 0;
		right: 0;
		width: 190px;
		z-index: 6;
		&--item {
			height: 70px;
			font-size: 20px;
			font-weight: 700;
			line-height: 70px;
			a {
				display: block;
				color: #fff;
				&:before {
					content: '';
					display: inline-block;
					background-position: center center;
					background-repeat: no-repeat;
					background-size: cover;
					vertical-align: top;
				}
			}
			&:first-child {
				a {
					border-radius: 15px 0 0 0;
				}
			}
			&:last-child {
				a {
					border-radius: 0 0 0 15px;
				}
			}
		}
		&--contact {
			a {
				background: #0168b7;
				&:before {
					margin: 25px 10px 0 20px;
					width: 26px;
					height: 20px;
					background-image: url(../img/icon/mail1.png);
				}
			}
		}
		@include device_sp {
			bottom: 82px;
			width: 80px;
			&--item {
				height: 52px;
				text-align: center;
				font-size: 10px;
				line-height: 1.2;
				a {
					height: 100%;
					&:before {
						display: block;
						margin: 0 auto;
					}
				}
				&:first-child {
					a {
						border-radius: 8px 0 0 0;
					}
				}
				&:last-child {
					a {
						border-radius: 0 0 0 8px;
					}
				}
			}
			&--contact {
				a {
					padding-top: 10px;
					&:before {
						margin-bottom: 8px;
						width: 20px;
						height: 16px;
					}
				}
			}
		}
	}
}

/* cookie policy
------------------------------------------------ */
.cookie-policy {
	&-box {
		position: fixed;
		bottom: 0;
		left: 0;
		max-width: 100%;
		background: rgb(237, 242, 240);
		padding: 8px 18px;
		text-align: left;
		font-size: 14px;
		color: rgb(17, 17, 17);
		z-index: 2147483647;
		&:after {
			content: '';
			display: table;
			clear: both;
		}
	}
	&-text {
		float: left;
		padding: 5px 0;
		width: calc(100% - 130px);
	}
	&-button {
		display: block;
		float: right;
		margin-left: 5px;
		padding: 5px 8px;
		width: 120px;
		min-width: 100px;
		background: rgb(0, 0, 153);
		border-radius: 5px;
		text-align: center;
		color: rgb(241, 241, 241);
		cursor: pointer;
	}
	@include device_sp {
		&-box {
			padding: 6px 10px;
			font-size: 10px;
		}
		&-text {
			float: none;
			width: auto;
			padding: 0;
		}
		&-button {
			float: none;
			margin: 10px 0 0 auto;
		}
	}
}
